import React from "react";

import {
  Typography,
  Grid,
} from "@material-ui/core";
import AppHeader from "../../../components/src/AppHeader.web"
import Footer from "../../../components/src/AppFooter.web"

import EventRegistrationController, {
  Props,
  configJSON
} from "./EventRegistrationController";
import EditEvent from "./EditEvent.web";

export default class EventRegistration extends EventRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  
  }

  // Customizable Area handleSubmitProject
  async componentDidMount() {
  }
  // Customizable Area End
  
  render() {
    const { navigation } = this.props;
    return (
      <>
      {/* Customizable Area Start */}
        <AppHeader
            // @ts-ignore
            toggleLoginModal={() => navigation.navigator('/')}
            toggleSignupModal={() => navigation.navigator('/')}
        />
        <EditEvent />
        <Footer />
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const wenStyles = {
}
// Customizable Area End