import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {toast} from "react-toastify";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  errorMessage: string;
  successMessage: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  fileData:any;
  roleData:any;
  userRole: any;
  accountData: any;
  coverImage: any;
  profileImage: any;
  filmographyData:any;
  selectedPhotos: any;
  loader: boolean;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  addLinksApiCallProject: string = "";
  allLinkSubmitApiCall: string = "";
  addFileAttachApiCallProject: string = "";
  getRoleDataAPICall: string = '';
  getAccountDetailsAPICall: string = '';
  getFilmographyDataAPICall: string = '';
  ProfilecoverphotoApiCall: string = '';
  ProfileDragDropApiCall : string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMessage:"",
      successMessage:"",
      errorMsg: "",
      token: "",
      loading: false,
      fileData: null,
      roleData:null,
      userRole: '',
      accountData: null,
      coverImage: "",
      profileImage: '',
      filmographyData:null,
      selectedPhotos: null,
      loader: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        this.getDashboardData();
        this.getAccountData();
        this.getFilmographyData();
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (apiRequestCallId === this.getFilmographyDataAPICall) {
        console.log("response json===>", responseJson)
        this.setState({
          filmographyData: responseJson
        })
      }
      if (apiRequestCallId === this.ProfilecoverphotoApiCall) {
        if (responseJson != null && responseJson.error === undefined) {
          toast.success("Profile updated successfully.")
          this.getAccountData();
        } else {
          toast.error(responseJson.error)
          this.parseApiErrorResponse(responseJson);
        }
      }
      if (apiRequestCallId === this.allLinkSubmitApiCall) {
        if (responseJson != null && responseJson.error === undefined) {
          // this.showAlert("Congratulations", "your News has been saved!");
          toast.success("Congratulations, Your News has been saved!")
          this.getAccountData();
        } else {
          // this.showAlert("Error", responseJson.error);
          toast.error(responseJson.error)
          this.parseApiErrorResponse(responseJson);
        }
      }
      if (apiRequestCallId === this.addFileAttachApiCallProject) {
        if (responseJson != null && responseJson.error === undefined) {
          // this.showAlert("Congratulations", "Your Files & attachment has been saved!");
          toast.success("Congratulations, Your Files & attachment has been saved!")
          this.getAccountData();
        } else {
          // this.showAlert("Error", responseJson.error);
          toast.error(responseJson.error)
          this.parseApiErrorResponse(responseJson);
        }
      }
      if (apiRequestCallId === this.addLinksApiCallProject) {
        if (responseJson != null && responseJson.error === undefined) {
          // this.showAlert("Congratulations", "your Links has been saved!");
          toast.success("Congratulations, Your Links has been saved!")
          this.getAccountData();
        } else {
          // this.showAlert("Error", responseJson.error);
          toast.error(responseJson.error)
          this.parseApiErrorResponse(responseJson);
        }
      }

      if (apiRequestCallId === this.getRoleDataAPICall) {
        this.setState({
          roleData: responseJson.data
        })
      }
      if(responseJson && responseJson.meta?.message === "Photos deleted successfully"){
        toast.success("Account Photo Deleted successfully.")
        this.getAccountData()
        this.setState({loader: false})
      }
      if (apiRequestCallId === this.getAccountDetailsAPICall) {
        this.setState({
          accountData: responseJson.data.attributes,
          coverImage: responseJson.data.attributes.cover_pic,
          profileImage: responseJson.data.attributes.profile_pic,
          loader: false
        })
      }
      if (responseJson && !responseJson.errors && responseJson.data) {
        if (responseJson.data.length === 0) {
          this.setState({
            errorMsg: "Data Not Found",
            loading: false
          });
        } else {
          this.setState({
            dashboardData: responseJson.data,
            errorMsg: "",
            loading: false
          });
        }
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if (errorReponse === undefined) {
          this.setState({
            errorMsg: "Something went wrong",
            loading: false
          });
        } else {
          this.setState({
            errorMsg: errorReponse,
            loading: false
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSubmitLinks= (links:any) => {
    var formData = new FormData();
    formData.append('profile_links[title]', links.title);
    formData.append("profile_links[url]", links.url);

    this.LinksHandler(formData)
  }

  handleSubmitNews = (newsreview:any) => {
    var formData = new FormData();
    formData.append('profile_news[title]', newsreview.title);
    formData.append("profile_news[publication]",newsreview.publication);
    formData.append("profile_news[url]",newsreview.url);
    this.NewsHandler(formData)
  }

  handleFileAttachLinks= (fileattach:any) => {
    let formData = new FormData();
    formData.append('profile_attachments[name]', fileattach.name);
    formData.append("profile_attachments[attachment]", fileattach.attachment);
  
    this.FilesAttachHandler(formData)
  }

  handleProfilePic = (profilepic:any, coverpic:any) => {
    var formData = new FormData();
    // if (profilepic != ""){
      console.log("profile picture API",profilepic)
      formData.append('account[profile_pic]', profilepic.profile_pic)
    // }
    // if(coverpic != ""){
    //   console.log("COVER picture API", coverpic.cover_pic)
    //   formData.append('account[cover_pic]', coverpic.cover_pic)
    // }
    console.log("value ====>>", profilepic.profile_pic)
    this.SetProfileandCoverPhoto(formData)

    console.log(formData)
    // this.showAlert("Congratulations", "your profile was updated!");
  }

  handleCoverPic = (coverpic:any) => {
    var formData = new FormData();
    // if (profilepic != ""){
      console.log("profile picture API",coverpic)
      formData.append('account[cover_pic]', coverpic.cover_pic)
    // }
    // if(coverpic != ""){
    //   console.log("COVER picture API", coverpic.cover_pic)
    //   formData.append('account[cover_pic]', coverpic.cover_pic)
    // }
    console.log("value ====>>", coverpic.cover_pic)
    this.SetCoverPhoto(formData)

    console.log(formData)
    // this.showAlert("Congratulations", "your profile was updated!");
  }

  handleDragImg = (selectedFile:any) => {
    var formData = new FormData();
    // if (profilepic != ""){
      console.log("DRAG API==>",selectedFile)
      formData.append('account[account_photo]', selectedFile.account_photo)
    // }
    // if(coverpic != ""){
    //   console.log("COVER picture API", coverpic.cover_pic)
    //   formData.append('account[cover_pic]', coverpic.cover_pic)
    // }
    console.log("API DRAG DROP IMG ====>>", selectedFile.account_photo)
    this.SetDragDropImg(formData)
    console.log(formData)
    // this.showAlert("Congratulations", "your profile was updated!");
  }



  //===================================ADD LINK API CALL============================
  LinksHandler = (data: any) => {
    this.setState({
      errorMessage: "ggfgfgfgfgf",
      successMessage: "success"
    })

    let url = ''
    url = `${configJSON.addMovieLinkEndpoint}`
    const token = localStorage.getItem('token')
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token" : token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addLinksApiCallProject = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addLinkAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteAccountPhotos(id: any) {
    let url = ''
    let httpBody = {}
    const token = localStorage.getItem('token')
    url = `${configJSON.endPointApiDeleteAccountPhotos}?id[]=${id}`
    const headers = {
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // this.accountPhotoDeleteCall = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRoleData(): boolean {
    const header = {
      "Content-Type": configJSON.urlGetValidations,
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRoleDataAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetRole
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methdTypeApiGetRole
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }

  //============================================GET FILMOGRAPHY =======================================

  getFilmographyData(): boolean {
    const header = {
      "Content-Type": configJSON.urlGetValidations,
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFilmographyDataAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetFilmography
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methdTypeApiGetFilmography
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }

    //=================================== ADD NEWS & REVIEWS ===========================================
    NewsHandler = (data: any) => {
      this.setState({
        errorMessage: "ggfgfgfgfgf",
        successMessage: "success"
      })
  
      let url = ''
      url = `${configJSON.addNewsEndpoint}`
      const token = localStorage.getItem('authToken')
      const headers = {
        "Content-Type": configJSON.exampleApiContentType,
        "token" : token
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.allLinkSubmitApiCall = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage), url
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        data
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.addNewsAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  //==============================================ADD FILES ATTACH UPLOAD==========================
  FilesAttachHandler = (data: any) => {
    this.setState({
      errorMessage: "ggfgfgfgfgf",
      successMessage: "success"
    })

    let url = ''
    url = `${configJSON.addFileAttachEndpoint}`
    const token = localStorage.getItem('token')
    const headers = {
      "token" : token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addFileAttachApiCallProject = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addFilesAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAccountData(): boolean {
    const header = {
      "Content-Type": configJSON.urlGetValidations,
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAccountDetailsAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiUpdateAccountDetails
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }

  handleUpdatePhotos = (files: any) => {
    let formData = new FormData();    
    if (files && files.length) {
      this.setState({
        selectedPhotos: files,
        loader: true
      })
      files && Object.entries(files)?.forEach((file : any) => 
       formData.append('account[account_photos][]', file[1])
      )

      this.updateAccountDetails(formData);
    }
  }

  SetProfileandCoverPhoto = (data: any) => {
    this.setState({
      errorMessage: "ggfgfgfgfgf",
      successMessage: "success"
    })

    let url = ''
    let httpBody = {}
    url = `${configJSON.endPointApiUpdateAccountDetails}`
    httpBody = {account: data}
    const token = localStorage.getItem('authToken')
    const headers = {
      // "Content-Type": configJSON.exampleApiContentType,
      "token" : token,
      'x-amz-acl': 'public-read'
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ProfilecoverphotoApiCall = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editProfileAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  SetCoverPhoto = (data: any) => {
    this.setState({
      errorMessage: "ggfgfgfgfgf",
      successMessage: "success"
    })

    let url = ''
    let httpBody = {}
    url = `${configJSON.endPointApiUpdateAccountDetails}`
    httpBody = {account: data}
    const token = localStorage.getItem('authToken')
    const headers = {
      // "Content-Type": configJSON.exampleApiContentType,
      "token" : token,
      'x-amz-acl': 'public-read'
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ProfilecoverphotoApiCall = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editProfileAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateAccountDetails = (data: any) => {
    this.setState({
      errorMessage: "ggfgfgfgfgf",
      successMessage: "success"
    })

    let url = ''
    url = `${configJSON.endPointApiUpdateAccountDetails}`
    const token = localStorage.getItem('authToken')
    const headers = {
      // "Content-Type": configJSON.exampleApiContentType,
      "token" : token,
      'x-amz-acl': 'public-read'
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ProfilecoverphotoApiCall = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editProfileAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  SetDragDropImg = (data: any) => {
    this.setState({
      errorMessage: "ggfgfgfgfgf",
      successMessage: "success"
    })

    let url = ''
    let httpBody = {}
    url = `${configJSON.endPointApiDragDropImg}`
    httpBody = {account: data}
    const token = localStorage.getItem('authToken')
    const headers = {
      // "Content-Type": configJSON.exampleApiContentType,
      "token" : token,
      'x-amz-acl': 'public-read'
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ProfileDragDropApiCall = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DragDropApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getUserRole = () => {
    const userRole = localStorage.getItem('usertoken')
    this.setState({ userRole:userRole })
  }
  // Customizable Area End

}
