  // Customizable Area Start
  import * as React from 'react';

import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  makeStyles, 
  Modal,
  Paper,
  Container,
  CircularProgress
} from "@material-ui/core";
import { dragDrop, noImage } from "./assets";
import { CanvasImg} from "./assets";
import CoverImg from './CoverImg.web'
import Footer from "../../../components/src/AppFooter.web";
import moment from "moment";


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
    addTrailerSection: {
        textAlign: 'center',
        background:"#fff",
        height:"300px",
        width:"100%",
        borderRadius:"12px",
        marginTop:"15px",
        position:"relative"
    },
    addShortSection:{
        height:"200px",
        width:"100%",
        background:"#fff",
        borderRadius:"12px",
        display:"flex",
    },
    filmography:{
        maxHeight:"130px",
        width:"100%",
        background:"#fff",
        borderRadius:"5px",
        display:"flex",
        marginTop:"20px",
        overflowY:"auto",
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            display: 'none',
          },
    },
    addPersonalDetail:{
        background:"#fff",
        color: "#A5259F", 
        width: "100%",
        textTransform: "capitalize",
        height:"55px"
    },
    newsDataSection: {
        paddingLeft: "5px",
        cursor: "pointer",
        background: "#EBECED"
    },
    addBiography:{
        background:"#A5259F", 
        color: "#fff", 
        margin: "30px 0",
        width: "100%",
        height:"55px"
    },
    newsLinkMainSection: {
        marginTop: '16px',
        width: "100%"
    },
    fontBold: {
        fontWeight: 'bold',
        padding: '5px'
    },
    newsLinkSection: {
        height: '55px',
        textAlign: 'center',
        paddingTop: '20px'
    },
    notUploadedVideoSectionPaper: {
        padding: '20px',
    },
    notUploadedVideoText: {
        fontWeight: 'bold'
    },
    notUploadedVideoTextURL: {
        marginTop: '20px',
        fontWeight: 'bold'
    },
    notUploadedVideoTextPassword: {
        marginTop: '25px',
        fontWeight: 'bold'
    },
    padding10px: {
        paddingLeft: '10px'
    },
    textFiled: {
        fontSize: '13px',
        padding: '10px'
    },
    addPhotos: {
        width:"100%", 
        paddingTop:"50px",
        paddingBottom:"160px", 
        background: "#ebeced"

    },
    addPhotosBtn: {
        position: "absolute" as const,
        opacity: 0,
        top: "-9px",
        left: "0",
        height: "53px",
        width: "120px",
        cursor: "pointer",
    },
    card: {
        marginTop: '30px',
        zIndex: 1,
        borderRadius: '5px',
        width: '80%',
    },
}))
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
  };

  export interface accountPhotosType {
    id: number, url:string,
  }

const ViewProfile = ({
    handleSubmitLinks,
    handleSubmitNews,
    handleFileAttachLinks,
    filmographyData,
    accountData,
    handleCoverPic,
    handleProfilePic,
    handleUpdatePhotos,
    deleteAccountPhotos,
    loader
}: any) => {
    const [Fileopen, setFileOpen] = React.useState(false);
    const [articleopen, setArticleOpen] = React.useState(false);
    const [linkOpen, setLinkOpen] = React.useState(false);
    const [files, setFiles] = React.useState<File[]>([]);
    const [inputKey, setInputKey] = React.useState(0);
    const [btnDisabled, setBtnDisabled] = React.useState(false) 
    const [isURLValid, setIsURLValid] = React.useState(true)
    const [accountPhotos, setAccountPhotos] = React.useState<Array<accountPhotosType>>();

    const classes = useStyles();

    const [newsreview, setNewsReview] = React.useState(
        {title:"", publication:"", url:""});
    const [fileattach, setFileAttach] = React.useState(
        {name: "", attachment:""});
    const [links, setLinks] = React.useState(
        {title: "", url:""});
    const [selectedFile, setSelectedFile] = React.useState ({account_photo:"" as any})
    const [fileNameError, setFileNameError] = React.useState(false)
    const [fileAttachError, setFileAttachError] = React.useState(false)

    const [newsreviewTitleError, setnewsreviewTitleError] = React.useState(false)
    const [newsreviewPubError, setnewsreviewPubError] = React.useState(false)
    const [newsreviewUrlError, setnewsreviewUrlError] = React.useState(false)

    const [linksTitleError, setlinksTitleError] = React.useState(false)
    const [linksUrlError, setlinksUrlError] = React.useState(false)
   
    const handleFileOpen = () => setFileOpen(true);
    const handleFileClose = () => setFileOpen(false);

    const handleArticleOpen = () => setArticleOpen(true);
    const handleArticleClose = () => setArticleOpen(false);

    const handleLinkOpen = () => setLinkOpen(true);
    const handleLinkClose = () => setLinkOpen(false); 
  
    React.useEffect(() => {
        if(accountData){
          setAccountPhotos(accountData.account_photo)
        }
      }, [accountData])

    const handleSubmit = async (data:any) => {

    }

    //===========================================NEWS & REVIEWS===================================

    React.useEffect (() => {
        if(!newsreview.title){
            setnewsreviewTitleError(true)
        }else {
            setnewsreviewTitleError(false)
        }
        if(!newsreview.publication){
            setnewsreviewPubError(true)
        } else {
            setnewsreviewPubError(false)
        }
        if(!newsreview.url){
            setnewsreviewUrlError(true)
        } else {
            setnewsreviewUrlError(false)
        }
    }, [newsreview])

    React.useEffect (() => {
        setnewsreviewTitleError(false)
        setnewsreviewPubError(false)
        setnewsreviewUrlError(false)
    }, [])

    const handleArticleChange = (e:any) => { 
        if(e.target.name === "url"){
            setBtnDisabled(!isValidURL(e.target.value))
            setIsURLValid(isValidURL(e.target.value))
        }
        setNewsReview({
          ...newsreview,
          [e.target.name]: e.target.value,
        });
    };
       
    const handleArticleSave = (e:any) => {
        if(!newsreview.title){
            setnewsreviewTitleError(true)
        }
        if(!newsreview.publication){
            setnewsreviewPubError(true)
        }
        if(!newsreview.url){
            setnewsreviewUrlError(true)
        }
        if(newsreview.title && newsreview.publication && newsreview.url) {
            e.preventDefault()
            if(newsreview.url){
                setBtnDisabled(!isValidURL(newsreview.url))
                setIsURLValid(isValidURL(newsreview.url))
            }
            isURLValid && handleSubmitNews(newsreview)
            isURLValid && setArticleOpen(false);
        }
    }

    //===========================================FILE ATTACHMENT===================================

    React.useEffect (() => {
        if(!fileattach.attachment){
            setFileAttachError(true)
        }else {
            setFileAttachError(false)
        }
        if(!fileattach.name){
            setFileNameError(true)
        } else {
            setFileNameError(false)
        }
    }, [fileattach])
    React.useEffect (() => {
        setFileAttachError(false)
        setFileNameError(false)
    }, [])

    const handleFileChange = async (e:any) => { 
        if (e.target.name == "name") {
            setFileAttach({
            ...fileattach,
            [e.target.name]: e.target.value,
            });
        } else {
            setFileAttach({
            ...fileattach,
            attachment: e.target.files[0],
            });
        }
    };

    const handleFileUpload = () => {
        if(!fileattach.attachment){
            setFileAttachError(true)
        }
        if(!fileattach.name){
            setFileNameError(true)
        }
        if(fileattach.attachment && fileattach.name) {
            handleFileAttachLinks(fileattach)
            setFileOpen(false);
        } 
    }

//===========================================LINKS===================================

    React.useEffect (() => {
        if(!links.title){
            setlinksTitleError(true)
        }else {
            setlinksTitleError(false)
        }
        if(!links.url){
            setlinksUrlError(true)
        } else {
            setlinksUrlError(false)
        }
    }, [links])

    React.useEffect (() => {
        setlinksTitleError(false)
        setlinksUrlError(false)
    }, [])
    const handleLinkChange = (e:any) => {
        if(e.target.name === "url"){
            setBtnDisabled(!isValidURL(e.target.value))
            setIsURLValid(isValidURL(e.target.value))
        }
        setLinks({
            ...links,
            [e.target.name]: e.target.value,
        });
    }

    const handleLinkUpload = () => {
        if(!links.title){
            setlinksTitleError(true)
        }
        if(!links.url){
            setlinksUrlError(true)
        }
        if(links.title && links.url) {
            if(newsreview.url){
                setBtnDisabled(!isValidURL(newsreview.url))
                setIsURLValid(isValidURL(newsreview.url))
            }
            isURLValid && handleSubmitLinks(links)
            isURLValid && setLinkOpen(false)
        }
    }

    const changeHandler = ({
        currentTarget: { files },
    }: React.ChangeEvent<HTMLInputElement>) => {
        if (files && files.length) {
        setFiles((existing) => existing.concat(Array.from(files)));
        }
        setInputKey((key) => key + 1);
        handleUpdatePhotos(files);
    };

    const handleDeleteImage = (id: any) => {
        deleteAccountPhotos(id)
    };

    const isValidURL = (string: any) => {
        var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return (res !== null)
    };

    const handleBiography = () => {
        window.location.replace('/Settings2');
    }

var visiblity:any = []

for(let i  = 0; i < filmographyData?.length; i++ ){
    if(filmographyData[i].visibilty){
        visiblity.push(filmographyData[i])
    }
}

    // @ts-ignore
    return(
        <>
            <CoverImg accountData={accountData} handleCoverPic={handleCoverPic} handleProfilePic = {handleProfilePic}/>
            <Box style={{backgroundColor:"#ebeced"}}>
                <Container>
                    <Box>
                        <Grid container className={classes.addPhotos}>
                            <Grid item xs={7}>
                                <Box
                                    sx={{
                                        padding: "10px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                    >
                                    <Typography variant="h4" style={{fontWeight:"bold"}}>
                                        Add Photo/s
                                    </Typography>
                                    {accountPhotos ? (
                                        <Typography
                                        variant="h4"
                                        style={{
                                            cursor: "pointer",
                                            paddingRight: "20px",
                                            position: "relative",
                                        }}
                                        >
                                        Add Photos
                                        <input
                                            key={inputKey}
                                            type="file"
                                            accept="image/*"
                                            onChange={changeHandler}
                                            name="account_photo"
                                            className={classes.addPhotosBtn}
                                            multiple
                                        />
                                        </Typography>
                                    ) : (
                                        ""
                                    )}
                                </Box>
                               
                                {accountPhotos && accountPhotos.length > 0 && (
                                    <Box style={{ padding: "20px", background: "white" }}>
                                        <Box
                                        style={{
                                            position: "relative",
                                            height: `${Math.ceil(accountPhotos.length / 3) * 204}px`,
                                            border: "2px solid #ccc",
                                            borderRadius: "10px",
                                        }}
                                        >
                                        {loader && <Box sx={webStyles.dragZonOverlay}>
                                            <Box sx={webStyles.dragZonLoader}>
                                                <CircularProgress />
                                            </Box>
                                        </Box> }
                                        <div
                                            style={{
                                            width: "100%",
                                            height: `${Math.ceil(accountPhotos.length / 3) * 204}px`,
                                            position: "absolute",
                                            top: "0",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            justifyContent: "start",
                                            }}
                                        >
                                            {accountPhotos.map((file, index) => {
                                            let width = "33.3%";
                                                
                                            return (
                                                <>
                                                <div
                                                    key={index}
                                                    style={{
                                                    flex: "0 0 auto",
                                                    width: width,
                                                    position: "relative",
                                                    }}
                                                >
                                                    <span
                                                        style={webStyles.closeButton}
                                                        onClick={() => handleDeleteImage(file.id)}
                                                    >X</span>
                                                    <img
                                                        src={file.url}
                                                        style={webStyles.imageContainer}
                                                    />
                                                </div>
                                                </>
                                            );
                                            })}
                                        </div>
                                        </Box>
                                    </Box>
                                    )}
                                {!accountPhotos && <Box style={{ background: "white" }}>
                                        <Box sx={webStyles.dragzon}>
                                        {loader && <Box sx={webStyles.dragZonOverlay}>
                                            <Box sx={webStyles.dragZonLoader}>
                                                <CircularProgress />
                                            </Box>
                                         </Box> }
                                        <input
                                            key={inputKey}
                                            type="file"
                                            accept="image/*"
                                            onChange={changeHandler}
                                            name="account_photo"
                                            multiple
                                            style={{
                                                opacity: 0,
                                                width: "100%",
                                                height: "200px",
                                                cursor: "pointer",
                                                position: "absolute",
                                                zIndex: 1,
                                                top: 0,
                                                left: 0,
                                            }}
                                        />
                                        <Box sx={webStyles.dragzonCard}>
                                        <img
                                            src={dragDrop}
                                            width={40}
                                            style={{ marginBottom: "10px" }}
                                            />
                                            <Typography variant="body2" style={webStyles.dragNDrop}>
                                            Drag & Drop image/s to upload
                                            </Typography>
                                        </Box>
                                        </Box>
                                    </Box>
                                }
                             
                                {accountData && accountData.bio ? (
                                    <Box sx={{ padding: "20px 0" }}>{accountData.bio}</Box>
                                ) : (
                                    ""
                                )}
                                <Button variant="outlined" className={classes.addBiography} onClick={handleBiography}>+ ADD BIOGRAPHY</Button>
                                {accountData && accountData.intro ? <Typography variant="h4" style={{fontWeight:"bold"}}>
                                    Short Intro
                                </Typography> : ""}
                                {accountData && accountData.intro ? <Box sx={{ padding: "20px", margin: "20px 0"}} style={{ background: "white" }}>{accountData.intro}</Box>
                                : ""}
                                <Typography variant="h4" style={{ fontWeight:"bold"}}>
                                    Personal Details
                                </Typography>
                                {accountData && (accountData.dob ||
                                accountData.nickname ||
                                accountData.birth_city ||
                                accountData.current_city ||
                                accountData.hometown ||
                                accountData.height ||
                                accountData.gender ||
                                accountData.ethnicity ||
                                accountData.eye_color ||
                                accountData.zodiac_sign ||
                                accountData.married_to ||
                                accountData.children) ? (
                                <>
                                  <Box sx={{ padding: "20px"}} style={{ background: "white" }}>
                                   { accountData.dob && <div><span  style={{fontWeight:"bold"}}>Birthdate :</span> { moment(accountData.dob).format("DD-MM-YYYY") } </div>}
                                   { accountData.nickname && <div><span  style={{fontWeight:"bold"}}>Nickname : </span>{accountData.nickname} </div>}
                                   { accountData.birth_city && <div><span  style={{fontWeight:"bold"}}>Birth City :</span> {accountData.birth_city} </div>}
                                   { accountData.current_city && <div><span  style={{fontWeight:"bold"}}>Current City : </span>{accountData.current_city} </div>}
                                   { accountData.hometown && <div><span  style={{fontWeight:"bold"}}>Hometown : </span>{accountData.hometown} </div>}
                                   { accountData.height && <div><span  style={{fontWeight:"bold"}}>Height :</span> {accountData.height} </div>}
                                   { accountData.gender && <div><span  style={{fontWeight:"bold"}}>Gender :</span> {accountData.gender} </div>}
                                   { accountData.ethnicity && <div><span  style={{fontWeight:"bold"}}>Ethnicity : </span>{accountData.ethnicity} </div>}
                                   { accountData.eye_color && <div><span  style={{fontWeight:"bold"}}>Eye Color :</span> {accountData.eye_color} </div>}
                                   { accountData.zodiac_sign && <div><span  style={{fontWeight:"bold"}}>Zodiac Sign : </span>{accountData.zodiac_sign} </div>}
                                   { accountData.married_to && <div><span  style={{fontWeight:"bold"}}>Married To :</span> {accountData.married_to} </div>}
                                   { accountData.children ? <div><span  style={{fontWeight:"bold"}}>Children :</span> {accountData.children} </div> : ""}
                                  </Box>
                                  <Typography  style={{ fontWeight:"bold", marginTop:"8px", marginBottom:"8px"}}>
                                  Trivia
                                </Typography>
                                  <Box sx={{ padding: "20px"}} style={{ background: "white" }}>
                                    { accountData.trivia && <div><span  style={{fontWeight:"bold"}}></span>{accountData.trivia} </div>}
                                  </Box>
                                  <Typography  style={{ fontWeight:"bold", marginTop:"8px", marginBottom:"8px"}}>
                                  Favourite Quote
                                </Typography>
                                <Box sx={{ padding: "20px"}} style={{ background: "white" }}>
                                   { accountData.favourite_quote && <div><span  style={{fontWeight:"bold"}}></span> {accountData.favourite_quote} </div>}
                                  </Box>
                                  </>
                                )
                               : <Button variant="outlined" style={{color:"#4684EB"}} className={classes.addPersonalDetail} onClick={handleBiography}>+ Add Personal Detail</Button> }

                                        {console.log("accountData-->",accountData)}
                                <Grid container className={classes.newsLinkMainSection}>
                                    <Grid item xs={4}>
                                        <Typography variant={'body2'} className={classes.fontBold}>News & Reviews</Typography>
                                        {accountData && accountData.news[0] ?
                                            <div className={classes.newsDataSection}>
                                                <a href={accountData?.news[0] && accountData.news[0].url ? accountData.news[0].url : "#"}>{accountData.news[0] && accountData.news[0].title ? accountData.news[0].title : ""}</a>
                                                <div>{accountData?.news[0] && accountData.news[0].publication ? accountData.news[0].publication : "" }</div>
                                            </div>
                                        : 
                                        <Paper elevation={2} className={classes.newsLinkSection} style={{cursor: 'pointer'}}>
                                            <Typography style={{color:"#4684EB"}} variant={'body2'} onClick={handleArticleOpen}>+ Add Article</Typography>
                                            <Modal
                                                open={articleopen}
                                                onClose={handleArticleClose}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box sx={style}>
                                                <Paper
                                                    elevation={2}
                                                    className={classes.notUploadedVideoSectionPaper}
                                                >
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <Typography
                                                            variant="body2"
                                                            className={classes.notUploadedVideoText}
                                                        >
                                                            Add Article / Review
                                                        </Typography>
                                                        <div
                                                            onClick={handleArticleClose}
                                                            style={{
                                                                cursor: "pointer",
                                                                fontWeight: 600,
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            X
                                                        </div>
                                                    </div>
                                                    <Typography
                                                        variant="body2"
                                                        className={classes.notUploadedVideoTextURL}
                                                    >
                                                    Title
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        name="title"
                                                        placeholder="Example: New director from.."
                                                        value={newsreview.title || ""}
                                                        onChange={handleArticleChange}
                                                        InputProps={{
                                                            classes: {
                                                            input: classes.textFiled,
                                                            },
                                                        }}
                                                    />
                                                    {newsreviewTitleError && <div style={{color: "red", fontSize:"12px"}}>Required</div>}
                                                    <Typography
                                                        variant="body2"
                                                        className={classes.notUploadedVideoTextURL}
                                                    >
                                                    Publication
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        name="publication"
                                                        placeholder="Example: daily varient"
                                                        value={newsreview.publication || ""}
                                                        onChange={handleArticleChange}
                                                        InputProps={{
                                                            classes: {
                                                            input: classes.textFiled,
                                                            },
                                                        }}
                                                    />
                                                    {newsreviewPubError && <div style={{color: "red", fontSize:"12px"}}>Required</div>}
                                                    <Typography
                                                        variant="body2"
                                                        className={classes.notUploadedVideoTextURL}
                                                    >
                                                    Link
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        name="url"
                                                        placeholder="Example: www.freeforyou.com"
                                                        onChange={handleArticleChange}
                                                        value={newsreview.url || ""}
                                                        InputProps={{
                                                            classes: {
                                                            input: classes.textFiled,
                                                            },
                                                        }}
                                                    />
                                                    {newsreviewUrlError ? <div style={{color: "red", fontSize:"12px"}}>Required</div> : !isURLValid ?
                                                    <div style={{color: "red", fontSize:"12px" }}>Please enter valid URL</div> : " " }
                                                    <div style={{ display: "flex", justifyContent: "end" }}>
                                                    <Button
                                                        variant="contained"
                                                        style={{
                                                        background: "#fff",
                                                        color: "#A5259F",
                                                        margin: "20px 10px 0 10px",
                                                        }}
                                                        onClick={handleArticleClose}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        style={{
                                                        background: "#A5259F",
                                                        color: "#fff",
                                                        margin: "20px 10px 0 10px",
                                                        }}
                                                        disabled={btnDisabled}
                                                        onClick={handleArticleSave}
                                                    >
                                                        Save
                                                    </Button>
                                                    </div>
                                                </Paper>
                                                </Box>
                                            </Modal>   
                                        </Paper>
                                        }
                                    </Grid>
                                    <Grid item xs={4} className={classes.padding10px}>
                                        <Typography variant={'body2'} className={classes.fontBold}>Files & Attachments</Typography>
                                        {accountData && accountData.profile_attachments.data[0] ?
                                        <div className={classes.newsDataSection}>
                                            <div>{accountData.profile_attachments.data[0] && accountData.profile_attachments.data[0].attributes.name ? accountData.profile_attachments.data[0].attributes.name : "" }</div>
                                            <a href={accountData.profile_attachments.data[0] && accountData.profile_attachments.data[0].attributes.attachment ? accountData.profile_attachments.data[0].attributes.attachment : "#"} download target='_blank'>Download</a>
                                        </div>
                                       :
                                        <Paper elevation={2} className={classes.newsLinkSection} style={{cursor: 'pointer'}}>
                                            <Typography style={{color:"#4684EB"}} variant={'body2'} onClick={handleFileOpen}>+ Add File/s</Typography>
                                            <Modal
                                                open={Fileopen}
                                                onClose={handleFileClose}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box sx={style}>
                                                <Paper
                                                    elevation={2}
                                                    className={classes.notUploadedVideoSectionPaper}
                                                >
                                                    <div
                                                    style={{ display: "flex", justifyContent: "space-between" }}
                                                    >
                                                    <Typography
                                                        variant="body2"
                                                        className={classes.notUploadedVideoText}
                                                    >
                                                        Add File
                                                    </Typography>
                                                    <div
                                                        onClick={handleFileClose}
                                                        style={{
                                                        cursor: "pointer",
                                                        fontWeight: 600,
                                                        fontSize: "14px",
                                                        }}
                                                    >
                                                        X
                                                    </div>
                                                    </div>
                                                    <Typography variant="caption">
                                                    Here you can add downloadable files such as a PDF press kit or
                                                    student ID.
                                                    </Typography>
                                                    <Typography
                                                    variant="body2"
                                                    className={classes.notUploadedVideoTextURL}
                                                    >
                                                    Name
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        name="name"
                                                        placeholder="Example: Student press kit"
                                                        onChange={handleFileChange}
                                                        value={fileattach.name || ""}
                                                        InputProps={{
                                                            classes: {
                                                            input: classes.textFiled,
                                                            },
                                                        }}
                                                    />
                                                    {fileNameError && <div style={{color: "red", fontSize: "12px"}}>Required</div>}
                                                    <Typography
                                                        variant="body2"
                                                        className={classes.notUploadedVideoTextPassword}
                                                    >
                                                    Upload
                                                    </Typography>
                                                    <input
                                                    type="file"
                                                    placeholder="choose file"
                                                    name="attachment"
                                                    onChange={handleFileChange}
                                                    accept="application/pdf"
                                                    // value={fileattach.attachment || ""}
                                                    className={classes.notUploadedVideoTextURL}
                                                    />
                                                    {fileAttachError && <div style={{color: "red", fontSize: "12px"}}>Required</div>}
                                                    <div style={{ display: "flex", justifyContent: "end" }}>
                                                    <Button
                                                        variant="contained"
                                                        style={{
                                                        background: "#fff",
                                                        color: "#A5259F",
                                                        margin: "20px 10px 0 10px",
                                                        }}
                                                        onClick={handleFileClose}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        style={{
                                                        background: "#A5259F",
                                                        color: "#fff",
                                                        margin: "20px 10px 0 10px",
                                                        }}
                                                        onClick={handleFileUpload}
                                                    >
                                                        Upload
                                                    </Button>
                                                    </div>
                                                </Paper>
                                                </Box>
                                            </Modal>
                                        </Paper>    
                                    }
                                    </Grid>
                                    <Grid item xs={4} className={classes.padding10px}>
                                        <Typography variant={'body2'} className={classes.fontBold}>Links</Typography>
                                            {accountData && accountData.links[0] ?
                                                <div className={classes.newsDataSection}>
                                                <a href={accountData.links[0] && accountData.links[0].url ? accountData.links[0].url : ""}>{accountData.links[0] && accountData.links[0].title ? accountData.links[0].title : "Links title"}</a>
                                                </div>
                                            : 
                                            <Paper elevation={2} className={classes.newsLinkSection} style={{cursor: 'pointer'}}>
                                                <Typography style={{color:"#4684EB"}} variant={'body2'} onClick={handleLinkOpen}>+Add Link/s</Typography>
                                                <Modal
                                                    open={linkOpen}
                                                    onClose={handleLinkClose}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                >
                                                    <Box sx={style}>
                                                    <Paper
                                                        elevation={2}
                                                        className={classes.notUploadedVideoSectionPaper}
                                                    >
                                                        <div
                                                        style={{ display: "flex", justifyContent: "space-between" }}
                                                        >
                                                        <Typography
                                                            variant="body2"
                                                            className={classes.notUploadedVideoText}
                                                        >
                                                            Add Link/s
                                                        </Typography>
                                                        <div
                                                            onClick={handleLinkClose}
                                                            style={{
                                                            cursor: "pointer",
                                                            fontWeight: 600,
                                                            fontSize: "14px",
                                                            }}
                                                        >
                                                            X
                                                        </div>
                                                        </div>
                                                        <Typography
                                                        variant="body2"
                                                        className={classes.notUploadedVideoTextURL}
                                                        >
                                                        Name
                                                        </Typography>
                                                        <TextField
                                                        fullWidth
                                                        name="title"
                                                        placeholder="Example: Student press kit"
                                                        onChange={handleLinkChange}
                                                        value={links.title || ""}
                                                        InputProps={{
                                                            classes: {
                                                            input: classes.textFiled,
                                                            },
                                                        }}
                                                        />
                                                        {linksTitleError && <div style={{color: "red", fontSize:"12px"}}>Required</div>}
                                                        <Typography
                                                        variant="body2"
                                                        className={classes.notUploadedVideoTextPassword}
                                                        >
                                                        Link
                                                        </Typography>
                                                        <TextField
                                                        fullWidth
                                                        name="url"
                                                        placeholder="Example: www.freeforyou.com"
                                                        onChange={handleLinkChange}
                                                        value={links.url || ""}
                                                        InputProps={{
                                                            classes: {
                                                            input: classes.textFiled,
                                                            },
                                                        }}
                                                        />
                                                        {linksUrlError ? <div style={{color: "red", fontSize:"12px"}}>Required</div> : !isURLValid ?
                                                        <div style={{color: "red", fontSize:"12px" }}>Please enter valid URL</div> : " " }
                                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                                        <Button
                                                            variant="contained"
                                                            style={{
                                                            background: "#fff",
                                                            color: "#A5259F",
                                                            margin: "20px 10px 0 10px",
                                                            }}
                                                            onClick={handleLinkClose}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            style={{
                                                            background: "#A5259F",
                                                            color: "#fff",
                                                            margin: "20px 10px 0 10px",
                                                            }}
                                                            disabled={btnDisabled}
                                                            onClick={handleLinkUpload}
                                                        >
                                                            Save
                                                        </Button>
                                                        </div>
                                                    </Paper>
                                                    </Box>
                                                </Modal>
                                        </Paper>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={5} style={{paddingLeft:"50px"}}>
                                <Typography variant="h4" style={{fontWeight:"bold"}}>
                                    Filmography
                                </Typography>
                            {filmographyData ?
                            (filmographyData.length > 0 ? filmographyData.map((project: any) =>
                            {
                                if(project.visibilty){
                                    console.log(project, "pro")
                                   return <Box className={classes.filmography}>
                                        <Grid xs={4}>
                                            <img style={{width:"100%", height:"100%", padding:"15px", objectFit: "cover"}} src={project.project_poster || noImage} alt="canvas" />
                                        </Grid>
                                        <Grid xs={8} style={{padding:"10px"}}>
                                            <Typography variant="h4" style={{borderBottom:"2px solid #ebeced", fontWeight: "bold"}}>
                                            {project.name}
                                            </Typography>
                                            <Typography variant="h5" style={{marginTop:"10px", width: "288px"}}>
                                                {project.description}
                                            </Typography>
                                        </Grid>
                                    </Box>
                                }
                            }
                            ): "No data found!")
                            : 
                            <Grid container className={classes.card} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <CircularProgress />
                            </Grid> }
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
            <Footer />
        </>
    )
}
export default ViewProfile;

const webStyles = {
    main: {
      display: "flex",
      justifyContent: "center",
      height: "100px",
      background: "000",
    },
    addPhotos: {
      position: "absolute" as const,
      opacity: 0,
      top: "-9px",
      left: "0",
      height: "53px",
      width: "120px",
      cursor: "pointer",
    },
    bottomRight: {
      display: "flex",
      flexDirection: "column",
      width: "75%",
    },
    formCard: {
      background: "#fff",
      marginBottom: "20px",
    },
    imageContainer: {
      width: "-webkit-fill-available",
      objectFit: "cover" as const,
      height: "200px",
      position: "absolute" as const,
      border: "2px solid white",
      borderRadius: "10px",
    },
    closeButton: {
      position: "absolute" as const,
      top: "5px",
      right: "5px",
      background: "#fff",
      borderRadius: "50%",
      padding: "0 6px",
      zIndex: 2 as const,
      cursor: "pointer",
    },
    dragzonImage: {
      position: "relative" as const,
      height: "200px",
      border: "2px solid #ccc",
      borderRadius: "10px",
    },
    dragzon: {
      height: "200px",
      borderRadius: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },
    dragZonOverlay: {
        position: "absolute",
        width: "100%",
        height: "100%",
        background:" rgba(0,0,0,0.2)",
        zIndex: 3
    },
    dragZonLoader: {
        top: "50%",
        left:" 50%",
        transform: "translate(-50%, -50%)",
        position: "absolute"
    },
    dragzonCard: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    filmCard: {
      width: "50%",
      display: "flex",
      padding: "10px",
      marginBottom: "20px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      boxShadow: "2px 2px 6px 5px #eee",
      justifyContent: "space-between",
      gap: "15px",
    },
    dragNDrop: {
      color: "#4684EB",
      textDecoration: "underline",
      fontWeight: 600,
    },
  };
// Customizable Area End