import * as React from 'react';

import {
    Box,
    Button,
    Typography,
    TextField,
    Grid,
    makeStyles,
    Modal,
    Paper,
    FormControl,
    FormLabel,
    TextareaAutosize,
    CircularProgress
} from "@material-ui/core";
import moment from "moment";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Console } from 'console';


const useStyles = makeStyles({
    icon: {
        color: "#a5259f",
    },
    root: {
        "& .MuiInputBase-input": {
            height: "1.5em",
        }
    },
    inputClass: {
        "& .Mui-focused:after": {
            border: "1px solid green",
        },
    },
    card: {
        marginTop: '20px',
        zIndex: 1,
        borderRadius: '5px',
        width: '80%',
        marginLeft: '10%',
    },
});


const AboutMe = ({
    handleSubmitProject,
    accountData
    // handleAccountSettings
}: any) => {
    const [accountapidata, setAccountAPIData] = React.useState([])
    const classes = useStyles();

    const valueRef: any = React.useRef()
    const majorRef: any = React.useRef()
    const [initialValues, setIntialValue] = React.useState<any>({})
    const [trivias, SetTrivia] = React.useState(0)
    const [quotes, SetQuotes] = React.useState(0)
    const [userData, setUserData] = React.useState("");
    const [loading, setIsLoading] = React.useState(true);
    const [educations, setEducation] = React.useState(accountData.educations);
    const [ openPassword, setOpenPassword ] = React.useState(false)
    const [formVal, dispatch] = React.useReducer(
        (curVal: any, newVal: any) => ({ ...curVal, ...newVal }),
        initialValues,
    )

    const handleChange = (event: any) => {
        const { name, value } = event.target
        // if (college != "" && college_major != "" && major != "" && school != "") {
        //     setBtnDisabled(false)
        // }
        dispatch({ [name]: value })
    }

     const togglePasswordArea = () => {
        setOpenPassword(!openPassword)
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        var values = initialValues
        console.log("values========>", values)
        handleSubmitProject(values)
    }

    const handleTrivia = () => {
        SetTrivia(1)
    }

    const handleQuotes = () => {
        SetQuotes(1)
    }
    // console.log("ACCOUNT DATA IN USEEFFECT-->", accountData)

    React.useEffect(() => {
        setIntialValue({
            title: accountData.title,
            intro: accountData != null ? accountData.intro : "",
            bio: accountData != null ? accountData.bio : "",
            last_name: accountData != null ? accountData.last_name : "",
            dob: accountData != null ? moment(accountData.dob).format('YYYY-MM-DD') : "",
            gender: accountData != null ? accountData.gender : "",
            nickname: accountData != null ? accountData.nickname : "",
            birth_city: accountData != null ? accountData.birth_city : "",
            current_city: accountData != null ? accountData.current_city : "",
            hometown: accountData != null ? accountData.hometown : "",
            hight: accountData != null ? accountData.hight : "",
            ethnicity: accountData != null ? accountData.ethnicity : "",
            eye_color: accountData != null ? accountData.eye_color : "",
            zodiac_sign: accountData != null ? accountData.zodiac_sign : "",
            married_to: accountData != null ? accountData.married_to : "",
            children: accountData != null ? accountData.children : "",
            trivia: accountData != null ? accountData.trivia : "",
            favourite_quote: accountData != null ? accountData.favourite_quote : "",
            college: accountData != null ? accountData.colleage : "",
            college_major: accountData != null ? accountData.colleage_major : "",
            college_sDate: accountData != null ? accountData.colleage_sDate : "",
            college_eDate: accountData != null ? accountData.colleage_eDate : "",
            school: accountData != null ? accountData.school : "",
            major: accountData != null ? accountData.major : "",
            start_date: accountData != null ? accountData.start_date : "",
            end_date: accountData != null ? accountData.end_date : "",
            educations: accountData.educations != null ? accountData.educations : ""
        })
        setIsLoading(false)
    }, [accountData])

    console.log("INITIALVALUE->", initialValues, accountData, accountData.title, loading)
    const styled = { color: '#a5259f' };
    return (
        <>
            {loading ? 
             <Grid container className={classes.card} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <CircularProgress />
             </Grid> 
                :
                <Box sx={webStyles.bottomRight}>
                    <form autoComplete="off">
                        <Box sx={webStyles.formCard}>
                            <Box sx={{ bgcolor: "#320b4c", padding: "10px" }}>
                                <Typography variant="h4" style={{ color: "#FFF" }} >About Me</Typography>
                            </Box>
                            <Box sx={{ padding: "20px" }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '100%' }}>
                                        <FormLabel component="legend">Title</FormLabel>
                                        <TextField
                                            name="title"
                                            id="outlined-disabled"
                                            onChange={(e) => setIntialValue({...initialValues,title:e.target.value})}
                                            // @ts-ignore
                                            value={initialValues.title}
                                            className={classes.inputClass}
                                            style={{width:"100%"}}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '100%' }}>
                                        <FormLabel component="legend">Intro</FormLabel>
                                        <TextField
                                            name="intro"
                                            id="outlined-disabled"
                                            onChange={(e) => setIntialValue({...initialValues,intro:e.target.value})}
                                            value={initialValues.intro}
                                            className={classes.inputClass}
                                            style={{width:"100%"}}
                                        />
                                    </FormControl>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '100%' }}>
                                        <FormLabel component="legend">Bio</FormLabel>
                                        <TextField
                                            name="bio"
                                            id="outlined-disabled"
                                            onChange={(e) => setIntialValue({...initialValues,bio:e.target.value})}
                                            value={initialValues.bio}
                                            className={classes.inputClass}
                                            style={{width:"100%"}}
                                        />
                                    </FormControl>
                                    {/* <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                                <FormLabel component="legend">Phone No</FormLabel>
                                <TextField
                                    id="outlined-disabled"
                                    placeholder="eg: +91-999-768-867"
                                    name="phone"
                                    onChange={handleChange}
                                    value={values.phone}
                                />
                            </FormControl> */}
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={webStyles.formCard}>
                        <Box sx={{bgcolor: "#320b4c", padding: "10px", display: "flex", justifyContent: "space-between" }} onClick={togglePasswordArea}>
                                <Typography variant="h4" style={{ color: "#FFF"}}>Education</Typography>
                                <div style={openPassword ? webStyles.toggleArrow : { marginBottom: "2px"}}>
                                <KeyboardArrowDownIcon style={{ fill: "#fff", marginBottom: "-12px"}}/>
                                </div>
                            </Box>
                            {openPassword && 
                            <>
                            <Box sx={{ padding: "20px" }}>
                                <Typography variant="h4" style={{ color: "#000", fontWeight: "bold" }} >College Details</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                                        <FormLabel component="legend">College <span style={styled}>*</span></FormLabel>
                                        <TextField
                                            id="outlined-disabled"
                                            required
                                            type="text"
                                            placeholder="Your Collage name"
                                            name="college"
                                            onChange={(e) => setIntialValue({...initialValues,colleage:e.target.value})}
                                            value={initialValues.college}
                                            // inputRef={valueRef}
                                            // onInput={(e:any) => setBtnDisabled(!e.target.value)}
                                            className={classes.inputClass}
                                        />

                                    </FormControl>
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                                        <FormLabel component="legend">Major <span style={styled}>*</span></FormLabel>
                                        <TextField
                                            id="outlined-disabled"
                                            required
                                            type="text"
                                            placeholder="Major"
                                            name="college_major"
                                            onChange={(e) => setIntialValue({...initialValues,college_major:e.target.value})}
                                            value={initialValues.college_major}
                                            // inputRef={majorRef}
                                            // onInput={(e:any) => setBtnDisabled(!e.target.value)}
                                            className={classes.inputClass}
                                        />
                                    </FormControl>
                                </Box>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <FormControl component="div" style={{ padding: '20px 0px', width: '80%' }}>
                                                <FormLabel component="legend">Year Attended</FormLabel>
                                                <TextField
                                                    id="outlined-disabled"
                                                    type="number"
                                                    placeholder="Start Date"
                                                    name="college_sDate"
                                                    onChange={(e) => setIntialValue({...initialValues,college_sDate:e.target.value})}
                                                    value={initialValues.college_sDate}
                                                    className={classes.inputClass}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl style={{ padding: '37px 0px', width: '80%' }}>
                                                <FormLabel component="legend"> </FormLabel>
                                                <TextField
                                                    type="number"
                                                    id="outlined-disabled"
                                                    placeholder="End Date"
                                                    onChange={(e) => setIntialValue({...initialValues,college_eDate:e.target.value})}
                                                    name="college_eDate"
                                                    value={initialValues.college_eDate}
                                                    className={classes.inputClass}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box
                                border={0.5}
                                borderLeft={0}
                                borderRight={0}
                                borderColor="#E8E8E8"
                            >
                            </Box>
                            <Box sx={{ padding: "20px" }}>
                                <Typography variant="h4" style={{ color: "#000", fontWeight: "bold" }} >High School Details</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                                        <FormLabel component="legend">School <span style={styled}>*</span></FormLabel>
                                        <TextField
                                            id="outlined-disabled"
                                            required
                                            type="text"
                                            placeholder="Your School Name"
                                            name="school"
                                            onChange={(e) => setIntialValue({...initialValues,school:e.target.value})}
                                            value={initialValues.school}
                                            // inputRef={valueRef}
                                            // onInput={(e:any) => setBtnDisabled(!e.target.value)}
                                            className={classes.inputClass}
                                        />

                                    </FormControl>
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                                        <FormLabel component="legend">Major <span style={styled}>*</span></FormLabel>
                                        <TextField
                                            id="outlined-disabled"
                                            required
                                            type="text"
                                            placeholder="Major"
                                            name="major"
                                            onChange={(e) => setIntialValue({...initialValues,major:e.target.value})}
                                            value={initialValues.major}
                                            // inputRef={majorRef}
                                            // onInput={(e:any) => setBtnDisabled(!e.target.value)}
                                            className={classes.inputClass}
                                        />
                                    </FormControl>
                                </Box>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <FormControl component="div" style={{ padding: '20px 0px', width: '80%' }}>
                                                <FormLabel component="legend">Year Attended</FormLabel>
                                                <TextField
                                                    id="outlined-disabled"
                                                    type="number"
                                                    placeholder="Start Date"
                                                    name="start_date"
                                                    onChange={(e) => setIntialValue({...initialValues,start_date:e.target.value})}
                                                    value={initialValues.start_date}
                                                    className={classes.inputClass}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl style={{ padding: '37px 0px', width: '80%' }}>
                                                <FormLabel component="legend"> </FormLabel>
                                                <TextField
                                                    id="outlined-disabled"
                                                    type="text"
                                                    placeholder="End Date"
                                                    onChange={(e) => setIntialValue({...initialValues,end_date:e.target.value})}
                                                    name="end_date"
                                                    value={initialValues.end_date}
                                                    className={classes.inputClass}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            </>
                            }
                        </Box>
                        <Box sx={webStyles.formCard}>
                            <Box sx={{ bgcolor: "#320b4c", padding: "10px" }}>
                                <Typography variant="h4" style={{ color: "#FFF" }} >Personal Details</Typography>
                            </Box>
                            <Box sx={{ padding: "20px" }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                                        <FormLabel component="legend">DOB</FormLabel>
                                        <TextField
                                            name="dob"
                                            type="date"
                                            id="outlined-disabled"
                                            placeholder="dob"
                                            InputProps={{ inputProps: { max: moment().format("YYYY-MM-DD") }, className: classes.root }}
                                            onChange={(e) => setIntialValue({...initialValues,dob:e.target.value})}
                                            value={initialValues.dob}
                                            className={classes.inputClass}
                                        />
                                    </FormControl>
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                                        <FormLabel component="legend">Nickname</FormLabel>
                                        <TextField
                                            name="nickname"
                                            type="text"
                                            id="outlined-disabled"
                                            placeholder="Enter your nickname"
                                            onChange={(e) => setIntialValue({...initialValues,nickname:e.target.value})}
                                            value={initialValues.nickname}
                                            className={classes.inputClass}
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                                        <FormLabel component="legend">Birth City</FormLabel>
                                        <TextField
                                            name="birth_city"
                                            type="text"
                                            id="outlined-disabled"
                                            placeholder="Enter a birth city"
                                            onChange={(e) => setIntialValue({...initialValues,birth_city:e.target.value})}
                                            value={initialValues.birth_city}
                                            className={classes.inputClass}
                                        />
                                    </FormControl>
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                                        <FormLabel component="legend">Current City</FormLabel>
                                        <TextField
                                            id="outlined-disabled"
                                            placeholder="Enter your current city"
                                            name="current_city"
                                            type="text"
                                            onChange={(e) => setIntialValue({...initialValues,current_city:e.target.value})}
                                            value={initialValues.current_city}
                                            className={classes.inputClass}
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                                        <FormLabel component="legend">Hometown</FormLabel>
                                        <TextField
                                            name="hometown"
                                            type="text"
                                            id="outlined-disabled"
                                            placeholder="Enter your home town"
                                            onChange={(e) => setIntialValue({...initialValues,hometown:e.target.value})}
                                            value={initialValues.hometown}
                                            className={classes.inputClass}
                                        />
                                    </FormControl>
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                                        <FormLabel component="legend">Height</FormLabel>
                                        <TextField
                                            id="outlined-disabled"
                                            placeholder="eg.5'6"
                                            name="hight"
                                            type="text"
                                            onChange={(e) => setIntialValue({...initialValues,hight:e.target.value})}
                                            value={initialValues.hight}
                                            className={classes.inputClass}
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                                        <FormLabel component="legend">Gender</FormLabel>
                                        <TextField
                                            id="standard-select-currency-native"
                                            select
                                            onChange={(e) => setIntialValue({...initialValues,gender:e.target.value})}
                                            InputProps={{ className: classes.root }}
                                            SelectProps={{
                                                classes: { icon: classes.icon },
                                                native: true
                                            }}
                                            variant="standard"
                                            name="gender"
                                            value={initialValues.gender}
                                        >
                                            <option value={'male'}>Select from option</option>
                                            <option value={'male'}>Male</option>
                                            <option value={'female'}>Female</option>
                                            <option value={'other'}>Others</option>
                                        </TextField>
                                    </FormControl>
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                                        <FormLabel component="legend">Ethnicity</FormLabel>
                                        <TextField
                                            id="outlined-disabled"
                                            placeholder="Enter your ethnicity"
                                            name="ethnicity"
                                            type="text"
                                            onChange={(e) => setIntialValue({...initialValues,ethnicity:e.target.value})}
                                            value={initialValues.ethnicity}
                                            className={classes.inputClass}
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                                        <FormLabel component="legend">Eye Color</FormLabel>
                                        <TextField
                                            name="eye_color"
                                            type="text"
                                            id="outlined-disabled"
                                            placeholder="Enter your eye color "
                                            onChange={(e) => setIntialValue({...initialValues,eye_color:e.target.value})}
                                            value={initialValues.eye_color}
                                            className={classes.inputClass}
                                        />
                                    </FormControl>
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                                        <FormLabel component="legend">Zodiac Sign</FormLabel>
                                        <TextField
                                            id="outlined-disabled"
                                            placeholder="Enter your Zodiac"
                                            name="zodiac_sign"
                                            type="text"
                                            onChange={(e) => setIntialValue({...initialValues,zodiac_sign:e.target.value})}
                                            value={initialValues.zodiac_sign}
                                            className={classes.inputClass}
                                        />
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                                        <FormLabel component="legend">Married To</FormLabel>
                                        <TextField
                                            name="married_to"
                                            type="text"
                                            id="outlined-disabled"
                                            placeholder="Spouse name"
                                            onChange={(e) => setIntialValue({...initialValues,married_to:e.target.value})}
                                            value={initialValues.married_to}
                                            className={classes.inputClass}
                                        />
                                    </FormControl>
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                                        <FormLabel component="legend">Children</FormLabel>
                                        <TextField
                                            id="outlined-disabled"
                                            placeholder="eg. 2"
                                            name="children"
                                            type="text"
                                            onChange={(e) => setIntialValue({...initialValues,children:e.target.value})}
                                            value={initialValues.children}
                                            className={classes.inputClass}
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={webStyles.formCard}>
                            <Box sx={{ bgcolor: "#320b4c", padding: "10px" }}>
                                <Typography variant="h4" style={{ color: "#FFF" }} >Trivia</Typography>
                            </Box>
                            {trivias == 0 ?
                                <Button variant="contained" type="button" style={{ background: "#fff", color: "#A5259F", margin: "15px", border: " 1px solid #A5259F" }} onClick={handleTrivia}>+ ADD TRIVIA</Button> :
                                <TextareaAutosize
                                    aria-label="empty textarea"
                                    placeholder="Has taken piano lessons since the age of 4."
                                    minRows={4}
                                    style={{ width: '95%', margin: "15px",fontFamily:"Open Sans",fontSize:"15px" }}
                                    onChange={(e) => setIntialValue({...initialValues,trivia:e.target.value})}
                                    name="trivia"
                                    value={initialValues.trivia}
                                />}
                        </Box>
                        <Box sx={webStyles.formCard}>
                            <Box sx={{ bgcolor: "#320b4c", padding: "10px" }}>
                                <Typography variant="h4" style={{ color: "#FFF" }} >Favourite Quotes</Typography>
                            </Box>
                            {quotes == 0 ?
                                <Button variant="contained" type="button" style={{ background: "#fff", color: "#A5259F", margin: "15px", border: " 1px solid #A5259F" }} onClick={handleQuotes}>+ ADD QUOTES</Button> :
                                <TextareaAutosize
                                    aria-label="empty textarea"
                                    placeholder="There are no rules in filmmaking. Only sins. And the cardinal sin is dullness."
                                    minRows={4}
                                    style={{ width: '95%', margin: "15px",fontFamily:"Open Sans",fontSize:"15px" }}
                                    onChange={(e) => setIntialValue({...initialValues,favourite_quote:e.target.value})}
                                    name="favourite_quote"
                                    value={initialValues.favourite_quote}
                                />}
                        </Box>
                        <Box>
                            <Button variant="contained" type="submit" style={{ background: "#A5259F", color: "#fff", marginTop: "10px" }} onClick={handleSubmit}>Save Changes</Button>
                        </Box>
                    </form>
                </Box>
            }
        </>
    )
}
// @ts-ignore
export default AboutMe;

const webStyles = {
    main: {
        display: "flex",
        justifyContent: "center",
        height: "100px",
        background: "000"
    },
    bottomRight: {
        display: "flex",
        flexDirection: "column",
        width: '75%',
    },
    formCard: {
        background: "#fff",
        marginBottom: "50px"
    },
    formError: {
        margin: "0px",
        color: "#ef2525"
    },
    toggleArrow: {
        transform: "rotate(180deg)"
    },
}