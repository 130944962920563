// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  formik?: any;
  addCustomFile?: any;
  deleteRow?: any;
  handleChangeInput?: any;
  customRow?: any;
  checkedValues?: any;
  checkboxError?: any;
  countryError?: any;
  handleSelect?: any;
  formikProps?: any;
  handleNextStep?: any;
  handleBack?: any;
  root?: any;
  waiverData?: any;
}

interface S {
  radiovalue:any,
  discount_type: any;
  code: any;
  label: any;
  discount_value: number;
  discount_per: number;
  max_uses: number;
  valid_from?: any;
  valid_to?: any;
  waiverData?: any;
  discountForm?: boolean;
  tabelForm?: boolean;
  loader?:boolean;
}

interface SS {
  id: any;
}

export default class CoverImageController extends BlockComponent<
  Props,
  S,
  SS
> {
  discountWaiverCall: any;
  getCurrencyDataAPICall: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  getDiscountwaiverDataApiCall: string = "";

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      radiovalue:'flat_discount',
      discount_type: "",
      code: "",
      label: "",
      discount_value: 10,
      discount_per: 10,
      max_uses: 0,
      valid_from: "",
      valid_to: "",
      waiverData:null,
      discountForm:true,
      tabelForm:false,
      loader:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
                                          
  async componentDidMount() {
    // this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    this.getDiscountwaiverData()
  }

  // async componentDidUpdate(prevProps: any, prevState: any) {
  //   const { waiverData } = this.state;
  //   if (prevState.waiverData !== waiverData) {
  //       console.log("prevState.waiverData",prevState.waiverData, waiverData)
  //       this.setState({ tabelForm: true, discountForm:false })

  //     } else {
  //       // this.setState({ tabelForm: false, discountForm:true})
  //     }
  //   }

  async receive(from: string, message: Message) {

console.log("message", message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getDiscountwaiverDataApiCall) {
        console.log("responseJson.data",responseJson.data)
        this.setState({
          waiverData: responseJson.data
        })
      }

      if (apiRequestCallId == this.discountWaiverCall) {
        console.log('Helloo=====----------->')
        this.setState({tabelForm:true, discountForm:false})
      }

      if (apiRequestCallId === this.discountWaiverCall) {
        if (responseJson != null && responseJson.error === undefined) {
          toast.success("Congratulations, Your changes have been saved")
        } else {
          toast.error(responseJson.error)
          this.parseApiErrorResponse(responseJson);
        }
      }

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          var arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }
      }
    }
  }


  handleSubmitEvent = (values: any, radiovalue:any) => {
    let formData = new FormData();
    if(radiovalue == "flat_discount"){
    formData.append('discount_coupon[discount_type]',radiovalue);
    formData.append("discount_coupon[code]", values.code);
    formData.append('discount_coupon[label]', values.label);
    formData.append("discount_coupon[discount_value]", values.discount_value);
    formData.append('discount_coupon[discount_per]', values.discount_per);
    formData.append("discount_coupon[max_uses]", values.max_uses);
    formData.append('discount_coupon[valid_from]', values.valid_from);
    formData.append('discount_coupon[valid_to]', values.valid_to);
    } else {
    formData.append('discount_coupon[discount_type]',radiovalue);
    formData.append("discount_coupon[code]", values.code);
    formData.append('discount_coupon[label]', values.label);
    formData.append("discount_coupon[max_uses]", values.max_uses);
    formData.append('discount_coupon[valid_from]', values.valid_from);
    formData.append('discount_coupon[valid_to]', values.valid_to);
  }
    
    this.discountwaiverData(formData)
    console.log("formData-->", values)
  }

  discountwaiverData(values: any) {
    const token = localStorage.getItem('token')
    const header = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.discountWaiverCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.discountwaiverDataendpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.discountwaiverdataApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      values
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDiscountwaiverData(): boolean {
    const header = {
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDiscountwaiverDataApiCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getdiscountwaiverDataendpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getdiscountwaiverdataApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }
 
  handleSelect = (e:any)  => {
    console.log("values-->",e.target.value)
    this.setState({radiovalue: e.target.value});
  };


  handleDiscountForm = () => {
    console.log("fnc called")
    this.setState({tabelForm:false, discountForm:true})
  } 

}
 // Customizable Area End
