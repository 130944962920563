// Customizable Area Start
import {IBlock} from "../../../framework/src/IBlock";
import {Message} from "../../../framework/src/Message";
import {BlockComponent} from "../../../framework/src/BlockComponent";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import {runEngine} from "../../../framework/src/RunEngine";


import {toast} from "react-toastify";
import React from "react";


export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    showHeader?:boolean;
    handleCloseModal?:boolean;
}

interface S {
    isLoading:boolean;
    isModalOpen:boolean;
    value:any;
    albumName:String;
    id:any;
}

interface SS {
    id: any;
}

export default class FestivalViewController extends BlockComponent<
    Props,
    S,
    SS
    > {

    createAlbumApiCallId:string = ""
    emailReg: RegExp;
    labelTitle: string = "";

    constructor(props: Props) {

        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ]

        this.state = {
            isLoading:false,
            value:0,
            isModalOpen:false,
            albumName:"",
            id:"",
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.doCreateAlbum = this.doCreateAlbum.bind(this);

        this.emailReg = new RegExp("");
        this.labelTitle = configJSON.labelTitle;

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    async componentDidMount() {
        const id = window.location.href.split("/")[5]
        if(id){
            this.setState({
                id:id
            })
        }
    }

    handleChange = (event: any) => {
        console.log("EVENT",event.target.value)
        this.setState({
            albumName:event.target.value
        })
    };

    handleSubmit = () =>{
        console.log(this.state.albumName)
        if(this.state.albumName !== ""){
            let formData = new FormData()
            formData.append('festival_id',this.state.id)
            // @ts-ignore
            formData.append('festival_album[name]',this.state.albumName)
            this.doCreateAlbum(formData)
        }else{
            toast.error("Please add Album name to create Album")
        }
    }

    async receive(from: string, message: Message) {
        if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            if(this.createAlbumApiCallId === apiRequestCallId) {
                if(responseJson.hasOwnProperty('data')){
                    toast.success("Album Created Successfully")
                    this.sentMessage("ALBUM_CREATED_SUCCESSFULLY")
                    // @ts-ignore
                    this.props.handleCloseModal()
                }else{
                    toast.error("Something went wrong!")
                }
            }
        }
    }

    doCreateAlbum = (data: any) => {
        const token = localStorage.getItem('authToken')
        const headers = {
            token : token,
            'x-amz-acl': 'public-read'
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.createAlbumApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.festivalPhotosAddAlbumApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            data
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalPhotosAddAlbumApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true
    }

    sentMessage (data:any) {
        const msg : Message = new Message(getName(MessageEnum.PostDetailDataMessage))
        msg.properties['text'] = data
        this.send(msg)
    }

}
// Customizable Area End