Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.exampleAPiMethod = "POST";
exports.putAPiMethod = "PUT";
exports.getAPiMethod = "GET";
exports.postAPiMethod = "POST";
exports.deleteAPiMethod = "DELETE";
exports.addNewsAPiMethod = "POST";
exports.addFilesAPiMethod = "POST";
exports.addLinkAPiMethod = "POST";
exports.contentTypeApiUpdateUserProfile = "multipart/form-data";
exports.endPointApiUpdateAccountDetails = "/account_block/accounts/3";
exports.endPointApiGetCurrency = "/bx_block_posts/specifications/get_currencies";
exports.endPointApiGetEmailPreferences = "/bx_block_notifsettings/notification_settings"
exports.endPointApiUpdateEmailPreferences = "/bx_block_notifsettings/notification_settings/update_all_notifications";
exports.endPointApiGetUserReviews = "/bx_block_catalogue/reviews"
exports.endPointApiUpdateUserReview = "/bx_block_catalogue/reviews/1";
exports.endPointApiGetFilmography = "/bx_block_posts/index_visibility";
exports.addNewsEndpoint = "bx_block_profile/profiles/add_news";
exports.addMovieLinkEndpoint = "bx_block_profile/profiles/add_links";
exports.addFileAttachEndpoint = "bx_block_profile/profiles/add_profile_attachments";
exports.endPointApiDeleteAccountPhotos = "account_block/accounts/delete_account_photos"
// Customizable Area End

