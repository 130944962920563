import * as React from 'react';
import {
    Box,
    Button,
    Divider,
    Container,
    Typography,
    List,
    Icon,
    ListItem,
    IconButton,
    Input
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import SubmissionButton from './SubmissionButton';
import ManageUser from './ManageUser.web';
import NotificationManager from './NotificationManage.web';
import DiscountWaiver from './Discountwaiver.web';
import DeadlinePeriod from './DeadlinePeriod.web';
import CustomSubmissionForm from './CustomSubmission.web';
import CustomJudgeForm from './CustomJudge.web';
import CustomFlag from './CustomFlag.web';
import EmailSubmitters from './EmailSubmitter.web';
import CheckAttendees from './CheckAttendees.web';
import InviteContacts from './InviteContact.web';
import JudgingInsight from './JudgingInsight.web';
import ConfirmationMessage from './ConformationMessage.web';
import LaurelCenter from './LaurelCenter.web';
import ExportConfiguration from './ExportConfiguration.web';
import Transactions from './Transcations.web';
import Reports from './Reports.web';
import Reviews from './Reviews.web';
import Help from './Help.web';



const ManageFestival  = () => {
    const [selectedTab, setSelectedTab] = React.useState(1)
    return(
        <Box style={{margin:"-24px"}}>
        <Box sx={webStyles.bottomSection} >
            <Box sx={webStyles.bottomLeft}>
                <List disablePadding>
                    <ListItem divider style={{ background: selectedTab === 1 ? "#320b4c" : "#fff",  }}>
                        <Button fullWidth
                            onClick={() => setSelectedTab(1)}
                            style={{ color: selectedTab === 1 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Submission Buttons & Logos
                        </Button>
                    </ListItem>
                    <ListItem divider style={{ background: selectedTab === 2 ? "#320b4c" : "#fff" }}>
                        <Button 
                            fullWidth 
                            onClick={() => setSelectedTab(2)}
                            style={{ color: selectedTab === 2 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Manage users
                        </Button>
                    </ListItem>
                    <ListItem divider style={{ background: selectedTab === 3 ? "#320b4c" : "#fff" }}>
                        <Button 
                            fullWidth 
                            onClick={() => setSelectedTab(3)}
                            style={{ color: selectedTab === 3 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Notification Manager
                        </Button>
                    </ListItem>
                    <ListItem divider style={{ background: selectedTab === 4 ? "#320b4c" : "#fff" }}>
                        <Button 
                            fullWidth 
                            onClick={() => setSelectedTab(4)}
                            style={{ color: selectedTab === 4 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Discount & Waivers
                        </Button>
                    </ListItem>
                    <ListItem divider style={{ background: selectedTab === 5 ? "#320b4c" : "#fff" }}>
                        <Button 
                            fullWidth 
                            onClick={() => setSelectedTab(5)}
                            style={{ color: selectedTab === 5 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Deadline Waiver Period
                        </Button>
                    </ListItem>
                    <ListItem divider style={{ background: selectedTab === 6 ? "#320b4c" : "#fff" }}>
                        <Button 
                            fullWidth 
                            onClick={() => setSelectedTab(6)}
                            style={{ color: selectedTab === 6 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Custom Submission Form
                        </Button>
                    </ListItem>
                    <ListItem divider style={{ background: selectedTab === 7 ? "#320b4c" : "#fff" }}>
                        <Button 
                            fullWidth 
                            onClick={() => setSelectedTab(7)}
                            style={{ color: selectedTab === 7 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Custom Judging Form
                        </Button>
                    </ListItem>
                    <ListItem divider style={{ background: selectedTab === 8 ? "#320b4c" : "#fff" }}>
                        <Button 
                            fullWidth 
                            onClick={() => setSelectedTab(8)}
                            style={{ color: selectedTab === 8 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Custom Flag
                        </Button>
                    </ListItem>
                <ListItem divider style={{ background: selectedTab === 9 ? "#320b4c" : "#fff" }}>
                        <Button 
                            fullWidth 
                            onClick={() => setSelectedTab(9)}
                            style={{ color: selectedTab === 9 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Email Submitters
                        </Button>
                    </ListItem>
                    <ListItem divider style={{ background: selectedTab === 10 ? "#320b4c" : "#fff" }}>
                        <Button 
                            fullWidth 
                            onClick={() => setSelectedTab(10)}
                            style={{ color: selectedTab === 10 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Check In Attendees
                        </Button>
                    </ListItem>
                    <ListItem divider style={{ background: selectedTab === 11 ? "#320b4c" : "#fff" }}>
                        <Button 
                            fullWidth 
                            onClick={() => setSelectedTab(11)}
                            style={{ color: selectedTab === 11 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Invite Contacts
                        </Button>
                    </ListItem>
                    <ListItem divider style={{ background: selectedTab === 12 ? "#320b4c" : "#fff" }}>
                        <Button 
                            fullWidth 
                            onClick={() => setSelectedTab(12)}
                            style={{ color: selectedTab === 12 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Judging Insights
                        </Button>
                    </ListItem>
                    <ListItem divider style={{ background: selectedTab === 13 ? "#320b4c" : "#fff" }}>
                        <Button 
                            fullWidth 
                            onClick={() => setSelectedTab(13)}
                            style={{ color: selectedTab === 13 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Confirmation Message
                        </Button>
                    </ListItem>
                    <ListItem divider style={{ background: selectedTab === 14 ? "#320b4c" : "#fff" }}>
                        <Button 
                            fullWidth 
                            onClick={() => setSelectedTab(14)}
                            style={{ color: selectedTab === 14 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Laurel Center
                        </Button>
                    </ListItem>
                    <ListItem divider style={{ background: selectedTab === 15 ? "#320b4c" : "#fff" }}>
                        <Button 
                            fullWidth 
                            onClick={() => setSelectedTab(15)}
                            style={{ color: selectedTab === 15 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Export Configurations
                        </Button>
                    </ListItem>
                    <ListItem divider style={{ background: selectedTab === 16 ? "#320b4c" : "#fff" }}>
                        <Button 
                            fullWidth 
                            onClick={() => setSelectedTab(16)}
                            style={{ color: selectedTab === 16 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Transactions
                        </Button>
                    </ListItem>
                    <ListItem divider style={{ background: selectedTab === 17 ? "#320b4c" : "#fff" }}>
                        <Button 
                            fullWidth 
                            onClick={() => setSelectedTab(17)}
                            style={{ color: selectedTab === 17 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Reports
                        </Button>
                    </ListItem>
                    <ListItem divider style={{ background: selectedTab === 18 ? "#320b4c" : "#fff" }}>
                        <Button 
                            fullWidth 
                            onClick={() => setSelectedTab(18)}
                            style={{ color: selectedTab === 18 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Reviews
                        </Button>
                    </ListItem>
                    <ListItem divider style={{ background: selectedTab === 19 ? "#320b4c" : "#fff" }}>
                        <Button 
                            fullWidth 
                            onClick={() => setSelectedTab(19)}
                            style={{ color: selectedTab === 19 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                            Help
                        </Button>
                    </ListItem>
                </List>
            </Box>
            {selectedTab === 1 ?
                <SubmissionButton /> :
                    selectedTab === 2 ?
                    <ManageUser /> : 
                    selectedTab === 3 ?
                    <NotificationManager radiovalue={''} /> :
                    selectedTab === 4 ?
                    <DiscountWaiver/> :
                    selectedTab === 5 ?
                    <DeadlinePeriod/> :
                    selectedTab === 6 ? 
                    <CustomSubmissionForm/> :
                    selectedTab === 7 ?
                    <CustomJudgeForm /> :
                    selectedTab === 8 ?
                    <CustomFlag /> :
                    selectedTab === 9 ?
                    <EmailSubmitters /> :
                    selectedTab === 10 ?
                    <CheckAttendees /> :
                    selectedTab === 11 ?
                    <InviteContacts /> :
                    selectedTab === 12 ?
                    <JudgingInsight /> :
                    selectedTab === 13 ?
                    <ConfirmationMessage /> :
                    selectedTab === 14 ?
                    <LaurelCenter /> :
                    selectedTab === 15 ?
                    <ExportConfiguration /> :
                    selectedTab === 16 ?
                    <Transactions /> :
                    selectedTab === 17 ?
                    <Reports /> :
                    selectedTab === 18 ?
                    <Reviews /> :
                    selectedTab === 19 ?
                    <Help /> :
                    "" }
        </Box>
        </Box>

    )
}

export default ManageFestival;

const webStyles = {
    landingText: {
        fontSize: "42px",
        fontWeight: 600,
    },
    headSection: {
        display: "flex",
        justifyContent: "space-between"
    },
    headLeft: {
        display: "flex",
        alignItems: "center"
    },
    avatarSection: {
        position: "relative",
        top: "-50px"
    },
    avatar: {
        height: "150px",
        width: "150px",
        borderRadius: "50%"
    },
    headContent: {
        display: "flex",
        alignItems: "end",
        marginLeft: "50px"
    },
    bottomSection: {
        display: "flex",
        padding: "50px 0px"
    },
    bottomLeft: {
        background: "#fff",
        width: '22%',
        height: "fit-content"
    },
    cameraPhoto: {
        position: 'absolute',
        top: '135px',
        right: '66px'
    }
}