import React from "react";

import {
    Box,
    Button,
    Divider,
    Container,
    Typography,
    List,
    Icon,
    ListItem,
    IconButton,
    Input
} from "@material-ui/core";

import ResponsiveAppBar from "../../../components/src/AppHeader.web";
// Customizable Area Start
import SideLinks from "./sideLinks.web"
import LaurelDisplay from "./LaurelDisplay.web"
 // Customizable Area End
import Footer from "../../../components/src/AppFooter.web";
import AboutMe from "./aboutme.web"
import MyProfile from "./myProfile.web"
import MovieExpressGold from "./movieGold.web"
import EmailPreferences from "./preferences.web";
import Reviews from "./reviews.web";
import Transcation from "./transcation.web"
import Privacy from "./privacy.web";
import HelpCenter from "./help.web";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";

import {coverImage, profileAvatar} from "./assets"

import UserProfileBasicController, {
  Props
} from "./UserProfileBasicController";
import AccountCredit from "./credit.web";
import MergeAccount from "./merge.web";


export default class UserProfileBasicBlock extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillMount() {
    const token = localStorage.getItem("authToken")
    console.log('fdfdstoken', token)
    if(token){
        this.setState({
            token: token
        })       
    }else{
        this.goToHomePage();
    }
  }
  
  async componentDidMount() {
    this.getCurrencyData();
    this.getAccountData();
    this.getEmailPreference();
    this.getUserReviews();
    this.getFilmographyData();
  }

  handleEditProfile = () => {
    window.location.replace('/Settings2');
  }
  // Customizable Area End
 // Customizable Area Start
  render() {
    const { navigation } = this.props;
    const { selectedTab } = this.state;

    return (
      //Required for all blocks
      <>
        <ResponsiveAppBar
            // @ts-ignore
            accountData={this.state.accountData}
        />
        <img src={this.state.coverImage !== "" ? this.state.coverImage : coverImage} alt="cover-image" height="250px" width="100%" style={{ objectFit: "cover", objectPosition: "center top"}} />
        <Box sx={{bgcolor: "#ebeced"}}>
            <Container>
                <Box sx={webStyles.headSection}>
                    <Box sx={webStyles.headLeft}>
                        <Box sx={webStyles.avatarSection}>
                            <img src={this.state.profileImage !== "" ? this.state.profileImage : profileAvatar} alt="profile-picture" style={webStyles.avatar} />
                            <label htmlFor="icon-button-file">
                                <Input id="icon-button-file" type="file" style={{display: 'none'}} onChange={this.handleUpdateProfileImage} />
                                <IconButton aria-label="upload picture" component="span" style={{position: 'absolute', top: '125px', right: '55px', padding: '8px', backgroundColor: '#a5259f', height: '40px', width: '40px', borderRadius: '50%'}}>
                                    <PhotoCameraIcon style={{fill: "white"}} />
                                </IconButton>
                            </label>
                        </Box>
                        <Box sx={{marginLeft: "50px"}}>
                            <Typography variant="h3"  >{this.state.accountData && `${this.state.accountData.first_name} ${this.state.accountData.last_name} ${this.state.accountData.title ? ("("+ this.state.accountData.title + ")") : ""}`}</Typography>
                            <Typography variant="subtitle1" style={{color: "#ccc"}} >Member Since August 2021 USA</Typography>
                        </Box>
                        <Box sx={webStyles.headContent}>
                            <Typography variant="h3" style={{color: "#A5259F"}} >{(this.state.accountData && this.state.accountData.event_count) ? this.state.accountData.event_count : 0}</Typography>
                            <Typography variant="h5" style={{fontWeight: 700, marginLeft: "5px"}} >Events</Typography>
                        </Box>
                        <Box sx={webStyles.headContent}>
                            <Typography variant="h3" style={{color: "#A5259F"}} >0</Typography>
                            <Typography variant="h5" style={{fontWeight: 700, marginLeft: "5px"}} >Discussions</Typography>
                        </Box>
                    </Box>
                    <Box sx={{display: "flex", flexDirection: "column", alignContent: "center"}}>
                        <Button variant="contained" component="label" style={{ background: "#A5259F", color: "#fff", marginTop: "10px"}}>
                            + COVER IMAGE
                            <input
                                type="file"
                                hidden
                                onChange={this.handleUpdateCoverImage}
                            />
                        </Button>
                        <Button variant="outlined" style={{ border: "1px solid #A5259F", color: "#A5259F", marginTop: "10px"}} onClick={this.handleEditProfile}>Edit PROFILE</Button>
                    </Box>
                </Box>
                <Divider />
                <Box sx={webStyles.bottomSection} >
                    <Box sx={webStyles.bottomLeft}>
                        <List disablePadding>
                            <ListItem divider style={{ background: selectedTab === 1 ? "#320b4c" : "#fff",  }}>
                                <Button fullWidth
                                    onClick={() => this.handleTabChange(1)}
                                    style={{ color: selectedTab === 1 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                                    Account
                                </Button>
                            </ListItem>
                            <ListItem divider style={{ background: selectedTab === 2 ? "#320b4c" : "#fff" }}>
                                <Button 
                                    fullWidth 
                                    onClick={() => this.handleTabChange(2)}
                                    style={{ color: selectedTab === 2 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                                    My Profile
                                </Button>
                            </ListItem>
                            <ListItem divider style={{ background: selectedTab === 3 ? "#320b4c" : "#fff" }}>
                                <Button 
                                    fullWidth 
                                    onClick={() => this.handleTabChange(3)}
                                    disabled //non-mvp
                                    style={{ color: selectedTab === 3 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}
                                    >
                                    Movie Expressway Gold
                                </Button>
                            </ListItem>
                            <ListItem divider style={{ background: selectedTab === 4 ? "#320b4c" : "#fff" }}>
                                <Button 
                                    fullWidth 
                                    onClick={() => this.handleTabChange(4)}
                                    disabled //non-mvp
                                    style={{ color: selectedTab === 4 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                                    Email Preferences
                                </Button>
                            </ListItem>
                            <ListItem divider style={{ background: selectedTab === 5 ? "#320b4c" : "#fff" }}>
                                <Button 
                                    fullWidth 
                                    onClick={() => this.handleTabChange(5)}
                                    disabled //non-mvp
                                    style={{ color: selectedTab === 5 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                                    Transactions
                                </Button>
                            </ListItem>
                            <ListItem divider style={{ background: selectedTab === 6 ? "#320b4c" : "#fff" }}>
                                <Button 
                                    fullWidth 
                                    onClick={() => this.handleTabChange(6)}
                                    disabled //non-mvp
                                    style={{ color: selectedTab === 6 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                                    Laurel Creation Tool
                                </Button>
                            </ListItem>
                            <ListItem divider style={{ background: selectedTab === 7 ? "#320b4c" : "#fff" }}>
                                <Button 
                                    fullWidth 
                                    onClick={() => this.handleTabChange(7)}
                                    disabled //non-mvp
                                    style={{ color: selectedTab === 7 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                                    Reviews
                                </Button>
                            </ListItem>
                         <ListItem divider style={{ background: selectedTab === 8 ? "#320b4c" : "#fff" }}>
                                <Button 
                                    fullWidth 
                                    onClick={() => this.handleTabChange(8)}
                                    disabled //non-mvp
                                    style={{ color: selectedTab === 8 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                                    Settings
                                </Button>
                            </ListItem>
                            <ListItem divider style={{ background: selectedTab === 9 ? "#320b4c" : "#fff" }}>
                                <Button 
                                    fullWidth 
                                    onClick={() => this.handleTabChange(9)}
                                    disabled //non-mvp
                                    style={{ color: selectedTab === 9 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                                    Account Credit
                                </Button>
                            </ListItem>
                            <ListItem divider style={{ background: selectedTab === 10 ? "#320b4c" : "#fff" }}>
                                <Button 
                                    fullWidth 
                                    onClick={() => this.handleTabChange(10)}
                                    disabled //non-mvp
                                    style={{ color: selectedTab === 10 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                                    Help
                                </Button>
                            </ListItem>
                        </List>
                        {selectedTab === 2 ?  
                        <SideLinks 
                            handleSubmitNews = {this.handleSubmitNews}
                            handleSubmitLinks = {this.handleSubmitLinks} 
                            handleFileAttachLinks = {this.handleFileAttachLinks}
                            accountData={this.state.accountData}
                        /> : ''}
                    </Box>
                    {console.log("accountdata-->",this.state.accountData)}
                    {selectedTab === 1 ?
                        <AboutMe 
                            handleAccountSettings={this.handleAccountSettings}
                            accountData={this.state.accountData}
                            currencyData={this.state.currencyData}
                            deleteAccount={() => this.deleteAccount()}
                            /> :
                            selectedTab === 2 ?
                            <MyProfile 
                               filmographyData={this.state.filmographyData}
                               accountData={this.state.accountData}
                               handleUpdatePhotos={this.handleUpdatePhotos}
                               deleteAccountPhotos={this.deleteAccountPhotos}
                               loader={this.state.loader}
                            /> : 
                            selectedTab === 3 ?
                            <MovieExpressGold /> :
                            selectedTab === 4 ?
                            <EmailPreferences
                                emailPreferencesData={this.state.emailPreferencesData}
                                updateEmailPreferencesCall={this.updateEmailPreferences}
                            /> :
                            selectedTab === 5 ?
                                <Transcation/> :
                            selectedTab === 6 ?
                                <LaurelDisplay/> :
                            selectedTab === 7 ?
                            <Reviews
                              userReviewsData={this.state.userReviewsData} 
                              handleUserReview={this.handleUserReview}
                              userReviewSaved={this.state.userReviewSaved}
                            /> :
                            selectedTab === 8 ?
                            <MergeAccount /> :
                            selectedTab === 9 ?
                            <AccountCredit /> :
                            selectedTab === 10 ?
                            <HelpCenter /> :
                            "" }
                </Box>
            </Container>
        </Box>
        <Footer />
      </>
    );
  }
}
// Customizable Area End
// Customizable Area Start
const webStyles = {
    landingText: {
        fontSize: "42px",
        fontWeight: 600,
    },
    headSection: {
        display: "flex",
        justifyContent: "space-between"
    },
    headLeft: {
        display: "flex",
        alignItems: "center"
    },
    avatarSection: {
        position: "relative",
        top: "-50px"
    },
    avatar: {
        height: "150px",
        width: "150px",
        borderRadius: "50%"
    },
    headContent: {
        display: "flex",
        alignItems: "end",
        marginLeft: "50px"
    },
    bottomSection: {
        display: "flex",
        justifyContent: "space-between",
        padding: "50px 0px"
    },
    bottomLeft: {
        background: "#fff",
        width: '22%',
        height: "fit-content"
    },
    cameraPhoto: {
        position: 'absolute',
        top: '135px',
        right: '66px'
    }
}
// Customizable Area End