import React from 'react';
import { Box,Container,FormControl,TextField, Tab, Tabs, Button,Typography,Modal,Paper,Grid,FormLabel,CircularProgress } from '@material-ui/core';
import { makeStyles,withStyles} from '@material-ui/core/styles';
// @ts-ignore
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ConformationMessageController, {
    Props
  } from "./ConformationMessageController";
import { Formik } from "formik";
import { inviteContact, formInitialValues } from "./validationSchemas"
import { cleanSingle } from 'react-native-image-crop-picker';

class ConfirmationMessage extends  ConformationMessageController{
    constructor(props: Props) {
        super(props);
      }

    useStyle = makeStyles((theme:any) => ({
        root: {
            flexGrow: 1,
            backgroundColor:"#F2F3F5",
            "& .MuiInputBase-input": {
                height: "1.5em",
                backgroundColor:"#fff"
              }
            // padding: theme.spacing(2),
        },
        paperstyle: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
              margin: theme.spacing(1),
              width: "100%",
              height: "100%",
            },
        },
        inputClass: {
            "& .Mui-focused:after": {
              border: "1px solid green",
            },
          },
          icon: {
            color: "#a5259f",
          },
          selectbox: {
            "& .MuiInputBase-input": {
              height: "1.5em",
            }
          },
    }));
    render() {
        const {confirmation_message} = this.state;
        const classes = this.useStyle
    return(
        <Formik
        enableReinitialize
        initialValues={formInitialValues}
        // validationSchema={stepOneSchema}
        onSubmit={() => {
          this.handleMessageSubmitEvent(confirmation_message)
        }}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {props => (
        <form onSubmit={props.handleSubmit}>
        <Container style={{paddingLeft:"30px"}}>
            <Box style={{padding:"0px 0px 0px 7px"}}>
                <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem",marginBottom:"10px"}}>Submission Confirmation Message</Typography>
            </Box>
            <Box style={{ display: 'flex',flexWrap: 'wrap'}}>
                <Paper style={{padding:"15px 15px 30px 15px"}}>
                    <Typography variant="h4" style={{marginBottom:'10px', fontWeight:500}}>After a submission is completed, submitters receive a confirmation page and corresponding email 
                    letting them know their submission was processed successfully.</Typography>
                    <Typography variant="h4" style={{fontWeight:500,marginBottom:'10px'}}>
                        You can include a custom message or any helpful information for your submitters here.
                    </Typography>
                    <Typography variant="h4" style={{marginBottom:'10px',fontWeight:800}}>
                        Important: <span style={{fontWeight:500}}>This tool is designed to help you communicate with submitters about your event. Please do not use this tool for marketing purposes.</span>
                    </Typography>
                    <Typography variant="h4" style={{marginTop:'20px',fontWeight:800}}>Message</Typography>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <ReactQuill theme="snow" value={confirmation_message} onChange={this.handleQuillChange}/>
                            {console.log("propsvalue",confirmation_message)}
                        </Grid>
                    </Grid>   
                </Paper>
            </Box>
            <Box style={{margin:"8px 0px 0px 0px"}}>
                <Button color="primary" type="submit">SAVE</Button>
            </Box>
        </Container>
        </form>
        )}
       </Formik> 
    )
}
}

export default ConfirmationMessage;