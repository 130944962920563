Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.saveLinkAPiMethod = "POST";
exports.addNewsAPiMethod = "POST";
exports.addFilesAPiMethod = "POST";
exports.addLinkAPiMethod = "POST";
exports.deleteAPiMethod = "DELETE"
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.endPointApiUpdateAccountDetails = "account_block/accounts/3";
exports.urlGetValidations = "profile/validations";
exports.addMovieLinkEndpoint = "/bx_block_profile/profiles/add_links";
exports.addNewsEndpoint = "/bx_block_profile/profiles/add_news";
exports.addFileAttachEndpoint = "/bx_block_profile/profiles/add_profile_attachments";
exports.methodTypeApiGetUserProfile = "GET";
exports.methdTypeApiGetRole = "GET";
exports.methdTypeApiGetFilmography = "GET";
exports.editProfileAPiMethod = "PATCH";
exports.DragDropApiMethodType = "POST";
exports.endPointApiDragDropImg = "/bx_block_posts/project_informations"
exports.endPointApiGetFilmography = "/bx_block_posts/index_visibility";
exports.endPointApiGetRole = "/bx_block_roles_permissions/roles";
exports.endPointApiDeleteAccountPhotos ="account_block/accounts/delete_account_photos"
// Customizable Area End