import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment, { Moment } from 'moment'
import {toast} from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  project_type:any;
  name: string;
  description: string;
  website_link: string;
  facebook_link:string;
  twitter_link:string;
  instagram_link:string;
  imdb_link: string;
  email: string;
  phoneno:string;
  date_of_birth: string;
  gender:string;
  country_of_origin:string;
  country_of_residence: string;
  address: string;
  city: string;
  state: string;
  postal_code:string;
  role_id: number;
  firstname:string;
  middlename: string;
  lastname: string;
  priorcredit: string;
  genres:string; 
  screeningandaward:string[];
  runtime:number;
  completiondate: string;
  productionbudget:string;
  currency:string;
  countryorigin: string;
  countryfilming: string;
  language: string;
  shooting_format:string;
  aspectration: number;
  filmcolour: string;
  studentproject:string;
  firstfilm: string;
  event: string;
  eventcity: string;
  eventcountry: string;
  screeningdate:string;
  premiere: string;
  awardselection: string;
  salesagent: string;
  errorMessage: string;
  successMessage: string;
  currencyData: any;
  languageData:any;
  budgetData:any;
  roleData:any;
  token:any;
  message:any;
  accountData: any;


  
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomForm2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  AddProjectApiCallProject: String = "";
  AllProjectSubmitApiCall: String = "";
  getAllProjectsCallId: string = "";
  getCurrencyDataAPICall: String = "";
  getRoleDataAPICall: String = "";
  getLanguageAPICall: String = "";
  getBudgetAPICall:String = "";
  arrayholder: any[];


  // Customizable Area Start
  getAccountDetailsAPICall: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.AddProjectMessage)
      
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      project_type:"",
      name:"",
      description:"",
      website_link:"",
      facebook_link:"",
      twitter_link:"",
      instagram_link:"",
      imdb_link: "",
      email: "",
      phoneno:"",
      date_of_birth: "",
      gender:"",
      country_of_origin:"",
      country_of_residence: "",
      address: "",
      city: "",
      state: "",
      postal_code:"",
      role_id: 0,
      firstname:"",
      middlename: "",
      lastname: "",
      priorcredit: "",
      genres:"", 
      screeningandaward:[],
      runtime:0,
      completiondate:"",
      productionbudget:"",
      currency:"",
      countryorigin: "",
      countryfilming: "",
      language: "",
      shooting_format:"",
      aspectration: 0,
      filmcolour: "",
      studentproject:"",
      firstfilm: "",
      event: "",
      eventcity: "",
      eventcountry: "",
      screeningdate:"",
      premiere: "",
      awardselection: "",
      salesagent: "",
      errorMessage: "",
      successMessage: "",
      currencyData: null,
      languageData:null,
      budgetData:null,
      roleData:null,
      token:"",
      message:"",
      accountData: null,
      
      
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
    // Customizable Area Start
    // Customizable Area End
    
  }
  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    this.getLanguageData();
    this.getRoleData();
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    // if (getName(MessageEnum.SessionResponseMessage) === message.id) {
    //   let token = message.getData(getName(MessageEnum.SessionResponseToken));
    //   console.log('token-SessionResponseToken:', token)
    //   this.setState({ token: token }, () => {
    //     this.getAllCountry();
    //   });
    // }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

     var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.AllProjectSubmitApiCall) {
        if (responseJson != null && !responseJson.error) {
          toast.success("Congratulations, Your project has been created!")
          const timer = setTimeout(() => {
            this.goToProjectPage()
          }, 2000);
          // this.goToProjectPage();
        } else {
          toast.error(responseJson.error)
        }
      }

        if (apiRequestCallId === this.getCurrencyDataAPICall) {
          this.setState({
            currencyData: responseJson.data
          })
        }
        if (apiRequestCallId === this.getAccountDetailsAPICall) {
          this.setState({
            accountData: responseJson.data.attributes,
          })
        }
        if (apiRequestCallId === this.getRoleDataAPICall) {
          console.log('responseJson.data=-=-=>', this.getRoleDataAPICall)
          this.setState({
            roleData: responseJson.data
          })
        }
        if (apiRequestCallId === this.getLanguageAPICall) {
          this.setState({
            languageData: responseJson.data
          })
        }
        if (apiRequestCallId === this.getBudgetAPICall) {
          this.setState({
            budgetData: responseJson.data
          })
        }
    }
  
    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    // Customizable Area End

  }

  handleSubmitProject = (values:any) => {
    console.log('values-=-=--=>', values)
    const firstfilms = (values.firstfilm == 'No' ? false : values.firstfilm == 'Yes' ? true : "")
    const studentproject = (values.studentproject == 'No' ? false : values.studentproject == 'Yes' ? true : "")
    for(let i of values.credits_attributes){
      delete i.id
    }
    for(let i of values.screening_and_awards_attributes){
      delete i.id
    }
    for(let i of values.distributor){
      delete i.id
    }
    let data = {
          "project_information":{  
              "project_type": values.project_type,
              "name":values.name,
              "description":values.description,
              "website_link":values.website_link,
              "facebook_link":values.facebook_link,
              "twitter_link":values.twitter_link,
              "instagram_link":values.instagram_link,
              "imdb_link":values.imdb_link,
            "submitter_info_attributes": { 
              "email":values.email,
              "phone_number":values.phoneno,
              "dob":values.date_of_birth,
              "country_of_origin":values.country_of_origin,
              "country_of_residence":values.country_of_residence,
              "address":values.address,  
              "city":values.city,
              "state":values.state,
              "postal_code":values.postal_code,
              "gender":values.gender,
            },
            "specification_attributes": {
              "first_time_filmmaker":firstfilms,
              "student_project":studentproject,
              "runtime":values.runtime,
              "completion_date": values.completiondate,
              "production_budget":values.productionbudget,
              "currency":values.currency,
              "country_of_origin":values.countryorigin,
              "country_of_residence":values.countryfilming,
              "language_id":values.language,
              "shooting_format":values.shooting_format,
              "aspect_ratio":values.aspectration,
              "film_color":values.filmcolour,
              "genres":values.genres,
              "screening_and_award":values.screeningandaward
            },
            "credits_attributes": values.credits_attributes,
            "screening_and_awards_attributes": values.screening_and_awards_attributes,
            "distributors_attributes":values.distributor
          },
      }
    console.log('values-=-=--=>', values)
      this.AllProjectHandler(data)
    }

  AllProjectHandler = (data: any) => {
    this.setState({
      errorMessage: "ggfgfgfgfgf",
      successMessage: "success"
    })

    let url = ''
    let httpBody = {}
    url = `${configJSON.addProjectEndpoint}`
    httpBody = data
    const token = localStorage.getItem('authToken')
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token" : token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.AllProjectSubmitApiCall = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  handleProjectType = (value: string) => {
    this.setState({
      project_type: value
    })
  }

  handlenameChange = (value: string) => {
    this.setState({
      name: value
    })
  }

  handledescriptionChange = (value: string) => {
    this.setState({
      description: value
    })
  }

  handledewebsiteChange = (value: string) => {
    this.setState({
      website_link: value
    })
  }

  handledetwitterChange = (value: string) => {
    this.setState({
      twitter_link: value
    })
  }

  handledefacebookChange = (value: string) => {
    this.setState({
      facebook_link: value
    })
  }

  handledeinstagramChange = (value: string) => {
    this.setState({
      twitter_link: value
    })
  }

  handledeimdbChange = (value: string) => {
    this.setState({
      imdb_link: value
    })
  }

  handledeemailChange = (value: string) => {
    this.setState({
      email: value
    })
  }

  handledephoneChange = (value: string) => {
    this.setState({
      phoneno: value
    })
  }

  handledeDobChange = (value: string) => {
    this.setState({
      date_of_birth: value
    })
  }

  handledeGenderChange = (value: string) => {
    this.setState({
      gender: value
    })
  }

  handledeCountryofOriginChange = (value: string) => {
    this.setState({
      country_of_origin: value
    })
  }

  handledeCountryofResidenceChange = (value: string) => {
    this.setState({
      country_of_residence: value
    })
  }

  handledeAddressChange = (value: string) => {
    this.setState({
      address: value
    })
  }

  handledeCityChange = (value: string) => {
    this.setState({
      city: value
    })
  }

  handledeStateChange = (value: string) => {
    this.setState({
      state: value
    })
  }

  handledePostalCodeChange = (value: string) => {
    this.setState({
      postal_code: value
    })
  }

  handledeRoleChange = (value: number) => {
    this.setState({
      role_id: value
    })
  }

  handledeFirstNameChange = (value: string) => {
    this.setState({
      firstname: value
    })
  }

  handledeMiddleNameChange = (value: string) => {
    this.setState({
      middlename: value
    })
  }

  handledeLastNameChange = (value: string) => {
    this.setState({
      lastname: value
    })
  }

  handledePriorCreditChange = (value: string) => {
    this.setState({
      priorcredit: value
    })
  }

  handledeScreenigAwardChange = (value: string) => {
    this.setState({
      priorcredit: value
    })
  }

  handledeGenreChange = (value: string) => {
    this.setState({
      genres: value
    })
  }

  handledeHrsChange = (value: number) => {
    this.setState({
      runtime: value
    })
  }

  handledeCompletionDateChange = (value: string) => {
    this.setState({
      completiondate: value
    })
  }

  handledeBudgetChange = (value: string) => {
    this.setState({
      productionbudget: value
    })
  }

  handledeCurrencyChange = (value: string) => {
    this.setState({
      currency: value
    })
  }

  handledeSpecificationCountryChange = (value: string) => {
    this.setState({
      countryorigin: value
    })
  }

  handledeCountryofFilmingChange = (value: string) => {
    this.setState({
      countryfilming: value
    })
  }

  handledeLanguageChange = (value: string) => {
    this.setState({
      language: value
    })
  }

  handledeShootingFormateChange = (value: string) => {
    this.setState({
      shooting_format: value
    })
  }

  handledeAspectRatioChange = (value: number) => {
    this.setState({
      aspectration: value
    })
  }

  handledeFilmColorChange = (value: string) => {
    this.setState({
      filmcolour: value
    })
  }

  handledeStudentProjectChange = (value: string) => {
    this.setState({
      studentproject: value
    })
  }

  handledeFirsttimeFilmChange = (value: string) => {
    this.setState({
      firstfilm: value
    })
  }

  handledeEventChange = (value: string) => {
    this.setState({
      event: value
    })
  }

  handledePremierChange = (value: string) => {
    this.setState({
      premiere: value
    })
  }

  handledeScreeningCityChange = (value: string) => {
    this.setState({
      eventcity: value
    })
  }

  handledeScreeningCountryChange = (value: string) => {
    this.setState({
      eventcountry: value
    })
  }

  handledeScreeningDateChange = (value: string) => {
    this.setState({
      screeningdate: value
    })
  }

  handledeAwardSelectionChange = (value: string) => {
    this.setState({
      awardselection: value
    })
  }

  handledeSalesAgentChange = (value: string) => {
    this.setState({
      salesagent: value
    })
  }



  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  getAccountData(): boolean {
    const header = {
      "Content-Type": configJSON.urlGetValidations,
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAccountDetailsAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiUpdateAccountDetails
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  
  getCurrencyData(): boolean {
    const header = {
      "Content-Type": configJSON.urlGetValidations,
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCurrencyDataAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetCurrency
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }

  getRoleData(): boolean {
    console.log("roleData--->")
    const header = {
      "Content-Type": configJSON.urlGetValidations,
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    console.log('jhddhsshdksd==>>', requestMessage.messageId)
    this.getRoleDataAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetRole
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methdTypeApiGetRole
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }

  getLanguageData(): boolean {
    console.log("language data call")
    const header = {
      "Content-Type": configJSON.urlGetValidations,
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLanguageAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetLanguage
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methdTypeApiGetRole
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }

  getProductionBudget(): boolean {
    const header = {
      "Content-Type": configJSON.urlGetValidations,
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBudgetAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetBudget
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methdTypeApiGetRole
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }

  goToProjectPage(){
    window.location.replace('/myProject');
  }
}

