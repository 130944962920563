// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  FormControl,
  FormLabel,
  TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Grid,
  Popover,
} from "@material-ui/core";
import { Delete } from "./assets";
import moment from "moment";
import "./style.css";
import DatesDeadlinesController, {
  Props
} from "./DatesDeadlinesController";
import { Formik } from "formik";
import { stepThreeSchema } from "./validationSchemas";
import Loader from "../../../components/src/Loader.web";

export default class DatesDeadlines extends DatesDeadlinesController {

  constructor(props: Props) {
    super(props);
  }

  render() {
    // @ts-ignore
    const { festivalData, customRow, anchorEl } = this.state;
    const { loading } = this.props;
    const open = Boolean(anchorEl);

    return (
      <>
      {loading && <Loader loading={loading} /> }
      <Formik
        enableReinitialize
        initialValues={{
          openingDate: festivalData?.opening_date || "",
          NotiDate: festivalData?.notification_date || "",
          EventStart: festivalData?.event_start_date || "",
          EventEnd: festivalData?.event_end_date || ""
        }}
        validationSchema={stepThreeSchema}
        onSubmit={(values, actions) => {
          this.handleSubmitEvent(values, customRow)
        }}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {props => (
          <form onSubmit={props.handleSubmit}>
            <Box sx={{ padding: "50px 0px 0px" }} bgcolor="#f2f3f5">
              <Typography variant="h2" component="h2">
                Accept Submissions to Your Event
              </Typography>
            </Box>

            <Box sx={{ padding: "20px 0px" }}>
              <Box className="formTitle">
                <Typography variant="h4" component="h4">
                  Dates & Deadlines
                </Typography>
              </Box>
              <Box bgcolor="#FFF">
                <Box component="form" sx={{ padding: "20px 20px 150px" }}>
                  <div>
                    <Box border={0.2} borderColor="#f5f5f5" borderRadius={4}>
                      <FormControl style={{ width: "100%", padding: "20px 0px" }}>
                        <FormLabel component="legend" className="label">
                          Opening Dates{" "}<span className="astrick">*</span>
                        </FormLabel>
                        <TextField
                          className="inputClass"
                          id="outlined-disabled"
                          type="date"
                          placeholder="www.xyz.com"
                          name="openingDate"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={moment(props.values.openingDate).format("YYYY-MM-DD")}
                        />
                        {props.errors.openingDate && props.touched.openingDate ? (<p className="formError">{props.errors.openingDate}</p>) : null}
                      </FormControl>

                      <FormControl style={{ width: "100%", padding: "20px 0px" }}>
                        <FormLabel component="legend" className="label">
                          Entry Deadlines{" "}<span className="astrick">*</span>
                        </FormLabel>
                        <TableContainer
                          component={Paper}
                          style={{ padding: "20px" }}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className="submissionsHeader">
                                  Deadline
                                </TableCell>
                                <TableCell
                                  className="submissionsHeader"
                                  align="left"
                                >
                                  Date
                                </TableCell>

                                <TableCell
                                  className="submissionsHeader"
                                  align="right"
                                >
                                  Action
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {customRow.map((newRow: any, i: any) => {
                                return (
                                  <TableRow className={"overrides"}>
                                    <TableCell style={{ width: "45%"}}>
                                      <TextField
                                        name="deadline_name"
                                        value={newRow.deadline_name}
                                        id="outlined-disabled"
                                        placeholder={i === 0 ? "Earlybird Deadline" : i === 1 ? "Regular Deadline" : i === 2 ? "Late Deadline" : "Extended Deadline"}
                                        style={{ width: "100%" }}
                                        className="inputClass"
                                        onChange={event => this.handleChangeInput(newRow.id, event)}
                                      />
                                    </TableCell>
                                    <TableCell style={{ width: "45%"}}>
                                      <TextField
                                        className="inputClass"
                                        style={{ width: "100%" }}
                                        id="outlined-disabled"
                                        type="date"
                                        placeholder="www.xyz.com"
                                        name="date"
                                        onChange={event => this.handleChangeInput(newRow.id, event)}
                                        value={moment(newRow.date).format("YYYY-MM-DD")}
                                      />
                                    </TableCell>
                                    <TableCell align="right" style={{ width: "10%"}}>
                                      <IconButton style={{ padding: '0px' }} onClick={() => this.deleteRow(newRow.id)}>
                                        <img style={{ width: "50px", marginLeft: "25px" }} src={Delete} alt="logo" />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                            <Button
                              className="submissionsButton"
                              onClick={() => this.addCustomFile()}
                            >
                              ADD ANOTHER DEADLINE
                            </Button>
                          </Table>
                        </TableContainer>
                      </FormControl>

                      <Grid container spacing={4}>
                        <Grid item xs={6}>
                          <FormControl
                            style={{ padding: "20px 0px", width: "100%" }}
                          >
                            <FormLabel component="legend" className="label">
                              Notification Date{" "}<span className="astrick">*</span>{" "}
                              <span
                                className="labelLink"
                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={this.handlePopoverOpen}
                                onMouseLeave={this.handlePopoverClose}
                              >
                                What is this?
                              </span>
                              <Popover
                                id="mouse-over-popover"
                                className="popover"
                                style={{ pointerEvents: 'none' }}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={this.handlePopoverClose}
                                disableRestoreFocus
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                                transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                              >
                                <Typography style={{ padding: "10px" }}>Information about field</Typography>
                              </Popover>
                            </FormLabel>
                            <TextField
                              className="inputClass"
                              id="outlined-disabled"
                              type="date"
                              placeholder="www.xyz.com"
                              name="NotiDate"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={moment(props.values.NotiDate).format("YYYY-MM-DD")}
                            />
                            {props.errors.NotiDate && props.touched.NotiDate ? (<p className="formError">{props.errors.NotiDate}</p>) : null}
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl
                            style={{
                              padding: "20px 0px",
                              width: "100%",
                            }}
                          >
                            <FormLabel component="legend" className="label">Event Dates{" "}<span className="astrick">*</span></FormLabel>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <TextField
                                  className="inputClass"
                                  style={{ width: "100%" }}
                                  id="outlined-disabled"
                                  type="date"
                                  placeholder="www.xyz.com"
                                  name="EventStart"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={moment(props.values.EventStart).format("YYYY-MM-DD")}
                                />
                                {props.errors.EventStart && props.touched.EventStart ? (<p className="formError">{props.errors.EventStart}</p>) : null}
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  // InputProps={{ inputProps: { max: moment().format("YYYY-MM-DD") } }}
                                  className="inputClass"
                                  style={{ width: "100%" }}
                                  id="outlined-disabled"
                                  type="date"
                                  placeholder="www.xyz.com"
                                  name="EventEnd"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={moment(props.values.EventEnd).format("YYYY-MM-DD")}
                                />
                                {props.errors.EventEnd && props.touched.EventEnd ? (<p className="formError">{props.errors.EventEnd}</p>) : null}
                              </Grid>
                            </Grid>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', width: '43%', flexDirection: 'row', pt: 2, marginBottom: "20px" }}>
              <Button
                color="inherit"
                onClick={this.props.handleBack}
                style={{ marginRight: "20px" }}
              >
                BACK
              </Button>
               {/* @ts-ignore */}
              <Button color="primary" type="submit" ref={this.callRef}>
                NEXT
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      </>
    );
  }
}
// Customizable Area End

