// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  formik?: any;
  addCustomFile?: any;
  deleteRow?: any;
  handleChangeInput?: any;
}

interface S {
  judgeFormType: any;
}

interface SS {
  id: any;
}

export default class CoverImageController extends BlockComponent<
  Props,
  S,
  SS
> {
  editEventCall: any;
  getCurrencyDataAPICall: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  getDiscountwaiverDataApiCall: string = "";

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      judgeFormType:'default'
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
                                          
  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
  }

  // async componentDidUpdate(prevProps: any, prevState: any) {
  //   const { waiverData } = this.state;
  //   if (prevState !== waiverData) {
  //       this.setState({ tabelForm: true, discountForm:false })

  //     } else {
  //       this.setState({ tabelForm: false, discountForm:true})
  //     }
  //   }

  async receive(from: string, message: Message) {

console.log("message", message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          var arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }
      }
    }
  }

  handleCustomJudgeSubmitEvent = (values: any, judgeFormType:any) => {
    let formData = new FormData();
    console.log("formData-->", judgeFormType)
    toast.success("Congratulations, Your changes have been saved")
  }
 
  handleSelect = (e:any)  => {
    console.log("values-->",e.target.value)
    this.setState({judgeFormType: e.target.value});
  };

}
 // Customizable Area End
