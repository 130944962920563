Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage2";
exports.labelBodyText = "LandingPage2 Body";
exports.userSignInEndpoint = "/bx_block_login/logins";
exports.userSignUpEndpoint = "/account_block/accounts";
exports.emailOTPEndpoint = "/account_block/accounts/email_confirm";
exports.googleClientID =
"803291345572-11o0ead0d0qh66tmrkf14i4v13cguddh.apps.googleusercontent.com";

exports.facebookAppID = "777738019946080";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End