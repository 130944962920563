import React, {Component} from 'react';
import {Grid, Typography, Box, TextField, Button} from "@material-ui/core";
import { purple } from '@material-ui/core/colors';
import { createTheme, withStyles} from '@material-ui/core/styles';
import CreateAlbumModalController,{Props} from "./CreateAlbumModalController";

class CreateAlbumModal extends CreateAlbumModalController {
    constructor(props:any) {
        super(props);
    }
    render() {
        return (
            <Box style={{width:"35vw",marginTop:'15px',backgroundColor:"white",padding:'20px',borderRadius:"20px"}}>
                <Typography variant="h5" style={{color:"purple",fontWeight:"bold",marginBottom:"10px"}}>
                    New Album Name
                </Typography>
                <TextField variant="outlined" style={{width:'100%',borderColor:'purple'}} onChange={this.handleChange}/>
                <Box style={{display:'flex',justifyContent:'flex-end',marginTop:"15px"}}>
                    {/*@ts-ignore*/}
                    <CancelButton variant="outlined" onClick={this.props.handleCloseModal}>Cancel</CancelButton>
                    <OkButton variant="contained" onClick={this.handleSubmit}>Ok</OkButton>
                </Box>
            </Box>
        );
    }
}

export default CreateAlbumModal;


const CancelButton = withStyles((theme) => ({
    root: {
        color: purple[500],
        marginRight:"20px",
        '&:hover': {
            color: purple[700],
        },
    },
}))(Button);

const OkButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: purple[500],
        '&:hover': {
            backgroundColor: purple[700],
        },
    },
}))(Button);
