import React from 'react';
import { Box,Container,Link,FormControl,TextField, Tab, Tabs, Button,Typography,Modal,Paper,Grid,FormLabel,CircularProgress } from '@material-ui/core';
import { makeStyles,withStyles} from '@material-ui/core/styles';
import './style.css';

class EmailSubmitters extends React.Component {

    useStyle = makeStyles((theme:any) => ({
        root: {
            flexGrow: 1,
            backgroundColor:"#F2F3F5",
            "& .MuiInputBase-input": {
                height: "1.5em",
                backgroundColor:"#fff"
              }
            // padding: theme.spacing(2),
        },
        paperstyle: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
              margin: theme.spacing(1),
              width: "100%",
              height: "100%",
            },
        },
        inputClass: {
            "& .Mui-focused:after": {
              border: "1px solid green",
            },
          },
          icon: {
            color: "#a5259f",
          },
          selectbox: {
            "& .MuiInputBase-input": {
              height: "1.5em",
            }
          },
    }));

    render() {
        const classes = this.useStyle
    return(
        <Container style={{paddingLeft:"30px"}}>
            <Box style={{padding:"0px 0px 0px 7px"}}>
                <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem",marginBottom:"10px"}}>Email Submitters</Typography>
            </Box>
            <Box style={{ display: 'flex',flexWrap: 'wrap'}}>
                <Paper style={{padding:"15px 15px 30px 15px"}}>
                    <Typography variant="h4" style={{marginBottom:'10px',fontWeight:800}}>Send a custom email to your submitters during a current entry season, up to 2 weeks after your Event Date.</Typography>
                    <Typography style={{fontWeight:800}}>
                        Important: <span style={{fontWeight:500}}>This tool is designed to help you communicate with submitters about your event. Please do not use this tool for marketing purposes or for
                        <Link style={webStyles.commonfontweight}> Selection / Rejection Notifications.</Link></span>
                    </Typography>
                    <Grid container style={{marginTop:"30px"}}>
                        <Grid xs={9}>
                            <Typography>Email <span className="astrick">*</span></Typography>
                        </Grid>
                        <Grid xs={3}>
                            <Button color="inherit" style={{height:"35px"}}>+ ADD NEW EMAIL</Button>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid xs={12}>
                        <FormControl  style={{ padding: '10px 0px 20px 0px', width: '100%'}}>
                            <TextField
                                type="number"  
                                id="outlined-disabled"
                                placeholder="Enter email id"
                                style={{height: "1.5em"}}
                                // onChange={props.handleChange}
                                // onBlur={props.handleBlur}
                                // value={props.values.discount_value}
                                //@ts-ignore
                                InputProps={{ className: classes.root }}
                                name="discount_value"
                                // value={initialValues.facebook_link}
                                //@ts-ignore  
                                className={webStyles.inputClass}
                            />
                        </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    )
}
}

export default EmailSubmitters;

const webStyles = {
    commonfontweight: {
      fontWeight:600
    }
  };
  