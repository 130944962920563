import * as React from "react";

import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  makeStyles,
  Modal,
  Checkbox,
  withStyles,
} from "@material-ui/core";
import { LandingPageImg, noImage } from "./assets";

const AwardSelect = ({ awardData, updateAwardsAndFilmography }: any) => {
  const [ awardAPIData, setAwardAPIData ] = React.useState([])
  React.useEffect(() => {
    setAwardAPIData(awardData)
  }, [awardData])

  const onCheckBoxClick = (id: number) => {
    let updatedList: any = awardAPIData.map((item: any) => {
      if (item.id === id) {
        const { visibilty } = item;
        return { ...item, visibilty: !visibilty };
      }
      return item;
    });
    setAwardAPIData(updatedList);
  };

  const handleSaveClick = () => {
    let updatedAwardsData: any = awardAPIData.map((item: any) => {
        const { visibilty, id } = item;
        return {
            id: id,
            visibilty: visibilty,
        };
      });
      updateAwardsAndFilmography(updatedAwardsData);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "75%",
        }}
      >
        <div style={{ padding: "5px 0" }}>
          Choose which projects appear in your filmography.
        </div>
        <div style={{ padding: "5px 0" }}>
          To add projects and awards to your filmography, go to{" "}
          <a href="/myProject" style={{ color: "#326BE4", textDecoration: "none"}}>My Projects.</a>
        </div>
        <div style={{ padding: "5px 0", marginBottom: "30px" }}>
          Note: Your project credit must match your account name exactly to
          appear in your filmography.
        </div>

        <Box sx={webStyles.formCard}>
          <Box sx={{ bgcolor: "#320b4c", padding: "10px" }}>
            <Typography variant="h4" style={{ color: "#FFF" }}>
              Edit Filmography & Awards
            </Typography>
          </Box>
          {awardAPIData.map((item: any) => {
            return <Box style={webStyles.addShortSection}  key={item.id}>
             <Grid xs>
               <CheckboxWithGreenCheck
                checked={item.visibilty}
                onChange={() => onCheckBoxClick(item.id)}
                />
             </Grid>
             <Grid xs={2}>
               <img
                 style={{ width: "150px", height: "150px", padding: "30px 30px 30px 10px", objectFit: "cover"}}
                 src={item.project_poster ? item.project_poster : noImage}
                 alt="canvas"
               />
             </Grid>
             <Grid xs={9} style={{ padding: "20px" }}>
               <Typography variant="h3">{item.name ? item.name : null}</Typography>
               <Typography variant="h5" style={{ marginTop: "10px" }}>
                 Visible on Profile
               </Typography>
             </Grid>
           </Box>
          })}
        </Box>
        <Box>
          <Button
            variant="contained"
            style={{ background: "#A5259F", color: "#fff", marginTop: "10px" }}
            onClick={() => handleSaveClick()}
          >
            Save Changes
          </Button>
        </Box>
      </div>
    </>
  );
};

export default AwardSelect;

const CheckboxWithGreenCheck = withStyles({
  root: {
    paddingLeft: "30px",
    "&$checked": {
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
      },
      "& .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "#A82BA2",
        zIndex: -1,
      },
      "&.Mui-checked": {
        color: " #fff",
      },
    },
  },
  checked: {},
})(Checkbox);

const webStyles = {
  addShortSection: {
    height: "150px",
    background: "#fff",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center"
  },
  bottomRight: {
    display: "flex",
    flexDirection: "column",
    width: "75%",
  },
  formCard: {
    background: "#fff",
    marginBottom: "50px",
  },
};
