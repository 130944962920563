// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  formik?: any;
  handleNextStep?: any;
  handleBack?: any;
  handleLoadingFalse?: any;
  handleLoadingTrue? : any;
  loading?: any;
  listButton? :any;
}

interface S {
  customRow: any;
  anchorEl: any;
  isReinitailize: boolean;
  festivalData: any;
  festivalID: any;
  error: boolean;
  isDraft: boolean;
}

interface SS {
  id: any;
}

export default class CoverImageController extends BlockComponent<
  Props,
  S,
  SS
> {
  editEventCall: any;
  getCurrencyDataAPICall: string = "";
  validationApiCallId: string = "";
  labelTitle: string = "";
  updateEventAPICall: string = "";
  getFestivalAPICall: string = "";

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.PostDetailDataMessage)
    ]

    this.state = {
      customRow: [{ id: Math.random(), deadline_name: "Earlybird", date: "" },{ id: Math.random(), deadline_name: "Regular", date: "" },{ id: Math.random(), deadline_name: "Late", date: "" }],
      anchorEl: null,
      isReinitailize: true,
      festivalData: null,
      festivalID: null,
      error: false,
      isDraft: true
    };
    // @ts-ignore
    this.callRef = React.createRef(); 
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const id = window.location.href.split("/")[5]
    if(id){
      this.setState({ festivalID: id})
    }
    this.getFestivalData();
  }

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.PostDetailDataMessage) === message.id){
      if(message.properties.text === "SAVE_FESTIVAL_FORM_DATA"){
          //@ts-ignore
          this.callRef?.current.click();
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.updateEventAPICall) {
        this.props.handleLoadingFalse(); 
        if(responseJson && !responseJson.errors) {
          toast.success("Congratulations, Your changes have been saved")
          this.props.handleNextStep();
        } else {
          toast.error(`Error, ${responseJson.errors[0]}`)
        }
      }

      if (apiRequestCallId === this.getFestivalAPICall) {
        const { festivalID } = this.state;
        this.props.handleLoadingFalse(); 
        responseJson && responseJson.data.map((rank: any, i: any, row: any) => {
          if(festivalID){
            if (rank.id === festivalID) {
              this.setState({
                festivalData: rank.attributes.deadline,
                isDraft: rank.attributes.is_draft,
                customRow: rank.attributes.entry_deadlines || [{ id: Math.random(), deadline_name: "Earlybird", date: "" },{ id: Math.random(), deadline_name: "Regular", date: "" },{ id: Math.random(), deadline_name: "Late", date: "" }]
              })
            }
          } else {
            if (i + 1 === row.length) {
              this.setState({
                festivalData: rank.attributes.deadline,
                festivalID: rank.id,
                customRow: rank.attributes.entry_deadlines || [{ id: Math.random(), deadline_name: "Earlybird", date: "" },{ id: Math.random(), deadline_name: "Regular", date: "" },{ id: Math.random(), deadline_name: "Late", date: "" }]
              })
            }
          }
          setTimeout(() => { this.setState({ isReinitailize: false }) }, 2000);
        })
      }
    }
  }

  addCustomFile = () => {
    this.setState({ customRow: [...this.state.customRow, { id: Math.random(), deadline_name: "", date: "" }] });
  };

  deleteRow = (index: any) => {
    const values = [...this.state.customRow];
    values.splice(values.findIndex(value => value.id === index), 1);
    this.setState({ customRow: values });
  };

  handleChangeInput = (id: any, event: any) => {
    const newInputFields = this.state.customRow.map((i: any) => {
      if (id === i.id) {
        i[event.target.name as any] = event.target.value
      }
      return i;
    })
    this.setState({ customRow: newInputFields });
  }

  handleSubmitEvent = (values: any, customRow: any) => {
    const { error, festivalID } = this.state;
    var isError = false;
    const { openingDate, NotiDate, EventStart, EventEnd } = values
    var formData = new FormData();
    formData.append('event[deadline_attributes][opening_date]', values.openingDate);
    formData.append('event[deadline_attributes][notification_date]', values.NotiDate);
    formData.append('event[deadline_attributes][event_start_date]', values.EventStart);
    formData.append('event[deadline_attributes][event_end_date]', values.EventEnd);
    formData.append('event[is_draft]',this.state.isDraft ? "true" : "false");
    customRow.forEach((element: any, id: any) => {
      formData.append(`event[deadline_attributes][entry_deadlines_attributes]${[id]}[deadline_name]`, element.deadline_name);
      formData.append(`event[deadline_attributes][entry_deadlines_attributes]${[id]}[date]`, element.date);
    })

    let deadlinesCorrect = customRow.every((ele:any, i:number) => (ele.date).toString() <= ((customRow[i+1] || {date : Infinity}).date).toString());
    if ((openingDate < (customRow[0].date).toString()) && 
        (deadlinesCorrect && (customRow[customRow.length - 1].date).toString() <= NotiDate) && 
        (NotiDate < EventStart)  && 
        (EventStart <= EventEnd)) {
      isError= false;
    } else {
      isError = true;
      toast.error(`Error!, All Dates & Deadlines must occur in chronological order.`)
    }

    customRow.forEach((element: any, id: any) => {
        if(element.deadline_name && !element.date){
          isError = true;
          let error = `Please provide date for ${element.deadline_name} deadline before proceeding to next step`
          toast.error(`Error!, ${error} `)
        } else if (!element.deadline_name && element.date) {
          isError = true;
          let error = `Please provide name for ${element.date} deadline before proceeding to next step`
          toast.error(`Error!, ${error} `)
        } else if(!element.deadline_name && !element.date){
          isError = true;
          let error = `Please provide deadlines info before proceeding to next step`
          toast.error(`Error!, ${error} `)
        }
    })
    if(!isError){
      this.updateEventDetails(formData, festivalID)
    }
    this.props.handleLoadingTrue(); 
  }

  updateEventDetails = (UpdatedData: any, festivalID: any) => {
    let url = `${configJSON.endPointApiUpdateEventDetails + festivalID}`
    
    const headers = {
      "Content-Type": configJSON.APiContentType,
      token:localStorage.getItem('authToken'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateEventAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      UpdatedData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFestivalData(): boolean {
    this.props.handleLoadingTrue(); 
    const header = {
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFestivalAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiFestivalData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }
  
  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };
  handlePopoverOpen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };
}
 // Customizable Area End
