Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.methdTypeApiGetRole = "GET";
exports.methodTypeApiGetProjectData = "GET";
exports.methodTypePosterData = "GET";
exports.methodTypeHeadshotData = "PATCH";
exports.methdTypeApiGetFilmography = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.addPosterApiMethod = "PATCH";
exports.methdTypeApiRemoveVideo = "POST";
exports.methdTypeApiRestoreVideo = "POST";
exports.editProjectAPiMethod = "PATCH";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Favourites";
exports.labelBodyText = "Favourites Body";
exports.addLinkEndpoint = "/bx_block_posts/over_views/add_news_and_reviews";
exports.addMovieLinkEndpoint = "/bx_block_posts/over_views/add_project_links";
exports.addFileAttachEndpoint = "/bx_block_posts/over_views/add_project_attachments"
exports.EditProjectEndpoint = "bx_block_posts/project_informations/"
exports.GetProjectEndpoint = "bx_block_posts/project_informations/"
exports.GetPosterEndPoint = "bx_block_posts/project_informations/"
exports.endPointApiGetCurrency = "/bx_block_posts/specifications/get_currencies";
exports.endPointApiGetRole = "/bx_block_roles_permissions/roles"
exports.endPointApiGetLanguage = "/bx_block_posts/specifications/get_languages";
exports.endPointApiGetBudget = "/bx_block_posts/specifications/get_production_budget";
exports.addTrailerFileEndpoint= "/bx_block_posts/over_views/add_project_trailer";
exports.addScannerEndpoint = "/bx_block_posts/over_views/add_online_screener";
exports.addHeadshoteEndpoint = "/bx_block_posts/over_views/add_biography";
exports.endPointApiRemoveVideo = "bx_block_posts/over_views/delete_online_screener";
exports.endPointApiRestoreVideo = "/bx_block_posts/over_views/restore_online_screener";
exports.addPosterAttachEndpoint = "bx_block_posts/project_informations/";
exports.endPointApiGetFilmography = "/bx_block_posts/index_visibility";
exports.btnExampleTitle = "CLICK ME";
exports.contentTypeApideleteVideo = "application/json"
exports.endPointApiUpdateAccountDetails = "account_block/accounts/3";
exports.methodTypeApiGetUserProfile = "GET";
exports.deleteAPiMethod = "DELETE";
exports.endPointApiDeleteAccountPhotos = "bx_block_posts/over_views/delete_photography_photos?photo_id[]="
// Customizable Area End