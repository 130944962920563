import React from 'react';
import { Box,Container,FormControl, TextField,Tab, Tabs, Button,Typography,Modal,Paper,Grid,FormLabel,CircularProgress } from '@material-ui/core';
import { makeStyles,withStyles} from '@material-ui/core/styles';
import { Formik } from "formik";
import { stepTwoSchema, formInitialValues } from "./validationSchemas"
import DeadlinePeriodController, {
    Props
  } from "./DeadlinePeriodController";
  import "./style.css";

class  DeadlinePeriod extends DeadlinePeriodController {
    constructor(props: Props) {
        super(props);
      }

    useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            backgroundColor:"#F2F3F5",
            "& .MuiInputBase-input": {
                height: "1.5em",
                backgroundColor:"#fff"
              }
            // padding: theme.spacing(2),
        },
        paperstyle: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
              margin: theme.spacing(1),
              width: "100%",
              height: "100%",
            },
        },
          savebtn:{
            '& > *': {
                margin: theme.spacing(1),
          }
        },
        inputClass: {
            "& .Mui-focused:after": {
              border: "1px solid green",
            },
          },
    }));
    render() {
        const {deadline_waiver_days} = this.state;
        const classes = this.useStyles
    return(
        <Formik
        enableReinitialize
        initialValues={formInitialValues}
        // validationSchema={stepOneSchema}
        onSubmit={(values, actions) => {
          this.handleSubmitEvent(values)
        }}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {props => (
        <form onSubmit={props.handleSubmit}>
        <Container style={{paddingLeft:"30px"}}>
            <Box style={{padding:"0px 0px 0px 7px"}}>
                <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem", marginBottom:"10px"}}>Deadline Waiver Period</Typography>
            </Box>
            <Box style={{ display: 'flex',flexWrap: 'wrap'}}>
                <Paper style={{padding:"15px 15px 30px 15px"}}>
                    <Typography variant="h4" style={{marginBottom:'10px',fontWeight:800}}>This tool allows you to choose exactly how many days after your final deadline your festival 
                        allows Deadline Waiver Codes to be used.</Typography>
                    <Typography style={{fontWeight:500}}>
                        A Deadline Waiver Code allows submitters to submit after your final entry deadline
                    </Typography>
                    <Typography variant="h4" style={{marginTop:'20px',fontWeight:800}}>Deadline Waiver Period <span className="astrick">*</span></Typography>
                    <Grid container spacing={6}>
                        <Grid item xs={6}>
                            <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                <TextField  
                                    id="outlined-disabled"
                                    type="number"
                                    placeholder="Eg. #14 Days"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.deadline_waiver_days}
                                    //@ts-ignore
                                    InputProps={{ className: classes.root }}
                                    name="deadline_waiver_days"
                                    // value={initialValues.facebook_link}
                                    //@ts-ignore  
                                    className={classes.inputClass}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>   
                </Paper>
            </Box>
            <Box style={{margin:"30px 0px 0px 0px"}}>
                <Button color="primary" type="submit">SAVE</Button>
            </Box>
        </Container>
         </form>
         )}
        </Formik>
    )
    }
}

export default DeadlinePeriod;