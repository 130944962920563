import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {toast} from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  title:string;
  publication: string;
  link: string;
  name:string;
  errorMessage: string;
  successMessage: string;
  id:any;
  file:any;
  fileData:any;
  ProjectInfo:any;
  currencyData:any;
  roleData:any;
  languageData:any;
  budgetData:any;
  filmographyData:any;
  headshotData:any;
  posterData:any;
  accountData: any;
  loading: boolean;
  loaders: boolean;
  Videovisible:boolean;
  VideoFlag:boolean;
  selectedPhotos: any;
  loader: boolean;
  imageloader:boolean;

  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FavouritesController extends BlockComponent<
  Props,
  S,
  SS
> {
  AllLinkSubmitApiCall: String = "";
  AddLinkApiCallProject: String = "";
  AddLinksApiCallProject: string = "";
  AddTrailerFileApiCallProject:string = "";
  AddApiCallProject: string = "";
  AddFileAttachApiCallProject:string = "";
  AddFilesAttachApiCallProject:string = "";
  AllEditProjectSubmitApiCall:string = "";
  getshowProjectDataAPICall:string = "";
  getHeadshotDataApiCall:string = "";
  getCurrencyDataAPICall:String = "";
  getRoleDataAPICall: String = "";
  getLanguageAPICall: String = "";
  getBudgetAPICall: String = "";
  AddScannerApiCallProject:String = "";
  ReplaceScannerApiCallProject:String = "";
  AddSubtitleApiCall:String = "";
  AddHeadshoteApiCallProject:String = "";
  removeVideoAPICall:String = "";
  restoreVideoAPICall:String = "";
  getFilmographyDataAPICall:String = "";
  AddPosterApiCallProject:String = "";
  AddStillsCallProject:String = "";
  getPosterDataApiCall:string = "";
  AddProjectsPhotos: string = "";
  getAccountDetailsAPICall: string = '';
  AddMultipleImageAPICall: String = "";
  accountPhotoDeleteCall: String = "";



  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      title:"", 
      publication:"",
      link:"",
      name:"",
      errorMessage: "",
      id:"",
      file:"",
      successMessage: "",
      fileData: null,
      ProjectInfo:null,
      currencyData:null,
      roleData:null,
      languageData:null,
      budgetData:null,
      filmographyData:null,
      headshotData:null,
      posterData:null,
      accountData: null,
      loading: false,
      loaders:false,
      Videovisible: false,
      VideoFlag:false,
      selectedPhotos: null,
      loader: false,
      imageloader:false,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    this.setState({id : window.location.pathname.trim().split("/")[2]})
    this.getProjectInformationData(window.location.pathname.trim().split("/")[2]); 
   }

  async receive(from: string, message: Message) {

    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
       var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        if (apiRequestCallId === this.getshowProjectDataAPICall) {
          this.setState({
            ProjectInfo: responseJson.data
          })
          {typeof this.state.ProjectInfo?.attributes?.online_screener?.data?.attributes != 'undefined' ? this.setState({VideoFlag: true}) : ""}
        }
        if (apiRequestCallId === this.getCurrencyDataAPICall) {
          this.setState({
            currencyData: responseJson.data
          })
        }
        if (apiRequestCallId === this.getRoleDataAPICall) {
          this.setState({
            roleData: responseJson.data
          })
        }
        if (apiRequestCallId === this.getLanguageAPICall) {
          this.setState({
            languageData: responseJson.data
          })
        }
        if (apiRequestCallId === this.getBudgetAPICall) {
          this.setState({
            budgetData: responseJson.data
          })
        }
        if (apiRequestCallId === this.AllLinkSubmitApiCall) {
          if (responseJson != null && responseJson.error === undefined) {
            // toast.success("Congratulations, Your News has been saved!")
            this.getProjectInformationData(window.location.pathname.trim().split("/")[2]);
          } else {
            // this.showAlert("Error", responseJson.error);
            toast.error(responseJson.error)
            this.parseApiErrorResponse(responseJson);
          }
        }
        if (apiRequestCallId === this.AddFileAttachApiCallProject) {
          if (responseJson != null && responseJson.error === undefined) {
            this.getProjectInformationData(window.location.pathname.trim().split("/")[2]);
          } else {
            // this.showAlert("Error", responseJson.error);
            toast.error(responseJson.error)
            this.parseApiErrorResponse(responseJson);
          }
        }
        if (apiRequestCallId === this.AddLinksApiCallProject) {
          if (responseJson != null && responseJson.error === undefined) {
            this.getProjectInformationData(window.location.pathname.trim().split("/")[2]);
          } else {
            // this.showAlert("Error", responseJson.error);
            toast.error(responseJson.error)
            this.parseApiErrorResponse(responseJson);
          }
        }
        if (apiRequestCallId === this.AddHeadshoteApiCallProject) {
          if(responseJson != null && responseJson.error === undefined){
            toast.success("Congratulations, Your Headshot has been saved!");
            this.getHeadShotData(window.location.pathname.trim().split("/")[2]);
          } else {
          toast.error(responseJson.error)
          this.parseApiErrorResponse(responseJson);
          }
        }
        if (apiRequestCallId === this.getFilmographyDataAPICall) {
          this.setState({
            filmographyData: responseJson
          })
        }
        if(apiRequestCallId === this.getHeadshotDataApiCall){
          this.setState({
            headshotData: responseJson
          })
        }
        if(apiRequestCallId === this.getPosterDataApiCall){
          this.setState({
            posterData: responseJson
          })
        }
        if(apiRequestCallId === this.AddScannerApiCallProject){
          if (responseJson != null && !responseJson.error) {
            this.setState({
              loading:false
            })
            toast.success("Congratulations, Your screener has been saved");
            this.getProjectInformationData(window.location.pathname.trim().split("/")[2]);
          } else {
            toast.error(responseJson.error)
            this.parseApiErrorResponse(responseJson);
            }
        }

        if(apiRequestCallId === this.AddMultipleImageAPICall){
          if (responseJson != null && responseJson.error === undefined) {
            toast.success("Photo added successfully.")
            this.getProjectInformationData(window.location.pathname.trim().split("/")[2]);
          } else {
            toast.error(responseJson.error)
            this.parseApiErrorResponse(responseJson);
          }
        }
        if(responseJson && responseJson.meta?.message === "Photos deleted successfully"){
          toast.success("Photo Deleted successfully.")
          this.getProjectInformationData(window.location.pathname.trim().split("/")[2]);
        }
        // if(apiRequestCallId === this.ReplaceScannerApiCallProject){
        //   if (responseJson != null && !responseJson.error) {
        //     this.setState({
        //       loaders:false
        //     })
        //     toast.success("Congratulations, Your screener has been replaced");
        //   }
        // }
        if(apiRequestCallId === this.AddSubtitleApiCall){
          if (responseJson != null && !responseJson.error) {
            toast.success("Congratulations, Your screener has been replaced");
          }
        }

        if (apiRequestCallId === this.AddPosterApiCallProject) {
          if (responseJson != null && !responseJson.error) {
            toast.success("Congratulations, Your poster has been saved")
            this.getPosterData(window.location.pathname.trim().split("/")[2]);
          } else {
            toast.error(responseJson.error)
            this.parseApiErrorResponse(responseJson);
          }
        }
        if(apiRequestCallId == this.AddStillsCallProject){
          if(responseJson != null && !responseJson.error){
            toast.success("Congratulations, Your frame updated!")
          }
        }
        if(apiRequestCallId === this.AddProjectsPhotos){
          if(responseJson != null && !responseJson.error){
            toast.success("Congratulations, Your photo has been saved!")
          }
        }

        if(responseJson.message == "online_screener deleted successfully!"){
          this.setState({
            VideoFlag:false
          })
          this.getProjectInformationData(this.state.id);
        }
        if(responseJson.message == "online_screener recover successfully!"){
          this.setState({
            VideoFlag:true
          })
          this.getProjectInformationData(this.state.id);
        }
        if (apiRequestCallId === this.AllEditProjectSubmitApiCall) {
          if (responseJson != null && !responseJson.error) {
            toast.success("Congratulations, Your project has been edited!")
              const timer = setTimeout(() => {
                this.goToProjectPage(window.location.pathname.trim().split("/")[2])
              }, 2000);
          } else {
            toast.error(responseJson.error)
          }
        }
      }   
    // Customizable Area Start
    // Customizable Area End

  }
  handleSubmitNews = (newsreview:any) => {    
    let data = {
      "project_information_id" : parseInt(newsreview.id),
      "news_and_reviews":{  
        "title": newsreview.title,
        "publication":newsreview.publication,
        "link":newsreview.link,
      }
    }
    this.NewsHandler(data)
    if (data != null) {
      toast.success("Congratulations,Your News & Review has been saved")
    } 
        // this.showAlert("Congratulations", " Your News & Review has been saved!");  
  }

  handleSubmitLinks= (links:any) => {
    let data = {
        "project_information_id" : parseInt(links.id),
          "project_links":{  
              "name": links.name,
              "link":links.link,
          }
        }
        this.LinksHandler(data)
        if (data != null) {
          toast.success("Congratulations, Your link has been saved")
        } 
        // this.showAlert("Congratulations", "Your link has been saved!");
  }

  handleSubmitTrailer= (trailer:any) => {
    let formData = new FormData();

    formData.append('project_trailers[video_link]', trailer.video_link);
    formData.append('project_information_id', trailer.id);
    if(trailer.trailer_file)
     formData.append('project_trailers[trailer_file]', trailer.trailer_file);
  
    this.trailerHandler(formData, trailer.id)
    if (formData != null) {
      toast.success("Congratulations,Your Trailer has been saved")
    } 
    // this.showAlert("Congratulations", "Your Trailer has been saved");
  }
  
  handleHeadshotSubmit= (selectedFile:any) => {
    let formData = new FormData();

    formData.append('director_bio_details[biography]', selectedFile.biography);
    formData.append('project_information_id', selectedFile.id);
    if(selectedFile.headshot)
     formData.append('director_bio_details[headshot]', selectedFile.headshot);
  
    this.headshotHandler(formData, selectedFile.id)
    // this.showAlert("Congratulations", "Your Headshot has been saved!");
  }

  handleFileAttachLinks= (fileattach:any) => {    
    let formData = new FormData();
  
    formData.append('project_files[name]', fileattach.name);
    formData.append('project_information_id', fileattach.id);
    if(fileattach.file)
     formData.append('project_files[file]', fileattach.file);
  
    this.FilesAttachHandler(formData, fileattach.id)
    // this.showAlert("Congratulations", "Your Files & attachment has been saved!");
  }

  handlePosterImg = (poster:any) => {
    let formData = new FormData();
    formData.append('project_information[project_poster]', poster.media_file);
    this.AddPosterImg(formData)
  }

  handleStillPhoto = (stillPhoto:any) => {
    let formData = new FormData();
    formData.append('project_information[still_frame]', stillPhoto.still_frame);
    this.AddStillImg(formData)
    // this.showAlert("Congratulations", "Your Files & attachment has been saved!");
  }
  handleScennerVid = (screenervideo:any) => {
    let formData = new FormData();
    formData.append('project_information_id', screenervideo.id);
    // formData.append('online_screeners[subtitle_file]', screenervideo.subtitle_file)
    formData.append('online_screeners[video_file]', screenervideo.video_file)
    this.addScanner(formData, screenervideo.id)
  }
  handleSubtitleFiles = (subtitllelan:any,subtitleFile:any) => {
    let formData = new FormData();
    formData.append('project_information_id', subtitllelan.id);
    formData.append('online_screeners[subtitle_language_id]', subtitllelan.subtitle_language_id);
    formData.append('online_screeners[subtitle_file]', subtitleFile.subtitle_file);
    this.addSubtitle(formData, subtitllelan.id)
  }

  handlePhotoImg = (mediaimage:any) => {
    let formData = new FormData();
    formData.append('project_information[photo]', mediaimage.photo);
    this.AddProjectPhoto(formData)
  }
  
  handleScanner= (screener:any) => {
    let formData = new FormData();
    formData.append('online_screeners[video_url]', screener.video_url);
    formData.append('online_screeners[password]', screener.password);
    formData.append('project_information_id', screener.id);
    if(screener.video_file)
     formData.append('online_screeners[video_file]', screener.video_file);
  
    this.setState({VideoFlag: true})
    this.onlineScannerHadler(formData, screener.id)
  }

  handleUpdatePhotos = (files: any) => {
    let formData = new FormData();    
    if (files && files.length) {
      this.setState({
        selectedPhotos: files,
        imageloader: true
      })
      
      formData.append('project_information_id', window.location.pathname.trim().split("/")[2]);
      files && Object.entries(files)?.forEach((file : any) => 
       formData.append('online_screeners[photography_photos][]', file[1])
      )
      this.AddMultipleImageHandler(formData,files.id);
    }
  }

AddMultipleImageHandler = (data: any, Id: any) => {
  // this.setState({
  //   errorMessage: "",
  //   successMessage: ""
  // })    
  let url = ''
  let httpBody = {}
  url = `${configJSON.addScannerEndpoint}`
  httpBody = {
    online_screeners: data,
    project_information_id:Id
  }
  const headers = {
    // "Content-Type": configJSON.contentTypeApiGetUserProfile,
    token: localStorage.getItem('authToken'),
    "x-amz-acl": 'public-read'
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.AddMultipleImageAPICall = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage), url
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    data
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

deleteAccountPhotos(id: any) {
  let url = ''
  let httpBody = {}
  const token = localStorage.getItem('token')
  url = `${configJSON.endPointApiDeleteAccountPhotos}${id}&project_information_id=${window.location.pathname.trim().split("/")[2]}`
  const headers = {
    "token": token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  // this.accountPhotoDeleteCall = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage), url
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.deleteAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}


handleUploadFileChange = (e:any) => {
  this.setState({
    fileData: e.target.files[0],
    file: URL.createObjectURL(e.target.files[0])
  })
}

getAccountData(): boolean {
  const header = {
    "Content-Type": configJSON.urlGetValidations,
    token: localStorage.getItem('authToken')
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getAccountDetailsAPICall = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.endPointApiUpdateAccountDetails
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodTypeApiGetUserProfile
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  
  return true;
}

  //================================================== EDIT PROJECT DETAILS ===============================

handleEditProject = (values:any, id:any) => {
  // let formData = new FormData();
  // formData.append('project_information[name]',values.name)
  // formData.append('project_information[project_type]',values.project_type)
  // formData.append('project_information[description]',values.description)
  // formData.append('project_information[website_link]',values.website_link)
  // formData.append('project_information[facebook_link]',values.facebook_link)
  // formData.append('project_information[twitter_link]',values.twitter_link)
  // formData.append('project_information[instagram_link]',values.instagram_link)
  // formData.append('project_information[imdb_link]',values.imdb_link)
  // formData.append('project_information[submitter_info_attributes][email]',values.email)
  // formData.append('project_information[submitter_info_attributes][phone_number]',values.phonenno)
  // formData.append('project_information[submitter_info_attributes][dob]',values.date_of_birth)
  // formData.append('project_information[submitter_info_attributes][country_of_origin]',values.country_of_origin)
  // formData.append('project_information[submitter_info_attributes][country_of_residence]',values.country_of_residence)
  // formData.append('project_information[submitter_info_attributes][address]',values.address)
  // formData.append('project_information[submitter_info_attributes][city]',values.city)
  // formData.append('project_information[submitter_info_attributes][state]',values.state)
  // formData.append('project_information[submitter_info_attributes][postal_code]',values.postal_code)
  // formData.append('project_information[submitter_info_attributes][gender]',values.gender)
  // formData.append('project_information[imdb_link]',values.imdb_link)
    let data = {
          "project_information":{  
              "project_type": values.project_type,
              "name":values.name,
              "description":values.description,
              "website_link":values.website_link,
              "facebook_link":values.facebook_link,
              "twitter_link":values.twitter_link,
              "instagram_link":values.instagram_link,
              "imdb_link":values.imdb_link,
            "submitter_info_attributes": { 
              "email":values.email,
              "phone_number":values.phoneno,
              "dob":values.date_of_birth,
              "country_of_origin":values.country_of_origin,
              "country_of_residence":values.country_of_residence,
              "address":values.address,  
              "city":values.city,
              "state":values.state,
              "postal_code":values.postal_code,
              "gender":values.gender,
            },
            "credits_attributes": values.credits_attributes,
            "specification_attributes": {
              "first_time_filmmaker":values.firstfilm,
              "student_project":values.studentproject,
              "runtime":values.runtime,
              "completion_date": values.completiondate,
              "production_budget":values.productionbudget,
              "currency":values.currency,
              "country_of_origin":values.countryorigin,
              "country_of_residence":values.countryfilming,
              "language_id":values.language,
              "shooting_format":values.shooting_format,
              "aspect_ratio":values.aspectration,
              "film_color":values.filmcolour,
              "genres":values.genres,
              "screening_and_award":values.screeningandaward
            },
            "screening_and_awards_attributes": values.screening_and_awards_attributes,
            "distributors_attributes":values.distributor
          },
        }
        console.log('values///==', values.distributor)
    this.EditProjectHandler(data, id)
}

  //====================================== EDIT PROJECT API CALL =======================================

EditProjectHandler = (data: any, id:any) => {
  this.setState({
    errorMessage: "ggfgfgfgfgf",
    successMessage: "success"
  })

  let url = ''
  let httpBody = {}
  url = `${configJSON.EditProjectEndpoint}${id}`
  httpBody = data
  const token = localStorage.getItem('token')
  const headers = {
    "Content-Type": configJSON.exampleApiContentType,
    "token" : token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.AllEditProjectSubmitApiCall = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage), url
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.editProjectAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

  //=================================== ADD NEWS & REVIEWS ===========================================

NewsHandler = (data: any) => {
  this.setState({
    errorMessage: "ggfgfgfgfgf",
    successMessage: "success"
  })

  let url = ''
  let httpBody = {}
  url = `${configJSON.addLinkEndpoint}`
  httpBody = data
  const token = localStorage.getItem('authToken')
  const headers = {
    "Content-Type": configJSON.exampleApiContentType,
    "token" : token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.AllLinkSubmitApiCall = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage), url
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

  //===================================ADD LINK API CALL============================

LinksHandler = (data: any) => {
  this.setState({
    errorMessage: "ggfgfgfgfgf",
    successMessage: "success"
  })

  let url = ''
  let httpBody = {}
  url = `${configJSON.addMovieLinkEndpoint}`
  httpBody = data
  const token = localStorage.getItem('token')
  const headers = {
    "Content-Type": configJSON.exampleApiContentType,
    "token" : token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.AddLinksApiCallProject = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage), url
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

  //==============================================ADD TRAILER & FILES =============================

trailerHandler = (data: any, Id:any) => {
  this.setState({
    errorMessage: "ggfgfgfgfgf",
    successMessage: "success"
  })

  let url = ''
  let httpBody = {}
  url = `${configJSON.addTrailerFileEndpoint}`
  httpBody = { project_trailers: data,
               project_information_id:Id}
  const headers = {
    // "Content-Type": configJSON.exampleApiContentType,
    "token": localStorage.getItem('token'),
    "x-amz-acl": 'public-read'
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.AddTrailerFileApiCallProject = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage), url
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    data
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

  //============================================ADD HEADSHOT & BIOGRAPHY ==========================

headshotHandler = (data: any, Id:any) => {
  this.setState({
    errorMessage: "ggfgfgfgfgf",
    successMessage: "success"
  })

  let url = ''
  let httpBody = {}
  url = `${configJSON.addHeadshoteEndpoint}`
  httpBody = { director_bio_details: data,
               project_information_id:Id}
  const headers = {
    // "Content-Type": configJSON.exampleApiContentType,
    "token": localStorage.getItem('token'),
    "x-amz-acl": 'public-read'
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.AddHeadshoteApiCallProject = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage), url
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    data
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

  //==============================================ADD ONLINE SCREENER==============================

onlineScannerHadler = (data: any, Id:any) => {
  this.setState({
    errorMessage: "ggfgfgfgfgf",
    successMessage: "success",
    loading: true
  })

  let url = ''
  let httpBody = {}
  url = `${configJSON.addScannerEndpoint}`
  httpBody = { online_screeners: data,
               project_information_id:Id}
  const headers = {
    // "Content-Type": configJSON.exampleApiContentType,
    "token": localStorage.getItem('token'),
    "x-amz-acl": 'public-read'
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.AddScannerApiCallProject = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage), url
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    data
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

  //===============================================ADD SCANNER ON PROJECT FILE====================

addScanner = (data: any, Id:any) => {
  this.setState({
    errorMessage: "ggfgfgfgfgf",
    successMessage: "success",
    loaders: true
  })
  let url = ''
  let httpBody = {}
  url = `${configJSON.addScannerEndpoint}`
  httpBody = { online_screeners: data,
               project_information_id:Id}
  const headers = {
    // "Content-Type": configJSON.exampleApiContentType,
    "token": localStorage.getItem('token'),
    "x-amz-acl": 'public-read'
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.AddSubtitleApiCall = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage), url
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    data
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

addSubtitle = (data: any, Id:any) => {
  this.setState({
    errorMessage: "ggfgfgfgfgf",
    successMessage: "success",
    loaders: true
  })
  let url = ''
  let httpBody = {}
  url = `${configJSON.addScannerEndpoint}`
  httpBody = { online_screeners: data,
               project_information_id:Id}
  const headers = {
    // "Content-Type": configJSON.exampleApiContentType,
    "token": localStorage.getItem('token'),
    "x-amz-acl": 'public-read'
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.ReplaceScannerApiCallProject = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage), url
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    data
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

  //==============================================ADD FILES ATTACH UPLOAD==========================

  FilesAttachHandler = (data: any, Id: any) => {
    let url = ''
    let httpBody = {}
    url = `${configJSON.addFileAttachEndpoint}`
    httpBody = {
      project_files: data,
      project_information_id:Id
    }
    const headers = {
      // "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "token": localStorage.getItem('token'),
      "x-amz-acl": 'public-read'
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.AddFileAttachApiCallProject = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  //========================================ADD POSTER IMAGE===========================================

AddPosterImg = (data: any) => {
  // this.setState({
  //   errorMessage: "ggfgfgfgfgf",
  //   successMessage: "success"
  // })
  let id =  window.location.pathname.trim().split("/")[2]
  let url = ''
  let httpBody = {}
  url = `${configJSON.addPosterAttachEndpoint}${id}`
  httpBody = {
    "project_information[project_poster]": data
  }
  const headers = {
    // "Content-Type": configJSON.contentTypeApiGetUserProfile,
    "token": localStorage.getItem('token'),
    "x-amz-acl": 'public-read'
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.AddPosterApiCallProject = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage), url
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    data
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.addPosterApiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

  //=========================================ADD STILL IMAGE============================================

AddStillImg = (data: any) => {
  // this.setState({
  //   errorMessage: "ggfgfgfgfgf",
  //   successMessage: "success"
  // })
  let id =  window.location.pathname.trim().split("/")[2]
  let url = ''
  let httpBody = {}
  url = `${configJSON.addPosterAttachEndpoint}${id}`
  httpBody = {
    "project_information[still_frame]": data
  }
  const headers = {
    // "Content-Type": configJSON.contentTypeApiGetUserProfile,
    "token": localStorage.getItem('token'),
    "x-amz-acl": 'public-read'
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.AddStillsCallProject = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage), url
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    data
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.addPosterApiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

  //=========================================ADD PROJECT PHOTO ==========================================

AddProjectPhoto = (data: any) => {
  // this.setState({
  //   errorMessage: "ggfgfgfgfgf",
  //   successMessage: "success"
  // })
  let id =  window.location.pathname.trim().split("/")[2]
  let url = ''
  let httpBody = {}
  url = `${configJSON.addPosterAttachEndpoint}${id}`
  httpBody = {
    "project_information[photo]": data
  }
  const headers = {
    // "Content-Type": configJSON.contentTypeApiGetUserProfile,
    "token": localStorage.getItem('token'),
    "x-amz-acl": 'public-read'
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.AddProjectsPhotos = requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage), url
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    data
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.addPosterApiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

  //========================================GET SHOW PROJECT API CALL ========================================

getProjectInformationData(id:any): boolean {
  const url = `${configJSON.GetProjectEndpoint}${id}` 
  const header = {
    "Content-Type": configJSON.urlGetValidations,
    token: localStorage.getItem('token'),
    "x-amz-acl": 'public-read'
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getshowProjectDataAPICall = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    url
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodTypeApiGetProjectData
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  
  return true;
}

  //===============================GET HEADSHOT DATA===================================================

getHeadShotData(id:any): boolean {

  let formData = new FormData();
  let httpBody = {}
  formData.append("project_information[id]", "")
  const token = localStorage.getItem('authToken')

  const url = `${configJSON.GetProjectEndpoint}${id}`
  const header = {
    "Content-Type": configJSON.urlGetValidations,
    token: localStorage.getItem('authToken'),
    "x-amz-acl": 'public-read'
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getHeadshotDataApiCall = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    url
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodTypeHeadshotData
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  
  return true;
}

  //=============================GET POSTER DATA================================

getPosterData(id:any): boolean {
  const url = `${configJSON.GetPosterEndPoint}${id}` 
  const header = {
    "Content-Type": configJSON.urlGetValidations,
    token: localStorage.getItem('authToken'),
    "x-amz-acl": 'public-read'
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getPosterDataApiCall = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    url
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodTypePosterData
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  
  return true;
}

  //====================================GET CURRENCY DATA API CALL ===================================

getCurrencyData(): boolean {
  const header = {
    "Content-Type": configJSON.urlGetValidations,
    token: localStorage.getItem('authToken')
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getCurrencyDataAPICall = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.endPointApiGetCurrency
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodTypeApiGetProjectData
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  
  return true;
}

  //===========================================GET ROLL API CALL=================================

getRoleData(): boolean {
  const header = {
    "Content-Type": configJSON.urlGetValidations,
    token: localStorage.getItem('authToken')
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getRoleDataAPICall = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.endPointApiGetRole
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodTypeApiGetProjectData
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  
  return true;
}
  //=========================================GET LANGUAGE DATA API CALL=============================== 


getLanguageData(): boolean {
  const header = {
    "Content-Type": configJSON.urlGetValidations,
    token: localStorage.getItem('authToken')
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getLanguageAPICall = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.endPointApiGetLanguage
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methdTypeApiGetRole
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  
  return true;
}

  //============================================== GET PRODUCTION BUDGET =======================

getProductionBudget(): boolean {
  const header = {
    "Content-Type": configJSON.urlGetValidations,
    token: localStorage.getItem('authToken')
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getBudgetAPICall = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.endPointApiGetBudget
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methdTypeApiGetRole
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  
  return true;
}

  //============================================GET FILMOGRAPHY =======================================

getFilmographyData(): boolean {
  const header = {
    "Content-Type": configJSON.urlGetValidations,
    token: localStorage.getItem('authToken')
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getFilmographyDataAPICall = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.endPointApiGetFilmography
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methdTypeApiGetFilmography
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  
  return true;
}
  
  //======================================== REMOVE VIDEO ==================================

deleteVideo(id:any) {
  let url = ''
  let httpBody = {}
  url = `${configJSON.endPointApiRemoveVideo}`
  httpBody = {project_information_id:parseInt(id)}
  const header= {
    "Content-Type": configJSON.contentTypeApideleteVideo,
    token: localStorage.getItem("authToken")
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  // this.deleteCall = requestMessage && requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage), url
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methdTypeApiRemoveVideo
  );
  runEngine.sendMessage(requestMessage.id, requestMessage) 

}

  //======================================= RESTORE VIDEO ===============================

restoreVideo(id:any): boolean {
  let url = ''
  let httpBody = {}
  url = `${configJSON.endPointApiRestoreVideo}`
  httpBody = {project_information_id:parseInt(id)}
  const header = {
    "Content-Type": configJSON.contentTypeApideleteVideo,
    token: localStorage.getItem('authToken')
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  // this.deleteCall = requestMessage && requestMessage.messageId;
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage), url
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methdTypeApiRestoreVideo
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  
  return true;
}

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }
  goToProjectPage(id:any){
    window.location.replace(`/MyprojectDetail/${id}/?step=0`);
  }

  // Customizable Area Start
  // Customizable Area End
  
}
