import React from "react";

import {
  Typography,
  Grid,
} from "@material-ui/core";
import AppHeader from "../../../components/src/AppHeader.web"
import Footer from "../../../components/src/AppFooter.web"

import Settings2Controller, {
  Props,
  configJSON
} from "./Settings2Controller";
import EditProfile from "./EditProfile.web";

// import { dividerClasses } from "@mui/material";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  
  }

  // Customizable Area handleSubmitProject
  async componentDidMount() {
    this.getFilmographyAndAwards();
    this.getUserData();
    this.getAccountData();
   }
  // Customizable Area End
  
  render() {
    const { navigation } = this.props;
    return (
      <>
      {/* Customizable Area Start */}
        <AppHeader
            // @ts-ignore
            toggleLoginModal={() => navigation.navigator('/')}
            toggleSignupModal={() => navigation.navigator('/')}
        />
        <EditProfile
          handleSubmitProject={this.handleSubmitProject}
          awardData={this.state.awardData}
          updateAwardsAndFilmography={this.updateAwardsAndFilmography}
          userData={this.state.userData}
          accountData={this.state.accountData}
          handleProfilePic = {this.handleProfilePic}
          handleCoverPic = {this.handleCoverPic}
        />
        <Footer />
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const wenStyles = {
}
// Customizable Area End