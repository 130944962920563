export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgNotification = require("../assets/ic_notification.png");
export const downArrow = require("../assets/down-arrow.png");
export const Delete = require("../assets/delete.png");
export const arrowUp = require("../assets/arrow-up.png");
export const logo = require("../assets/logo.png");
export const noNotification = require("../assets/no-alarm.png")
export const imageIcon = require("../assets/img-icon.png")
export const awardIcon = require("../assets/award-img.png")
export const selectIcon = require("../assets/select-img.png")
export const projectTitleImg = require("../assets/square-img.png")
// export const defaultproject = require("../assets/defaultproject.jpg")
export const defaultproject = require("../assets/noImage.png");
