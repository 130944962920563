import React from 'react';
import { Box,Container,FormControl,TextField,Checkbox,Button,Typography,FormGroup,FormControlLabel,Paper,Grid,FormLabel} from '@material-ui/core';
import { makeStyles,withStyles} from '@material-ui/core/styles';
import { checked } from '../../categoriessubcategories/src/assets';
import { Formik } from "formik";
import { stepTwoSchema, manageUserInitialValue } from "./validationSchemas";
import ManageUserController, {
    Props
  } from "./ManageUserController";
import "./style.css";

const CustomColorCheckbox = withStyles({
    root: {
      color: "#a5259f",
      "&$checked": {
        color: "#a5259f"
      } 
    },
    checked: {}
  })((props) => <Checkbox color="default" {...props} />);

class ManageUser extends ManageUserController{
    constructor(props:any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    useStyle = makeStyles((theme:any) => ({
        root: {
            flexGrow: 1,
            backgroundColor:"#F2F3F5",
            "& .MuiInputBase-input": {
                height: "1.5em",
                backgroundColor:"#fff"
              }
            // padding: theme.spacing(2),
        },
        paperstyle: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
              margin: theme.spacing(1),
              width: "100%",
              height: "100%",
            },
        },
        inputClass: {
            "& .Mui-focused:after": {
              border: "1px solid green",
            },
          },
          icon: {
            color: "#a5259f",
          },
          selectbox: {
            "& .MuiInputBase-input": {
              height: "1.5em",
            }
          },
    }));

    render() {
        const { checked } = this.state;
        const classes = this.useStyle

    return(
        <Formik
        enableReinitialize
        initialValues={manageUserInitialValue}
        // validationSchema={stepOneSchema}
        onSubmit={(values, actions) => {
          this.handleSubmitEvent(values)
        }}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {props => (
        <form onSubmit={props.handleSubmit} style={{width:"100%"}}>
        <Container style={{paddingLeft:"30px"}}>
            <>
            {
                //@ts-ignore
            checked == true ?                                                                                                                                                                           
            <>
            <Box style={{padding:"0px 0px 0px 7px"}}>
                <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem",marginBottom:"10px"}}>Manage Users</Typography>
            </Box>
            <Box style={{flexWrap:"wrap"}}>
                <Paper style={{padding:"15px 15px 30px 15px"}}>
                    <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>Create sub-accounts with customized roles and permissions for your 
                        staff and judges.</Typography>
                    <Grid container spacing={3} style={{marginTop:"20px"}}>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="h4" style={{fontWeight:800}}>Action</Typography>
                        </Grid>
                    </Grid> 
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={8}>
                            <FormControl  style={{width: '100%'}}>
                                    <TextField
                                      id="standard-select-currency-native"
                                      select
                                    //   onChange={(e) => setIntialValue({...initialValues,gender:e.target.value})}
                                    //@ts-ignore
                                      className={classes.inputClass}
                                      //@ts-ignore
                                      InputProps={{ className: classes.selectbox}}
                                      SelectProps={{
                                        //@ts-ignore
                                        classes: { icon: classes.icon },
                                        native:true
                                      }}
                                      variant="standard"
                                      name="gender"
                                    //   value={initialValues.gender}
                                    >
                                        <option value={""}>select from option</option>
                                        <option value={'bulk action'}>Bulk Actions</option>
                                        <option value={'single action'}>Single Action</option>
                                    </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button color="inherit" style={{width: '100%'}} onClick={this.handleAddUserClick}> + ADD USER</Button>
                        </Grid>
                    </Grid> 
                    <Paper>
                        <Grid container style={{alignItems:"center", justifyContent:"center",marginTop:"20px"}}>
                            <Grid item xs={12} sm={4}>
                                <FormGroup>
                                    <FormControlLabel control={<CustomColorCheckbox />} label="Name" style={{margin:"0px"}}/>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography style={{fontWeight:600, fontSize:"1rem"}}>Email</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} style={{marginBottom:"25px"}}>
                                <FormGroup>
                                    <Typography variant="h4" style={{paddingLeft:"40px",fontWeight:800}}>John Doe</Typography>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={8} style={{marginBottom:"25px"}}>
                                <Typography variant="h4"  style={{fontWeight:800}}>John@gmail.com</Typography>
                            </Grid>
                        </Grid>
                    </Paper>  
                </Paper>
            </Box>
            </>
            :
          <>
            <Box style={{padding:"0px 0px 0px 7px"}}>
                <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem",marginBottom:"10px"}}>New User</Typography>
            </Box>
            <Box style={{flexWrap:"wrap"}}>
                <Paper style={{padding:"15px 15px 30px 15px"}}>
                    <Grid container spacing={6}>
                        <Grid item xs={6}>
                            <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                <FormLabel component="legend">First Name <span className="astrick">*</span></FormLabel>
                                <TextField  
                                    id="outlined-disabled"
                                    placeholder="Enter your first name"
                                    style={{height: "1.5em"}}
                                    onChange={props.handleChange}
                                    value={props.values.fname}
                                    //@ts-ignore
                                    InputProps={{ className: classes.root }}
                                    name="fname"
                                    //@ts-ignore  
                                    className={classes.inputClass}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                <FormLabel component="legend">Last Name <span className="astrick">*</span></FormLabel>
                                <TextField
                                    id="standard-select-currency-native"
                                    placeholder="Enter your last name"
                                    onChange={props.handleChange}
                                    value={props.values.lname}
                                    //@ts-ignore
                                    InputProps={{ className: classes.root }}
                                    name="lname"
                                    //@ts-ignore
                                    className={classes.inputClass}
                                    />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                    <FormLabel component="legend">Email <span className="astrick">*</span></FormLabel>
                                    <TextField
                                        id="standard-select-currency-native"
                                        placeholder="Enter your email id"
                                        onChange={props.handleChange}
                                        value={props.values.email}
                                        //@ts-ignore
                                        InputProps={{ className: classes.root }}
                                        name="email"
                                        //@ts-ignore 
                                        className={classes.inputClass}
                                        />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                    <FormLabel component="legend">Internal Note (Optional)</FormLabel>
                                    <TextField
                                        id="standard-select-currency-native"
                                        placeholder="Note"
                                        onChange={props.handleChange}
                                        value={props.values.internal_note}
                                        //@ts-ignore
                                        InputProps={{ className: classes.root }}
                                        name="internal_note"
                                        //@ts-ignore
                                        className={classes.inputClass}
                                        />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>

            <Box style={{padding:"0px 0px 0px 7px", marginTop:"30px"}}>
                <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem",marginBottom:"10px"}}>Permissions</Typography>
            </Box>
            <Box style={{display:"flex", flexWrap:"wrap"}}>
                <Paper style={{padding:"15px 15px 30px 15px"}}>
                    <Typography variant="h4" style={{fontWeight:800}}>Select the permissions you would like to grant this user.</Typography>
                    <Box style={{display: "flex",  flexDirection: 'row', width: '90%'}}>
                        <Button variant="text" name="check-all" style={{fontWeight:500,color:"#A5259F"}}>Check All</Button>
                        <Typography style={{ borderRight:"1px solid #000", height:"20px", marginTop:"8px"}}></Typography>
                        <Button variant="text" name="uncheck-all"  style={{fontWeight:500,color:"#A5259F"}}>Uncheck All</Button>
                    </Box>

                    <FormControl component="div" style={{ padding: '20px 0px', width: '100%'}}>
                        <FormLabel component="legend">Administration</FormLabel>
                        <FormControl style={{display: "flex",  flexDirection: 'row', width: '90%', justifyContent:"space-between"}}>
                            <FormControlLabel control={<CustomColorCheckbox/>}label="Edit Festival"/>
                              <FormControlLabel control={<CustomColorCheckbox />} label="Manage Photos" />
                              <FormControlLabel control={<CustomColorCheckbox />} label="Discounts & Waivers" />
                              <FormControlLabel control={<CustomColorCheckbox />} label="Purchase Marketing" />                         
                        </FormControl>
                        <FormControl style={{display: "flex",  flexDirection: 'row', width: '60%', justifyContent:"space-between"}}>
                              <FormControlLabel control={<CustomColorCheckbox />} label="Manage Users and Permissions" />               
                        </FormControl>   
                    </FormControl>


                    <FormControl component="div" style={{ padding: '20px 0px', width: '100%'}}>
                        <FormLabel component="legend">Financials</FormLabel>
                        <FormControl style={{display: "flex",  flexDirection: 'row', width: '90%'}}>
                            <FormControlLabel control={<CustomColorCheckbox/>}label="Reports"/>
                            <FormControlLabel control={<CustomColorCheckbox />} label="Payout Information" />                     
                        </FormControl> 
                    </FormControl>


                    <FormControl component="div" style={{ padding: '20px 0px', width: '100%'}}>
                        <FormLabel component="legend">Submissions</FormLabel>
                        <FormControl style={{display: "flex",  flexDirection: 'row', width: '100%', justifyContent:"space-between"}}>
                            <FormControlLabel control={<CustomColorCheckbox/>}label="View Submissions"/>
                              <FormControlLabel control={<CustomColorCheckbox />} label="Manage Submissions" />
                              <FormControlLabel control={<CustomColorCheckbox />} label="Assign Submissions to Judges" />
                              <FormControlLabel control={<CustomColorCheckbox />} label="Batch Submissions" />                         
                        </FormControl> 
                    </FormControl>


                    <FormControl component="div" style={{ padding: '20px 0px', width: '100%'}}>
                        <FormLabel component="legend">Judging</FormLabel>
                        <FormControl style={{display: "flex",  flexDirection: 'row', width: '90%', justifyContent:"space-between"}}>
                            <FormControlLabel control={<CustomColorCheckbox/>}label="Judge Submissions"/>
                              <FormControlLabel control={<CustomColorCheckbox />} label="Update Judging Status" />
                              <FormControlLabel control={<CustomColorCheckbox />} label="View Other Judge's Ratings & Notes" />
                        </FormControl>
                        <FormControl style={{display: "flex",  flexDirection: 'row', width: '100%', justifyContent:"space-between"}}>
                              <FormControlLabel control={<CustomColorCheckbox />} label="View Submitter Contact Information" />                         
                              <FormControlLabel control={<CustomColorCheckbox />} label="View Submission Flags" />
                              <FormControlLabel control={<CustomColorCheckbox />} label="Update Submission Flag" />               
                        </FormControl>   
                    </FormControl>


                    <FormControl component="div" style={{ padding: '20px 0px', width: '100%'}}>
                        <FormLabel component="legend">Tickets</FormLabel>
                        <FormControl style={{display: "flex",  flexDirection: 'row', width: '90%', justifyContent:"space-between"}}>
                            <FormControlLabel control={<CustomColorCheckbox/>}label="Edit & Manage Tickets"/>
                              <FormControlLabel control={<CustomColorCheckbox />} label="Manage Orders" />
                              <FormControlLabel control={<CustomColorCheckbox />} label="Manage Attendees" />                         
                        </FormControl> 
                    </FormControl>
                </Paper>
            </Box>

            <Box sx={{ display: 'flex', width: '43%', flexDirection: 'row', pt: 2 }}>
                <Button color="primary" type="submit">CREATE USER</Button> 
                <Box sx={{ flex: '1 1 auto', marginLeft:"10px"}} />
                    <Button
                        color="inherit"
                        onClick={this.handleAddUserCancle}
                        style={{color:"#a5259f"}}
                    >
                        CANCLE
                    </Button>    
            </Box>
            </>
            }
            </>
        </Container>
        </form>
        )}
       </Formik>
    )
}
}

export default ManageUser;