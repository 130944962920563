import React from 'react';
import { Box,Container,FormControlLabel, FormControl,Radio, RadioGroup,Switch,Table,TableCell,TableBody,TableHead,TableRow,Button,Typography,Modal,Paper,Grid,FormLabel,CircularProgress } from '@material-ui/core';
import { makeStyles,withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import "./style.css";



const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#52d869',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
    // @ts-ignore
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  function createData(Notification:string, Message:string, modified:string, detail:any) {
    return { Notification, Message, modified,detail };
  }

  const rows = [
    createData(
      'Selected',
      'default',
      'Never',
      'aa'
    ),
    createData(
      'Award Winner',
      'default',
      'Never',
      'Maecenas rutrum urna vel lacus viverra, id ultrices dui rutrum'
    ),
    createData(
      'Finalist',
      'default',
      'Never',
      'Morbi congue gravida nunc, eu cursus felis vulputate id'
    ),
    createData(
      'Semi-Finalist',
      'default',
      'Never',
      'Vestibulum efficitur, ipsum consectetur finibus maximus, ligula dolor vehicula ex, sed viverra nulla mauris id purus'
    ),
    createData(
      'Quarter-Finalist',
      'default',
      'Never',
      ' Suspendisse vehicula eu libero eget viverra'
    ),
    createData(
        'Nominee',
        'default',
        'Never',
        ' Suspendisse vehicula eu libero eget viverra'
        )
  ];

  //@ts-ignore
  const ExpandableTableRow = ({ children, expandComponent, ...otherProps }:any) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
  
    return (
      <>
        <TableRow {...otherProps}>
          {children}
          <TableCell padding="checkbox">
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        {isExpanded && (
          <TableRow>
            {expandComponent}
          </TableRow>
        )}
      </>
    );
  };

  interface S {
    radiovalue:string
  }

class NotificationManager extends React.Component<S>{
  constructor(props:any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
    this.state = {
      radiovalue:'entrants',
      togglestate:{checkedswitch: false}}
      
      this.handleChange = this.handleChange.bind(this);
  }
  // const [, setValue] = React.useState('entrants');
  // const [, setState] = React.useState<any>({
  //     checkedswitch: false,
  //   });
  

  handleChange (event: React.ChangeEvent<HTMLInputElement>) {
      this.setState({radiovalue: (event.target as HTMLInputElement).value});
    };

  handleSwitchChange = (event:React.ChangeEvent<HTMLInputElement>) => {
      this.setState(togglestate => ({togglestate:{ ...togglestate, [event.target.name]: event.target.checked }}));
      console.log("checkedswitch-->",event.target.checked)
  };
  

  useStyle = withStyles((theme) => ({
     root: {
      flexGrow: 1,
      backgroundColor:"#F2F3F5",
      "& .MuiInputBase-input": {
          height: "1.5em",
          backgroundColor:"#fff"
        }
    // padding: theme.spacing(2),
    },
    paperstyle: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
          margin: theme.spacing(1),
          width: "100%",
          height: "100%",
        },
    },
    mainheader: {
      padding:"0px 0px 0px 7px"
  },
  }));

  
  render() {
    const classes = this.useStyle
    
    return(
        <Container style={{paddingLeft:"30px"}}>
            <Box
            //@ts-ignore 
            className={classes.mainheader}>
                <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem",marginBottom:"10px"}}>Manage Users</Typography>
            </Box>
            
            <Box 
            //@ts-ignore
            className={classes.paperstyle}>
                <Paper style={{padding:"15px 15px 30px 15px"}}>
                    <Typography variant="h4" style={{marginBottom:'10px',fontWeight:800}}>FilmFreeway will automatically notify submitters of their final Judging
                    Status according to your preference:</Typography>

                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            //@ts-ignore
                            value={this.state.radiovalue}
                            onChange={this.handleChange}
                        >
                            <FormControlLabel value="entrants" control={<Radio size="small" color="primary"/>} label="Nofity entrants of their Judging Status only on my festival's Notification Date: N/A" />
                            <FormControlLabel value="submitters" control={<Radio size="small" color="primary"/>} label="Notify submitters immediately when their Judging Status is changed." />
                        </RadioGroup>
                    </FormControl>

                    <Typography variant="h4" style={{margin:'20px 0px 10px 0px',fontWeight:800}}>Important:<em style={{fontWeight:400}}> FilmFreeway has an obligation to inform submitters of 
                        their selection or rejection to your event. Submitters expect to be notified of their final Judging Status on or before your Notification Date. Even
                        if you notify submitters by another means, updating the final judging Status of submissions within FilmFreeway is an important requirement.</em></Typography>
                </Paper>

                <Paper style={{padding:"15px 15px 30px 15px",marginTop:"20px"}}>
                    <Grid container>
                        <Grid item xs={12} sm={4}>
                            <Typography style={{fontWeight:800, fontSize:"1.5rem", color:"#A5259F", textAlign:"center"}}>00</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography style={{fontWeight:800, fontSize:"1.5rem", color:"#A5259F", textAlign:"center"}}>00</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography style={{fontWeight:800, fontSize:"1.5rem", color:"#A5259F", textAlign:"center"}}>00</Typography>
                        </Grid>
                    </Grid>
                    <Grid container style={{marginTop:"20px"}}>
                        <Grid item xs={12} sm={4}>
                            <Typography style={{fontWeight:800, fontSize:"1rem", textAlign:"center"}}>Submissions Undecided</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography style={{fontWeight:800, fontSize:"1rem", textAlign:"center"}}>Submissions Awaiting Notification </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography style={{fontWeight:800, fontSize:"1rem", textAlign:"center"}}>Submissions Notified</Typography>
                        </Grid>
                    </Grid>
                </Paper>

                <Box
                  //@ts-ignore 
                className={classes.mainheader} style={{marginTop:"40px"}}>
                    <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem",marginBottom:"10px"}}>Email Notification</Typography>
                </Box>
                <Paper style={{padding:"15px 15px 30px 15px"}}>
                    <Grid container style={{fontWeight:600, marginBottom:"15px"}}>
                        <Grid item xs={12} sm={6}>
                            <Typography style={{fontWeight:800, fontSize:"1.5rem"}}>Notifications</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                // @ts-ignore
                                control={<IOSSwitch checked={this.state.checkedswitch} onChange={this.handleSwitchChange} name="checkedswitch" />}
                                label=""
                            />
                        </Grid>
                    </Grid>
                    
                    {
                      //@ts-ignore
                    this.state.checkedswitch == false ? 
                    <Box>
                        <Typography style={{fontWeight:600, marginBottom:"15px"}}>You have turned of email notifications. Submitters will not receive ant emails regarding Judging Status of their submissions to your event.</Typography>
                        <Typography style={{fontWeight:600, marginBottom:"15px"}}>Submitters will only see their final Judging Status within their FilmFreeway accounts, according to your Notification Perference above.</Typography>
                        <Typography style={{fontWeight:600, marginBottom:"15px"}}>Please make certain that you contact any Selected submitters directly.</Typography>
                    </Box>
                    : 
                    <Paper className='root'>
                        <Table style={{minWidth:650}} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">Notification</TableCell>
                                <TableCell align="left" padding="checkbox">Message</TableCell>
                                <TableCell align="left" padding="checkbox">Modified</TableCell>
                                <TableCell padding="checkbox"/>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map(row => (
                                <ExpandableTableRow
                                    style={{width:"100%"}}
                                    key={row.Notification}
                                //@ts-ignore
                                    expandComponent={
                                      <> 
                                        <FormControl>
                                          <RadioGroup
                                              aria-labelledby="demo-controlled-radio-buttons-group"
                                              name="controlled-radio-buttons-group"
                                              //@ts-ignore
                                              value={this.state.value}
                                              onChange={this.handleChange}
                                          >
                                              <FormControlLabel value="entrants" control={<Radio />} label="Default Message" />
                                              <FormControlLabel value="submitters" control={<Radio />} label="Custom Message" />
                                          </RadioGroup>
                                        </FormControl>
                                    
                                        <Box
                                        //@ts-ignore 
                                        className={classes.mainheader}>
                                          <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem"}}>Message Preview</Typography>
                                        </Box>
                                      
                                        <Paper style={{padding:"15px 15px 30px 15px"}}>
                                          <Grid container>
                                            <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem"}}>Message Preview
                                              Message PreviewMessage PreviewMessage PreviewMessage PreviewMessage PreviewMessage PreviewMessage PreviewMessage Preview</Typography>
                                          </Grid>
                                        </Paper>
                                      </>
                                    }
                                >
                                <TableCell component="th" scope="row">
                                    {row.Notification}
                                </TableCell>
                                <TableCell align="left">{row.Message}</TableCell>
                                <TableCell align="left">{row.modified}</TableCell>
                                </ExpandableTableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </Paper>}
                </Paper>
            </Box>
        </Container>
    )
  }
}

export default NotificationManager;

