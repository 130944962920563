import * as React from 'react';

import {
  Box,
  Checkbox,
  Typography,
  Button,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  makeStyles
} from "@material-ui/core";

const useStyles = makeStyles({
    icon: {
      color: '#a5259f',
    },
    root: {
        '& .MuiInputBase-input': {
          height: '1.5em'
  
      },
    }
  });

const Transcation = () => {
const classes = useStyles();
  return (
        <Box sx={webStyles.bottomRight}>
            <Box sx={webStyles.formCard}>
                <Box sx={{bgcolor: "#320b4c", padding: "10px"}}>
                    <Typography variant="h4" style={{ color: "#FFF"}} >Transcation</Typography>
                </Box>
                <Box sx={{padding: "20px"}}>
                    <Box sx={{borderBottom: '1px solid #999', display: "flex"}}>
                       
                        <Box sx={{padding: "20px", flexGrow: 1  }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={10}>
                            <FormControl  style={{width: '100%'}}>
                                <FormLabel component="legend">Show Transcations</FormLabel>
                                  <TextField
                                    id="standard-select-currency-native"
                                    select
                                    InputProps={{ className: classes.root }}
                                    SelectProps={{
                                      classes: { icon: classes.icon },
                                      native:true
                                    }}
                                    variant="standard"
                                    name="gender"
                                  >
                                      <option value={""}>All</option>
                                      <option value={'male'}>Completed</option>
                                      <option value={'female'}>Pending</option>
                                      <option value={'other'}>Failed</option>
                                  </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <Button variant="contained" style={{ background: "#fff", color: "#A5259F", marginTop: "20px", width:"100%"}}>EXPORT</Button>
                            </Grid>
                        </Grid>
                            <div style={{display: "flex", justifyContent: "space-between", marginTop: "20px"}}>
                                <Grid container spacing={2} style={{width: "85%", marginLeft:"20px"}}>
                                    <Grid item md={4}>
                                        <Typography style={{color:"gray"}}>Order#</Typography>
                                    </Grid>
                                    <Grid item md={8}>
                                        <Typography style={{color:"gray"}}>Date</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container style={{width: "10%"}}>
                                    <Grid item md={6}>
                                        <Typography style={{color:"gray"}}>Total</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                            <Box sx={{border:"2px solid #f5f5f5"}} borderRadius={4} style={{marginTop:"10px", marginBottom:"20px", boxShadow:"0px 10px 15px 0px #f1f2f3"}}>
                                <div style={{display: "flex", justifyContent: "space-between", margin:"20px"}}>
                                    <Grid container spacing={2} style={{width: "85%"}}>
                                        <Grid item md={4}>
                                            <Typography style={{color:"#A5259F",fontWeight:"bold"}}>#13309463</Typography>
                                        </Grid>
                                        <Grid item md={8}>
                                            <Typography style={{fontWeight:"bold"}}>April 17, 2020</Typography>
                                            <Typography style={{marginTop:"10px"}}>Feast to the Great indial film festival sdsddsdsdsd adadssd</Typography>
                                            <Typography>Gutter to Great message film festival</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{width: "10%"}}>
                                        <Grid item md={6}>
                                            <Typography style={{fontWeight:"bold"}}>$2009.50</Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Button variant="contained" style={{ background: "#A5259F", color: "#fff", marginTop: "10px"}}>Save Changes</Button>
            </Box>
        </Box>
  );
};
export default Transcation;


const webStyles = {
    main: {
        display: "flex",
        justifyContent: "center",
        height: "100px",
        background: "000"
    },
    bottomRight: {
        display: "flex",
        flexDirection: "column",
        width: '75%'
    },
    formCard: {
        background: "#fff",
        marginBottom: "50px"
    }
}