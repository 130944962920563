// Customizable Area Start
import {IBlock} from "../../../framework/src/IBlock";
import {Message} from "../../../framework/src/Message";
import {BlockComponent} from "../../../framework/src/BlockComponent";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import {runEngine} from "../../../framework/src/RunEngine";


import {toast} from "react-toastify";
import React from "react";


export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    showHeader?:boolean;
}

interface S {
    isLoading:boolean;
    festivalDetails:Array<Object>;
    pagination:Object;
    value:any;
    isModalOpen:boolean;
    searchKey:any;
    perPageCount:number;
    currentPage:number;
    filterValue:string;
}

interface SS {
    id: any;
}

export default class FestivalViewController extends BlockComponent<
    Props,
    S,
    SS
    > {

    ProfileAndCoverPhotoApiCall:string = ""
    getBrowseFestivalDataApiCall:string = ""
    emailReg: RegExp;
    labelTitle: string = "";

    constructor(props: Props) {

        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.PostDetailDataMessage)
        ]

        this.state = {
            isLoading:false,
            festivalDetails:[],
            pagination:{},
            value:0,
            isModalOpen:false,
            searchKey:"",
            perPageCount:6,
            currentPage:1,
            filterValue:""
        };

        // @ts-ignore
        this.myRef = React.createRef();
        // @ts-ignore
        this.myRef1 = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleSearchKey = this.handleSearchKey.bind(this)
        this.handleSearchClick = this.handleSearchClick.bind(this)
        this.handlePagination = this.handlePagination.bind(this)
        this.handelFilter = this.handelFilter.bind(this)
        this.emailReg = new RegExp("");
        this.labelTitle = configJSON.labelTitle;

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {

        if(newValue === 1){
            // @ts-ignore
            this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }else if(newValue === 2){
            // @ts-ignore
            this.myRef1.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        } else{
            this.setState({
                value:newValue
            })
        }
    };

    handleSearchKey (e:any) {
        this.setState({
            searchKey:e.target.value
        })
    }

    handlePagination (e:any,value:any) {
        this.setState({
            currentPage:value
        })
        if(this.state.searchKey !== "" && this.state.pagination){
            this.getFestivalSearchData(value,this.state.searchKey,this.state.filterValue)
        }else{
            this.getBrowseFestivalData(value)
        }
    }

    handleSearchClick () {
        this.getFestivalSearchData(1,this.state.searchKey,this.state.filterValue)
    }

    handelFilter (e:any) {
        this.setState({
            filterValue:e.target.value
        })
        this.getFestivalSearchData(1,this.state.searchKey,e.target.value)
    }

    async componentDidMount() {
        this.getBrowseFestivalData(this.state.currentPage)
    }

    async receive(from: string, message: Message) {

        if(getName(MessageEnum.PostDetailDataMessage) === message.id){
            console.log("MESSAGE RECEIVED",message.properties.text)
        }

        if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            if(this.getBrowseFestivalDataApiCall === apiRequestCallId) {
                if(responseJson?.hasOwnProperty('data')){
                     this.setState({
                        festivalDetails:responseJson.data,
                         pagination:responseJson.meta
                    })
                }else{
                    responseJson.errors.map((item:any)=>{
                        if(item.token === "Token has Expired"){
                            localStorage.removeItem("authToken")
                            localStorage.removeItem("profile")
                            localStorage.removeItem("token")
                            localStorage.removeItem("usertoken")
                            // @ts-ignore
                            this.props.history.push("/")
                            toast.error("Token Expired")
                        }else{
                            console.log("GET FESTIVAL DATA ERROR",item)
                        }
                    })
                }
                this.setState({
                    isLoading:false
                })
            }
        }
    }

    handleOpenModal() {
        this.setState({
            isModalOpen:true
        })
    }

    manageRedirect (id:any){
        // @ts-ignore
        this.props.history.push(`/myFestival/view/${id}`)
    }

    handleCloseModal() {
        this.setState({
            isModalOpen:false
        })
    }

    getBrowseFestivalData(page:any): boolean {
        this.setState({
            isLoading:true,
        })
        const header = {
            "Content-Type": configJSON.browsefestivalDataApiContentType,
            token: localStorage.getItem('authToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBrowseFestivalDataApiCall  = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.browsefestivalDataApiEndPoint}?page=${page}&per_page=${this.state.perPageCount}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalReviewDataApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    getFestivalSearchData(page:any,search?:any,filter?:any): boolean {
        this.setState({
            isLoading:true,
        })
        const header = {
            "Content-Type": configJSON.festivalDataApiContentType,
            token: localStorage.getItem('authToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBrowseFestivalDataApiCall  = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.browsefestivalDataApiEndPoint}/filter?page=${page}&per_page=${this.state.perPageCount}&search=${search}&query=${filter}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalDataApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }
}
// Customizable Area End