import React, {useEffect} from 'react';

import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  Button,
  Box,
  TextField,
  Icon,
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@material-ui/core";
import FacebookLogin from 'react-facebook-login'
import GoogleLogin from 'react-google-login';
import CancelIcon from "@material-ui/icons/Cancel";
import { gogoleIcon } from './assets'

const configJSON = require("./config");


interface SignUpModalProps {
  isOpen: any;
  userRole: string;
  handleClose: any;
  handleSocialMediaSignup: any;
  handleFirstnameChange: any;
  handleLastnameChange: any;
  handleEmailChange: any;
  handlePasswordChange: any;
  handleSignup: any;
  errorMessage: string;
  successMessage: string;
  handleLoginOpen: any;
  handleUserRole: any;
  navigateToProject: any;
}

interface SocialSignupProps {
  email: string;
  name: string;
  id: string;
  type: string;
}

const SignUpModal: React.FC<SignUpModalProps> = ({
  isOpen,
  userRole,
  handleClose,
  handleSocialMediaSignup,
  handleFirstnameChange,
  handleLastnameChange,
  handleEmailChange,
  handlePasswordChange,
  handleSignup,
  errorMessage,
  successMessage,
  handleLoginOpen,
  handleUserRole,
  navigateToProject,
}) => {

  const [signupWithMail, setSignupWithMail] = React.useState(false)
  const [emailError, setEmailError] = React.useState("")
  const [eventName, setEventName] = React.useState("")
  const [passwordError, setPasswordError] = React.useState("")
  const [isLoading, setIsLoading] = React.useState(false)
  const [socialDetails, setSocialDetails] = React.useState<SocialSignupProps>()
  const [opens, setOpens] = React.useState(false);

  const handleSocialSignup = () => {
    if(socialDetails) {
      setIsLoading(true)
      handleSocialMediaSignup(socialDetails.email, socialDetails.id, socialDetails.type);
    }
  }

  const responseFacebook = async (response: any) => {
    if(response.email){
      const name = response.name.split(" ")
      handleSocialMediaSignup(response.email, response.id, 'facebook',name[0],name[2]||name[1]||"");
      // handleClose()
      setSocialDetails({
        email: response.email,
        name: response.name,
        id: response.id,
        type: 'facebook'
      })
      // setOpens(true);
    }
  }
  const responseGoogle = (response: any) => {
    if(response.googleId){
      handleSocialMediaSignup(response.profileObj.email, response.googleId, 'google',response.profileObj.givenName,response.profileObj.familyName);
      // handleClose()
      setSocialDetails({
        email: response.profileObj.email,
        name: response.profileObj.name,
        id: response.googleId,
        type: 'google'
      })
      // setOpens(true);
    }else{
      console.log('google-Error', response.error)
    }
  }

  useEffect(() => {
    if(successMessage === "Signup Successfully") {
      handleClose()
      setOpens(true);
    }
  }, [successMessage])
  
  const isValidEmailAddress = (textEmail: string) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(textEmail)){
      handleEmailChange(textEmail)
      setEmailError("")
    }else{
      handleEmailChange("")
      setEmailError("Enter Valid Email")
    }
  }

  const isValidPasswordAddress = (textPassword: string) => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if(re.test(textPassword)){
      handlePasswordChange(textPassword)
      setPasswordError("")
    }else{
      handlePasswordChange("")
      setPasswordError("Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character")
    }
  }
  const EventName = (description: string) => {
   setEventName(description)
  }
  

  const DividerWithOr = () => {
    return (
      <div style= {{  display: "flex", alignItems: "center"}} >
        <div style= {{ borderBottom: "1px solid #ccc", width: "100%"}} />
        <span style= {{padding: "0 10px 0 10px", fontWeight: 700}}>
          or
        </span>
        <div style={{borderBottom: "1px solid #ccc", width: "100%"}} />
      </div>
    );
  };

  return (
      <>
         <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
        >
            <DialogContent style={{ padding: "0px", overflow: "hidden" }} >
            {(errorMessage || successMessage )&& 
                <Box component="div" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px', bgcolor:`${errorMessage? '#e73939' : '#30813a'}` }} >
                  <Typography variant="subtitle1" style={{color: '#FFF'}}>
                    {errorMessage || successMessage }
                  </Typography>
                </Box>
              }
              <Grid container spacing={2} justify="space-between">
                <Grid item md={12} xs={12}>
                  <form>
                  <Box py={3}>
                    <Grid container justify="center" spacing={1}>
                      <Grid item xs={9}>
                        <Typography variant="h3" >
                          Sign Up
                        </Typography>
                      </Grid>
                      <Grid item xs={1} container justifyContent="flex-end" style={{cursor: 'pointer'}}>
                        <Icon onClick={handleClose}>
                          <CancelIcon style={{fill: "grey"}} />
                        </Icon>
                      </Grid>
                      {signupWithMail?
                      <>
                        <Grid item xs={10} >
                          <Typography variant="h5" >
                            Sign Up With  
                            <span 
                              style={{color: '#567de9'}} 
                              onClick={() => setSignupWithMail(false)}
                            > Facebook or Google</span>
                            {/* <span>or &nbsp;</span>
                            <span    style={{color: '#567de9'}} 
                              onClick={() => setSignupWithMail(false)}>Google</span> */}
                          </Typography>
                        </Grid>
                        <Grid xs={10} style={{padding: '8px'}}>
                          <DividerWithOr />
                        </Grid>
                        <Grid item xs={10}>
                          <TextField
                            type="text"
                            fullWidth
                            id="outlined-basic" 
                            variant="outlined"
                            placeholder="First Name"
                            onChange={(e) => handleFirstnameChange(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={10}>
                        <TextField
                            type="text"
                            fullWidth
                            id="outlined-basic" 
                            variant="outlined"
                            placeholder="Last Name"
                            onChange={(e) => handleLastnameChange(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={10}>
                        <TextField
                            type="email"
                            fullWidth
                            id="outlined-basic" 
                            variant="outlined"
                            placeholder="Email Address"
                            onChange={(e) => isValidEmailAddress(e.target.value)}
                            onBlur={(e) => isValidEmailAddress(e.target.value)}
                          />
                          {emailError && <span style={{fontSize: '12px', color: 'red'}}>{emailError}</span> }
                        </Grid>
                        <Grid item xs={10}>
                        <TextField
                            type="password"
                            fullWidth
                            id="outlined-basic" 
                            variant="outlined"
                            placeholder="Password"
                            onChange={(e) => isValidPasswordAddress(e.target.value)}
                            onBlur={(e) => isValidPasswordAddress(e.target.value)}
                          />
                          {passwordError && <span style={{fontSize: '12px', color: 'red', lineHeight: 1}}>{passwordError}</span> }
                        </Grid>
                        <Grid item xs={10}>
                          <RadioGroup
                            aria-label="gender"
                            defaultValue="submitter"
                            name="radio-buttons-group"
                            onChange={(e) => handleUserRole(e.target.value)}
                          >
                            <FormControlLabel 
                              value="submitter" 
                              control={<Radio style={{color: '#A5259F'}} />} 
                              label={<Typography variant="h5" >I want to submit my work or get tickets to festivals.</Typography>}
                              style={{ padding: '2px 0px' }}
                            />
                            <FormControlLabel 
                              value="organizer" 
                              control={<Radio style={{color: '#A5259F'}} />} 
                              label={<Typography variant="h5" >I manage a festival and want to list it on Movie Express way.</Typography>}
                            />
                          </RadioGroup>
                          {userRole === 'organizer' &&  
                            <Box sx={{paddingTop: '10px'}}>
                              <TextField
                                type="text"
                                fullWidth
                                id="outlined-basic" 
                                variant="outlined"
                                placeholder="What's the name of your event?"
                                onChange={(e) => EventName(e.target.value)}
                                onBlur={(e) => EventName(e.target.value)}
                                required={userRole === 'organizer'}
                              />
                            </Box>
                          }
                        </Grid>
                        <Grid item xs={10}>
                          {userRole === 'submitter' &&
                          <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            disabled={passwordError !== "" || emailError !== ""}
                            onClick={() => handleSignup()}
                          >
                            Sign Up with Email
                          </Button>}
                          {userRole === 'organizer' && eventName.length===0 &&
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            fullWidth
                          >
                            Sign Up with Email
                          </Button>}
                          {userRole === 'organizer' && eventName.length>0 &&
                          <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            disabled={passwordError !== "" || emailError !== ""}
                            onClick={() => handleSignup()}
                          >
                            Sign Up with Email
                          </Button>}
                        </Grid>
                      </> 
                      :
                      <>
                        <Grid item xs={10}>
                          <FacebookLogin
                              appId={configJSON.facebookAppID}
                              fields="name,email,picture"
                              icon="fa-facebook-square"
                              textButton="Sign Up with Facebook"
                              callback={(res: any) => responseFacebook(res)}
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <GoogleLogin
                              clientId={configJSON.clientID} //CLIENTID NOT CREATED YET
                              buttonText="LOGIN WITH GOOGLE"
                              onSuccess={(res) => responseGoogle(res)}
                              onFailure={(res) => responseGoogle(res)}
                              icon={true}
                              render={renderProps => (
                              <Button
                                  fullWidth
                                  style={{ border: '1px solid #878383', height: '42px', fontSize: '12px', fontWeight: 700, textTransform: 'capitalize'}}
                                  onClick={renderProps.onClick} 
                              >
                                <img src={gogoleIcon} width={20} style={{position: 'absolute', left: '15px'}}  />
                                Sign Up with Google
                              </Button>
                              )}
                          />
                        </Grid>
                        <Grid xs={10} style={{padding: '8px'}}>
                          <DividerWithOr />
                        </Grid>
                        <Grid item xs={10}>
                          <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            onClick={() => setSignupWithMail(true)}
                          >
                            Sign Up with Email
                          </Button>
                        </Grid>
                        <Grid item xs={10} >
                          <Typography display='inline' variant="h5" >
                            By signing up, I agree with FilmFreeway's
                          </Typography>
                          <Typography  color="inherit" display='inline' >
                             &nbsp;
                          </Typography>
                          <Typography display='inline' color="secondary" variant="h5" >
                            Terms of Service and Privacy Policy.
                          </Typography>
                          {/* <Typography display='inline' variant="h5" >
                            and &nbsp;
                          </Typography>
                          <Typography display='inline' color="secondary" variant="h5" >
                             Privacy Policy.
                          </Typography> */}
                        </Grid>
                      </> 
                      }
                      </Grid>
                  </Box> 
                  </form>
                  <Grid container justify="center" spacing={2}>
                    <Grid xs={10} style={{padding: '8px'}}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Box py={3} >
                    <Grid container justify="center" spacing={2}>
                      <Grid component={Box} item xs={6} alignSelf='center'>
                        <Typography variant="subtitle1" >
                          Already a member?
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                            color="primary"
                            variant="outlined"
                            fullWidth
                            onClick={() => handleLoginOpen()}
                        >
                          Log In
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
        </Dialog>
        <Dialog
          maxWidth={"xs"}
          open={opens}
        >
          {(errorMessage || successMessage )&& 
            <Box component="div" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px', bgcolor:`${errorMessage? '#e73939' : '#30813a'}` }} >
              <Typography variant="subtitle1" style={{color: '#FFF'}}>
                {errorMessage || successMessage }
              </Typography>
              {errorMessage &&
              <Icon onClick={() => setOpens(false)}>
                <CancelIcon style={{fill: "grey"}} />
              </Icon> }
            </Box>
          }
          <form>
            <div style={{margin:"15px 15px " }}>
              <h2 style={{ marginLeft:"100px"}} >Hey, {socialDetails && socialDetails.name}!</h2>
              <h3 style={{color:"grey",fontWeight:450}}>
                We just need to know one last thing before you can  get started
              </h3>
              <RadioGroup
                aria-label="gender"
                defaultValue="organizer"
                name="radio-buttons-group"
                onChange={(e) => handleUserRole(e.target.value)}
              >
                <FormControlLabel 
                  value="organizer" 
                  control={<Radio style={{color: '#A5259F'}} />} 
                  label={<Typography variant="h5" >I want to submit my work or get tickets to film festivals and events.</Typography>}
                  style={{ padding: '2px 0px' }}
                />
                <FormControlLabel 
                  value="submitter" 
                  control={<Radio style={{color: '#A5259F'}} />} 
                  label={<Typography variant="h5" >I manage a festival or contest and want to list my event on Movie Express way.</Typography>}
                />
              </RadioGroup>
              {userRole === 'submitter' &&  
                <Box sx={{paddingTop: '10px'}}>
                  <TextField
                    type="text"
                    fullWidth
                    id="outlined-basic" 
                    variant="outlined"
                    placeholder="What's the name of your event?"
                    onChange={(e) => EventName(e.target.value)}
                    onBlur={(e) => EventName(e.target.value)}
                    required
                  />
                </Box>
              }
            </div>

            <Box py={2} px={2}>
              {userRole === 'organizer' &&
                <Button 
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={isLoading}
                  onClick={() => navigateToProject()}
                > {isLoading? 'Loading...' : 'Get started'}</Button>  }
              {userRole === 'submitter' && eventName.length===0 &&
                <Button 
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={isLoading}
                > {isLoading? 'Loading...' : 'Get started'}</Button>}
              {userRole === 'submitter' && eventName.length>0 &&
                <Button 
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={isLoading}
                  onClick={() => navigateToProject()}
                > {isLoading ? 'Loading...' : 'Get started'}</Button>}
            </Box>
          </form>
        </Dialog>
      </>
       
  );
};
export default SignUpModal;