import * as React from 'react';

import {
  Box,
  Button,
  TextField,
  FormLabel,
  FormControl,
  Typography,
  FormControlLabel,
  Radio,
  Link,
  Container
} from "@material-ui/core";
import moment from "moment";




class PrivacyData extends React.Component<any, any>{
    constructor(props:any) {
        super(props);
        this.state={

        }
    }
  render(){
  return (
        <Container>
            <Box sx={webStyles.bottomRight}>
                <form autoComplete="off">
                    <Box sx={webStyles.formCard}>
                        <Box sx={{bgcolor: "#320b4c", padding: "10px"}}>
                            <Typography variant="h4" style={{ color: "#FFF"}} >Project Visibility</Typography>
                        </Box>
                        <Box sx={{padding: "20px"}}>
                                <Typography>Customize your project's visibility.Limit to festival only, share privately with industry professionals, or promote your work publicly.</Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between'}} >
                                <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                                    <FormControlLabel
                                    value="0"
                                    control={<Radio size="small" color="primary"/>}
                                    label={<Typography style={{fontWeight:"bolder"}}>Password Protected</Typography>}
                                    />
                                <Typography>My Project Page display my project details publicly (trailer, photos, bio, specs), but my project file is visible only to festival and people with password</Typography>

                                    {/* <FormControlLabel style={{padding:'0px 0px 0px 20px', marginTop:"-30px"}} label="My Project also has a non-English Title and Synopsis" /> */}

                                </FormControl>
                                <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                                    <FormControlLabel
                                    value="1"
                                    control={<Radio size="small" color="primary"/>}
                                    label={<Typography style={{fontWeight:"bolder"}}>Limited</Typography>}
                                    />
                                <Typography>My Project Page display my project details publicly (trailer, photos, bio, specs), but my project file is only visible to festival.</Typography>

                                </FormControl>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'space-between'}} >
                                <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                                    <FormControlLabel
                                    value="2"
                                    control={<Radio size="small" color="primary"/>}
                                    label={<Typography style={{fontWeight:"bolder"}}>Festivals only</Typography>}

                                    />
                                    <Typography>Only festival I submit to can view my project details and project file.</Typography>

                                </FormControl>
                                <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                                    <FormControlLabel
                                        value="2"
                                        control={<Radio size="small" color="primary"/>}
                                        label={<Typography style={{fontWeight:"bolder"}}>Public</Typography>}
                                        />
                                    <Typography>My Project Page dislpays my complete project publicly.</Typography>

                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                    <React.Fragment>
                        <Box sx={{ display: 'flex', width: '43%', flexDirection: 'row', pt: 2, mb: 2}}>
                            <Button
                            color="inherit"
                            >
                            REVIEW
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button color="primary"  type="submit">SAVE </Button>
                        </Box>
                </React.Fragment>
                    <Box sx={webStyles.formCard}>
                        <Box sx={{bgcolor: "#320b4c", padding: "10px"}}>
                            <Typography variant="h4" style={{ color: "#FFF"}} >Project Page URL </Typography>
                        </Box>
                        <Box sx={{padding: "20px"}}>
                            <Typography variant="h5">Your project Page address on FilmFreeway is: <Link>https://filmfreeway.com/</Link></Typography>
                            <Box>
                                <FormControl component="div" style={{ padding: '20px 0px', width: '100%'}}>
                                <FormLabel component="legend">FilmFreeway.com/</FormLabel>
                                <TextField
                                    id="outlined-disabled"
                                    type="text"
                                    placeholder="Loremispum"
                                    name="web-prefix"
                                />
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                    <React.Fragment>
                        <Box sx={{mb: 2}}>
                            <Button color="primary" type="submit" style={{width: '20%'}}>SAVE</Button>
                        </Box>
                    </React.Fragment>
                    <Box sx={webStyles.formCard}>
                        <Box sx={{bgcolor: "#320b4c", padding: "10px"}}>
                            <Typography variant="h4" style={{ color: "#FFF"}} >Downloads</Typography>
                        </Box>
                        <Box sx={{padding: "20px"}}>
                            <Typography variant="h5">By default, festivals that you submit to can stream your video but may not download.</Typography>
                            <Box sx={{display: 'flex', justifyContent: "space-between", padding: "20px"}}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between'}} >
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                                        <FormControlLabel
                                        value="0"
                                        control={<Radio size="small" color="primary"/>}
                                        label={<Typography style={{fontWeight:"bolder"}}>Festivals must request my permision to download my video.</Typography>}
                                        />
                                    </FormControl>
                                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                                        <FormControlLabel
                                        value="1"
                                        control={<Radio size="small" color="primary"/>}
                                        label={<Typography style={{fontWeight:"bolder"}}>Festivals that I submit to may download my video.</Typography>}
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <React.Fragment>
                        <Box sx={{mb: 2}}>
                            <Button color="primary" type="submit" style={{width: '20%'}}>SAVE</Button>
                        </Box>
                    </React.Fragment>
                </form>
            </Box>
        </Container>
  )};
};
export default PrivacyData;


const webStyles = {
    main: {
        display: "flex",
        justifyContent: "center",
        height: "100px",
        background: "000"
    },
    bottomRight: {
        display: "flex",
        flexDirection: "column",
        width: '100%'
    },
    formCard: {
        background: "#fff",
        marginBottom: "25px"
    },
    formError: {
        margin: "0px",
        color: "#ef2525"
    }
}