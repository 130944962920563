// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  FormControl,
  FormLabel,
  TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Modal,
  makeStyles
} from "@material-ui/core";
import { Delete, EditIcon } from "./assets";
import "./style.css";

import CategoryEntryFeesController, {
  Props
} from "./CategoryEntryFeesController";
import { Formik } from "formik";
import Loader from "../../../components/src/Loader.web";
import { stepFourSchema } from "./validationSchemas"
//@ts-ignore
import Data from './countries.json';

export default class CategoryEntryFees extends CategoryEntryFeesController {

  constructor(props: Props) {
    super(props);
  }

  render() {
    // @ts-ignore
    const { currencyData, customRow, isReinitailize, festivalData, currentCurrency, openModal } = this.state;
    const { loading } = this.props;
    return (
      <>
      {loading && <Loader loading={loading} /> }
      <Formik
        enableReinitialize={festivalData && isReinitailize}
        initialValues={{
          // currency: festivalData?.currency_id || "",
        }}
        validationSchema={stepFourSchema}
        onSubmit={(values, actions) => {
          this.handleSubmitEvent(values, customRow, currentCurrency.countryCode)
          this.props.handleNextStep();
        }}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {props => (
          <form onSubmit={props.handleSubmit}>
            {openModal?.open ?
              <Modal
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                open={openModal.open}
                onClose={this.handleClose}
              >
                <Box className="modalStyles">
                  <div>
                    {`You are changing your currency from ${openModal.prevCur} to ${openModal.cur}`}
                  </div>
                  <div className="bold">This will convert all your entry fees, ticket fees, and discount code values to your new currency at today's exchange rate.</div>
                  <div>You can easily edit your entry fees and discount code values after proceeding.</div>
                  <div>Are you sure you wish to convert your entry fees?</div>
                  <div className="modalButtonContainer">
                  <Button
                    color="inherit"
                    onClick={this.handleClose}
                    style={{ marginRight: "20px" }}
                    className="modalButtons"
                  >
                    Cancel
                  </Button>
                  <Button color="primary" onClick={this.handleChangeCurrency} className="modalButtons">
                    Proceed with conversion
                  </Button>
                  </div>
                </Box>
              </Modal>
              : ""}
            <Box sx={{ padding: "50px 0px 0px" }} bgcolor="#f2f3f5">
              <Typography variant="h2" component="h2">
                Accept Submissions to Your Event
              </Typography>
            </Box>

            <Box sx={{ padding: "20px 0px" }}>
              <Box className="formTitle">
                <Typography variant="h4" component="h4">
                  Categories & Entry Fees
                </Typography>
              </Box>
              <Box bgcolor="#FFF">
                <Box component="form" sx={{ padding: "20px 20px 150px" }}>
                  <div>
                    <Box border={0.2} borderColor="#f5f5f5" borderRadius={4}>
                      <FormControl style={{ width: "100%", padding: "20px 0px" }}>
                        <FormLabel component="legend" className="label">
                          Currency{" "}<span className="astrick">*</span>
                        </FormLabel>
                        <TextField
                          id="standard-select-currency-native"
                          select
                          className="inputClass"
                          SelectProps={{
                            native: true,
                            classes: { icon: "icon" },
                          }}
                          variant="standard"
                          name="currency"
                          value={currentCurrency?.countryCode || "IN"}
                          onChange={this.handleCurrencyChange}
                        >
                          <option value={''}>Select Currency</option>
                          {/* {currencyData && currencyData.map((cur: any) =>
                            <option key={cur.id} value={cur.id}>{cur.attributes.name} - {cur.attributes.code}</option>
                          )} */}
                          {Data.countries.country.map((curr: any) => 
                            <option value={curr.countryCode}>{curr.countryName} - {curr.currencyCode} </option>)
                          }
                        </TextField>
                      </FormControl>
                      <FormControl style={{ width: "100%", padding: "20px 0px" }}>
                        <TableContainer
                          component={Paper}
                          style={{ padding: "20px" }}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className="submissionsHeader">
                                  Category Name
                                </TableCell>
                                <TableCell
                                  className="submissionsHeader"
                                  align="right"
                                >
                                  Action
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {customRow.map((newRow: any, i: any) => {
                                return (
                                  <TableRow className="overrides">
                                    <TableCell style={{ width: "80%" }}>
                                      <TextField
                                        name="category_name"
                                        value={newRow.category_name}
                                        id="outlined-disabled"
                                        placeholder="Add Category Name"
                                        style={(!newRow.hasOwnProperty("disabled") || (newRow.hasOwnProperty('disabled') && newRow.disabled === true)) ? { background: "#ddd", width: "100%" } : { width: "100%" }}
                                        disabled={(!newRow.hasOwnProperty('disabled') || (newRow.hasOwnProperty('disabled') && newRow.disabled === true))}
                                        className="inputClass"
                                        onChange={event => this.handleChangeInput(newRow.id, event)}
                                      />
                                    </TableCell>

                                    <TableCell align="right">
                                      <IconButton style={{ padding: '0px' }} onClick={() => this.editRow(newRow.id)}>
                                        <img style={{ width: "50px", marginLeft: "25px" }} src={EditIcon} alt="logo" />
                                      </IconButton>
                                      <IconButton style={{ padding: '0px' }} onClick={() => this.deleteRow(newRow.id)}>
                                        <img style={{ width: "50px", marginLeft: "25px" }} src={Delete} alt="logo" />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}

                            </TableBody>
                            <Button
                              className="submissionsButton"
                              onClick={() => this.addCustomFile()}
                            >
                              ADD ANOTHER CATEGORY
                            </Button>
                          </Table>
                        </TableContainer>
                      </FormControl>
                    </Box>
                  </div>
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', width: '43%', flexDirection: 'row', pt: 2, marginBottom: "20px" }}>
              <Button
                color="inherit"
                onClick={this.props.handleBack}
                style={{ marginRight: "20px" }}
              >
                BACK
              </Button>
               {/* @ts-ignore */}
              <Button color="primary" type="submit" ref={this.callRef}>
                NEXT
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      </>
    );
  }
}
// Customizable Area End
