import * as React from "react";

import {
  Box,
  Button,
  TextField,
  FormLabel,
  FormControl,
  Typography,
  TextareaAutosize,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddCompany from "./AddCompany.web";
import AddTalentAgent from "./AddTalentAgent.web";
import moment from "moment";

import { Country, State, City } from "country-state-city";

const useStyles = makeStyles({
  icon: {
    color: "#a5259f",
  },
  root: {
    "& .MuiInputBase-input": {
      height: "1.5em",
    },
  },
});
const ContactInfo = ({ handleSubmitProject, userData }: any) => {
   // State and city API
   const [allStates, setAllStates] = React.useState(new Array());
   const [allCities, setAllCities] = React.useState(new Array());
   const [countryResi, setCountryResi] = React.useState("");
  //Opens form area
  const [companyOpen, setCompanyOpen] = React.useState(0);
  const [talentOpen, setTalentOpen] = React.useState(0);
  //Retrive form data 
  const [companyData, setCompanyData] = React.useState("");
  const [talentData, setTalentData] = React.useState("");
  const [representativeData, setRepresentativeData] = React.useState(new Array());
  //Update data with unique id
  const [companyId, setCompanyId] = React.useState(0)
  const [talentId, setTalentId] = React.useState(0)
  //To disable/enable button based on required field
  const [btnCompanyDisabled, setBtnCompanyDisabled] = React.useState(true) 
  const [btnTalentDisabled, setBtnTalentDisabled] = React.useState(true) 

  const initialValues = {
    country_of_origin: userData ? userData.country_of_origin : "",
    country_of_residence: userData ? userData.country_of_residence : "",
    address: userData ? userData.address : "",
    city: userData ? userData.city : "",
    state: userData ? userData.state : "",
    postal_code: userData ? userData.postal_code : "",
    dob: userData ? moment(userData.dob).format('YYYY-MM-DD') : "",
    gender: userData ? userData.gender : "",
    email: userData ? userData.email : "",
    phoneno: userData? userData.full_phone_number : "",
  };

  const [formVal, dispatch] = React.useReducer(
    (curVal, newVal) => ({ ...curVal, ...newVal }),
    initialValues
  );
  React.useEffect(() => {
    if(userData){
      setAllStates(State.getStatesOfCountry(userData.country_of_residence))
      setAllCities(City.getCitiesOfState(userData.country_of_residence, userData.state ))
    }
  }, []);
 
  const {
    country_of_origin,
    country_of_residence,
    address,
    city,
    state,
    postal_code,
    dob,
    gender,
    email,
    phoneno,
  } = formVal;

  React.useEffect (() => {
    if(userData){
      setCompanyId(userData?.company?.id)
      setTalentId(userData?.talent_agent?.id)
      if(userData.company?.name){
        setCompanyOpen(1)
      }
      if(userData.talent_agent?.name){
        setTalentOpen(1)
      }
    }
  }, [userData])

  const handleSubmit = (e: any) => {
    e.preventDefault();
    var values = formVal;
    let submitCompanyData = companyOpen === 1 ? companyData : null;
    let submitTalentData = talentOpen === 1 ? talentData : null;
    
    handleSubmitProject(values, submitCompanyData, companyId, submitTalentData, talentId, representativeData);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    dispatch({ [name]: value });
  };

  const handleCountryOfOrigin = (event: any) => {
    const { name, value } = event.target;
    dispatch({ [name]: value });
  };

  const handleCountryOfResidence = (event: any) => {
    const { name, value } = event.target;
    setCountryResi(value);
    setAllStates(State.getStatesOfCountry(value));
    dispatch({ [name]: value });
  };

  const handleStateChange = (event: any) => {
    const { name, value } = event.target;
    setAllCities(City.getCitiesOfState(countryResi, value));
    initialValues.state = value;
    dispatch({ [name]: value });
  };

  const handleCityChange = (event: any) => {
    const { name, value } = event.target;
    initialValues.city = value;
    dispatch({ [name]: value });
  };
  
  const handleCompany = () => {
    setCompanyOpen(1);
  };
  const closeCompany = () => {
    setCompanyOpen(0);
  };
  const closeTalentAgent = () => {
    setTalentOpen(0);
  };

  const handleTalent = () => {
    setTalentOpen(1);
  };
  const countries = Country.getAllCountries();
  const classes = useStyles();

  return (
    <Box sx={webStyles.bottomRight}>
      <Box sx={webStyles.formCard}>
        <Box sx={{ bgcolor: "#320b4c", padding: "10px" }}>
          <Typography variant="h4" style={{ color: "#FFF" }}>
            Contact Information
          </Typography>
        </Box>
        <Box sx={{ padding: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <FormControl
              component="div"
              style={{ padding: "20px 0px", width: "45%" }}
            >
              <FormLabel component="legend">Email</FormLabel>
              <TextField
                placeholder="Enter Your Email ID"
                variant="standard"
                id="outlined-disabled"
                onChange={handleChange}
                InputProps={{ className: classes.root }}
                name="email"
                value={email}
              />
            </FormControl>
            <FormControl
              component="div"
              style={{ padding: "20px 0px", width: "45%" }}
            >
              <FormLabel component="legend">Phone No.</FormLabel>
              <TextField
                id="outlined-disabled"
                placeholder="eg: +91 999-876-543"
                type="number"
                onChange={handleChange}
                InputProps={{ className: classes.root }}
                name="phoneno"
                value={phoneno}
              />
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <FormControl
              component="div"
              style={{ padding: "20px 0px", width: "45%" }}
            >
              <FormLabel component="legend">DOB</FormLabel>
              <TextField
                name="dob"
                type="date"
                id="outlined-disabled"
                onChange={handleChange}
                value={dob}
                placeholder="DD / MM/ YYYY"
                InputProps={{inputProps: { max: moment().format("YYYY-MM-DD")},className: classes.root}}
              />
            </FormControl>
            <FormControl
              component="div"
              style={{ padding: "20px 0px", width: "45%" }}
            >
              <FormLabel component="legend">Gender</FormLabel>
              <TextField
                variant="standard"
                id="standard-select-currency-native"
                select
                onChange={handleChange}
                InputProps={{ className: classes.root }}
                SelectProps={{
                  classes: { icon: classes.icon },
                  native: true,
                }}
                name="gender"
                value={gender}
              >
                <option value={""}>select from option</option>
                <option value={"male"}>Male</option>
                <option value={"female"}>Female</option>
                <option value={"other"}>Others</option>
              </TextField>
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <FormControl
              component="div"
              style={{ padding: "20px 0px", width: "100%" }}
            >
              <FormLabel component="legend">Address</FormLabel>
              <TextField
                placeholder="Enter your full Address"
                id="outlined-disabled"
                onChange={handleChange}
                InputProps={{ className: classes.root }}
                name="address"
                value={address}
              />
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <FormControl
              component="div"
              style={{ padding: "20px 0px", width: "30%" }}
            >
              <FormLabel component="legend">Country of Origin</FormLabel>
              <TextField
                id="standard-select-currency-native"
                select
                onChange={handleCountryOfOrigin}
                SelectProps={{
                  classes: { icon: classes.icon },
                  native: true,
                }}
                InputProps={{ className: classes.root }}
                variant="standard"
                name="country_of_origin"
                value={country_of_origin}
              >
                <option value={""}>Select from option</option>
                {countries &&
                  countries.map((cur: any) => (
                    <option value={cur.isoCode}>{cur.name}</option>
                  ))}
              </TextField>
            </FormControl>
            <FormControl
              component="div"
              style={{ padding: "20px 0px", width: "30%" }}
            >
              <FormLabel component="legend">Country of Residence</FormLabel>
              <TextField
                id="standard-select-currency-native"
                select
                onChange={handleCountryOfResidence}
                SelectProps={{
                  classes: { icon: classes.icon },
                  native: true,
                }}
                InputProps={{ className: classes.root }}
                variant="standard"
                name="country_of_residence"
                value={country_of_residence}
              >
                <option value={""}>Select from option</option>
                {countries &&
                  countries.map((cur: any) => (
                    <option value={cur.isoCode}>{cur.name}</option>
                  ))}
              </TextField>
            </FormControl>
            <FormControl
              component="div"
              style={{ padding: "20px 0px", width: "30%" }}
            >
              <FormLabel component="legend">State / Province</FormLabel>
              <TextField
                id="standard-select-currency-native"
                select
                onChange={handleStateChange}
                SelectProps={{
                  classes: { icon: classes.icon },
                  native: true,
                }}
                InputProps={{ className: classes.root }}
                variant="standard"
                name="state"
                // onBlur={handleBlur}
                value={state}
              >
                <option value={""}>Select from option</option>
                {allStates &&
                  allStates.map((state: any) => (
                    <option value={state.isoCode}>{state.name}</option>
                  ))}
              </TextField>
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <FormControl
              component="div"
              style={{ padding: "20px 0px", width: "30%" }}
            >
              <FormLabel component="legend">City</FormLabel>
              <TextField
                id="standard-select-currency-native"
                select
                onChange={handleCityChange}
                SelectProps={{
                  classes: { icon: classes.icon },
                  native: true,
                }}
                InputProps={{ className: classes.root }}
                variant="standard"
                name="city"
                value={city}
              >
                <option value={""}>Select from option</option>
                {allCities &&
                  allCities.map((city: any) => (
                    <option value={city.name}>{city.name}</option>
                  ))}
              </TextField>
            </FormControl>
            <FormControl
              component="div"
              style={{ padding: "20px 0px", width: "30%" }}
            >
              <FormLabel component="legend">Postal Code</FormLabel>
              <TextField
                type="number"
                id="standard-select-role"
                placeholder="Enter pin code"
                onChange={handleChange}
                InputProps={{ className: classes.root }}
                name="postal_code"
                value={postal_code}
                //    onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              />
            </FormControl>
            <FormControl
              component="div"
              style={{ padding: "20px 0px", width: "30%" }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={webStyles.formCard}>
        <Box sx={{ bgcolor: "#320b4c", padding: "10px" }}>
          <Typography variant="h4" style={{ color: "#FFF" }}>
            My Company
          </Typography>
        </Box>
        {companyOpen === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
            }}
          >
            <Button
              variant="outlined"
              style={{
                border: "1px solid #A5259F",
                color: "red",
                marginTop: "10px",
              }}
              onClick={handleCompany}
            >
              + Add Company
            </Button>
          </Box>
        ) : (
          <AddCompany
            closeCompany={closeCompany}
            handleChange={handleChange}
            userData={userData && userData.company}
            passCompanyData={setCompanyData}
            buttonDisable={setBtnCompanyDisabled}
          />
        )}
      </Box>
      <Box sx={webStyles.formCard}>
        <Box sx={{ bgcolor: "#320b4c", padding: "10px" }}>
          <Typography variant="h4" style={{ color: "#FFF" }}>
            Talent Agent
          </Typography>
        </Box>
        {talentOpen === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
            }}
          >
            <Button
              variant="outlined"
              style={{
                border: "1px solid #A5259F",
                color: "red",
                marginTop: "10px",
              }}
              onClick={handleTalent}
            >
              + Add Talent Agent
            </Button>
          </Box>
        ) : (
          <AddTalentAgent 
            closeCompany={closeTalentAgent}
            handleChange={handleChange}
            passTalentData={setTalentData}
            passRepresentativeData={setRepresentativeData}
            userData={userData && userData.talent_agent}
            buttonDisable={setBtnTalentDisabled}
         />
        )}
      </Box>
      <Box>
        {(companyOpen === 1 && btnCompanyDisabled || talentOpen === 1 && btnTalentDisabled) ? 
           <Button
           variant="contained"
           style={{ background: "#A5259F", color: "#ccc", marginTop: "10px" }}
           onClick={handleSubmit}
           disabled={true}
         >
           Save Changes
         </Button>
          : <Button
            variant="contained"
            style={{ background: "#A5259F", color: "#fff", marginTop: "10px" }}
            onClick={handleSubmit}
            disabled={false}
          >
            Save Changes
          </Button>
        }
       
      </Box>
    </Box>
  );
};
export default ContactInfo;

const webStyles = {
  main: {
    display: "flex",
    justifyContent: "center",
    height: "100px",
    background: "000",
  },
  bottomRight: {
    display: "flex",
    flexDirection: "column",
    width: "75%",
  },
  formCard: {
    background: "#fff",
    marginBottom: "50px",
  },
};
