import React from 'react';
import { Box,FormControl,Container,TextField,TableContainer,Table,TableHead,TableBody,TableRow,TableCell, Button,Typography,Paper,Grid,FormLabel,CircularProgress } from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';

class JudgingInsight extends React.Component {

    useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            backgroundColor:"#F2F3F5",
            "& .MuiInputBase-input": {
                height: "1.5em",
                backgroundColor:"#fff"
              }
            // padding: theme.spacing(2),
        },
        paperstyle: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
              margin: theme.spacing(1),
              width: "100%",
              height: "100%",
            },
        },
          savebtn:{
            '& > *': {
                margin: theme.spacing(1),
          }
        },
        inputClass: {
            "& .Mui-focused:after": {
              border: "1px solid green",
            },
          },
    }));
    render() {
        const classes = this.useStyles
    return(
        <Container style={{paddingLeft:"30px"}}>
            <Box style={{padding:"0px 0px 0px 7px"}}>
                <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem",marginBottom:"10px"}}>Judging Insight</Typography>
            </Box>
            <Grid container style={{margin:"15px 0px 15px 0px"}}>
                <Grid xs={3}>
                    <Box>
                        <Button color="inherit" type="submit">NO SEASON AVAILABLE</Button>
                    </Box>
                    </Grid>
                    <Grid xs={3}>
                    <Box>
                        <Button color="primary" type="submit">SHOW ALL 1 JUDGES</Button>
                    </Box>
                </Grid> 
            </Grid>

            <Box style={{flexWrap: 'wrap'}}>
                <Paper style={{padding:"15px 15px 30px 15px"}}>
                    <Grid container spacing={2} style={{alignItems:"center"}}>
                        <Grid item xs={10}>
                            <TextField
                                id="outlined-full-width"
                                style={{ margin: 8 }}
                                placeholder="Serach by Judge Name or Email"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Box>
                                <Button color="primary" type="submit" style={{width:"100%"}}>SEARCH</Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Typography variant="h4" style={{margin:'20px 0px 20px 0px',fontWeight:800}}>Totals</Typography>
                    <Typography variant="h4" style={{marginTop:'8px',fontWeight:800}}>Total Judges:</Typography>
                    <Typography variant="h4" style={{marginTop:'8px',fontWeight:800}}>Total Submissions:</Typography>
                    <Typography variant="h4" style={{marginTop:'8px',fontWeight:800}}>Submissions Judged:</Typography>
                    <Typography variant="h4" style={{marginTop:'8px',fontWeight:800}}>Submissions Not Judged:</Typography>
                    <Typography variant="h4" style={{marginTop:'8px',fontWeight:800}}>Percentage Judged:</Typography>

                    <Typography variant="h4" style={{marginTop:'35px',fontWeight:800}}>Filters</Typography>
                    
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                <FormLabel component="legend" style={{fontWeight:600}}>Last Login</FormLabel>
                                <TextField
                                        id="standard-select-currency-native"
                                        select
                                        // onChange={props.handleChange}
                                        // onBlur={props.handleBlur}
                                        // value={props.values.max_uses}
                                        // InputProps={{ className: classes.root }}
                                        SelectProps={{
                                        // classes: { icon: classes.icon },
                                        native:true
                                        }}
                                        variant="standard"
                                        name="max_uses"
                                    // value={gender}
                                  >
                                      <option value={""}>select from option</option>
                                      <option value={'7days'}>Over 1 Weeks</option>
                                      <option value={'7days'}>Over 2 Weeks</option>
                                      <option value={'7days'}>Over 3 Weeks</option>
                                      <option value={'7days'}>Over 4 Weeks</option>
                                      <option value={'7days'}>Over 5 Weeks</option>
                                      <option value={'7days'}>Over 6 Weeks</option>
                                      <option value={'7days'}>Never</option>
                                  </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                <FormLabel component="legend" style={{fontWeight:600}}>Percentage Judges</FormLabel>
                                    <TextField
                                            id="standard-select-currency-native"
                                            select
                                            // onChange={props.handleChange}
                                            // onBlur={props.handleBlur}
                                            // value={props.values.max_uses}
                                            // InputProps={{ className: classes.root }}
                                            SelectProps={{
                                            // classes: { icon: classes.icon },
                                            native:true
                                            }}
                                            variant="standard"
                                            name="max_uses"
                                        // value={gender}
                                    >
                                        <option value={""}>select from option</option>
                                        <option value={'7days'}>100%</option>
                                        <option value={'7days'}>90%</option>
                                        <option value={'7days'}>80%</option>
                                        <option value={'7days'}>70%</option>
                                        <option value={'7days'}>60%</option>
                                        <option value={'7days'}>50%</option>
                                        <option value={'7days'}>40%</option>
                                        <option value={'7days'}>30%</option>
                                        <option value={'7days'}>20%</option>
                                        <option value={'7days'}>10%</option>
                                    </TextField>
                            </FormControl>
                        </Grid>
                    </Grid>   
                    <Typography variant="h4" style={{marginTop:'8px',fontWeight:800}}>0 judges match your criteria</Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={3}>
                            <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                <TextField
                                        id="standard-select-currency-native"
                                        select
                                        // onChange={props.handleChange}
                                        // onBlur={props.handleBlur}
                                        // value={props.values.max_uses}
                                        // InputProps={{ className: classes.root }}
                                        SelectProps={{
                                        // classes: { icon: classes.icon },
                                        native:true
                                        }}
                                        variant="standard"
                                        name="max_uses"
                                    // value={gender}
                                >
                                    <option value={""}>select from option</option>
                                    <option value={'7days'}>Export Ratings for Selected Judges</option>
                                    <option value={'7days'}>Unassign Submissions for Selected Judges</option>
                                    <option value={'7days'}>Send Reminder Email to Selected Judges</option>
                                    <option value={'7days'}>Show Submissions Assigned to this Judge</option>
                                    <option value={'7days'}>Edit Permissions of this Judge</option>
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                <TextField
                                        id="standard-select-currency-native"
                                        select
                                        // onChange={props.handleChange}
                                        // onBlur={props.handleBlur}
                                        // value={props.values.max_uses}
                                        // InputProps={{ className: classes.root }}
                                        SelectProps={{
                                        // classes: { icon: classes.icon },
                                        native:true
                                        }}
                                        variant="standard"
                                        name="max_uses"
                                    // value={gender}
                                >
                                    <option value={""}>select from option</option>
                                    <option value={'7days'}>Email All</option>
                                    <option value={'7days'}>Email Selected</option>
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                <TextField
                                        id="standard-select-currency-native"
                                        select
                                        // onChange={props.handleChange}
                                        // onBlur={props.handleBlur}
                                        // value={props.values.max_uses}
                                        // InputProps={{ className: classes.root }}
                                        SelectProps={{
                                        // classes: { icon: classes.icon },
                                        native:true
                                        }}
                                        variant="standard"
                                        name="max_uses"
                                    // value={gender}
                                >
                                    <option value={""}>select from option</option>
                                    <option value={'7days'}>100 judges</option>
                                    <option value={'7days'}>500 judges</option>
                                    <option value={'7days'}>1000 judges</option>
                                </TextField>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <TableContainer component={Paper}>
                        <Table style={webStyles.table} aria-label="simple table">
                        <TableHead>
                            <TableRow style={{background:"#F8F8F8"}}>
                            <TableCell align="center" style={{fontWeight:800}}>Name</TableCell>
                            <TableCell align="center" style={{fontWeight:800}}>Judged</TableCell>
                            <TableCell align="center" style={{fontWeight:800}}>Assigned</TableCell>
                            <TableCell align="center" style={{fontWeight:800}}>% Judged</TableCell>
                            <TableCell align="center" style={{fontWeight:800}}>Runtime Assigned</TableCell>
                            <TableCell align="center" style={{fontWeight:800}}>Last Login</TableCell>
                            <TableCell align="center" style={{fontWeight:800}}>Email</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* {rows.map((row) => ( */}
                            <TableRow>
                                <TableCell component="th" scope="row" align="center">Sample</TableCell>
                                <TableCell component="th" scope="row" align="center">0</TableCell>
                                <TableCell component="th" scope="row" align="center">83</TableCell>
                                <TableCell component="th" scope="row" align="center">0%</TableCell>
                                <TableCell component="th" scope="row" align="center">20 hr</TableCell>
                                <TableCell component="th" scope="row" align="center">Nov 12, 2021</TableCell>
                                <TableCell component="th" scope="row" align="center">Send Email</TableCell>
                            </TableRow>
                        </TableBody>
                        </Table>
                    </TableContainer>  
                </Paper>
            </Box>
        </Container>
    )
}
}

export default JudgingInsight;

const webStyles = {
      table: {
        minWidth: 650
      },
      commonFontWeight:800
  };