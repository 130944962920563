import React from 'react';
import { Button,Typography,Modal,Paper,Grid,CircularProgress, Box } from '@material-ui/core';
import { makeStyles} from '@material-ui/core/styles';
import {selectIcon, awardIcon, DeleteIcon,ViewIcon,EditIcon,PrivacyIcon, defaultproject } from "./assets";
import Footer from "../../../components/src/AppFooter.web";


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(1),
    marginTop: '20px',
    zIndex: 1,
    borderRadius: '5px',
    width: '80%',
    marginLeft: '10%',
  },
  cardHeight: {
    height: '100%',
    maxHeight:"340px"
  },
  icon: {
    cursor:"pointer",
    padding: theme.spacing(1, 0),
  },
  iconBorder: {
    padding: '2px',
    height:"40px",
    width:"40px"
  },
  iconBackground: {
    padding: '2px',
    border: '1px solid purple',
    borderRadius: '5px',
    background: 'purple',
    color: '#fff'
  },       
  title: {
    padding: theme.spacing(2),
    marginLeft: '15%',
    fontWeight:400
  },
  featureList: {
    padding: theme.spacing(2),
  },
  cardTitle: {
    padding: theme.spacing(2),
  },
  cardImage: {
    width: '100%',
    maxHeight:"340px",
    objectFit:"cover",
    height:"100%"
  },
  cardDescription: {
    padding: theme.spacing(2),
    color: 'gray'
  },
  cardFooterText: {
    fontSize: '14px',
    color: 'gray'
  },
  submissionsButton: {
    border: "2px solid #BC2BB5",
    color: "#BC2BB5",
    padding: "6px 40px",
    borderRadius: "7px",
    fontSize: "16px",
    height:"67%",
    margin: "10px 0",
  },

  listButton: {
    backgroundColor: "#BC2BB5",
    color: "#fff",
    padding: "6px 30px",
    border: "2px solid #BC2BB5",
    borderRadius: "7px",
    fontSize: "16px",
    margin: "10px 0",
  },
}));
const handleAddProject = () => {
  window.location.replace('/CustomForm2');
}


const MyProjectList = ({
  data,
  deleteProjects,
  isLoading
}:any ) => {
  const [ProjectData, setProjectData] = React.useState([])
  
  React.useEffect(() => {
    setProjectData(data)
  })

  const handleRemoveProject = async (id:any) => {
    console.log("project is ===>>",id)
    let shouldDelete = confirm('Do you really want to delete this Account ?');
    if (shouldDelete) {
      deleteProjects(id)
    }
    console.log("Remove video")
}

const handleProjectEdit = (id:any) => {
  localStorage.setItem("tabIndex", "1")
  window.location.replace(`/MyprojectDetail/${id}/?step=1`);
}
const handleViewProject = (id:any) => {
  window.location.replace(`/MyprojectDetail/${id}/?step=0`);
}

  const classes = useStyles();

  // Customizable Area Start
  return (
    <>
    <div style={{ minHeight: `${data?.length > 1 ? "auto" : "100%"}`, background:"#f9f9f9"}}>
    <Grid className={classes.root}>
      <Paper elevation={1} >
        <Grid container>
        {data.length > 0 ?
          <Grid item xs={8}>
            <Typography variant="h3" className={classes.title}>
              Project({data.length})
            </Typography>
          </Grid>
          : 
          <Grid item xs={8}>
            <Typography variant="h3" className={classes.title}>
              Project(0)
            </Typography>
          </Grid>
        }
          <Grid container item xs={4} >
            <Grid item xs={4}>
              <Button className={classes.submissionsButton} variant="outlined" color="primary" size='large' onClick={handleAddProject}>
                +Project
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" color="primary" size='large' className={classes.listButton}>
                MARKETING OPTION
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>

    {isLoading ? 
     <Grid container className={classes.card} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
     <CircularProgress />
    </Grid> :
    <> 
     {ProjectData.length > 0 ?
     (ProjectData && ProjectData.map((project: any) =>
      <Grid container className={classes.card}>
        {console.log("project list img",project.attributes.project_poster)}
        <Grid item xs={3}>
        <img src={project?.attributes?.project_poster == "" ? defaultproject : project?.attributes?.project_poster} alt='placeholder' className={classes.cardImage} />
        </Grid>
        <Grid item xs={9} style={{paddingLeft:"25px"}}>
          <Paper elevation={2} className={classes.cardHeight}>
            <Grid container>
              <Grid item xs={9}>
                <Typography variant="h6" className={classes.cardTitle} style={{fontWeight:600}}>
                  {console.log(project.id)}
                  {project.attributes.name == "" ? "" : project.attributes.name}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={3} className={classes.icon} data-id={project.id} onClick = { () => {handleViewProject(project.id)}}>
                    <img src={ViewIcon} className={classes.iconBorder}/>
                    {/* <ViewIcon className={classes.iconBorder} /> */}
                  </Grid>
                  <Grid item xs={3} className={classes.icon} data-id={project.id} onClick = { () => {handleProjectEdit(project.id)}}>
                    <img src={EditIcon}  className={classes.iconBorder}/>
                    {/* <EditIcon className={classes.iconBorder} /> */}
                  </Grid>
                  <Grid item xs={3} className={classes.icon}>
                    <img src={PrivacyIcon}  className={classes.iconBorder}/>
                    {/* <PrivacyIcon className={classes.iconBorder} /> */}
                  </Grid>
                  <Grid item xs={3} className={classes.icon} data-id={project.id} onClick = { () => {handleRemoveProject(project.id)}}>
                    <img src={DeleteIcon}  className={classes.iconBorder}/>
                    {/* <DeleteIcon className={classes.iconBackground} /> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.cardTitle} style={{fontWeight:500}}>
                  This Project has not yet been submitted to any. { <a href='#' style={{color:"#BC2BB5"}}>Browse Festival</a>}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.cardDescription}>
              <Grid item xs={11}>
                <Typography >
                  {project.attributes.description == "" ? "" : project.attributes.description}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
            <Grid item xs={9} style={{display:"flex", flexDirection:"row", margin:"15px"}}>
                <Box style={{display:"flex", flexDirection:"row"}}>
                    <Box style={{marginRight:"15px"}}> 
                    <img style={{width:"20px"}} src={selectIcon} alt="logo" />
                    </Box>
                    <Box>
                        <Typography variant="body2" className={classes.cardFooterText}>
                            0 Not Selected
                        </Typography>
                    </Box>
                </Box>
                <Typography style={{margin:"-3px 20px 0px 20px"}}> | </Typography>
                <Box style={{display:"flex", flexDirection:"row"}}>
                    <Box style={{marginRight:"15px"}}>
                    <img style={{width:"20px"}} src={awardIcon} alt="logo" />
                    </Box>
                    <Box>
                        <Typography variant="body2" className={classes.cardFooterText}>
                            0 Award Winner
                        </Typography>
                    </Box>
                </Box>
                </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
        ))
      : <Typography style={{textAlign:"center", marginTop:"15%"}}>There is no any project.</Typography>}
      </>
     }
    </div>
    <Footer />
    </>
  );
  // Customizable Area End
};

export default MyProjectList;

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};