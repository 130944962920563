Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "EventRegistration";
exports.labelBodyText = "EventRegistration Body";
exports.editEventEndpoint = 'bx_block_events/festivals?'
exports.endPointApiFestivalData = "bx_block_events/festivals";
exports.methodTypeGet = "GET";
exports.endPointApiGetCurrency = "/bx_block_posts/specifications/get_currencies";
exports.methodTypeApiGetUserProfile = "GET";
exports.endPointApiUpdateEventDetails = "/bx_block_events/festivals/";
exports.patchAPiMethod = "PATCH";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End