import React from 'react';
import { Box,Container,FormControl,Radio,TextField,RadioGroup,FormControlLabel, Tab, Tabs, Button,Typography,Modal,Paper,Grid,FormLabel,CircularProgress } from '@material-ui/core';
import { makeStyles,styled,withStyles} from '@material-ui/core/styles';
import { Formik } from "formik";
import { inviteContact, customJudgeInitialValue } from "./validationSchemas";
import CustomJudgeController, {
    Props
  } from "./CustomJudgeController";
import Rating from '@material-ui/lab/Rating';
//   import ReactStars from "react-rating-stars-component";

const StyledRating = withStyles({
    iconFilled: {
      color: '#A5259F',
    },
    iconHover: {
      color: '#A5259F',
    },
  })(Rating);
class CustomJudgeForm extends CustomJudgeController{
    constructor(props: Props) {
        super(props);
      }
      useStyle = makeStyles((theme:any) => ({
        root: {
            flexGrow: 1,
            backgroundColor:"#F2F3F5",
            "& .MuiInputBase-input": {
                height: "1.5em",
                backgroundColor:"#fff"
              }
            // padding: theme.spacing(2),
        },
        paperstyle: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
              margin: theme.spacing(1),
              width: "100%",
              height: "100%",
            },
        },
        inputClass: {
            "& .Mui-focused:after": {
              border: "1px solid green",
            },
          },
          icon: {
            color: "#a5259f",
          },
          selectbox: {
            "& .MuiInputBase-input": {
              height: "1.5em",
            }
          },
    }));

    render() {
        const {judgeFormType} = this.state;
        const classes = this.useStyle
    return(
        <Formik
        enableReinitialize
        initialValues={customJudgeInitialValue}
        // validationSchema={stepOneSchema}
        onSubmit={(values, actions) => {
          this.handleCustomJudgeSubmitEvent(values,judgeFormType)
        }}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {props => (
        <form onSubmit={props.handleSubmit} style={{width:"100%"}}>
        <Container style={{paddingLeft:"30px"}}>
            <Box style={{padding:"0px 0px 0px 7px"}}>
                <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem",marginBottom:"10px"}}>Custom Judging Form</Typography>
            </Box>
            <Box style={{flexWrap: 'wrap'}}>
                <Paper style={{padding:"15px 15px 30px 15px"}}>
                    <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>You can customize your Judging Forms below.</Typography>
                    <Grid container>
                        <Grid xs={6}>
                            <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                <FormLabel component="legend" style={{fontWeight:600}}>Select a Project Type</FormLabel>
                                <TextField
                                    id="standard-select-currency-native"
                                    select
                                    // onChange={props.handleChange}
                                    // onBlur={props.handleBlur}
                                    // value={props.values.max_uses}
                                    // InputProps={{ className: classes.root }}
                                    SelectProps={{
                                    // classes: { icon: classes.icon },
                                    native:true
                                    }}
                                    variant="standard"
                                    name="max_uses"
                                // value={gender}
                                  >
                                      <option value={""}>select from option</option>
                                      <option value={'7days'}>Film/Video</option>
                                      <option value={'7days'}>Screenplay/Script</option>
                                      <option value={'7days'}>Song/Music</option>
                                      <option value={'7days'}>Art/Photography</option>
                                  </TextField>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Judging Form Type</FormLabel>
                        <RadioGroup aria-label="gender" name="judgeFormType" 
                            onChange={this.handleSelect}
                            value={this.state.judgeFormType}>
                            <div>
                                <FormControlLabel value="default" control={<Radio size="small" color="primary"/>} label="Default Form" />
                                <FormControlLabel value="custom" control={<Radio size="small" color="primary"/>} label="Custom Form" />
                            </div>
                        </RadioGroup>
                    </FormControl>
                    <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>Preview</Typography>
                    <Paper style={{padding:"20px", backgroundColor:"#F8F8F8"}}>
                    <Grid container>
                        <Grid xs={5}>
                            <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>Overall Rating</Typography>
                            <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>Originality / Creativity</Typography>
                            <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>Direction</Typography>
                            <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>Writing</Typography>
                            <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>Cinematography</Typography>
                            <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>Performances</Typography>
                            <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>Direction</Typography>
                            <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>Pacing</Typography>
                            <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>Structure</Typography>
                        </Grid>
                        <Grid xs={2}>
                            <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>5/10</Typography>
                            <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>5/10</Typography>
                            <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>5/10</Typography>
                            <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>5/10</Typography>
                            <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>5/10</Typography>
                            <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>5/10</Typography>
                            <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>5/10</Typography>
                            <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>5/10</Typography>
                            <Typography variant="h4" style={{marginBottom:'10px', fontWeight:800}}>5/10</Typography>
                        </Grid>
                        <Grid xs={5}>
                            <StyledRating name="customized-color"  style={{marginBottom:'19px',fontWeight:800}} defaultValue={5} max={10} />
                            <StyledRating name="customized-color"  style={{marginBottom:'19px',fontWeight:800}} defaultValue={5} max={10} />
                            <StyledRating name="customized-color"  style={{marginBottom:'19px',fontWeight:800}} defaultValue={5} max={10} />
                            <StyledRating name="customized-color"  style={{marginBottom:'19px',fontWeight:800}} defaultValue={5} max={10} />
                            <StyledRating name="customized-color"  style={{marginBottom:'19px',fontWeight:800}} defaultValue={5} max={10} />
                            <StyledRating name="customized-color"  style={{marginBottom:'19px',fontWeight:800}} defaultValue={5} max={10} />
                            <StyledRating name="customized-color"  style={{marginBottom:'19px',fontWeight:800}} defaultValue={5} max={10} />
                            <StyledRating name="customized-color"  style={{marginBottom:'19px',fontWeight:800}} defaultValue={5} max={10} />
                            <StyledRating name="customized-color"  style={{marginBottom:'19px',fontWeight:800}} defaultValue={5} max={10} />
                        </Grid>
                    </Grid>                        
                    </Paper>
                    <Typography variant="h4" style={{marginTop:'20px',fontWeight:800}}>Comments</Typography>
                        <FormControl style={{width:"100%"}}>
                            <TextField  
                                id="outlined-disabled"
                                placeholder="Film/video"
                                // onChange={(e) => setIntialValue({...initialValues,facebook_link:e.target.value})}
                                //@ts-ignore
                                InputProps={{ className: classes.root }}
                                name="facebook_link"
                                // value={initialValues.facebook_link}
                                //@ts-ignore  
                                className={classes.inputClass}
                            />
                        </FormControl>
                    <Typography variant="h4" style={{marginTop:'20px',fontWeight:800}}>Recommendation</Typography>
                    <Grid container spacing={1} style={{marginTop:"10px"}}>
                        <Grid xs={3} style={{textAlign:"center"}}>
                            <Button color="primary" style={webStyles.commonButton} disabled={true}>Pass</Button>   
                        </Grid>
                        <Grid xs={3} style={{textAlign:"center"}}>
                            <Button color="primary" style={webStyles.commonButton} disabled={true}>Recommend</Button>   
                        </Grid>
                        <Grid xs={3} style={{textAlign:"center"}}>
                            <Button color="primary" style={webStyles.commonButton} disabled={true}>Award</Button>   
                        </Grid>
                        <Grid xs={3} style={{textAlign:"center"}}>
                            <Button color="primary" style={webStyles.commonButton} disabled={true}>May be?</Button>   
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            <Box style={{margin:"8px 0px 0px 0px"}}>
                <Button color="primary" type="submit">SAVE</Button>
            </Box>
        </Container>
        </form>
        )}
       </Formik>
    )
}
}

export default CustomJudgeForm;
const webStyles = {
      commonButton:{
        color: "#000", 
        border: "none", 
        backgroundColor:"#F8F8F8",
        fontWeight:800,
        width:"200px"
      }
      
  };