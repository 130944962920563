Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Events2";
exports.labelBodyText = "Events2 Body";
exports.allProjectsURL = "bx_block_posts/project_informations";
exports.endPointDeleteProject = "bx_block_posts/project_informations/";
exports.discountwaiverDataendpoint = "/bx_block_discountsoffers/discount_coupons";
exports.getdiscountwaiverDataendpoint = "/bx_block_discountsoffers/discount_coupons/";
exports.DiscountwaiverPeriod = "/bx_block_discountsoffers/update_deadline_waiver_period"
exports.submissionConformMessageendpoint = "/account_block/accounts/confirmation_messages/";
exports.reviewTypeendpoint = "/bx_block_reviews/review_visibility";
exports.customSubmissonDataendpoint = "/bx_block_customform2/customform_submissions/";
exports.getcustomSubmissonDataendpoint = "/bx_block_customform2/customform_submissions/"

exports.reviewTypeApiMethod = "POST";
exports.discountwaiverdataApiMethod = "POST";
exports.submissionConformMessageApiMethod = "POST";
exports.getdiscountwaiverdataApiMethod = "GET";
exports.getReviewApiMethod = "GET";
exports.getcustomSubmissonDataApiMethod = "GET";
exports.deleteAPiMethod = "DELETE";

exports.btnExampleTitle = "CLICK ME";
exports.endPointApiUpdateAccountDetails = "account_block/accounts/3";
exports.methodTypeApiGetUserProfile = "GET"

// Festival Data Config
exports.festivalDataApiContentType="application/json";
exports.festivalDataApiEndPoint="/bx_block_events/festivals";
exports.festivalDataApiMethodType="GET";

exports.festivalReviewDataApiContentType="application/json";
exports.festivalReviewDataApiEndPoint="/bx_block_events/browse_festivals";
exports.festivalReviewDataApiMethodType="GET";

exports.browsefestivalDataApiContentType="application/json";
exports.browsefestivalDataApiEndPoint="/bx_block_events/browse_festivals";
exports.browsefestivalDataApiMethodType="GET";

exports.festivalPhotoUploadApiEndPoint="/bx_block_events/festival_photos/add_festival_photo";
exports.festivalPhotoUploadApiMethodType="POST";

exports.festivalPhotosApiContentType="application/json";
exports.festivalPhotosDataApiEndPoint="/bx_block_events/festival_photos/photo_list";
exports.festivalPhotosDataApiMethodType="GET";

exports.festivalPhotosOrderApiEndPoint="/bx_block_events/festival_photos/change_photo_order";
exports.festivalPhotosOrderApiMethodType="PATCH";

exports.festivalPhotosDeleteApiEndPoint="/bx_block_events/festival_photos/delete_photo";
exports.festivalPhotosDeleteApiMethodType="DELETE";

exports.festivalPhotosAddAlbumApiEndPoint="/bx_block_events/festival_photos/create_album";
exports.festivalPhotosAddAlbumApiMethodType="POST";

exports.festivalPhotosAlbumApiContentType="application/json";
exports.festivalPhotosAlbumApiEndPoint="/bx_block_events/festival_photos/album_list?festival_id=";
exports.festivalPhotosAlbumApiMethodType="GET";

exports.festivalPhotosMoveToAlbumApiEndPoint="/bx_block_events/festival_photos/move_photo";
exports.festivalPhotosMoveToAlbumApiMethodType="PATCH";

exports.festivalPhotosRemoveFromAlbumApiEndPoint="/bx_block_events/festival_photos/delete_album_photo";
exports.festivalPhotosRemoveFromAlbumApiMethodType="PATCH";

exports.festivalAddPhotosToAlbumApiEndPoint="/bx_block_events/festival_photos/add_album_photo";
exports.festivalAddPhotosToAlbumApiMethodType="POST";

exports.festivalDeleteAlbumApiEndPoint="/bx_block_events/festival_photos/delete_album";
exports.festivalDeleteApiMethodType="DELETE";

exports.festivalAlbumPhotosGETApiContentType="application/json";
exports.festivalAlbumPhotosGETApiEndPoint="/bx_block_events/festival_photos/album_photo_list?";
exports.festivalAlbumPhotosGETApiMethodType="GET";

exports.festivalReOrderAlbumApiEndPoint="/bx_block_events/festival_photos/change_album_photo_order";
exports.festivalReOrderApiMethodType="PATCH";

exports.festivalRenameAlbumApiEndPoint="/bx_block_events/festival_photos/rename_album";
exports.festivalRenameAlbumApiMethodType="PATCH";

exports.festivalHelpFulReviewApiEndPoint="/bx_block_reviews/festival_reviews/";
exports.festivalHelpFulReviewMethodType="PATCH";
// Customizable Area End