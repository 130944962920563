import * as React from 'react';

import {
  Box,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  InputLabel,
  TextField,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";

const steps = [1, 2, 3, 4, 5];

const currencies = [
  {
    value: 'USD',
    label: 'United States',
  },
  {
    value: 'EUR',
    label: 'Europ',
  },
  {
    value: 'INR',
    label: 'India',
  },
  {
    value: 'JPY',
    label: 'Japan',
  },
];

export default function StepperView() {
  const [activeStep, setActiveStep] = React.useState(1);
  const [currency, setCurrency] = React.useState('EUR');

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrency(event.target.value);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={styles.StepperCard}>
        <Stepper activeStep={activeStep} style={{ width: '75%'}}>
          {steps.map((label, index) => {
            return (
              <Step key={label} >
                <StepLabel></StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <Box sx={{ padding: '50px 200px'}} bgcolor="#f2f3f5">
        
        <Typography variant="h2" component="h2">Add Project</Typography>

        {activeStep === 0 &&
          <Box sx={{padding: '30px 0px'}}>
            <Box sx={styles.formTitle}>
              <Typography variant="h4" component="h4">Project Information</Typography>
            </Box>
            <Box bgcolor="#FFF">
              <Box
                component="form"
                sx={{ padding: '30px'}}
              >
                <div>
                  <FormControl component="div" style={{ padding: '20px 0px'}}>
                    <FormLabel component="legend">Project Type</FormLabel>
                    <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
                      <FormControlLabel 
                        value="1" 
                        control={<Radio size="small" color="primary"/>} 
                        label="Film/Video" 
                      />
                      <FormControlLabel 
                        value="2" 
                        control={<Radio size="small" color="primary"/>}  
                        label="Script" 
                      />
                      <FormControlLabel 
                        value="3" 
                        control={<Radio size="small" color="primary"/>}  
                        label="Documentary" 
                      />
                      <FormControlLabel 
                        value="4" 
                        control={<Radio size="small" color="primary"/>}  
                        label="Music / Songwriting" 
                      />
                      <FormControlLabel 
                        value="5" 
                        control={<Radio size="small" color="primary"/>}  
                        label="Photography / Design" 
                      />
                      <FormControlLabel 
                        value="6" 
                        control={<Radio size="small" color="primary"/>}  
                        label="VR / XR / Immersive BETA" 
                      />
                    </RadioGroup>
                  </FormControl>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between'}} >
                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                      <FormLabel component="legend">Name</FormLabel>
                      <TextField
                        id="outlined-disabled"
                        placeholder="Enter Your Name"
                      />
                    </FormControl>
                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                      <FormLabel component="legend">Brief Synopsis (English)</FormLabel>
                      <TextField
                        id="outlined-disabled"
                        placeholder="Enter Your Description"
                      />
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between'}} >
                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                      <FormLabel component="legend">Website</FormLabel>
                      <TextField
                        id="outlined-disabled"
                        placeholder="www.xyz.com"
                      />
                    </FormControl>
                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                      <FormLabel component="legend">Twitter</FormLabel>
                      <TextField
                        id="outlined-disabled"
                        placeholder="twitter.com/xyz"
                      />
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between'}} >
                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                      <FormLabel component="legend">Facebook</FormLabel>
                      <TextField
                        id="outlined-disabled"
                        placeholder="facebook.com/xyz"
                      />
                    </FormControl>
                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                      <FormLabel component="legend">Instagram</FormLabel>
                      <TextField
                        id="outlined-disabled"
                        placeholder="instagram.com/xyz"
                      />
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between'}} >
                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                      <FormLabel component="legend">IMDB</FormLabel>
                      <TextField
                        id="outlined-disabled"
                        placeholder="https://www.imdb.com/"
                      />
                    </FormControl>
                  </Box>

                </div>
              </Box>
            </Box> 
          </Box>
        }

        {activeStep === 1 &&
          <Box sx={{padding: '30px 0px'}}>
            <Box sx={styles.formTitle}>
              <Typography variant="h4" component="h4">Submitter Information</Typography>
            </Box>
            <Box bgcolor="#FFF">
              <Box
                component="form"
                sx={{ padding: '30px'}}
              >
                <div>
                  <FormControl component="div" style={{ padding: '20px 0px'}}>
                    <FormLabel component="legend">Contact Information</FormLabel>
                  </FormControl>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between'}} >
                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                      <FormLabel component="legend">Email</FormLabel>
                      <TextField
                        id="standard-basic"
                        placeholder="Enter Your Email Address"
                        variant="standard"
                      />
                    </FormControl>
                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                      <FormLabel component="legend">Phone No.</FormLabel>
                      <TextField
                        id="outlined-disabled"
                        placeholder="eg: +91 999-876-543"
                      />
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between'}} >
                    <FormControl component="div" style={{ padding: '20px 0px', width: '20%'}}>
                      <FormLabel component="legend">DOB</FormLabel>
                      <TextField
                        id="outlined-disabled"
                        type="date"
                        placeholder="www.xyz.com"
                      />
                    </FormControl>
                    <FormControl component="div" style={{ padding: '20px 0px', width: '20%'}}>
                      <FormLabel component="legend">Gender</FormLabel>
                      <TextField
                        id="standard-select-currency-native"
                        select
                        // value={currency}
                        // onChange={handleChange}
                        SelectProps={{
                          native: true,
                        }}
                        // helperText="Please select your currency"
                        variant="standard"
                      >
                          <option value={'male'}>Male</option>
                          <option value={'female'}>Female</option>
                          <option value={'others'}>Others</option>
                      </TextField>
                    </FormControl>
                    <FormControl component="div" style={{ padding: '20px 0px', width: '20%'}}>
                      <FormLabel component="legend">Country of Origin</FormLabel>
                      <TextField
                        id="standard-select-currency-native"
                        select
                        value={currency}
                        onChange={handleChange}
                        SelectProps={{
                          native: true,
                        }}
                        // helperText="Please select your currency"
                        variant="standard"
                      >
                        {currencies.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </FormControl>
                    <FormControl component="div" style={{ padding: '20px 0px', width: '20%'}}>
                      <FormLabel component="legend">Country of Residence</FormLabel>
                      <TextField
                        id="standard-select-currency-native"
                        select
                        value={currency}
                        onChange={handleChange}
                        SelectProps={{
                          native: true,
                        }}
                        // helperText="Please select your currency"
                        variant="standard"
                      >
                        {currencies.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between'}} >
                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                      <FormLabel component="legend">Address</FormLabel>
                      <TextField
                        id="outlined-disabled"
                        placeholder="Enter Full Address"
                      />
                    </FormControl>
                    <FormControl component="div" style={{ padding: '20px 0px', width: '20%'}}>
                      <FormLabel component="legend">City</FormLabel>
                      <TextField
                        id="standard-select-currency-native"
                        select
                        value={currency}
                        onChange={handleChange}
                        SelectProps={{
                          native: true,
                        }}
                        // helperText="Please select your currency"
                        variant="standard"
                      >
                        {currencies.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </FormControl>
                    <FormControl component="div" style={{ padding: '20px 0px', width: '20%'}}>
                      <FormLabel component="legend">State / Province</FormLabel>
                      <TextField
                        id="standard-select-currency-native"
                        select
                        value={currency}
                        onChange={handleChange}
                        SelectProps={{
                          native: true,
                        }}
                        // helperText="Please select your currency"
                        variant="standard"
                      >
                        {currencies.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </FormControl>
                  </Box>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between'}} >
                    <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                      <FormLabel component="legend">Postal Code</FormLabel>
                      <TextField
                        id="outlined-disabled"
                        placeholder="Enter pin code"
                      />
                    </FormControl>
                  </Box>

                </div>
              </Box>
            </Box> 
          </Box>
        }
        <React.Fragment>
          <Box sx={{ display: 'flex', width: '43%', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button color="primary" onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      </Box>
    </Box>
  );
}


const styles = {
  StepperCard: {
    width: '100%', 
    display: 'flex',
    justifyContent: 'center',
  },
  formTitle: {
    background: '#330a4c',
    padding: '20px',
    color: '#fff'
  }
}