// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  formik?: any;
  handleBack?: any;
  handleLoadingFalse?: any;
  handleLoadingTrue? : any;
  loading?: any;
}

interface S {
  checkboxError: boolean;
  checkedValues: any;
  anchorEl: any;
  festivalData: any;
  festivalID: any;
  isReinitailize: boolean;
  isChecked: boolean;
  listButtonClicked: boolean;
  isDraft: boolean;
}

interface SS {
  id: any;
}

export default class CoverImageController extends BlockComponent<
  Props,
  S,
  SS
> {
  editEventCall: any;
  updateEventAPICall: string = '';
  getFestivalAPICall: string = '';

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.PostDetailDataMessage)
    ]

    this.state = {
      checkboxError: false,
      checkedValues: [''],
      anchorEl: null,
      isReinitailize: true,
      festivalData: null,
      festivalID: null,
      isChecked: false,
      listButtonClicked: false,
      isDraft: true
    };
    // @ts-ignore
    this.callRef = React.createRef(); 
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const id = window.location.href.split("/")[5]
    if(id){
      this.setState({ festivalID: id})
    }
    this.getFestivalData();
    this.setState({ checkboxError: false })
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    const { checkedValues } = this.state;

    if (!(checkedValues.length === prevState.checkedValues.length && checkedValues.every((a1:any) => prevState.checkedValues.includes(a1)))) {
      if (checkedValues?.length < 2) {
        this.setState({ checkboxError: true })
      } else {
        this.setState({ checkboxError: false })
      }
    }
  }

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.PostDetailDataMessage) === message.id){
      if(message.properties.text === "SAVE_FESTIVAL_FORM_DATA"){
          //@ts-ignore
          this.callRef?.current.click();
          this.setState({ listButtonClicked: false})
      }
      if(message.properties.text === "LIST_FESTIVAL_FORM_DATA") {
          //@ts-ignore
          this.callRef?.current.click();
          this.setState({ listButtonClicked: true})
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getFestivalAPICall) {
        const { festivalID } = this.state;
        responseJson && responseJson.data.map((rank: any, i: any, row: any) => {
          if(festivalID){
            if (rank.id === festivalID) {
              this.setState({
                festivalData: rank.attributes.category_and_entry_fee,
                checkedValues: rank.attributes.category_and_entry_fee?.event_type || [""],
                isDraft: rank.attributes.is_draft
              })
            }
          } else {
            if (i + 1 === row.length) {
              this.setState({
                festivalData: rank.attributes.category_and_entry_fee,
                festivalID: rank.id,
                checkedValues: rank.attributes.category_and_entry_fee?.event_type || [""],
                // isDraft: 
              })
            }
          }
          setTimeout(() => { this.setState({ isReinitailize: false }) }, 2000);
        })
      }

      if (apiRequestCallId === this.updateEventAPICall) {
        if (responseJson != null && !responseJson.error) {
          toast.success("Congratulations, Your Event is listed")
          //@ts-ignore
          this.props.history.push("/myFestival/list")
        } else {
          toast.error(responseJson.error)
        }
      }
    }
  }

  handleSubmitEvent = (values: any) => {
    const { isChecked, checkedValues } = this.state;
    var formData = new FormData();
    formData.append('event[category_and_entry_fee_attributes][category_search]', values.category_search);
    formData.append('event[category_and_entry_fee_attributes][search_term]', values.search_terms);
    formData.append('event[category_and_entry_fee_attributes][runtime_search_minimum]', isChecked ? 0 : values.min_minutes);
    formData.append('event[category_and_entry_fee_attributes][runtime_search_maximum]', isChecked ? 500 : values.max_minutes );
    formData.append('event[category_and_entry_fee_attributes][all_length]', isChecked ? "true" : "false");
    formData.append('event[category_and_entry_fee_attributes][listing_url]', values.listingUrl);
    formData.append('event[category_and_entry_fee_attributes][listing_visibility]', values.listingVisibility);
    formData.append('event[category_and_entry_fee_attributes][rules_terms]', values.rules_terms);
    if(this.state.isDraft && this.state.listButtonClicked) {
      formData.append('event[is_draft]',"false");
    } else if(this.state.isDraft && !this.state.listButtonClicked) {
      formData.append('event[is_draft]',"true");
    } else {
      formData.append('event[is_draft]',"false");
    }
    checkedValues.forEach((element: any, id: any) => {
      formData.append(`event[category_and_entry_fee_attributes][event_type][]`, element);
    })
    this.updateEventDetails(formData, this.state.festivalID)
  }

  updateEventDetails = (UpdatedData: any, festivalID: any) => {
    let url = `${configJSON.endPointApiUpdateEventDetails + festivalID}`

    const headers = {
      "Content-Type": configJSON.APiContentType,
      token: localStorage.getItem('authToken'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateEventAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      UpdatedData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFestivalData(): boolean {
    const header = {
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFestivalAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiFestivalData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleSelect = (checkedName: any) => {
    const newNames = this.state.checkedValues?.includes(checkedName)
      ? this.state.checkedValues?.filter((name: any) => name !== checkedName)
      : [...(this.state.checkedValues ?? []), checkedName];
    this.setState({ checkedValues: newNames });
    return newNames;
  }

  handleSubmit = () => {
    const { checkedValues } = this.state;
    if (checkedValues.length < 2) {
      this.setState({ checkboxError: true })
    }
    this.setState({ listButtonClicked: true})
  }

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  handlePopoverOpen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCheckBoxEvent = (e: any) => {
    this.setState({isChecked: e.target.checked })
  }
}
 // Customizable Area End
