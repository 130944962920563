import React, {Component} from 'react';
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography
} from "@material-ui/core";
import {Link, NavLink} from "react-router-dom";
import LoginModal from "../../blocks/LandingPage2/src/login.web"
import SignUpModal from "../../blocks/LandingPage2/src/signup.web"

import NavBarController, {Props} from "./NavBarController";
const logo = require("./logo.png");
const downArrow = require("./down-arrow.png");
const imgNotification = require("./ic_notification.png");
const noNotification = require("./no-alarm.png");


class NavBar extends NavBarController {
    render() {
        return (
            <AppBar
                position="sticky"
                color="default"
                elevation={1}
                style={wenStyles.appBar}
            >
                <Container style={{ maxWidth:'1400px'  }} maxWidth={false} >
                    {this.state.authToken ?
                        <Toolbar disableGutters>
                            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={this.state.anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(this.state.anchorElNav)}
                                    onClose={this.handleCloseNavMenu}
                                >
                                    <MenuItem onClick={this.handleCloseNavMenu}>
                                        <Typography color="primary" >Browse Festivals</Typography>
                                    </MenuItem>
                                    {/* <MenuItem onClick={handleCloseNavMenu}>
                  <Typography color="primary" >Dashboard</Typography>
                </MenuItem> */}
                                    {this.state.userRole === "organizer" ?
                                        <MenuItem onClick={this.handleMyEvent}>
                                            <Typography color="primary" >My Festival</Typography>
                                        </MenuItem> :  <MenuItem onClick={this.handleNavigateMyProject}>
                                            <Typography color="primary" >My Projects</Typography>
                                        </MenuItem>
                                    }
                                    <MenuItem onClick={this.handleCloseNavMenu}>
                                        <Typography color="primary" >Submissions</Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                            <Box sx={wenStyles.toolbarLogo}>
                                <img src={logo} alt="logo" />
                            </Box>
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                {
                                    this.state.userRole === "organizer" ?
                                        <NavLink exact className="routes" activeClassName="active_route" to="#">
                                            Submission
                                        </NavLink> :
                                        <NavLink exact className="routes" activeClassName="active_route" to="/BrowseFestival">
                                            Browse Festivals
                                        </NavLink>
                                }
                                {
                                    this.state.userRole === "organizer" ?
                                        <NavLink exact className="routes" activeClassName="active_route" to="/Dashboard">
                                            Dashboard
                                        </NavLink>
                                        :
                                        <NavLink exact className="routes" activeClassName="active_route" to="#">
                                            Dashboard
                                        </NavLink>
                                }
                                {
                                    this.state.userRole === "organizer" ?
                                        <NavLink exact className="routes" activeClassName="active_route" to="/myFestival/list">
                                            My Festival
                                        </NavLink> :
                                        <NavLink exact className="routes" activeClassName="active_route" to="/myProject">
                                            My Projects
                                        </NavLink>
                                }
                                {
                                    this.state.userRole === "organizer" ?
                                        <NavLink exact className="routes" activeClassName="active_route" to="#">
                                            Tickets
                                        </NavLink>
                                        :
                                        <NavLink exact className="routes" activeClassName="active_route" to="#">
                                            Submissions
                                        </NavLink>
                                }
                            </Box>
                            <Box sx={{ flexGrow: 0 }}>
                                <IconButton style={{padding: "0px"}} onClick={this.handleOpenNotofication}>
                                    <Avatar src={imgNotification} alt="logo" style={{height:"27px", width:"27px", marginRight: "7px"}} />
                                </IconButton>
                                {this.state.open ? (
                                    <Box sx={styles} boxShadow={3}>
                                        <div style={{textAlign:"center"}}>
                                            <img style={{width:"25px", marginRight:"10px"}} src={noNotification} alt="logo" />
                                            <Typography color="primary" >You, have no notifications.</Typography>
                                        </div>
                                    </Box>
                                ) : null}
                            </Box>

                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={this.handleOpenUserMenu} style={{ padding: '0px' }}>
                                        <Avatar
                                            src={this.state.accountData &&
                                                // @ts-ignore
                                                (this.state.accountData?.profile_pic ? this.state.accountData?.profile_pic : "")}
                                        >
                                            {
                                                // @ts-ignore
                                                (this.state.accountData  && this.state.accountData.profile_pic == "") ?
                                                // @ts-ignore
                                                this.state.accountData?.first_name?.charAt(0).toUpperCase()  : "R"
                                            }
                                        </Avatar>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    style={{ marginTop: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={this.state.anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(this.state.anchorElUser)}
                                    onClose={this.handleCloseUserMenu}
                                >
                                    {this.state.userRole !== 'organizer' && <MenuItem component={Link} to="/Dashboard" onClick={this.handleViewProfile}>
                                        <Typography >View Profile</Typography>
                                    </MenuItem>}
                                    {
                                        this.state.userRole === 'organizer' &&
                                        <MenuItem component={Link} to="/UserProfileBasicBlock" onClick={this.handleNavigateAccountSettings}>
                                            <Typography >Account settings</Typography>
                                        </MenuItem>
                                    }
                                    {/* <MenuItem onClick={handleCloseNavMenu}>
                  <Typography >Dashboard</Typography>
                </MenuItem> */}
                                    <MenuItem onClick={() =>{this.handleLogout()}}>
                                        <Typography >Logout</Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={this.handleOpenUserMenu} style={{ padding: '0px' }}>
                                        <img style={{width:"25px", marginLeft:"10px"}} src={downArrow} alt="logo" />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    style={{ marginTop: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={this.state.anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(this.state.anchorElUser)}
                                    onClose={this.handleCloseUserMenu}
                                >
                                    {this.state.userRole !== 'organizer' && <MenuItem component={Link} to="/Dashboard" onClick={this.handleCloseNavMenu}>
                                        <Typography >View Profile</Typography>
                                    </MenuItem>
                                    }
                                    <MenuItem component={Link} to="/UserProfileBasicBlock" onClick={this.handleNavigateAccountSettings}>
                                        <Typography >Account settings</Typography>
                                    </MenuItem>
                                    { this.state.userRole !== 'organizer' && this.state.userRole !== 'submitter' && <MenuItem component={Link} to="/Dashboard" onClick={this.handleCloseNavMenu}>
                                        <Typography >Dashboard</Typography>
                                    </MenuItem>}
                                    <MenuItem onClick={this.handleLogout}>
                                        <Typography >Logout</Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </Toolbar>
                        :
                        <Toolbar >
                            <Box sx={wenStyles.toolbarLogo } >
                                <img
                                    onClick={() => {
                                        // this.props.history.push("/");
                                    }}
                                    src={logo}
                                    alt="logo"
                                    style={{ width: "21%",marginLeft:"60px" }}
                                />
                            </Box>
                            <div style={{ marginRight:"120px",display:'flex',alignItems:'center' }}>
                                <NavLink exact className="routes" activeClassName="active_route" to="/BrowseFestival"  style={{color: "#fff", fontWeight: "bold", fontSize: "18px", textTransform: "capitalize", width: 'fit-content',height: 'fit-content',background: 'none',border: 'none',marginRight:"75px"}}>
                                    Browse Festivals
                                </NavLink>
                                <Button
                                    color="primary"
                                    style={{color: "#fff", fontWeight: "bold", fontSize: "18px", textTransform: "capitalize", width: 'fit-content',height: 'fit-content',background: 'none',border: 'none',right:"50px"}}
                                    onClick={() => this.toggleLoginModal()}
                                >
                                    Log in
                                </Button>
                                <Button
                                    color="primary"
                                    style={{color: "#fff", fontWeight: "bold", fontSize: "18px", textTransform: "capitalize", width: 'fit-content',height: 'fit-content',background: 'none',border: 'none',right:"25px"}}
                                    onClick={() => this.toggleSignupModal()}
                                >
                                    Sign Up
                                </Button>
                            </div>
                        </Toolbar>
                    }
                </Container>
            </AppBar>
        );
    }
}

const wenStyles = {
    appBar: {
        borderBottom: `1px solid #000`,
        width: "100%",
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
        backgroundColor: "#330a4c",

        // height: theme.spacing(8.75),
    },

    toolbar: {
        flexWrap: "nowrap",
        // padding: theme.spacing(0, 10),
    },
    toolbarLogo: {
        flexGrow: 1,
    },
    toolbarLogoToken: {
        flexGrow: "0.5",
    },
    navBarButtons: {
        color: "#fff",
        fontSize: "18px",
        textTransform: "capitalize",
        width: 'fit-content',
        height: 'fit-content',
        background: 'none',
        border: 'none'
    },
    landingText:{
        fontSize:'42px',
        color: '#fff',
        fontWeight: 'bold',
    }
}

const styles = {
    position:'absolute',
    top: 57,
    right: 0,
    width:300,
    height:300,
    zIndex: 1,
    p: 1,
    bgcolor: 'background.paper',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

export default NavBar;