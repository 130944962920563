import * as React from 'react';

import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  Button,
  Box,
  TextField,
  Icon,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider"
import FacebookLogin from 'react-facebook-login'
import GoogleLogin from 'react-google-login';

import CancelIcon from "@material-ui/icons/Cancel";
import { gogoleIcon } from './assets'

const configJSON = require("./config");

interface LoginModalProps {
  isOpen: any;
  handleClose: any;
  handleSignupOpen: any;
  handleEmailChange: any;
  handlePasswordChange: any;
  handleSocialMediaSignup: any
  handleLogin: any;
  errorMessage: string;
  successMessage: string;
  navigateToForgot: any;
}

const LoginModal: React.FC<LoginModalProps> = ({
  isOpen,
  handleClose,
  handleSignupOpen,
  handleEmailChange,
  handlePasswordChange,
  handleSocialMediaSignup,
  handleLogin,
  errorMessage,
  successMessage,
  navigateToForgot,
}) => {

  const [emailError, setEmailError] = React.useState("")
  const [passwordError, setPasswordError] = React.useState("")

  const responseFacebook = async (response: any) => {
    console.log('response', response)
    const name = response.name.split(" ")
    if(response.email){
      handleSocialMediaSignup(response.email, response.id ,"facebook",name[0],name[2]||name[1]||"");
    }
  }
  const responseGoogle = (response: any) => {
    if(response.googleId){
      handleSocialMediaSignup(response.profileObj.email, response.googleId ,"google",response.profileObj.givenName,response.profileObj.familyName);
    }else{
      console.log('google-Error', response.error)
    }
  }


  const DividerWithOr = () => {
    return (
      <div style= {{  display: "flex", alignItems: "center"}} >
        <div style= {{ borderBottom: "1px solid #ccc", width: "100%"}} />
        <span style= {{padding: "0 10px 0 10px", fontWeight: 700}}>
          or
        </span>
        <div style={{borderBottom: "1px solid #ccc", width: "100%"}} />
      </div>
    );
  };

  const isValidEmailAddress = (textEmail: string) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(textEmail)){
      handleEmailChange(textEmail)
      setEmailError("")
    }else{
      handleEmailChange("")
      setEmailError("Enter Valid Email")
    }
  }

  const isValidPasswordAddress = (textPassword: string) => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if(re.test(textPassword)){
      handlePasswordChange(textPassword)
      setPasswordError("")
    }else{
      handlePasswordChange("")
      setPasswordError("Enter Valid Password")
    }
  }

  return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            disableEscapeKeyDown={true}
        >
            <DialogContent style={{ padding: "0px", overflow: "hidden" }}>
              {(errorMessage || successMessage) &&
                <Box component="div" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60px', bgcolor:`${errorMessage? '#e73939' : '#30813a'}`, padding: '0px 15px'}} >
                  <Typography variant="subtitle1" style={{color: '#FFF'}}>
                    {errorMessage || successMessage }
                  </Typography>
                </Box>
              }
                <Grid container spacing={2} justify="space-between">
                <Grid item md={12} xs={12}>
                    <Box py={4} >
                      <Grid container justify="center" spacing={1}>
                        <Grid item xs={9}>
                        <Typography variant="h3" >
                            Log In
                        </Typography>
                        </Grid>
                        <Grid item xs={1} container justifyContent="flex-end" style={{cursor: 'pointer'}}>
                        <Icon onClick={() => handleClose()}>
                            <CancelIcon style={{fill: "grey"}} />
                        </Icon>
                        </Grid>
                        <Grid container direction="row" alignItems={'center'} justify={'center' } item xs={12}style={{ width: "100%"}} >
                          <Grid item xs={10}>
                            <FacebookLogin
                              appId={configJSON.facebookAppID}
                              fields="name,email,picture"
                              icon="fa-facebook-square fa-1x"
                              textButton="Log In with Facebook"
                              callback={(res: any) => responseFacebook(res)}
                            />
                          </Grid>
                        </Grid>
                        <Grid  container direction="row" alignItems={'center'} justify={'center' }  style={{ width: "100%"}}>
                            <Grid item xs={10} style={{padding: '4px'}}>
                              <GoogleLogin
                                clientId={configJSON.clientID} //CLIENTID NOT CREATED YET
                                buttonText="LOGIN WITH GOOGLE"
                                onSuccess={(res) => responseGoogle(res)}
                                onFailure={(res) => responseGoogle(res)}
                                render={renderProps => (
                                  <Button
                                      fullWidth
                                      style={{ border: '1px solid #878383', height: '42px', fontSize: '12px', fontWeight: 700, textTransform: 'capitalize'}}
                                      onClick={renderProps.onClick} 
                                  >
                                      <img src={gogoleIcon} width={20} style={{position: 'absolute', left: '15px'}}  />
                                      Log in with Google
                                  </Button>
                                )}
                            />
                          </Grid>
                        </Grid>
                        <Grid xs={10} style={{padding: '4px'}}>
                          <DividerWithOr />
                        </Grid>
                        <Grid item xs={10}>
                          <TextField
                            type="email"
                            fullWidth
                            variant="outlined" 
                            id="outlined-disabled"
                            placeholder="Email Address"
                            onChange={(e) => isValidEmailAddress(e.target.value)}
                            onBlur={(e) => isValidEmailAddress(e.target.value)}
                          />
                          {emailError && <span style={{fontSize: '12px', color: 'red'}}>{emailError}</span> }
                        </Grid>

                        <Grid item xs={10}>
                          <TextField
                            type="password"
                            fullWidth
                            variant="outlined" 
                            id="outlined-disabled"
                            placeholder="Password"
                            onChange={(e) => isValidPasswordAddress(e.target.value)}
                            onBlur={(e) => isValidPasswordAddress(e.target.value)}
                          />
                          {passwordError && <span style={{fontSize: '12px', color: 'red'}}>{passwordError}</span> }
                        </Grid>
                        <Grid item xs={10} style={{cursor: 'pointer'}}>
                          <Typography variant="subtitle1" align="right" onClick={() => navigateToForgot()} >Forget Password?</Typography>
                        </Grid>

                        <Grid item xs={10}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            disabled={passwordError !== "" || emailError !== ""}
                            onClick={() => handleLogin()}
                        >
                            Log In
                        </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid container justify="center" spacing={2}>
                      <Grid xs={10} style={{padding: '4px'}}>
                        <Divider />
                      </Grid>
                    </Grid>
                    <Box py={2} >
                      <Grid container justify="center" spacing={2}>
                        <Grid component={Box} item xs={6} alignSelf='center'>
                          <Typography variant="subtitle1" >
                              Not a member yet?
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Button
                              color="primary"
                              variant="outlined"
                              fullWidth
                              onClick={() => handleSignupOpen()}
                          >
                            Sign Up
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
  );
};
export default LoginModal;