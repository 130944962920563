import React, { useState } from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button';
import { Box, Tab, Tabs, TextField, FormControl, Input,Modal,FormLabel,CircularProgress } from '@material-ui/core';
import {imageIcon, selectIcon, awardIcon, projectTitleImg, defaultproject } from "./assets";
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import EditForm from './EditForm.web';
import PrivacyData from './PrivacyData.web';
import ProjectFiles from './ProjectFiles.web';
import Specification from './Specification.web';
import Credits from './Credit.web';
import ScreeningandAwards from './Screeningsandaward.web';
import Distribution from './Distribution.web';
import "./style.css";
import {toast} from "react-toastify";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor:"#F2F3F5"
        // padding: theme.spacing(2),
    },
    backgroundDashboard: {
        background: '#FFF'
    },
    inputClass: {
        "& .Mui-focused:after": {
          border: "1px solid green",
        },
      },
    customfiles: {
        "& ::-webkit-file-upload-button": {
            background: "black",
            color: "red",
            padding: "1em",
          },
    },
    tab: {
        padding: theme.spacing(3),
        boxShadow:"none"
    },
    card: {
        padding: theme.spacing(1),
        marginTop: '20px',
        zIndex: 1,
        borderRadius: '5px',
        width: '80%',
        marginLeft: '10%',
    },
    cardHeight: {
        height: '100%',
        maxHeight:"340px"
    },
    icon: {
        padding: theme.spacing(2, 0),
    },
    title: {
        padding: theme.spacing(2),
        marginLeft: '15%'
    },
    cardTitle: {
        padding: theme.spacing(2),
    },
    cardLink: {
        padding: theme.spacing(2),
        textDecoration: 'underline',
        cursor: 'pointer',
        color: '#9F03CC',
        fontWeight: 'bold',
        fontSize: '15px'
    },
    cardImage: {
        width: '100%',
        maxHeight:"340px",
        objectFit:"cover",
        height:"100%"
    },
    cardDescription: {
        padding: theme.spacing(2),
        color: 'gray'
    },
    cardFooterText: {
        fontSize: '14px',
        // marginLeft: '-16px',
        color: 'gray'
    },

    // View Project

    rootViewProject: {
        padding: theme.spacing(2),
        width: '80%',
        margin: "0 auto",

    },
    cardViewProject: {
        zIndex: 1,
        borderRadius: '5px',
        width: '100%',
    },
    cardImageViewProject: {
        width: '100%',
        height: '100%',
        padding: '5%'
    },
    cardHeightViewProject: {
        height: '100%'
    },
    cardTitleViewProject: {
        paddingTop: '10px',
        fontWeight:"bold",
        // paddingLeft: theme.spacing(3),
        borderBottom: '1px solid #c3c3c3'

    },
    cardDescriptionViewProject: {
        // paddingTop: theme.spacing(2),
        // paddingLeft: theme.spacing(3),
        padding:'10px 30px 0px 30px',
        color: 'gray'
    },
    zindexDown:{
        zIndex:-1
    },
    zindexUp:{
        zIndex:1
    },
    hideVideo:{
        display:"none" 
    },

    // Add Director
    addDirectorCardViewProject: {
        marginTop: theme.spacing(3),
        height: '100%'
    },
    addDirectorTextViewProject: {
        padding: '30px',
        marginTop: '30px',
        // marginLeft: '20px'
    },
    addDirectorHeadshotSection: {
        padding: '65px',
        textAlign: 'center',
        borderStyle: 'dashed',
        cursor: 'pointer'
    },
    addDirectorHeadshotText: {
        fontSize: '12px',
        color: 'blue',
        textDecoration: 'underline',
    },

    // Add Trailer & Photo Section

    addTrailerPhotoSection: {
        marginTop: '24px'
    },
    addTrailerSection: {
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },

    addOnlineScanner: {
        padding: '10px'
    },
    addOnlineScannerUploadText: {
        width: '100%',
        color: 'blue'
    },
    addOnlineScannerUploadSection: {
        padding: '35px',
        marginTop: '15px',
        textAlign: 'center',
        borderStyle: 'dashed',
        cursor: 'pointer'
    },

    notUploadeVideoSection: {
        padding: '10px',
        marginTop: '15px'
    },

    notUploadedVideoSectionPaper: {
        padding: '20px',
    },

    notUploadedVideoText: {
        fontWeight: 'bold',
        marginBottom:"10px",
        textAlign:"left"
    },

    notUploadedVideoTextURL: {
        marginTop: '20px',
        fontWeight: 'bold',
        textAlign:"left"
    },

    notUploadedVideoTextPassword: {
        marginTop: '25px',
        fontWeight: 'bold',
        textAlign:"left"
    },

    notUploadedVideoTrailer:{
        fontWeight: 'bold',
        textAlign:"left"
    },

    newsLinkSection: {
        height: '55px',
        textAlign: 'center',
        paddingTop: '20px'
    },
    // uploadtrailer: {
    //     display:'flex',
    //     alignItem:'center',
    //     justifyContent:'center'
    // },

    newsLinkMainSection: {
        marginTop: '16px',
        width: "100%"
    },

    uploadTextFile: {
        display:"flex",
        justifyContent:"center",
        margin:"10px 0px"
    },

    fontBold: {
        fontWeight: 'bold',
        padding: '5px'
    },

    padding10px: {
        paddingRight: '10px'
    },
    textFiled: {
        fontSize: '13px',
        padding: '10px'
    },
    textFieldBio: {
        paddingTop: '20px',
        "& .Mui-focused:after": {
            border: "1px solid green",
          },
    },
    textFieldBioInputProps: {
        fontSize: '13px',
        padding: '10px',
        width: '100%',
    },
    addPhotos:{
    position: "absolute",
    maxWidth: "253px",
    cursor: "pointer",
    textAlign: "center",
    left: 0,
    right: 0,
    margin: "auto",
    top: "38%",
    color:"#4684EB"
    },
    addDirHeadshot:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        width: "100%",
        height: "100%", 
        border: '2px dashed #c3c3c3', 
        position: 'relative', 
        maxHeight:"277px"
    },
    headshotInput:{
        opacity: 0,
        width: "100%",
        height: "100%",
        cursor: 'pointer',
        position: "absolute",
        zIndex: 1, 
        top: 0, 
        left: 0
    },
    newsDataSection: {
        paddingLeft: "5px",
        cursor: "pointer",
        background: "#EBECED"
    },
    formdisplay:{
        display:"flex"
    },
    formwidth:{
        width:"100%"
    },
    defaultposter:{
        border:"1px solid #f5f5f5",
        borderRadius:'4px'
    }
}));

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 70,
      width: "100%",
      backgroundColor: "#000"
    }
  }
})((props:any) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
};

let customizeTabText: React.CSSProperties = {
    fontWeight: 800,
    textTransform: 'capitalize',
    color:"#000"
}

let fontspacification: React.CSSProperties = {
    fontWeight:500,
    borderRight: "1px solid #000",
    maxHeight:50,
    height:"100%",
}
let customizeViewTabText: React.CSSProperties = {
    fontWeight: 600,
    textTransform: 'capitalize',
    fontSize: '13px'
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>{children}</Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

  const Dashboard  = ({
    handleSubmitNews,
    handleSubmitLinks,
    handleFileAttachLinks,
    handleUploadFileChange,
    handleEditProject,
    handleSubmitTrailer,
    handleHeadshotSubmit,
    handlePosterImg,
    handlePhotoImg,
    handleScanner,
    deleteVideo,
    restoreVideo,
    ProjectInfo,
    currencyData,
    roleData,
    languageData,
    budgetData,
    filmographyData,
    headshotData,
    posterData,
    handleStillPhoto,
    handleScennerVid,
    handleSubtitleFiles,
    loading,
    loaders,
    Videovisible,
    VideoFlag,
    handleUpdatePhotos,
    deleteAccountPhotos,
    imageloader,
    getProjectInformationData

}:any)=>{   
    
    const classes = useStyles();
    const [tabValue, setTabValue] = React.useState<any>(0);
    const [value, setValue] = React.useState<any>(tabValue);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
      setValue(newValue);
      console.log("handlechange===>", newValue)
    };
    React.useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        if(params.has("step")){
            var step = params.get("step") == null ? "0" : params.get("step");
            let setStep = parseInt(step == null ? "0" : step);
            if(setStep == 1){
                setValue(1)
                setTabValue(1)
            }
        }
        else{
            setTabValue(0)
        }
    },[tabValue])

    


    return (
        <>
        <Grid className={classes.backgroundDashboard}>
              {ProjectInfo != null ?
            <Grid container className={classes.card}>
                <Grid item xs={3} className={classes.defaultposter}>
                    <img src={posterData?.data?.attributes?.project_poster == "" ? defaultproject : posterData?.data?.attributes?.project_poster} alt='placeholder' className={classes.cardImage} />
                </Grid>
                <Grid item xs={9} style={{paddingLeft:"25px"}}>
                    <Paper elevation={2} className={classes.cardHeight}>
                        <Grid container>
                            <Grid item xs={8}>
                                <Typography variant="h4" className={classes.cardTitle} style={{fontWeight:600}}>
                                    {ProjectInfo?.attributes?.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={9} style={{display:"flex", flexDirection:"row", margin:"15px"}}>
                                <Box style={{display:"flex", flexDirection:"row"}}>
                                    <Box style={{marginRight:"15px"}}> 
                                    <img style={{width:"20px"}} src={selectIcon} alt="logo" />
                                    </Box>
                                    <Box>
                                        <Typography variant="body2" className={classes.cardFooterText}>
                                            0 Not Selected
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography style={{margin:"-3px 20px 0px 20px"}}> | </Typography>
                                <Box style={{display:"flex", flexDirection:"row"}}>
                                    <Box style={{marginRight:"15px"}}>
                                    <img style={{width:"20px"}} src={awardIcon} alt="logo" />
                                    </Box>
                                    <Box>
                                        <Typography variant="body2" className={classes.cardFooterText}>
                                            0 Award Winner
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={5}>
                                <Typography variant="body2" className={classes.cardTitle} style={{fontWeight:600}}>
                                    This Project has not yet been submitted to any.
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography variant="body2" className={classes.cardLink}>
                                    Browse Festivals
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.cardDescription}>
                            <Grid item xs={11}>
                                <Typography variant="body2" >
                                   {ProjectInfo?.attributes?.description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            
             :
             <Grid container className={classes.card} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <CircularProgress />
             </Grid> 
            }

            <Grid className={classes.root}>
                <Paper elevation={1} className={classes.tab}>
                    <Box sx={{ width: '100%'}}>
                        <Tabs 
                            value={value}
                            onChange={handleChange}
                            textColor="primary"
                            indicatorColor="primary"
                            aria-label="primary tabs example"
                            centered={true}>
                            <Tab label="View Project" {...a11yProps(0)} style={customizeTabText} />
                            <Tab label="Edit Project" {...a11yProps(1)} style={customizeTabText} />
                            <Tab label="Project File" {...a11yProps(2)} style={customizeTabText} />
                            <Tab label="Privacy" disabled {...a11yProps(3)} style={customizeTabText} />
                            <Tab label="Marketing Option" disabled {...a11yProps(4)} style={customizeTabText} />
                        </Tabs>
                    </Box>
                </Paper>
                <TabPanel value={value} index={0}><ViewProject handleSubmitNews={handleSubmitNews} 
                                                               handleSubmitLinks={handleSubmitLinks} 
                                                               handleFileAttachLinks={handleFileAttachLinks} 
                                                               handleUploadFileChange={handleUploadFileChange} 
                                                               handleSubmitTrailer={handleSubmitTrailer}
                                                               handleUpdatePhotos={handleUpdatePhotos}
                                                               deleteAccountPhotos={deleteAccountPhotos}
                                                               handleScanner={handleScanner}
                                                               handleHeadshotSubmit={handleHeadshotSubmit}
                                                               handlePosterImg={handlePosterImg}
                                                               handlePhotoImg={handlePhotoImg}
                                                               filmographyData={filmographyData}
                                                               ProjectInfo={ProjectInfo}
                                                               posterData={posterData}
                                                               loading={loading}
                                                               headshotData={headshotData}/></TabPanel>
                <TabPanel value={value} index={1}><EditProject  ProjectInfo={ProjectInfo}
                                                                currencyData={currencyData}
                                                                roleData={roleData}
                                                                languageData={languageData}
                                                                budgetData={budgetData}
                                                                handleEditProject={handleEditProject}
                                                               /></TabPanel>
                <TabPanel value={value} index={2}><ProjectFile ProjectInfo={ProjectInfo}
                                                               deleteVideo={deleteVideo}
                                                               restoreVideo={restoreVideo}
                                                               languageData={languageData}
                                                               Videovisible={Videovisible}
                                                               handleStillPhoto={handleStillPhoto}
                                                               handleScennerVid={handleScennerVid}
                                                               handleSubtitleFiles={handleSubtitleFiles}
                                                               VideoFlag={VideoFlag}
                                                               loaders={loaders}
                                                               handleUpdatePhotos={handleUpdatePhotos}
                                                               imageloader={imageloader}
                                                               handleScanner={handleScanner}
                                                               deleteAccountPhotos={deleteAccountPhotos}
                                                               /></TabPanel>
                <TabPanel value={value} index={3}><Privacy /></TabPanel>
                <TabPanel value={value} index={4}><MarketingOption /></TabPanel>
            </Grid> 
        </Grid>
        
        </>
    )
};




export interface accountPhotosType {
    id: number, url:string,
  }
const ViewProject = ({
    handleSubmitNews,
    handleSubmitLinks,
    handleFileAttachLinks,
    handleUploadFileChange,
    handleSubmitTrailer,
    handleUpdatePhotos,
    handleHeadshotSubmit,
    handlePosterImg,
    handlePhotoImg,
    handleScanner,
    filmographyData,
    ProjectInfo,
    headshotData,
    posterData,
    loading,
    imageloader,
    deleteAccountPhotos
}:any)=>{
    const [viewProjectTabsValue, setViewProjectTabsValue] = useState(0);
    const [bio, setBio] = useState('')
    const [open, setOpen] = React.useState(false);
    const [Fileopen, setFileOpen] = React.useState(false);
    const [articleopen, setArticleOpen] = React.useState(false);
    const [secondarticle, setSeconfArticle] = React.useState(false);
    const [linkOpen, setLinkOpen] = React.useState(false);
    const [previewImg, setPreviewImg] = React.useState("");
    const [previewVideo, setPreviewVideo] = React.useState("");
    const [trailerVideo, setPreviewTrailerVideo] = React.useState("");
    const [posterView, setPosterPreview] = React.useState("");
    const [mediapreview, setMediaPreview] = React.useState("");
    const [id, setId] = React.useState(window.location.pathname.trim().split("/")[2]) 
    const [btnDisabled, setBtnDisabled] = React.useState(false) 
    const [isURLValid, setIsURLValid] = React.useState(true);
    const [headApiData, setHeadApiData] = React.useState("")
    // const initialValues = {
    //     newsreview:
    //         {id: Math.random(),title: "", publication:"", link:""}
    // }
    const [newsreview, setNewsReview] = React.useState(
        {id: id ,title: "", publication:"", link:""});
    const [fileattach, setFileAttach] = React.useState(
        {id: id , name: "", file:"" as any});
    const [links, setLinks] = React.useState(
        {id: id, name: "", link:""});
    const [trailer, setTrailer] = React.useState(
        {trailer_file:"" as any, id: id, video_link: ""});
    const [poster, setPoster] = React.useState({media_file:"" as any});
    const [screener, setScreener] = React.useState(
            {video_url: ProjectInfo ? ProjectInfo?.attributes?.online_screener?.data.attributes?.video_url: "", 
            password:ProjectInfo ? ProjectInfo?.attributes?.online_screener?.data.attributes?.password: "", 
            video_file:"" as any, id: id});
    const [selectedFile, setSelectedFile] = React.useState(
            {biography: headshotData ? headshotData?.data?.attributes?.director_bio_detail?.data?.attributes?.biography : "",
            headshot:"" as any, id: id});
    const [mediaimage, setMediaImage] = React.useState({photo:"" as any})
    const [fileNameError, setFileNameError] = React.useState(false)
    const [fileAttachError, setFileAttachError] = React.useState(false)

    const [newsreviewTitleError, setnewsreviewTitleError] = React.useState(false)
    const [newsreviewPubError, setnewsreviewPubError] = React.useState(false)
    const [newsreviewUrlError, setnewsreviewUrlError] = React.useState(false)

    const [linksTitleError, setlinksTitleError] = React.useState(false)
    const [linksUrlError, setlinksUrlError] = React.useState(false)

    // const [trailerLinkError, setTrailerLinkError] = React.useState(false)
    const [trailerMediaError, setTrailerMediaError] = React.useState(false)
    const [selectedFilename, SetSelectedFileName] = React.useState("")
    const [multiselectPhoto, setMultiselectPhoto] = React.useState<Array<accountPhotosType>>();
    const [inputKey, setInputKey] = React.useState(0);
    const [files, setFiles] = React.useState<File[]>([]);

    const youtubeTag = headshotData?.data?.attributes?.project_trailer?.data?.attributes?.video_link.replace("watch?v=", "embed/")
    console.log(youtubeTag)

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleFileOpen = () => setFileOpen(true);
    const handleFileClose = () => setFileOpen(false);

    const handleArticleOpen = () => setArticleOpen(true);
    const handleArticleClose = () => setArticleOpen(false);

    const handleSecondArticleOpen = () => setSeconfArticle(true)
   const handleSecondArticleClose = () => setSeconfArticle(false)

    const handleLinkOpen = () => setLinkOpen(true);
    const handleLinkClose = () => setLinkOpen(false); 

    const isValidURL = (string: any) => {
        var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        return (res !== null)
    };

    React.useEffect(() => {
        setHeadApiData(headshotData)
    },[headApiData])
    
    React.useEffect(() => {
        if(ProjectInfo){
          setMultiselectPhoto(ProjectInfo?.attributes?.online_screener?.data?.attributes?.photography_photos)
        }
      }, [ProjectInfo])

//===========================================NEWS & REVIEWS===================================

    React.useEffect (() => {
        if(!newsreview.title){
            setnewsreviewTitleError(true)
        }else {
            setnewsreviewTitleError(false)
        }
        if(!newsreview.publication){
            setnewsreviewPubError(true)
        } else {
            setnewsreviewPubError(false)
        }
        if(!newsreview.link){
            setnewsreviewUrlError(true)
        } else {
            setnewsreviewUrlError(false)
        }
    }, [newsreview])

    React.useEffect (() => {
        setnewsreviewTitleError(false)
        setnewsreviewPubError(false)
        setnewsreviewUrlError(false)
    }, [])

    const handleArticleChange = (e:any) => { 
    if(e.target.name === "link"){
        setBtnDisabled(!isValidURL(e.target.value))
        setIsURLValid(isValidURL(e.target.value))
    }
    setNewsReview({
      ...newsreview,
      [e.target.name]: e.target.value,
    });
    };
   
   const handleArticleSave = (e:any) => {
    if(!newsreview.title){
        setnewsreviewTitleError(true)
    }
    if(!newsreview.publication){
        setnewsreviewPubError(true)
    }
    if(!newsreview.link){
        setnewsreviewUrlError(true)
    }
    if(newsreview.title && newsreview.publication && newsreview.link) {
        e.preventDefault()
        if(newsreview.link){
            setBtnDisabled(!isValidURL(newsreview.link))
            setIsURLValid(isValidURL(newsreview.link))
        }
        isURLValid && handleSubmitNews(newsreview)
        isURLValid && setArticleOpen(false);
        setSeconfArticle(false);
    }
    
}

//===========================================FILE ATTACHMENT===================================

    React.useEffect (() => {
        if(!fileattach.file){
            setFileAttachError(true)
        }else {
            setFileAttachError(false)
        }
        if(!fileattach.name){
            setFileNameError(true)
        } else {
            setFileNameError(false)
        }
    }, [fileattach])
    React.useEffect (() => {
        setFileAttachError(false)
        setFileNameError(false)
    }, [])

const handleFileChange = async (e:any) => {
    if(e.target.name == "name") {
        setFileAttach({
        ...fileattach,
        [e.target.name]: e.target.value,
        });
    }else{  
        setFileAttach({
            ...fileattach,
           "file" : e.target.files[0]
        });
    }
};


const handleFileUpload = () => {
    if(!fileattach.file){
        setFileAttachError(true)
    }
    if(!fileattach.name){
        setFileNameError(true)
    }
    if(fileattach.file && fileattach.name) {
        handleFileAttachLinks(fileattach)
        setFileOpen(false)
    } 
}

//===========================================LINKS===================================

    React.useEffect (() => {
        if(!links.name){
            setlinksTitleError(true)
        }else {
            setlinksTitleError(false)
        }
        if(!links.link){
            setlinksUrlError(true)
        } else {
            setlinksUrlError(false)
        }
    }, [links])

    React.useEffect (() => {
        setlinksTitleError(false)
        setlinksUrlError(false)
    }, [])
const handleLinkChange = (e:any) => {
    if(e.target.name === "link"){
        setBtnDisabled(!isValidURL(e.target.value))
        setIsURLValid(isValidURL(e.target.value))
    }
    setLinks({
        ...links,
        [e.target.name]: e.target.value,
      });
}

const handleLinkUpload = () => {
    if(!links.name){
        setlinksTitleError(true)
    }
    if(!links.link){
        setlinksUrlError(true)
    }
    if(links.name && links.link) {
        if(newsreview.link){
            setBtnDisabled(!isValidURL(newsreview.link))
            setIsURLValid(isValidURL(newsreview.link))
        }
        isURLValid && handleSubmitLinks(links)
        isURLValid && setLinkOpen(false)
    }
}

//===========================================ATTACHMENT===================================

const handleAttachment = (e:any) =>{
    // if(e.target.name == "video_url"){
    //     setBtnDisabled(!isValidURL(e.target.value))
    //     setIsURLValid(isValidURL(e.target.value))
    // }
    if(e.target.name == "video_url" || e.target.name == "password") {
        setScreener({
        ...screener,
        [e.target.name]: e.target.value,
        });
    }else{  
        setScreener({
            ...screener,
           "video_file" : e.target.files[0]
        });
        setPreviewVideo(URL.createObjectURL(e.target.files[0]) as any)
        SetSelectedFileName(e.target.files[0].name)
    }
}
 const handleScreenerUpload = () => {
    // if(screener.video_url){
    //     setBtnDisabled(!isValidURL(screener.video_url))
    //     setIsURLValid(isValidURL(screener.video_url))
    // }
    handleScanner(screener)
 }
//==========================================TRAILER==========================================

// React.useEffect (() => {
//     if(!trailer.video_link){
//         setTrailerLinkError(true)
//     }else {
//         setTrailerLinkError(false)
//     }
//     if(!trailer.trailer_file){
//         setTrailerMediaError(true)
//     } else {
//         setTrailerMediaError(false)
//     }
// }, [trailer])

// React.useEffect (() => {
//     setTrailerLinkError(false)
//     setTrailerMediaError(false)
// }, [])

const handleTrailerChange = (e:any) => {
    if(e.target.name == "video_link") {
        setTrailer({
        ...trailer,
        [e.target.name]: e.target.value,
        });
    }else{  
        setTrailer({
            ...trailer,
           "trailer_file" : e.target.files[0]
        });
        setPreviewTrailerVideo(URL.createObjectURL(e.target.files[0]) as any)
    }
}
const handletrailerSubmit = (e:any) => {
    //@ts-ignore
    if(trailer.video_link == ""){
        if(typeof trailer.trailer_file.name == 'undefined'){
            toast.error('Please enter any one')    
        }
        else{
            handleSubmitTrailer(trailer)
            setOpen(false)
        }
    }
    else{
        handleSubmitTrailer(trailer)
        setOpen(false)
    }
}

//============================= MULTIPLE PHOTOS ===============================
const changeHandler = ({
    currentTarget: { files },
}: React.ChangeEvent<HTMLInputElement>) => {
    if (files && files.length) {
        setFiles((existing) => existing.concat(Array.from(files)));
    }
    setInputKey((key) => key + 1);
    handleUpdatePhotos(files);
  };

  
  const handleDeleteImage = (id: any) => {
    deleteAccountPhotos(id)
  };

//============================ VIEW PROJECT TABS ===============================

const handleViewProjectTabs = (event: React.ChangeEvent<{}>, newValue: any) => {
    setViewProjectTabsValue(newValue);
};

const handleChangeBio = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setBio(event.target.value)
}                                                                                                                     
const headshotHnadler = (e:any) => {
    if(e.target.name == "biography") {
        setSelectedFile({
        ...selectedFile,
        [e.target.name]: e.target.value,
        });
    }else{  
        setSelectedFile({
            ...selectedFile,
           "headshot" : e.target.files[0],
        });
        setPreviewImg(URL.createObjectURL(e.target.files[0]) as any)
    }
};
const handleHeadshot = () => {
    handleHeadshotSubmit(selectedFile)
}

const changePosterHandler = (e:any) => {
    setPoster({
        ...poster,
        "media_file" : e.target.files[0]
    });
    setPosterPreview(URL.createObjectURL(e.target.files[0]) as any)
};

const changePhotoHandler = (e:any) => {
    setMediaImage({
        ...mediaimage,
        "photo" : e.target.files[0]
    })
    setMediaPreview(URL.createObjectURL(e.target.files[0]) as any)
}

React.useEffect(() => {
    if(mediaimage.photo !== ""){
        setTimeout(() => {
            handlePhotoImg(mediaimage)
          }, 2000);
    }     
}, [mediaimage]) 

React.useEffect(() => {
    if(poster.media_file !== ""){
        setTimeout(() => {
            handlePosterImg(poster)
          }, 2000);
    }     
}, [poster]) 

React.useEffect(() => {
    if(headshotData){
        setSelectedFile( {biography: headshotData != null ? headshotData?.data?.attributes?.director_bio_detail?.data?.attributes.biography : "",
        headshot:headshotData != null ? headshotData?.data?.attributes?.director_bio_detail?.data?.attributes.headshot : "", id: id})
        setScreener( {video_url: ProjectInfo != null ? ProjectInfo?.attributes?.online_screener?.data.attributes?.video_url: "", 
        password:ProjectInfo != null ? ProjectInfo?.attributes?.online_screener?.data.attributes?.password: "", 
        video_file:"" as any, id: id})
    }
},[headshotData])

var projectId: string = window.location.pathname.trim().split("/")[2]
var visiblity:any = []
for(let i  = 0; i < filmographyData?.length; i++ ){
    if(filmographyData[i].visibilty && filmographyData[i].id == projectId){
        visiblity.push(filmographyData[i])
    }
}

const classes = useStyles();
    return (
        <Box sx={{margin:"-24px -24px 24px -24px"}}>
        {headshotData == null ? 
            <Grid className={classes.rootViewProject} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <CircularProgress />
            </Grid> 
        :
        <Grid className={classes.rootViewProject}>
                <Box style={{padding:'16px 16px 16px 20px'}}>
                <Typography variant="h2" style={{fontWeight:500}}>
                    {ProjectInfo?.attributes?.name}
                </Typography>
                </Box>
                <Grid container>
                    <form style={{display:"flex", width:"100%"}}>
                        <Grid item xs={8}>
                                <Box sx={{ width: '100%'}}>
                                    <StyledTabs
                                        value={viewProjectTabsValue}
                                        onChange={handleViewProjectTabs}
                                        variant="fullWidth"
                                        // indicatorColor="secondary"
                                        // textColor={"primary"}
                                        aria-label={"primary tabs example"}
                                        style={{marginLeft:"-37px"}}
                                        centered={true}
                                        // className="My-projecct-overview"
                                    >
                                        <Tab label="Overview" {...a11yProps(0)} style={fontspacification}/>
                                        <Tab label="Credits" {...a11yProps(0)} style={fontspacification}/>
                                        <Tab label="Specification" {...a11yProps(0)} style={fontspacification}/>
                                        <Tab label="Screenings / Awards" {...a11yProps(0)} style={fontspacification}/>
                                        <Tab label="Distribution Information" {...a11yProps(0)} style={fontspacification}/>
                                    </StyledTabs>
                                </Box>
                            <Grid>
                                <TabPanel value={viewProjectTabsValue} index={0}>
                                    <Paper elevation={2} className={classes.cardHeightViewProject}>
                                    {visiblity != null ?
                                    (visiblity && visiblity.slice(0, 1).map((project: any) =>
                                    <Grid container className={classes.cardViewProject}>
                                            {ProjectInfo?.attributes?.project_type == "film/video" ? 
                                            <>
                                            <Grid item xs={3}>
                                                <img src={project.pic == "" ? projectTitleImg : project.pic} alt='placeholder' className={classes.cardImageViewProject} />
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Grid container>
                                                    <Grid item xs={12} style={{padding:'0px 30px 0px 30px'}}>
                                                        <Typography variant="h4" className={classes.cardTitleViewProject} >
                                                            {project.name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container className={classes.cardDescriptionViewProject}>
                                                    <Grid item xs={12}>
                                                        <Typography>Brief Synopsis</Typography>
                                                        <Typography variant="body2" style={{margin:'10px 0px 30px 0px', color:"#000"}}>
                                                            {project.description}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            </>
                                            :
                                            <>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12} style={{padding:'0px 30px 0px 30px'}}>
                                                        <Typography variant="h4" className={classes.cardTitleViewProject} >
                                                            {project.name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container className={classes.cardDescriptionViewProject}>
                                                    <Grid item xs={12}>
                                                    {ProjectInfo?.attributes?.project_type == "photography/design" ?
                                                        <Typography>Brief Description</Typography> :
                                                        ProjectInfo?.attributes?.project_type == "music/song_writing" ? 
                                                        <Typography>Lyrics</Typography> :
                                                        ProjectInfo?.attributes?.project_type == 'vr/xr/immersive_beta' ? 
                                                        <Typography>Brief Synopsis</Typography> : <Typography>Brief Synopsis</Typography> 
                                                        }
                                                        <Typography variant="body2" style={{margin:'10px 0px 30px 0px', color:"#000"}}>
                                                            {project.description}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            </>
                                            }
                                        </Grid>
                                        
                                         ))
                                         : <Typography>Loading ...</Typography>} 
                                    </Paper>
                                    <Paper elevation={3} className={classes.addDirectorCardViewProject}>
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <div className={classes.addDirHeadshot}>
                                                    {previewImg == "" && headshotData?.data?.attributes?.director_bio_detail == null
                                                     ?
                                                     <>
                                                     {ProjectInfo?.attributes?.project_type == "music/song_writing" || ProjectInfo?.attributes?.project_type == "photography/design" ? 
                                                     <Typography style={{textDecoration: 'underline',color:'#4684EB', cursor:"pointer", maxWidth:"100px", textAlign:"center"}}>Add Artist Headshot</Typography> :
                                                     ProjectInfo?.attributes?.project_type == "script" ? 
                                                    <Typography style={{textDecoration: 'underline',color:'#4684EB', cursor:"pointer", maxWidth:"100px", textAlign:"center"}}>Add Writer Headshot</Typography> :
                                                     <Typography style={{textDecoration: 'underline',color:'#4684EB', cursor:"pointer", maxWidth:"100px", textAlign:"center"}}>Add Director Headshot</Typography>
                                                    }
                                                     </>
                                                        : <img src={previewImg == "" ? headshotData?.data?.attributes?.director_bio_detail?.data?.attributes?.headshot : previewImg} style={{width: "100%", height: "100%",cursor: 'pointer', maxHeight:"270px", objectFit:"cover"}}/>
                                                    }
                                                    <input  
                                                        type='file'
                                                        onChange={headshotHnadler} 
                                                        name="headshot" 
                                                        className={classes.headshotInput}
                                                        placeholder="Link or Upload your Video. Only Fastivals you submit to can view your Photo"
                                                        accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Grid container>
                                                    <Grid item xs={12} className={classes.addDirectorTextViewProject}>
                                                        <>
                                                        {ProjectInfo?.attributes?.project_type == "music/song_writing" ? 
                                                        <Typography variant="body1" className={classes.fontBold}>Add Music / Song Writer Biography</Typography> :
                                                        ProjectInfo?.attributes?.project_type == "script" ? 
                                                        <Typography variant="body1" className={classes.fontBold}>Add Script Writer Biography</Typography> :
                                                        ProjectInfo?.attributes?.project_type == "photography/design" ? 
                                                        <Typography variant="body1" className={classes.fontBold}>Photography / Designer Biography</Typography> :
                                                        ProjectInfo?.attributes?.project_type == "photography/design" ? 
                                                        <Typography variant="body1" className={classes.fontBold}>Add XR / VR Immersive Biography </Typography> :
                                                        <Typography variant="body1" className={classes.fontBold}>Add Director Biography</Typography>
                                                        }
                                                        <TextField
                                                            className={classes.textFieldBio}
                                                            placeholder='Enter the Bio'
                                                            name="biography"
                                                            value={selectedFile.biography || ""}
                                                            onChange={headshotHnadler}
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.textFieldBioInputProps,
                                                                },
                                                            }}
                                                            fullWidth
                                                        />
                                                        </> 
                                                    </Grid>
                                                </Grid>
                                                <Grid container className={classes.cardDescriptionViewProject}>
                                                    <Grid item xs={11}>
                                                    </Grid>
                                                </Grid>
                                                <Button variant="contained" style={{ background: "#A5259F", color: "#fff", width:"25%", float:"right", marginRight:30, marginBottom:30}} onClick={handleHeadshot}>SAVE</Button>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                    <Grid container className={classes.addTrailerPhotoSection} >
                                        {(ProjectInfo?.attributes?.project_type == "script" || ProjectInfo?.attributes?.project_type=="photography/design" || ProjectInfo?.attributes?.project_type == "music/song_writing") ? "" 
                                        :
                                        <Grid item style={{width:"33.33%", paddingRight:"5px"}}>
                                            <Paper elevation={2} className={classes.addTrailerSection} style={{height:"195px", overflow:"hidden"}}>
                                                <Grid>
                                                        {console.log("vales---=-=-=-=->", headshotData?.data?.attributes?.project_trailer?.data?.attributes?.video_link)}
                                                    <div style={{width: "100%", height: "100%", position: 'relative', textAlign:"center"}}>
                                                    {trailerVideo == "" && headshotData?.data?.attributes?.project_trailer == null
                                                     ?
                                                     <>
                                                     <img style={{width:"50px"}} src={imageIcon} alt="logo"/>
                                                     <Typography style={{textDecoration: 'underline',color:'#4684EB', cursor:"pointer", marginTop:"10px"}} onClick={handleOpen}>Add a Trailer</Typography>
                                                     </>
                                                        : headshotData?.data?.attributes?.project_trailer?.data?.attributes?.video_link ? 
                                                        <iframe src={youtubeTag} 
                                                                //@ts-ignore    
                                                                allowFullScreen="allowFullScreen"
                                                                /> 
                                                        :
                                                        <video 
                                                            controls 
                                                            src={trailerVideo == "" ? headshotData?.data?.attributes?.project_trailer?.data?.attributes?.trailer_file : trailerVideo} style={{width: "100%", height: "200px",cursor: 'pointer'}} onClick={handleOpen}/>
                                                    }
                                                    </div>
                                                </Grid>    
                                            </Paper>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box sx={style}>
                                                    <Paper elevation={2} className={classes.notUploadedVideoSectionPaper}>
                                                    
                                                        <Typography variant="body2" className={classes.notUploadedVideoText}>
                                                            Link
                                                        </Typography>
                                                        <Typography variant="inherit">
                                                            If your trailer is on Youtube or Vimeo, simply paste the URL below.
                                                        </Typography>
                                                            <TextField
                                                                fullWidth
                                                                name="video_link"
                                                                placeholder='Enter the URL'
                                                                value={trailer.video_link || ""}
                                                                onChange={handleTrailerChange}
                                                                InputProps={{
                                                                    classes: {
                                                                        input: classes.textFiled,
                                                                    },
                                                                }}
                                                            />
                                                        {/* {trailerLinkError && <div style={{color: "red", fontSize:"12px"}}>Required</div>} */}
                                                        <div style={{textAlign:'center', margin:'15px 0px'}}>OR</div>
                                                        <Typography variant="body2" className={classes.notUploadedVideoTrailer}>
                                                            Upload
                                                        </Typography>
                                                        <Typography variant="inherit">
                                                            Upload your trailer, up to 1 GB.
                                                        </Typography>
                                                        <Box>
                                                            <input
                                                                type="file"
                                                                name="trailer_file"
                                                                // value={trailer.trailer_file || ""}
                                                                onChange={handleTrailerChange}
                                                                className={classes.notUploadedVideoTextURL}
                                                                accept="video/mp4,video/x-m4v,video/*"
                                                            />
                                                            {trailerMediaError && <div style={{color: "red", fontSize:"12px"}}>Required</div>}
                                                        </Box>
                                                    </Paper>
                                                    <div style={{textAlign:"center"}}>
                                                    <Button variant="contained" style={{ background: "#fff", color: "#A5259F", marginTop: "20px", width:"100%", maxWidth: "300px"}} onClick={handleClose}>cancel</Button>
                                                    <Button variant="contained" style={{ background: "#A5259F", color: "#fff", marginTop: "20px",marginBottom:"20px", width:"100%", maxWidth: "300px"}} onClick={handletrailerSubmit}>Upload</Button>
                                                    </div>
                                                </Box>
                                            </Modal>
                                        </Grid>
                                        }
                                        <Grid item style={{width:"33.33%", padding:"0px 5px"}}>
                                            <Paper elevation={2} className={classes.addTrailerSection} style={{height:"195px", overflow:"hidden"}}>
                                                <Grid>
                                                <div style={{width: "100%", height: "100%", position: 'relative', textAlign:"center"}}>
                                                    {posterView == "" && posterData?.data?.attributes?.project_poster == ""
                                                     ?
                                                     <>
                                                     <img style={{width:"50px"}} src={imageIcon} alt="logo"/>
                                                     {ProjectInfo?.attributes?.project_type == "music/song_writing" ? 
                                                     <Typography style={{textDecoration: 'underline',color:'#4684EB', cursor:"pointer", marginTop:"10px"}}>Add Album Art</Typography> : 
                                                     <Typography style={{textDecoration: 'underline',color:'#4684EB', cursor:"pointer", marginTop:"10px"}}>Add a poster</Typography>
                                                    }
                                                     </>
                                                        : <img src={posterView == "" ? posterData?.data?.attributes?.project_poster : posterView} style={{width: "100%", height: "200px",cursor: 'pointer'}}/>
                                                    }
                                                    <input  
                                                        type='file'
                                                        onChange={changePosterHandler} 
                                                        name="media_file" 
                                                        accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
                                                        style={{opacity: 0,width: "100%", height: "100%",cursor: 'pointer', position: "absolute", zIndex: 1, top: 0, left: 0}}
                                                    />
                                                </div>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                        
                                        {ProjectInfo.attributes.project_type == "script" || ProjectInfo?.attributes?.project_type=="music/song_writing" || ProjectInfo?.attributes?.project_type=="photography/design" ? "" 
                                        :
                                        <Grid item style={{width:"33.33%", paddingLeft:"5px"}}>
                                            <Paper elevation={2} className={classes.addTrailerSection} style={{height:"195px", overflow:"hidden"}}>
                                                <Grid>
                                                    <>
                                                <div style={{width: "100%", height: "100%", position: 'relative', textAlign:"center"}}>
                                                    {mediapreview == "" && posterData?.data?.attributes?.photo == ""
                                                     ?
                                                     <>
                                                     <img style={{width:"50px"}} src={imageIcon} alt="logo"/> 
                                                     <Typography style={{textDecoration: 'underline',color:'#4684EB', cursor:"pointer", marginTop:"10px"}}>Add a Photo</Typography>
                                                     </>
                                                        : <img src={mediapreview == "" ? posterData?.data?.attributes?.photo : mediapreview} style={{width: "100%", height: "200px",cursor: 'pointer'}}/>
                                                    }
                                                     
                                                    <input  
                                                        type='file'
                                                        onChange={changePhotoHandler} 
                                                        name="photo" 
                                                        style={{opacity: 0,width: "100%", height: "100%",cursor: 'pointer', position: "absolute", top: 0, left: 0}}
                                                        placeholder="Link or Upload your Video. Only Fastivals you submit to can view your Photo"
                                                        accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
                                                    />
                                                </div>
                                                </>
                                                </Grid>
                                               
                                            </Paper>
                                        </Grid>
                                        }
                                    </Grid>
                                    <Grid container className={classes.newsLinkMainSection}>
                                        <Grid item xs={4} className={classes.padding10px}>
                                            <Typography variant={'body2'} className={classes.fontBold}>News & Reviews</Typography>
                                            {ProjectInfo && ProjectInfo.attributes && ProjectInfo.attributes.news_and_reviews == "" ?
                                            <>
                                              <Paper elevation={2} className={classes.newsLinkSection} style={{cursor: 'pointer'}}>
                                                <Typography style={{color:"#4684EB"}} variant={'body2'} onClick={handleArticleOpen}>+Add Article</Typography>
                                                <Modal
                                                    open={articleopen}
                                                    onClose={handleArticleClose}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                >
                                                    <Box sx={style}>
                                                    <Paper
                                                        elevation={2} className={classes.notUploadedVideoSectionPaper}>
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <Typography variant="body2" className={classes.notUploadedVideoText}>
                                                                Add Article / Review
                                                            </Typography>
                                                            <div
                                                                onClick={handleArticleClose}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    fontWeight: 600,
                                                                    fontSize: "14px",
                                                                }}
                                                            > X </div>
                                                        </div>
                                                        <Typography
                                                            variant="body2"
                                                            className={classes.notUploadedVideoTextURL}
                                                        >
                                                        Title
                                                        </Typography>
                                                        <TextField
                                                            fullWidth
                                                            name="title"
                                                            placeholder="Example: New director from.."
                                                            value={newsreview.title || ""}
                                                            onChange={handleArticleChange}
                                                            InputProps={{
                                                                classes: {
                                                                input: classes.textFiled,
                                                                },
                                                            }}
                                                        />
                                                        {newsreviewTitleError && <div style={{color: "red", fontSize:"12px"}}>Required</div>}
                                                        <Typography
                                                            variant="body2"
                                                            className={classes.notUploadedVideoTextURL}
                                                        >
                                                        Publication
                                                        </Typography>
                                                        <TextField
                                                            fullWidth
                                                            name="publication"
                                                            placeholder="Example: daily varient"
                                                            value={newsreview.publication || ""}
                                                            onChange={handleArticleChange}
                                                            InputProps={{
                                                                classes: {
                                                                input: classes.textFiled,
                                                                },
                                                            }}
                                                        />
                                                        {newsreviewPubError && <div style={{color: "red", fontSize:"12px"}}>Required</div>}
                                                        <Typography
                                                            variant="body2"
                                                            className={classes.notUploadedVideoTextURL}
                                                        >
                                                        Link
                                                        </Typography>
                                                        <TextField
                                                            fullWidth
                                                            name="link"
                                                            placeholder="Example: www.freeforyou.com"
                                                            onChange={handleArticleChange}
                                                            value={newsreview.link || ""}
                                                            InputProps={{
                                                                classes: {
                                                                input: classes.textFiled,
                                                                },
                                                            }}
                                                        />
                                                        {newsreviewUrlError ? <div style={{color: "red", fontSize:"12px"}}>Required</div> : !isURLValid ?
                                                        <div style={{color: "red", fontSize:"12px" }}>Please enter valid URL</div> : " " }
                                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                                        <Button
                                                            variant="contained"
                                                            style={{
                                                            background: "#fff",
                                                            color: "#A5259F",
                                                            margin: "20px 10px 0 10px",
                                                            }}
                                                            onClick={handleArticleClose}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            style={{
                                                            background: "#A5259F",
                                                            color: "#fff",
                                                            margin: "20px 10px 0 10px",
                                                            }}
                                                            disabled={btnDisabled}
                                                            onClick={handleArticleSave}
                                                        >
                                                            Save
                                                        </Button>
                                                        </div>
                                                    </Paper>
                                                    </Box>
                                                </Modal>   
                                            </Paper>
                                            </> :
                                                <div style={{ paddingLeft: "5px", cursor: "pointer",background: "#F9F9F9"}}>
                                                    <a href={ProjectInfo?.attributes?.news_and_reviews[0]?.link}> {ProjectInfo?.attributes?.news_and_reviews[0]?.title}</a>
                                                    <div>{ProjectInfo?.attributes?.news_and_reviews[0]?.publication}</div>
                                                </div>
                                            }
                                        </Grid>                  
                                        <Grid item xs={4} className={classes.padding10px}>
                                            <Typography variant={'body2'} className={classes.fontBold}>Files & Attachments</Typography>
                                            {ProjectInfo?.attributes?.project_files.data.length == 0 ?
                                            <>
                                                <Paper elevation={2} className={classes.newsLinkSection} style={{cursor: 'pointer'}}>
                                                    <Typography style={{color:"#4684EB", cursor:"pointer"}} variant={'body2'} onClick={handleFileOpen}>+Add File/s</Typography>
                                                    <Modal
                                                        open={Fileopen}
                                                        onClose={handleFileClose}
                                                        aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"
                                                    >
                                                        <Box sx={style}>
                                                        <Paper elevation={2} className={classes.notUploadedVideoSectionPaper}>
                                                            <div style={{ display: "flex", justifyContent: "space-between" }} >
                                                            <Typography variant="body2" className={classes.notUploadedVideoText}>
                                                                Add File
                                                            </Typography>
                                                            <div
                                                                onClick={handleFileClose}
                                                                style={{
                                                                cursor: "pointer",
                                                                fontWeight: 600,
                                                                fontSize: "14px",
                                                                }}
                                                            >
                                                                X
                                                            </div>
                                                            </div>
                                                            <Typography variant="body2">
                                                                Here you can add downloadable files such as a PDF press kit or
                                                                student ID.
                                                            </Typography>
                                                            <Typography variant="body2" className={classes.notUploadedVideoTextURL}>
                                                            Name
                                                            </Typography>
                                                            <TextField
                                                                fullWidth
                                                                name="name"
                                                                placeholder="Example: Student press kit"
                                                                onChange={handleFileChange}
                                                                value={fileattach.name || ""}
                                                                InputProps={{
                                                                    classes: {
                                                                    input: classes.textFiled,
                                                                    },
                                                                }}
                                                            />
                                                            {fileNameError && <div style={{color: "red", fontSize: "12px"}}>Required</div>}
                                                            <Typography variant="body2" className={classes.notUploadedVideoTextPassword}>
                                                            Upload
                                                            </Typography>
                                                            <input
                                                                type="file"
                                                                placeholder="choose file"
                                                                name="file"
                                                                onChange={handleFileChange}
                                                                // value={fileattach.attachment || ""}
                                                                accept="application/pdf"
                                                                className={classes.notUploadedVideoTextURL}
                                                            />
                                                            {fileAttachError && <div style={{color: "red", fontSize: "12px"}}>Required</div>}
                                                            <div style={{ display: "flex", justifyContent: "end" }}>
                                                            <Button
                                                                variant="contained"
                                                                style={{
                                                                background: "#fff",
                                                                color: "#A5259F",
                                                                margin: "20px 10px 0 10px",
                                                                }}
                                                                onClick={handleFileClose}
                                                            >
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                style={{
                                                                background: "#A5259F",
                                                                color: "#fff",
                                                                margin: "20px 10px 0 10px",
                                                                }}
                                                                onClick={handleFileUpload}
                                                                // disabled={btnDisabled}
                                                            >
                                                                Upload
                                                            </Button>
                                                            </div>
                                                        </Paper>
                                                        </Box>
                                                    </Modal>
                                                </Paper>
                                            </>
                                            :
                                            <div style={{paddingLeft: "5px", cursor: "pointer", background: "#F9F9F9"}}>
                                                <div>{ProjectInfo?.attributes?.project_files.data[0].attributes.name}</div>
                                                    <a href={ProjectInfo?.attributes?.project_files.data[0].attributes.project_file ? ProjectInfo?.attributes?.project_files.data[0].attributes.project_file : "#"} download target='_blank'>Download</a>
                                                </div>
                                            }
                                        </Grid>
                                        <Grid item xs={4} className={classes.padding10px}>
                                            <Typography variant={'body2'} className={classes.fontBold}>Links</Typography>
                                            {ProjectInfo?.attributes?.project_links == "" ? 
                                            <>
                                            <Paper elevation={2} className={classes.newsLinkSection} style={{cursor: 'pointer'}}>
                                                <Typography style={{color:"#4684EB", cursor:"pointer"}} variant={'body2'} onClick={handleLinkOpen}>+Add Link/s</Typography>
                                                <Modal
                                                    open={linkOpen}
                                                    onClose={handleLinkClose}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                >
                                                    <Box sx={style}>
                                                    <Paper elevation={2} className={classes.notUploadedVideoSectionPaper}>
                                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                            <Typography variant="body2" className={classes.notUploadedVideoText}>
                                                                Add Link/s
                                                            </Typography>
                                                            <div
                                                                onClick={handleLinkClose}
                                                                style={{
                                                                cursor: "pointer",
                                                                fontWeight: 600,
                                                                fontSize: "14px",
                                                                }}
                                                            >
                                                                X
                                                            </div>
                                                        </div>
                                                        <Typography variant="body2" className={classes.notUploadedVideoTextURL} >
                                                            Name
                                                        </Typography>
                                                        <TextField
                                                            fullWidth
                                                            name="name"
                                                            placeholder="Example: Student press kit"
                                                            onChange={handleLinkChange}
                                                            value={links.name || ""}
                                                            InputProps={{
                                                                classes: {
                                                                input: classes.textFiled,
                                                                },
                                                            }}
                                                        />
                                                        {linksTitleError && <div style={{color: "red", fontSize:"12px"}}>Required</div>}
                                                        <Typography variant="body2" className={classes.notUploadedVideoTextPassword}>
                                                            Link
                                                        </Typography>
                                                        <TextField
                                                        fullWidth
                                                        name="link"
                                                        placeholder="Example: www.freeforyou.com"
                                                        onChange={handleLinkChange}
                                                        value={links.link || ""}
                                                        InputProps={{
                                                            classes: {
                                                            input: classes.textFiled,
                                                            },
                                                        }}
                                                        />
                                                        {linksUrlError ? <div style={{color: "red", fontSize:"12px"}}>Required</div> : !isURLValid ?
                                                        <div style={{color: "red", fontSize:"12px" }}>Please enter valid URL</div> : " " }
                                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                                            <Button
                                                                variant="contained"
                                                                style={{
                                                                background: "#fff",
                                                                color: "#A5259F",
                                                                margin: "20px 10px 0 10px",
                                                                }}
                                                                onClick={handleLinkClose}
                                                            >
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                style={{
                                                                background: "#A5259F",
                                                                color: "#fff",
                                                                margin: "20px 10px 0 10px",
                                                                }}
                                                                disabled={btnDisabled}
                                                                onClick={handleLinkUpload}
                                                            >
                                                                Save
                                                            </Button>
                                                        </div>
                                                        </Paper>
                                                        </Box>
                                                    </Modal>
                                            </Paper>
                                            </>
                                            :
                                            <div style={{paddingLeft: "5px", cursor: "pointer", background: "#F9F9F9"}}>
                                                <a href={ProjectInfo?.attributes?.project_links[0]?.link}>{ProjectInfo?.attributes?.project_links[0]?.link}</a>
                                            </div>
                                            }
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={viewProjectTabsValue} index={1}>
                                    <Credits
                                    //@ts-ignore 
                                    ProjectInfo = {ProjectInfo} />
                                </TabPanel>
                                 <TabPanel value={viewProjectTabsValue} index={2}>
                                    <Specification ProjectInfo={ProjectInfo}/>
                                </TabPanel>
                                <TabPanel value={viewProjectTabsValue} index={3}>
                                    <ScreeningandAwards
                                    //@ts-ignore
                                     ProjectInfo={ProjectInfo} />
                                </TabPanel>
                                <TabPanel value={viewProjectTabsValue} index={4}>
                                    <Distribution
                                    //@ts-ignore 
                                    ProjectInfo={ProjectInfo} />
                                </TabPanel>
                            </Grid>

                        </Grid>
                       
                        <Grid item xs={4} style={{textAlign:"center", justifyContent:"center"}}>
                        <Box>
                            {ProjectInfo.attributes.project_type ==  "music/song_writing" ? 
                                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                                    Add Lyrics or Audio files
                                </Typography> :
                                ProjectInfo.attributes.project_type ==  "script" ? 
                                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                                    Add Script File
                                </Typography> :
                                 ProjectInfo.attributes.project_type ==  "photography/design" ? 
                                 <Typography variant="h6" style={{ fontWeight: "bold" }}>
                                     Add Your Photography
                                 </Typography> :
                                 ProjectInfo.attributes.project_type ==  "vr/xr/immersive_beta" ? 
                                 <Typography variant="h6" style={{ fontWeight: "bold" }}>
                                     Add Your vr/xr/immersive_beta Video
                                 </Typography> :
                                 ProjectInfo.attributes.project_type ==  "documentary" ? 
                                 <Typography variant="h6" style={{ fontWeight: "bold" }}>
                                    Add Your Documentry Video
                                 </Typography>
                                :
                                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                                    Add an Online Screener
                                </Typography>
                            }
                            <Paper elevation={2} style={{ marginTop: "42px" }}>
                                {ProjectInfo.attributes.project_type ==  "music/song_writing"? 
                                <Grid className={classes.addOnlineScanner}>
                                    Upload your audio file. Only events you submit to can access your file.
                                </Grid> :
                                ProjectInfo.attributes.project_type ==  "script" ? 
                                <Grid className={classes.addOnlineScanner}>
                                    Upload your file for this Project.
                                </Grid> :
                                ProjectInfo.attributes.project_type ==  "photography/design" ? 
                                <Grid className={classes.addOnlineScanner}>
                                    Upload your Photography for this project.
                                </Grid> :
                                <Grid className={classes.addOnlineScanner} style={{textAlign:"initial", fontWeight:500}}>
                                    Link or Upload your Video. Only Fastivals you submit to can view your video
                                </Grid>
                                }      
                            <Grid>
                                <Typography variant="body2" className={classes.addOnlineScannerUploadText} >
                                    {loading == true ? 
                                    <div style={{display:"flex", justifyContent:"center", alignItems:"center", width: "100%", height: 200, border: '2px dashed #c3c3c3'}}>
                                        <CircularProgress />
                                    </div>
                                    :
                                    <>
                                    {(ProjectInfo?.attributes?.project_type ==  "music/song_writing" || ProjectInfo?.attributes?.project_type == "script") ?
                                    <>
                                    <input  
                                    type='file'
                                    onChange={handleAttachment}
                                    name="video_file" 
                                    //  className={previewVideo == "" ? classes.zindexUp : classes.zindexDown}
                                    style={{width: "100%",cursor: 'pointer', position: "absolute", top: 0, left: 0, display:"none"}}
                                    id="contained-button-file"
                                    placeholder="Upload Files"
                                    accept="application/pdf,application/msword,.mp3,audio/"
                                    />
                                    <label htmlFor="contained-button-file">
                                        <Button variant="contained" style={{width:"100px", backgroundColor:'#f1f1f1', color:"#000"}} component="span">
                                            Upload
                                        </Button>
                                        <div className={classes.uploadTextFile}>{selectedFilename == "" ? ProjectInfo?.attributes?.online_screener?.data.attributes?.filename : selectedFilename} |
                                            <a href={ProjectInfo?.attributes?.online_screener?.data.attributes.video_file ? ProjectInfo?.attributes?.online_screener?.data.attributes.video_file : "#"} download target='_blank'>Download</a>
                                         </div>
                                    </label>
                                    </>
                                    :
                                    (ProjectInfo?.attributes?.project_type ==  "photography/design") ?
                                    <>
                                    <Box sx={webStyles.formCard}>
                                        <Box sx={{bgcolor: "#320b4c", padding: "10px", display: "flex", justifyContent: "space-between"}}>
                                                {multiselectPhoto ? (
                                                <Typography
                                                variant="h4"
                                                style={{
                                                    color: "#FFF",
                                                    cursor: "pointer",
                                                    paddingRight: "20px",
                                                    position: "relative",
                                                }}
                                                >
                                                Add Photos
                                                <input
                                                    key={inputKey}
                                                    type="file"
                                                    onChange={changeHandler}
                                                    name="account_photo"
                                                    style={webStyles.addPhotos}
                                                    multiple
                                                    accept="image/*"
                                                />
                                                </Typography>
                                            ) : (
                                                ""
                                            )}
                                        </Box>
                                            {multiselectPhoto && multiselectPhoto.length > 0 && (
                                            <Box sx={{ padding: "20px" }}>
                                            <Box
                                                style={{
                                                position: "relative",
                                                height: `${Math.ceil(multiselectPhoto.length / 2) * 205}px`,
                                                border: "2px solid #ccc",
                                                borderRadius: "10px",
                                                }}
                                            >
                                                {imageloader && <Box sx={webStyles.dragZonOverlay}>
                                                    <Box sx={webStyles.dragZonLoader}>
                                                        <CircularProgress />
                                                    </Box>
                                                </Box> }
                                                <div
                                                style={{
                                                    width: "100%",
                                                    height: `${Math.ceil(multiselectPhoto.length / 2) * 205}px`,
                                                    position: "absolute",
                                                    top: "0",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    justifyContent: "center",
                                                }}
                                                >
                                                {multiselectPhoto.map((file, index) => {
                                                    let width = multiselectPhoto.length <= 2
                                                    ? `${100 / multiselectPhoto.length}%`
                                                    : "50%";
                                                    return (
                                                    <>
                                                        <div
                                                        key={index}
                                                        style={{
                                                            flex: "0 0 auto",
                                                            width: width,
                                                            position: "relative",
                                                        }}
                                                        >
                                                        <span
                                                            style={webStyles.closeButton}
                                                            onClick={() => handleDeleteImage(file.id)}
                                                        >
                                                            X
                                                        </span>
                                                        <img
                                                            src={file.url}
                                                            style={webStyles.imageContainer}
                                                        />
                                                        </div>
                                                    </>
                                                    );
                                                })}
                                                </div>
                                            </Box>
                                            </Box>
                                            ) }
                                        {!multiselectPhoto && <Box style={{ background: "white" }}>
                                                <Box sx={webStyles.dragzon}>
                                                {imageloader && <Box sx={webStyles.dragZonOverlay}>
                                                    <Box sx={webStyles.dragZonLoader}>
                                                        <CircularProgress />
                                                    </Box>
                                                </Box> }
                                                    <input
                                                    key={inputKey}
                                                    type="file"
                                                    onChange={changeHandler}
                                                    name="account_photo"
                                                    multiple
                                                    accept="image/*"
                                                    style={{
                                                        opacity: 0,
                                                        width: "100%",
                                                        height: "200px",
                                                        cursor: "pointer",
                                                        position: "absolute",
                                                        zIndex: 1,
                                                        top: 0,
                                                        left: 0,
                                                    }}
                                                    />
                                                    <Box sx={webStyles.dragzonCard}>
                                                        <img src={imageIcon} width={40} style={{marginBottom: "10px"}} />
                                                        <Typography variant="body2" style={webStyles.dragNDrop} >Drag & Drop image/s to upload</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        }
                                    </Box>
                                    </>
                                    :
                                    <div style={{width: "100%", height: 200, border: '2px dashed #c3c3c3', position: 'relative'}}> 
                                    {(previewVideo == "" && ProjectInfo?.attributes?.online_screener == null)
                                        ?
                                        <>
                                        <Typography className={classes.addPhotos}>Upload a high-quality video file, up to 10 GB.</Typography>
                                        <input  
                                        type='file'
                                        onChange={handleAttachment}
                                        name="video_file" 
                                        className={previewVideo == "" ? classes.zindexUp : classes.zindexDown}
                                        style={{opacity: 0,width: "100%", height: 200,cursor: 'pointer', position: "absolute", top: 0, left: 0}}
                                        placeholder="Link or Upload your Video. Only Fastivals you submit to can view your Photo"
                                        accept="video/mp4,video/x-m4v,video/*"
                                        />
                                        </>
                                        : 
                                        <>
                                        <video controls src={previewVideo == "" ? ProjectInfo?.attributes?.online_screener?.data.attributes?.video_file : previewVideo} style={{width: "100%", height: "200px",cursor: 'pointer'}}/>
                                        <input  
                                        type='file'
                                        onChange={handleAttachment}
                                        name="video_file" 
                                        className={previewVideo == "" ? classes.zindexUp : classes.zindexDown}
                                        style={{opacity: 0,width: "100%", height: 200,cursor: 'pointer', position: "absolute", top: 0, left: 0}}
                                        placeholder="Link or Upload your Video. Only Fastivals you submit to can view your Photo"
                                        accept="video/mp4,video/x-m4v,video/*"
                                        />
                                        </>
                                    }
                                    </div>
                                    }
                                    </>
                                    }
                                </Typography>
                            </Grid>
                            <Grid className={classes.notUploadeVideoSection}>
                                {ProjectInfo.attributes.project_type ==  "music/song_writing" || ProjectInfo.attributes.project_type ==  "script" || ProjectInfo.attributes.project_type == "photography/design" ? ""
                                :
                                <Paper elevation={2} className={classes.notUploadedVideoSectionPaper}>
                                    <Typography variant="body2" className={classes.notUploadedVideoText}>
                                        Link
                                    </Typography>
                                    <Grid style={{textAlign:"initial", fontWeight:500}}>
                                        If your video is on Vimeo or youtube, simple paste the URL and Password below
                                    </Grid>
                                    <Typography variant="body2" className={classes.notUploadedVideoTextURL}>
                                        URL
                                    </Typography>   
                                        <TextField
                                            fullWidth
                                            // className={classes.inputClass}
                                            placeholder='Enter the URL'
                                            name="video_url"
                                            value={screener.video_url || ""}
                                            onChange={handleAttachment}
                                            InputProps={{  
                                                classes: {
                                                    input: classes.textFiled,
                                                },
                                            }}
                                        />
                                    <Typography variant="body2" className={classes.notUploadedVideoTextPassword}>
                                        Password
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        type="password"
                                        className={classes.inputClass}
                                        placeholder='Password (Optional)'
                                        name="password"
                                        value={screener.password || ""}
                                        onChange={handleAttachment}
                                        InputProps={{
                                            classes: {
                                                input: classes.textFiled,
                                            },
                                        }}
                                    />
                                </Paper>
                                    }
                            </Grid>
                            {ProjectInfo?.attributes?.project_type ==  "photography/design" ? "" :
                                <Grid style={{ marginTop: '30px', marginLeft: '15%', paddingBottom: '80px', width: '70%' }}>
                                    <Button variant="contained" color="primary" fullWidth disabled={previewVideo != "" ? false : true} onClick={handleScreenerUpload}>Submit</Button>
                                </Grid>
                            }
                        </Paper>    
                        </Box>
                        </Grid>
                        {/* <div>
                            <iframe
                                src="https://www.youtube.com/embed/E7wJTI-1dvQ"
                                // frameborder="0"
                                allow="autoplay; encrypted-media"
                                //@ts-ignore    
                                allowfullscreen
                                title="video"
                            />{" "}
                        </div> */}
                    </form>
                </Grid>
            </Grid>
        }
        </Box>
    )
}

const EditProject = (props:any) => {
    // props.ProjectInfo
    let demodata = props.ProjectInfo
    let budgetData = props.budgetData
    let roleData= props.roleData
    let currencyData = props.currencyData
    let languageData = props.languageData
    return (
        <>
            <EditForm demodata={demodata} budgetData={budgetData} roleData={roleData} currencyData={currencyData} languageData={languageData} handleEditProject={props.handleEditProject} />
        </>
    )
}

const ProjectFile = (props:any) => {
    let ProjectInfo = props.ProjectInfo
    // var delvideo = props.deleteVideo
    let languageData = props.languageData
    let VideoFlag = props.VideoFlag
    let loaders = props.loaders
    let imageloader = props.imageloader
    return (
        <>
            <ProjectFiles ProjectInfo={ProjectInfo} 
                        VideoFlag={VideoFlag} 
                        Videovisible={props.Videovisible}
                        deleteVideo={props.deleteVideo} 
                        restoreVideo={props.restoreVideo} 
                        languageData = {languageData} 
                        handleStillPhoto={props.handleStillPhoto} 
                        handleScennerVid={props.handleScennerVid} 
                        handleSubtitleFiles={props.handleSubtitleFiles} 
                        loaders={loaders} 
                        imageloader = {imageloader}
                        handleScanner={props.handleScanner}
                        deleteAccountPhotos={props.deleteAccountPhotos}
                        handleUpdatePhotos={props.handleUpdatePhotos}/>        
        </>
    )
}

const Privacy = () => {
    return (
        <>
            <PrivacyData />
        </>
    )
}

const MarketingOption = () => {
    return (
        <>
            Hello this is Marketing Option
        </>
    )
}

export {
    Dashboard,
    ViewProject
}
// export default Dashboard;

const webStyles = {
    main: {
        display: "flex",
        justifyContent: "center",
        height: "100px",
        background: "000"
    },
    bottomRight: {
        display: "flex",
        flexDirection: "column",
        width: '75%'
    },
    formCard: {
        background: "#fff",
        marginBottom: "20px"
    },
    dragzon: {
        height: "200px",
        border: "2px solid #ccc",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "30px 50px",
        position: "relative" as const,
    },
    dragZonOverlay: {
        position: "absolute",
        width: "100%",
        height: "100%",
        background:" rgba(0,0,0,0.2)",
        zIndex: 3
    },
    dragZonLoader: {
        top: "50%",
        left:" 50%",
        transform: "translate(-50%, -50%)",
        position: "absolute"
    },
    dragzonCard: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    filmCard: {
        width: "50%",
        display: "flex",
        padding: "10px",
        marginBottom: "20px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        boxShadow: "2px 2px 6px 5px #eee",
        justifyContent: "space-between",
        gap: "15px"
    },
    dragNDrop: {
        color: "#4684EB", textDecoration: "underline", fontWeight: 600
    },
    addPhotos: {
      position: "absolute" as const,
      opacity: 0,
      top: "-9px",
      left: "0",
      height: "53px",
      width: "120px",
      cursor: "pointer",
  },
  imageContainer: {
      width: "-webkit-fill-available",
      objectFit: "cover" as const,
      height: "200px",
    //   position: "absolute" as const,
      border: "3px solid white",
      borderRadius: "10px",
  },
  closeButton: {
      position: "absolute" as const,
      top: "5px",
      right: "5px",
      background: "#fff",
      borderRadius: "50%",
      padding: "0 6px",
      zIndex: 2 as const,
      cursor: "pointer",
  },
  dragzonImage: {
      position: "relative" as const,
      height: "200px",
      border: "2px solid #ccc",
      borderRadius: "10px",
  },
}