import * as React from 'react';

import {
  Box,
  Button,
  TextField,
  Typography
} from "@material-ui/core";
import { Gold } from "./assets"

const AccountCredit = () => {

  return (
        <Box sx={webStyles.bottomRight}>
            <Box sx={webStyles.formCard}>
                <Box sx={{bgcolor: "#320b4c", padding: "10px"}}>
                    <Typography variant="h4" style={{ color: "#FFF"}} >Account Credits</Typography>
                </Box>
                <Box sx={{padding: "50px 20px", display: "flex", justifyContent: "space-evenly"}}>
                    <img src={Gold} height={120} width={170} />
                    <Box sx={{ width: 500, maxWidth: '100%', color: "#eee", display: "flex", flexDirection: "column", justifyContent: "center", gridGap: "10px" }} >
                        <Typography variant="subtitle1" style={{ color: "#000"}} >You do not have any</Typography>
                        <Typography variant="h2" style={{fontWeight: 800, color: "#A5259F"}} >Account Details</Typography> 
                    </Box>
                </Box> 
            </Box>
            <Box >
                <Button variant="contained" style={{ background: "#A5259F", color: "#fff", marginTop: "10px"}}>Merge Account</Button>
            </Box>
        </Box>
  );
};
export default AccountCredit;


const webStyles = {
    main: {
        display: "flex",
        justifyContent: "center",
        height: "100px",
        background: "000"
    },
    bottomRight: {
        display: "flex",
        flexDirection: "column",
        width: '75%'
    },
    formCard: {
        background: "#fff",
        marginBottom: "20px"
    },
}