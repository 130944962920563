export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo = require("../assets/logo.png");
export const coverImage = require("../assets/LandingPageImg.png");
export const profileAvatar = require("../assets/Profile-pic.jpeg");
// export const awardImage = require("../assets/FilmPoster.png");
export const dragDrop = require("../assets/AddAPhoto.png");
export const Gold = require("../assets/meeting.jpeg");

export const exampleLaurel = require("../assets/exampleLaurel.png")
export const laurelSelectionPlaceHolder = require("../assets/laurelSelectionPlaceHolder.png")
export const awardImage = require("../assets/noImage.png")