// Customizable Area Start
import React from "react";
import {
  Box,
  TextField,
  FormLabel,
  FormControl,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Country, State, City } from "country-state-city";

export interface representativeType {
  attrId: number, name:string, email:string,full_phone_number:string, _destroy?: boolean, id?: number
}
const useStyles = makeStyles({
  icon: {
    color: "#a5259f",
  },
  root: {
    "& .MuiInputBase-input": {
      height: "1.5em",
    },
  },
});

const AddTalentAgent = ({ passTalentData, closeCompany, passRepresentativeData, userData, buttonDisable}: any) => {
  const initialValues = {
    nameTalent: userData ? userData.name : "",
    emailTalent: userData ? userData.email : "",
    phonenoTalent: userData ? userData.full_phone_number : "",
    address1Talent: userData ? userData.address1 :"",
    address2Talent: userData ? userData.address2 : "",
    cityTalent: userData ? userData.city :"",
    stateTalent: userData ? userData.state : "",
    postal_codeTalent: userData ? userData.postal_code : "",
    country_of_originTalent: userData ? userData.country : "",
    representativeAttributes: userData?.representatives ? userData.representatives : [
      {attrId: Math.random(), name:"", email:"",full_phone_number:""}, 
    ],
  };
  const [allStates, setAllStates] = React.useState(new Array());
  const [allCities, setAllCities] = React.useState(new Array());
  const [countryOrigin, setCountryOrigin] = React.useState("");
  const [btnDisabled, setBtnDisabled] = React.useState(true) 
  const [representativeAttributes, setRepresentativeAttributes] = React.useState<Array<representativeType>>();
  const [representativeDisabled, setRepresentativeDisabled] = React.useState(false) 
  const [formVal, dispatch] = React.useReducer(
    (curVal, newVal) => ({ ...curVal, ...newVal }),
    initialValues
  );
  
  const {
    nameTalent,
    emailTalent,
    phonenoTalent,
    address1Talent,
    address2Talent,
    cityTalent,
    stateTalent,
    postal_codeTalent,
    country_of_originTalent,
  } = formVal;

  React.useEffect(() => {
    passTalentData(formVal);
    passRepresentativeData(representativeAttributes);
    if(representativeAttributes){
     let names = representativeAttributes.map((attr:any) => {
        return attr.name === "" && !attr._destroy
      })
      names.includes(true) ? setRepresentativeDisabled(true) :  setRepresentativeDisabled(false)
    }
    buttonDisable(((nameTalent ? !nameTalent : btnDisabled) || representativeDisabled))
  });

  React.useEffect(() => {
    if(userData){
      setAllStates(State.getStatesOfCountry(userData.country))
      setAllCities(City.getCitiesOfState(userData.country, userData.state ))
      if(userData.representatives){
        setRepresentativeAttributes(userData.representatives)
      }
    }
  }, []);


  const handleChangeInput = (id:any, event:any) => {
    if(representativeAttributes){
      const newInputFields : Array<representativeType> =  representativeAttributes.map((i:any) => {
       if(id === i.attrId) {
         i[event.target.name as any] = event.target.value
       }
       return i;
     })
    setRepresentativeAttributes(newInputFields);
    }
  }
  const addCustomFile = (index: any) => {
    if(!representativeAttributes){
      setRepresentativeAttributes([
        { attrId: index, name: "", email: "", full_phone_number: "",_destroy: false  },
      ]);
    }
    if(representativeAttributes){
      setRepresentativeAttributes([
        ...representativeAttributes,
        { attrId: index, name: "", email: "", full_phone_number: "", _destroy: false },
      ]);
    }
  };

  const deleteRow = (id: any) => {
    if(representativeAttributes){
      const values =  [...representativeAttributes];
      const finalValues = values.map((value) => (value.attrId === id || (value.hasOwnProperty('id') && value.id === id)) ?  {...value, "_destroy": true} :  value)
      setRepresentativeAttributes(finalValues);
    }
  };

  const classes = useStyles();
  const styled ={color: 'red'};
  const countries = Country.getAllCountries();
  
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    dispatch({ [name]: value });
  };

  const handleCountryOfOrigin = (event: any) => {
    const { name, value } = event.target;
    setCountryOrigin(value);
    setAllStates(State.getStatesOfCountry(value));
    dispatch({ [name]: value });
  };

  const handleStateChange = (event: any) => {
    const { name, value } = event.target;
    setAllCities(City.getCitiesOfState(countryOrigin, value));
    dispatch({ [name]: value });
  };

  const handleCityChange = (event: any) => {
    const { name, value } = event.target;
    initialValues.cityTalent = value;
    dispatch({ [name]: value });
  };

  return (
    <div style={{ margin: "20px", border: "1px solid #ccc" }}>
      <Box
        sx={{
          bgcolor: "#ccc",
          padding: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">{nameTalent}</Typography>
        <CloseIcon
          style={{ fill: "#aaa", cursor: "pointer" }}
          onClick={closeCompany}
        />
      </Box>
      <Box style={{ padding: "20px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl
            component="div"
            style={{ padding: "10px 0px", width: "100%" }}
          >
            <FormLabel component="legend">Company{" "}<span style={styled}>*</span></FormLabel>
            <TextField
              id="outlined-disabled"
              placeholder="My Production Company, LLC"
              onChange={handleChange}
              name="nameTalent"
              value={nameTalent}
              required
              onInput={(e:any) => setBtnDisabled(!e.target.value)}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl
            component="div"
            style={{ padding: "10px 0px", width: "100%" }}
          >
            <FormLabel component="legend">Address</FormLabel>
            <TextField
              id="outlined-disabled"
              placeholder="Address"
              name="address1Talent"
              value={address1Talent}
              onChange={handleChange}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl
            component="div"
            style={{ padding: "10px 0px", width: "100%" }}
          >
            <TextField
              id="outlined-disabled"
              placeholder="Address Line 2"
              name="address2Talent"
              value={address2Talent}
              onChange={handleChange}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl
            component="div"
            style={{ padding: "10px 0px", width: "45%" }}
          >
            <FormLabel component="legend">Country of origin</FormLabel>
            <TextField
              id="standard-select-currency-native"
              select
              onChange={handleCountryOfOrigin}
              SelectProps={{
                classes: { icon: classes.icon },
                native: true,
              }}
              InputProps={{ className: classes.root }}
              variant="standard"
              name="country_of_originTalent"
              value={country_of_originTalent}
            >
              <option value={""}>Select from option</option>
              {countries &&
                countries.map((cur: any) => (
                  <option value={cur.isoCode} key={cur.isoCode}>{cur.name}</option>
                ))}
            </TextField>
          </FormControl>
          <FormControl
            component="div"
            style={{ padding: "10px 0px", width: "45%" }}
          >
            <FormLabel component="legend">State</FormLabel>
            <TextField
              id="standard-select-currency-native"
              select
              onChange={handleStateChange}
              SelectProps={{
                classes: { icon: classes.icon },
                native: true,
              }}
              InputProps={{ className: classes.root }}
              variant="standard"
              name="stateTalent"
              value={stateTalent}
            >
              <option value={""}>Select from option</option>
              {allStates &&
                allStates.map((stateTalent: any) => (
                  <option value={stateTalent.isoCode} key={stateTalent.isoCode}>
                    {stateTalent.name}
                  </option>
                ))}
            </TextField>
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl
            component="div"
            style={{ padding: "10px 0px", width: "45%" }}
          >
            <FormLabel component="legend">City</FormLabel>
            <TextField
              id="standard-select-currency-native"
              select
              onChange={handleCityChange}
              SelectProps={{
                classes: { icon: classes.icon },
                native: true,
              }}
              InputProps={{ className: classes.root }}
              variant="standard"
              name="cityTalent"
              value={cityTalent}
            >
              <option value={""}>Select from option</option>
              {allCities &&
                allCities.map((cityTalent: any) => (
                  <option value={cityTalent.name} key={cityTalent.name}>{cityTalent.name}</option>
                ))}
            </TextField>
          </FormControl>
          <FormControl
            component="div"
            style={{ padding: "10px 0px", width: "45%" }}
          >
            {" "}
            <FormLabel component="legend">Postal Code</FormLabel>
            <TextField
              type="number"
              id="standard-select-role"
              placeholder="Enter pin code"
              onChange={handleChange}
              InputProps={{ className: classes.root }}
              name="postal_codeTalent"
              value={postal_codeTalent}
              //  onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
            />
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl
            component="div"
            style={{ padding: "10px 0px", width: "100%" }}
          >
            <FormLabel component="legend">Email</FormLabel>
            <TextField
              id="outlined-disabled"
              placeholder="john@example.ca"
              variant="standard"
              onChange={handleChange}
              InputProps={{ className: classes.root }}
              name="emailTalent"
              value={emailTalent}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl
            component="div"
            style={{ padding: "10px 0px", width: "100%" }}
          >
            <FormLabel component="legend">Phone</FormLabel>
            <TextField
              id="outlined-disabled"
              placeholder="(604) 229-0600"
              type="number"
              onChange={handleChange}
              InputProps={{ className: classes.root }}
              name="phonenoTalent"
              value={phonenoTalent}
            />
          </FormControl>
        </Box>
        <div style={{ margin: "20px 0 0"}}>
        {representativeAttributes && 
          representativeAttributes.filter((newRow) => !newRow._destroy).map((newRow, id: any) => {
              if(!newRow._destroy){
                return (
                  <div style={{  border: "1px solid #ccc" }} key={newRow.attrId}>
                    <Box
                      sx={{
                        bgcolor: "#ccc",
                        padding: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h4">Representative #{id + 1}</Typography>
                      <CloseIcon
                        style={{ fill: "#aaa", cursor: "pointer" }}
                        onClick={() => deleteRow(newRow.id ? newRow.id : id)}
                      />
                    </Box>
                    <Box sx={{ padding: "20px" }}>
                      <Box
                        sx={{ display: "flex", justifyContent: "space-between" }}
                      >
                        <FormControl
                          component="div"
                          style={{ padding: "10px 0px", width: "100%" }}
                        >
                          <FormLabel component="legend">Name{" "}<span style={styled}>*</span></FormLabel>
                          <TextField
                            id="outlined-disabled"
                            onChange={event => handleChangeInput(newRow.attrId, event)}
                            name="name"
                            value={newRow.name}
                            required
                            onInput={(e:any) => setBtnDisabled(!e.target.value)}
                          />
                        </FormControl>
                      </Box>
                      <Box
                        sx={{ display: "flex", justifyContent: "space-between" }}
                      >
                        <FormControl
                          component="div"
                          style={{ padding: "10px 0px", width: "100%" }}
                        >
                          <FormLabel component="legend">Email</FormLabel>
                          <TextField
                            id="outlined-disabled"
                            variant="standard"
                            onChange={event => handleChangeInput(newRow.attrId, event)}
                            InputProps={{ className: classes.root }}
                            name="email"
                            value={newRow.email}
                          />
                        </FormControl>
                      </Box>
                      <Box
                        sx={{ display: "flex", justifyContent: "space-between" }}
                      >
                        <FormControl
                          component="div"
                          style={{ padding: "10px 0px", width: "100%" }}
                        >
                          <FormLabel component="legend">Phone</FormLabel>
                          <TextField
                            id="outlined-disabled"
                            type="number"
                            onChange={event => handleChangeInput(newRow.attrId, event)}
                            InputProps={{ className: classes.root }}
                            name="full_phone_number"
                            value={newRow.full_phone_number}
                          />
                        </FormControl>
                      </Box>
                    </Box>
                  </div>
                );
              }
          })}
          </div>
        {(!representativeAttributes || representativeAttributes.filter(n => !n._destroy).length < 3 ) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Button color="inherit" onClick={() => addCustomFile(!representativeAttributes ? 0 : representativeAttributes.length)}>
              Add Representative
            </Button>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default AddTalentAgent;
// Customizable Area end
