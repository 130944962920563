// Customizable Area Start

import React from "react";

import CoverImageController, {
    Props
} from "./CoverImageController";
import AppHeader from "./AppHeader.web";
import {LandingPageImg} from "./assets";
import {Box, Button, Container, Divider, IconButton, Input, Typography} from "@material-ui/core";
import moment from "moment";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import {Link} from "react-router-dom";

export default class CoverImage extends CoverImageController {
    constructor(props: Props) {
        super(props);
    }

    webStyles = {
        landingText: {
            fontSize: "42px",
            fontWeight: 600,
        },
        headSection: {
            display: "flex",
            justifyContent: "space-between"
        },
        headLeft: {
            display: "flex",
            alignItems: "center"
        },
        avatarSection: {
            position: "relative",
            top: "-50px"
        },
        avatar: {
            height: "200px",
            width: "200px",
            borderRadius: "50%"
        },
        headContent: {
            display: "flex",
            alignItems: "end",
            marginLeft: "50px"
        },
        bottomSection: {
            display: "flex",
            justifyContent: "space-between",
            padding: "50px 0px"
        },
        bottomLeft: {
            background: "#fff",
            width: '22%',
            height: "fit-content"
        },
        cameraPhoto: {
            position: 'absolute',
            top: '135px',
            right: '66px'
        },
        card: {
            // padding: theme.spacing(1),
            marginTop: '20px',
            zIndex: 1,
            borderRadius: '5px',
            width: '80%',
            marginLeft: '10%',
        },
    }

    render() {
        return (
            <>
                {
                    this.props.showHeader &&
                    <AppHeader accountData={this.state.accountAPIData} />
                }
                {this.state.accountAPIData === null ?
                    "Loading..." : <>
                        <img
                            // @ts-ignore
                            src={this.state.accountAPIData?.cover_pic == "" ? (this.state.coverpreview == "" ? LandingPageImg : this.state.coverpreview) : this.state.coverpreview=="" ? this.state.accountAPIData?.cover_pic : this.state.coverpreview } alt="cover-image" height="250px" width="100%" style={{ objectFit: "cover", objectPosition: "center top"}} />
                        <Box sx={{bgcolor: "#ebeced"}}>
                            <Container>
                                <Box sx={this.webStyles.headSection}>
                                    <Box sx={this.webStyles.headLeft}>
                                        <Box sx={this.webStyles.avatarSection}>
                                            {/*@ts-ignore*/}
                                            <img src={this.state.accountAPIData?.profile_pic == "" ? (this.state.previewprofile == "" ? LandingPageImg : this.state.previewprofile) : this.state.previewprofile == "" ? this.state.accountAPIData?.profile_pic : this.state.previewprofile} alt="profile-picture" style={this.webStyles.avatar} />
                                            <label htmlFor="icon-button-file">
                                                <Input id="icon-button-file"
                                                       name="profile_pic"
                                                       type="file" style={{display: 'none'}}
                                                       onChange={this.handleUpdateProfileImage}
                                                />
                                                <IconButton aria-label="upload picture" component="span" style={{position: 'absolute', top: '175px', right: '80px', padding: '8px', backgroundColor: '#a5259f', height: '40px', width: '40px', borderRadius: '50%'}}>
                                                    <PhotoCameraIcon style={{fill: "white"}} />
                                                </IconButton>
                                            </label>
                                        </Box>
                                        <Box sx={{marginLeft: "50px"}}>
                                            <Typography variant="h3">
                                                {/*@ts-ignore*/}
                                                {this.state.accountAPIData?.first_name} {this.state.accountAPIData?.last_name} {this.state.accountAPIData?.title ? ("("+ this.state.accountAPIData?.title + ")") : ""}</Typography>
                                            {/*@ts-ignore*/}
                                            <Typography variant="subtitle1" style={{color: "#ccc"}} >Member Since {moment(this.state.accountAPIData?.created_at).format("MMMM YYYY")} USA</Typography>
                                        </Box>
                                        {/*<Box sx={this.webStyles.headContent}>*/}
                                        {/*    <Typography variant="h3" style={{color: "#A5259F"}} >0</Typography>*/}
                                        {/*    <Typography variant="h5" style={{fontWeight: 700, marginLeft: "5px"}} >Events</Typography>*/}
                                        {/*</Box>*/}
                                        {/*<Box sx={this.webStyles.headContent}>*/}
                                        {/*    <Typography variant="h3" style={{color: "#A5259F"}} >0</Typography>*/}
                                        {/*    <Typography variant="h5" style={{fontWeight: 700, marginLeft: "5px"}} >Discussions</Typography>*/}
                                        {/*</Box>*/}
                                    </Box>
                                    <Box sx={{display: "flex", flexDirection: "column", alignContent: "center"}}>
                                        <Button variant="contained" component="label" style={{ background: "#A5259F", color: "#fff", marginTop: "10px"}}>
                                            <label htmlFor="icon-button-cover">
                                                + COVER IMAGE
                                                <Input
                                                    type="file"
                                                    id="icon-button-cover"
                                                    hidden
                                                    onChange={this.handleUpdateCoverImage}
                                                    name="cover_pic"
                                                    style={{display: 'none'}}
                                                />
                                            </label>
                                        </Button>
                                        <Box style={{ border: "1px solid #A5259F", marginTop: "10px",textDecoration:"none",display:"flex",borderRadius:"5px",justifyContent:'center' }}>
                                            <Button style={{color: "#A5259F"}} disabled>EDIT PROFILE</Button>
                                        </Box>
                                         </Box>
                                </Box>
                                <Divider />
                            </Container>
                        </Box>
                    </> }
            </>
        );
    }
}


// Customizable Area End
