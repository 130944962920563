import React from 'react';
import { Box,Container, Tab, Tabs, Button,Typography,Modal,Paper,Grid,FormLabel,CircularProgress } from '@material-ui/core';
import { makeStyles,withStyles} from '@material-ui/core/styles';

class Reports extends React.Component {

    render() {
    return(
        <Container style={{paddingLeft:"30px"}}>
            <Box style={{padding:"0px 0px 0px 7px"}}>
                <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem",marginBottom:"10px"}}>Reports</Typography>
            </Box>
            <Box style={{flexWrap: 'wrap'}}>
                <Paper style={{padding:"35px 15px 30px 25px"}}>
                    <Typography variant="h4" style={{marginBottom:'10px',fontWeight:800}}>Inactive Submission Season</Typography>
                    <Typography style={{fontWeight:500}}>
                    Your festival's Call for Entries has not yet begun.
                    </Typography>  
                    <Box style={{margin:"15px 0px 0px 0px"}}>
                        <Button color="primary" type="submit">Edit Festival</Button>
                     </Box>
                </Paper>
            </Box>
        </Container>
    )
}
}

export default Reports;