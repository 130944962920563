import React from "react";

import {
  Typography,
  Button,
  Grid,
  Box,
  Divider,
  TextField,
} from "@material-ui/core";
import AppHeader from "../../../components/src/AppHeader.web"

import { Formik } from "formik";

import * as Yup from "yup";
// Customizable Area End

import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";

export default class NewPassword extends ForgotPasswordController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.isChangePassword = true;
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      <Box sx={webStyles.imgContainer}>
        <AppHeader
          // @ts-ignore
          toggleLoginModal={() => this.goToHome()}
          toggleSignupModal={() => this.goToHome()}
        />
        {this.state.apiResponseError &&
          <Box sx={webStyles.msgBarError}>
            <span>Error: {this.state.apiResponseError} </span>
          </Box> }
          {this.state.apiResponseMsg &&
          <Box sx={webStyles.msgBar}>
            <span>SUCCESS  {this.state.apiResponseMsg}.</span>
          </Box> }
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          // style={webStyles.imgContainer}
        >
          <Grid item md={6} xs={8}>
            <Box py={4} >
              <Grid container spacing={2}>
                <Grid item xs={9} >
                  <Typography variant="h3" onClick={() => this.startForgotPassword("sms")}>
                    Change Your Password
                  </Typography>
                </Grid>
              </Grid>
            </Box>
              <Box sx={{bgcolor: '#fff'}}>
              <Formik
                initialValues={{ password: "", confirmPassword: "" }}
                validationSchema={Yup.object().shape(this.state.passwordSchema)}
                validateOnMount={true}
                validateOnChange={true}
                onSubmit={(values, actions) => {
                  this.goToConfirmationAfterPasswordChange(values);
                  actions.setSubmitting(false);
                }}
              >
                {({
                  handleChange,
                  handleSubmit,
                  errors,
                  setFieldTouched,
                  touched
                }) => (
                  <Box py={4} >
                    <Grid container justify="center" spacing={2}>
                      <Grid item xs={10}>
                        <TextField
                          type="password"
                          fullWidth
                          variant="outlined" 
                          id="outlined-disabled"
                          placeholder={this.placeholderIsPassword}
                          onChange={handleChange("password")}
                          onBlur={() => setFieldTouched("password")}
                        />
                        {touched.password && errors.password ? (
                            <span style={{fontSize: '12px', color: 'red'}}>{errors.password}</span>
                          ) : null}
                      </Grid>
                      <Grid item xs={10}>
                        <TextField
                          type="password"
                          fullWidth
                          variant="outlined" 
                          id="outlined-disabled"
                          placeholder={this.placeholderIsReTypePassword}
                          onChange={handleChange("confirmPassword")}
                          onBlur={() => setFieldTouched("confirmPassword")}
                        />
                        {touched.confirmPassword && errors.confirmPassword ? (
                            <span style={{fontSize: '12px', color: 'red'}}>{errors.confirmPassword}</span>
                          ) : null}
                      </Grid>
                      <Grid item xs={10}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            onClick={() => handleSubmit()}
                        >
                          {this.buttonTextIsNext}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  )}
                </Formik>
                <Grid container justify="center" spacing={2}>
                  <Grid xs={10} style={{padding: '8px'}}>
                    <Divider />
                  </Grid>
                </Grid>
                <Box py={3} >
                  <Grid container justify="center" spacing={2}>
                    <Grid item xs={6} justify="center">
                      <Typography variant="subtitle1" >
                          Not a member yet?
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                          color="primary"
                          variant="outlined"
                          fullWidth
                          onClick={() => this.goToHome()}
                      >
                        Sign Up
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

// Customizable Area Start
const webStyles = {
  imgContainer: {
    bgcolor: '#eee',
    height: '100vh'
  },
  msgBar: {
    width: '100%', 
    height: '50px', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    background: '#2db482',
    color: "#fff"
  },
  msgBarError: {
    width: '100%', 
    height: '50px', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    background: '#c43731',
    color: "#fff"
  }
}
// Customizable Area End