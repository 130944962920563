import React from "react";

import {
  Typography,
  Button,
  Grid,
  Box,
  Dialog,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import AppHeader from "../../../components/src/AppHeader.web"

import { logo } from "./assets";
import { LandingPageImg } from "./assets";

import LoginModal from "./login.web"
import SignUpModal from "./signup.web"
import {withRouter} from "react-router-dom";
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';

import LandingPage2Controller, {
  Props,
  configJSON
} from "./LandingPage2Controller";
// import { dividerClasses } from "@mui/material";

class LandingPage2 extends LandingPage2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  
  }

  // Customizable Area Start

  // isValidPasswordAddress = (textPassword: string) => {
  //   var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  //   if(re.test(textPassword)){
  //     handlePasswordChange(textPassword)
  //     setPasswordError("")
  //   }else{
  //     handlePasswordChange("")
  //     setPasswordError("Enter Valid Password")
  //   }
  // }

  // Customizable Area End
  
  

  render() {
    const { navigation } = this.props;
    return (
      // Customizable Area Start
      <>
          <AppHeader
            // @ts-ignore
            toggleLoginModal={this.toggleLoginModal}
            toggleSignupModal={this.toggleSignupModal}
          />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={wenStyles.imgContainer}
          >
            <Grid item xs={10}>
              <Typography align="center"  style={wenStyles.landingText}>
                Discover the World's Best Submission Platform
              </Typography>
            </Grid>
          </Grid>
          <LoginModal 
            isOpen={this.state.loginOpen} 
            handleSignupOpen={this.toggleSignupModal}
            handleClose={this.toggleLoginModal} 
            handleEmailChange={this.handleEmailChange}
            handlePasswordChange={this.handlePasswordChange}
            handleSocialMediaSignup={this.handleSocialMediaSignup}
            handleLogin={this.handleLogin}
            errorMessage={this.state.errorMessage}
            successMessage={this.state.successMessage}
            navigateToForgot={this.goToLogin}
          />
          <SignUpModal 
            isOpen={this.state.signupOpen} 
            userRole={this.state.userRole}
            handleLoginOpen={this.toggleLoginModal} 
            handleClose={this.toggleSignupModal} 
            handleSocialMediaSignup={this.handleSocialMediaSignup}
            handleFirstnameChange={this.handleFirstnameChange}
            handleLastnameChange={this.handleLastnameChange}
            handleEmailChange={this.handleEmailChange}
            handlePasswordChange={this.handlePasswordChange}
            handleUserRole={this.handleUserRole}
            handleSignup={this.handleSignup}
            errorMessage={this.state.errorMessage}
            successMessage={this.state.successMessage}
            navigateToProject={this.navigateToProject}
          />
      </>
      // Customizable Area End
    );
  }
}

export default (...props:any) => {
  // @ts-ignore
  const WithRouteAppHeader = withRouter(LandingPage2)
  return (
      <WithRouteAppHeader {...props} />
  )
};

// Customizable Area Start
const wenStyles = {
  appBar: {
    borderBottom: `1px solid #000`,
    width: "100%",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "#330a4c",
    
    // height: theme.spacing(8.75),
  },

  toolbar: {
    flexWrap: "nowrap",
    // padding: theme.spacing(0, 10),
  },
  toolbarLogo: {
    flexGrow: 1,
  },
  navBarButtons: {
    color: "#fff",
    fontSize: "18px",
    textTransform: "capitalize",
    width: 'fit-content',
    height: 'fit-content',
    background: 'none',
    border: 'none'
    
  },
  imgContainer: {
    backgroundImage: `url(${LandingPageImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    padding: "300px",
    height:"100vh"
    // height:"100vh !important"
  },
  landingText: {
    fontSize: "42px",
    color: "#fff",
    fontWeight: 600,
  },
}
// Customizable Area End