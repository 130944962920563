import React from 'react';
import { Box, Tab, Tabs, Button,Typography,Modal,Paper,Grid,FormLabel,CircularProgress } from '@material-ui/core';
import { makeStyles,withStyles} from '@material-ui/core/styles';

class LaurelCenter extends React.Component{

    render() {
    return(
        <Box>
            <h1>LaurelCenter</h1>
        </Box>
    )
}
}

export default LaurelCenter;