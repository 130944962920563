import React, {Component} from 'react';
import {Grid,Box,Typography,Divider,Avatar,CircularProgress} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Chip from '@material-ui/core/Chip';
import AboutFestivalController, {
    Props
} from "./AboutFestivalController";
import {examplePhoto,defaultAvatar} from "./assets";

import "./style.css"
import Timeline from "./Timeline.web";
import moment from "moment";

class AboutFestival extends AboutFestivalController{
    constructor(props: Props) {
        super(props);
    }
    render(){
        return (
            <Grid container spacing={2}>
                {
                    this.state.isLoading ?
                        <Grid xs={12} style={{height:"50vh",display:'flex',alignItems:'center',justifyContent:"center"}}>
                            <CircularProgress />
                        </Grid> :
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                {
                                    // @ts-ignore
                                    this.state.festivalDetails.festival_photos?.data?.map((item:any,key:any)=>{
                                        if(key < 8){
                                            return(
                                                <Grid item xs={12} sm={6} md={3} key={key}>
                                                    <ImgCustom url={item.attributes.festival_photo}/>
                                                </Grid>
                                            )
                                        }
                                    })
                                }
                            </Grid>
                            <Grid container spacing={3} >
                                <Grid xs={12} sm={12} md={8}>
                                    <Grid container>
                                        <Grid xs={12} style={{marginTop:"50px"}}>
                                            <Typography variant="h6" style={{fontWeight:"bold",color:"gray"}}>About</Typography>
                                            <Box style={{backgroundColor:"#fff",borderRadius:"8px",marginTop:"20px",width:"100%" }}>
                                                <Typography style={{padding:"20px"}}>
                                                    {/*@ts-ignore*/}
                                                    {this.state.festivalDetails?.event_description}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} style={{marginTop:"50px"}}>
                                            <Typography variant="h6" style={{fontWeight:"bold",color:"gray"}}>Awards & Prizes </Typography>
                                            <Box style={{backgroundColor:"#fff",borderRadius:"8px",marginTop:"20px",width:"100%" }}>
                                                {/*<Typography style={{paddingTop:"20px",paddingLeft:"20px"}}>*/}
                                                {/*    Cannes Shorts will be celebrating the years best in short film genre : <br/><br/>*/}
                                                {/*</Typography>*/}
                                                <Grid container style={{paddingLeft:"20px"}}>
                                                    <Grid xs={12} sm={6}>
                                                        <List dense={false}>
                                                            <ListItem>
                                                                <ListItemIcon>
                                                                    <RadioButtonUncheckedIcon style={{color:"maroon"}}/>
                                                                </ListItemIcon>
                                                                {/*@ts-ignore*/}
                                                                <ListItemText primary={this.state.festivalDetails?.award_prize} />
                                                            </ListItem>
                                                        </List>
                                                        {/*{*/}
                                                        {/*    awardList.map((item,key)=>{*/}
                                                        {/*        if(key <= parseInt(String(awardList.length / 2))){*/}
                                                        {/*            return(*/}
                                                        {/*                <List dense={false}>*/}
                                                        {/*                    <ListItem>*/}
                                                        {/*                        <ListItemIcon>*/}
                                                        {/*                            <RadioButtonUncheckedIcon style={{color:"maroon"}}/>*/}
                                                        {/*                        </ListItemIcon>*/}
                                                        {/*                        <ListItemText primary={item.name}/>*/}
                                                        {/*                    </ListItem>*/}
                                                        {/*                </List>*/}
                                                        {/*            )*/}
                                                        {/*        }*/}
                                                        {/*    })*/}
                                                        {/*}*/}
                                                    </Grid>
                                                    {/*<Grid xs={12} sm={6}>*/}
                                                    {/*    {*/}
                                                    {/*        awardList.map((item,key)=>{*/}
                                                    {/*            if(key > parseInt(String(awardList.length / 2))){*/}
                                                    {/*                return(*/}
                                                    {/*                    <List dense={false}>*/}
                                                    {/*                        <ListItem>*/}
                                                    {/*                            <ListItemIcon>*/}
                                                    {/*                                <RadioButtonUncheckedIcon style={{color:"maroon"}}/>*/}
                                                    {/*                            </ListItemIcon>*/}
                                                    {/*                            <ListItemText primary={item.name}/>*/}
                                                    {/*                        </ListItem>*/}
                                                    {/*                    </List>*/}
                                                    {/*                )*/}
                                                    {/*            }*/}
                                                    {/*        })*/}
                                                    {/*    }*/}
                                                    {/*</Grid>*/}
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        {/*// @ts-ignore*/}
                                        <Grid xs={12} style={{marginTop:"50px"}} ref={this.props.refProp}>
                                            <Typography variant="h6" style={{fontWeight:"bold",color:"gray"}}>Rules and Terms</Typography>
                                            <Box style={{backgroundColor:"#fff",borderRadius:"8px",marginTop:"20px",width:"100%" }}>
                                                {/*<Typography style={{paddingTop:"20px",paddingLeft:"20px"}}>*/}
                                                {/*    Cannes Shorts will be celebrating the years best in short film genre : <br/><br/>*/}
                                                {/*</Typography>*/}
                                                <Grid container style={{paddingLeft:"20px"}}>
                                                    <Grid xs={12}>
                                                        <List dense={false}>
                                                            <ListItem>
                                                                <ListItemIcon>
                                                                    <RadioButtonUncheckedIcon style={{color:"maroon"}}/>
                                                                </ListItemIcon>
                                                                {/*@ts-ignore*/}
                                                                <ListItemText primary={this.state.festivalDetails?.category_and_entry_fee?.rules_terms}/>
                                                            </ListItem>
                                                            {/*<ListItem>*/}
                                                            {/*    <ListItemIcon>*/}
                                                            {/*        <RadioButtonUncheckedIcon style={{color:"maroon"}}/>*/}
                                                            {/*    </ListItemIcon>*/}
                                                            {/*    <ListItemText primary="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"/>*/}
                                                            {/*</ListItem>*/}
                                                            {/*<ListItem>*/}
                                                            {/*    <ListItemIcon>*/}
                                                            {/*        <RadioButtonUncheckedIcon style={{color:"maroon"}}/>*/}
                                                            {/*    </ListItemIcon>*/}
                                                            {/*    <ListItemText primary="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"/>*/}
                                                            {/*</ListItem>*/}
                                                        </List>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        {/*// @ts-ignore*/}
                                        <Grid xs={12} style={{marginTop:"50px"}} ref={this.props.refProp1}>
                                            <Box className="reviewHeading">
                                                <Typography variant="h6" style={{fontWeight:"bold",color:"gray"}}>Review & Rating</Typography>
                                                <Typography variant="body2" style={{fontWeight:"bold",color:"#7ca0ea",textDecoration:"underline"}}>Review Festival</Typography>
                                            </Box>
                                            <Box style={{backgroundColor:"#fff",borderRadius:"8px",marginTop:"20px",width:"100%" }}>
                                                <Grid container style={{padding:"25px",marginBottom:"10px"}}>
                                                    <Grid xs={12} sm={6}>
                                                        <Box
                                                            // @ts-ignore
                                                            style={ratingBox}>
                                                            <Typography variant="h4" style={{fontWeight:"bold",width:"200px"}}>Overall Rating</Typography>
                                                            <StyledRating
                                                                name="overall-rating"
                                                                defaultValue={0}
                                                                precision={0.5}
                                                                // @ts-ignore
                                                                value={this.state.reviewDetails?.overall_rating || 0}
                                                                readOnly
                                                            />
                                                        </Box>
                                                        <Box
                                                            // @ts-ignore
                                                            style={ratingBox}>
                                                            <Typography variant="h4" style={{fontWeight:"bold",width:"200px"}}>Quality</Typography>
                                                            <StyledRating
                                                                name="overall-rating"
                                                                defaultValue={0}
                                                                precision={0.5}
                                                                // @ts-ignore
                                                                value={this.state.reviewDetails?.quality_rating || 0}
                                                                readOnly
                                                            />
                                                        </Box>
                                                        <Box
                                                            // @ts-ignore
                                                            style={ratingBox}>
                                                            <Typography variant="h4" style={{fontWeight:"bold",width:"200px"}}>Value</Typography>
                                                            <StyledRating
                                                                name="overall-rating"
                                                                defaultValue={4}
                                                                precision={0.5}
                                                                // @ts-ignore
                                                                value={this.state.reviewDetails?.value_rating || 0}
                                                                readOnly
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid xs={12} sm={6}>
                                                        <Box
                                                            // @ts-ignore
                                                            style={ratingBox}>
                                                            <Typography variant="h4" style={{fontWeight:"bold",width:"200px"}}>Communication</Typography>
                                                            <StyledRating
                                                                name="overall-rating"
                                                                defaultValue={4}
                                                                precision={0.5}
                                                                // @ts-ignore
                                                                value={this.state.reviewDetails?.communication_rating || 0}
                                                                readOnly
                                                            />
                                                        </Box>
                                                        <Box
                                                            // @ts-ignore
                                                            style={ratingBox}>
                                                            <Typography variant="h4" style={{fontWeight:"bold",width:"200px"}}>Networking</Typography>
                                                            <StyledRating
                                                                name="overall-rating"
                                                                defaultValue={4}
                                                                precision={0.5}
                                                                // @ts-ignore
                                                                value={this.state.reviewDetails?.networking_rating || 0}
                                                                readOnly
                                                            />
                                                        </Box>
                                                        <Box
                                                            // @ts-ignore
                                                            style={ratingBox}>
                                                            <Typography variant="h4" style={{fontWeight:"bold",width:"200px"}}>Hospitality</Typography>
                                                            <StyledRating
                                                                name="overall-rating"
                                                                defaultValue={4}
                                                                precision={0.5}
                                                                // @ts-ignore
                                                                value={this.state.reviewDetails?.hospitality_rating || 0}
                                                                readOnly
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Box style={{display: "flex",justifyContent:"center"}}>
                                                    <Divider style={{width:"95%"}}/>
                                                </Box>
                                                <Grid container style={{padding:"20px"}}>
                                                    {
                                                        // @ts-ignore
                                                        this.state.reviewDetails?.data?.length > 0 &&
                                                        // @ts-ignore
                                                        this.state.reviewDetails?.data?.map((item:any,key:any)=>{
                                                            console.log("REVIEW ITEM",item)
                                                            return(
                                                                <Grid xs={12}>
                                                                    <Box style={{padding:"10px",width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                                                                        <Box style={{display:'flex',flexDirection:"row",alignItems:'center'}}>
                                                                            <Avatar src={item.attributes.profile_pic || defaultAvatar}/>
                                                                            <Typography variant="h3" style={{marginLeft:"10px"}}>
                                                                                {item.attributes.name}
                                                                            </Typography>
                                                                            <Typography variant="subtitle2" style={{marginLeft:"10px",marginTop:"5px",color:"gray"}} gutterBottom={false}>
                                                                                {moment(item.attributes.created_at).format("MMMM YYYY")}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Chip
                                                                            label={item.attributes.marked_as_helpful ? "Marked as helpful" : "Mark as helpful"}
                                                                            variant="outlined"
                                                                            style={{fontWeight:'bold'}}
                                                                            onClick={()=>this.handleMark(!item.attributes.marked_as_helpful,item.id)}
                                                                        />
                                                                    </Box>
                                                                    <Typography variant="body2" style={{paddingTop:"5px",paddingBottom:"10px",marginLeft:"10px"}}>
                                                                        {item.attributes.feedback}
                                                                    </Typography>
                                                                    <Divider/>
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={12} md={4}>
                                    <Grid className="secondGrid" container spacing={2}>
                                        <Grid xs={12} style={{marginTop:"60px"}}>
                                            <>
                                                <Typography variant="h6" style={{fontWeight:"bold",color:"gray"}}>Dates & Deadline</Typography>
                                                <Box>
                                                    {/*// @ts-ignore*/}
                                                    <Timeline deadline={this.state.festivalDetails?.entry_deadlines} otherDeadlines={this.state.festivalDetails?.deadline}/>
                                                </Box>
                                            </>
                                        </Grid>
                                        <Grid xs={12} style={{marginTop:"50px"}}>
                                            {
                                                // @ts-ignore
                                                this.state.festivalDetails?.category_names?.length > 0 &&
                                                <>
                                                    <Typography variant="h6" style={{fontWeight:"bold",color:"gray"}}>Categories & Fees</Typography>
                                                    <Box style={{backgroundColor:"#fff",borderRadius:"8px",marginTop:"20px",width:"100%",padding:'20px' }}>
                                                        {
                                                            // @ts-ignore
                                                            this.state.festivalDetails?.category_names?.map((item:any,key:any)=>{
                                                                return(
                                                                    <>
                                                                        <Typography variant="h6" style={{padding:"20px",color:"#a5259f",fontWeight:"bold"}}>
                                                                            {item.category_name}
                                                                        </Typography>
                                                                        <Divider/>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </Box>
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                }
            </Grid>
        );
    };
};

export default AboutFestival;

const StyledRating = withStyles({
    iconFilled: {
        color: '#a5259f',
    },
    iconHover: {
        color: '#6c1167',
    },
})(Rating);

let ratingBox = {
    display:'flex',
    flexDirection:'row',
    width:"100%",
    alignItems:'center'
}

const ImgCustom = (props:any) => {
    const {url} = props
    const [isPhotoLoading, setIsPhotoLoading] = React.useState(true);
    const manageImageLoading = () => {
        setIsPhotoLoading(false)
    }
    return(
        <>
            {
                isPhotoLoading &&
                <Box className="loaderBox">
                    <CircularProgress/>
                </Box>
            }
            <img
                src={url}
                width="100%"
                height="200px"
                onLoad={manageImageLoading}
                style={isPhotoLoading ? {display:"none"} :{display:"block",objectFit:"fill"}}
            />
        </>
    )
}