import React from 'react';
import { Box,Container,Link,FormControl,TextField, Tabs, Button,Typography,Modal,Paper,Grid,FormLabel,CircularProgress } from '@material-ui/core';
import { makeStyles,withStyles} from '@material-ui/core/styles';
import {defaultBackground,defaultProfile} from './assets';
import InviteContactController, {
    Props
  } from "./InviteContactController";
import { Formik } from "formik";
import { inviteContact, formInitialValues } from "./validationSchemas";
import {DeleteIcon} from './assets'

class InviteContacts extends InviteContactController {
    constructor(props: Props) {
        super(props);
      }

    useStyle = makeStyles((theme:any) => ({
        root: {
            flexGrow: 1,
            backgroundColor:"#F2F3F5",
            "& .MuiInputBase-input": {
                height: "1.5em",
                backgroundColor:"#fff"
              }
            // padding: theme.spacing(2),
        },
        paperstyle: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
              margin: theme.spacing(1),
              width: "100%",
              height: "100%",
            },
        },
        inputClass: {
            "& .Mui-focused:after": {
              border: "1px solid green",
            },
          },
          icon: {
            color: "#a5259f",
          },
          selectbox: {
            "& .MuiInputBase-input": {
              height: "1.5em",
            }
          },
    }));

    render() {
        const { customValue, invitationmail } = this.state;
        const classes = this.useStyle

        console.log("customvalue", customValue, invitationmail)
    return(
        <Formik
        enableReinitialize
        initialValues={formInitialValues}
        // validationSchema={stepOneSchema}
        onSubmit={(values, actions) => {
          this.handleSubmitEvent(values)
        }}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {props => (
        <form>
        <Container style={{paddingLeft:"30px"}}>
            <Box style={{padding:"0px 0px 0px 7px"}}>
                <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem",marginBottom:"10px"}}>Submitter Invitations</Typography>
            </Box>
            <Box style={{flexWrap: 'wrap'}}>
                <Paper style={{padding:"15px 15px 30px 15px"}}>
                    <Typography variant="h4" style={{marginBottom:'10px'}}>Create a custom email to invite your contacts and past participants to submit to your festival.</Typography>
                    <Grid container spacing={6}>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="h4" style={webStyles.commonfontweight}>Email Subject</Typography>
                                    <FormControl style={{width:"100%"}}>
                                        <TextField  
                                            id="outlined-disabled"
                                            placeholder="Eg. #14 Days"
                                            // onChange={(e) => setIntialValue({...initialValues,facebook_link:e.target.value})}
                                            //@ts-ignore
                                            InputProps={{ className: classes.root }}
                                            name="facebook_link"
                                            // value={initialValues.facebook_link}
                                            //@ts-ignore  
                                            className={classes.inputClass}
                                        />
                                    </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h4" style={webStyles.commonfontweight}>Reply-To</Typography>
                                <FormControl style={{width:"100%"}}>
                                    <TextField  
                                        id="outlined-disabled"
                                        placeholder="Eg. #14 Days"
                                        // onChange={(e) => setIntialValue({...initialValues,facebook_link:e.target.value})}
                                        //@ts-ignore
                                        InputProps={{ className: classes.root }}
                                        name="facebook_link"
                                        // value={initialValues.facebook_link}
                                        //@ts-ignore  
                                        className={classes.inputClass}
                                    />
                                </FormControl>
                            </Grid>
                    </Grid>
                    <Grid container>
                        <Typography variant="h4" style={webStyles.commonfontweight}>Custom Message</Typography>
                        <Grid item xs={12} sm={12}>
                            <textarea  
                                id="outlined-basic"
                                rows={10}
                                value={this.state.customValue}
                                style={{ resize: "none",width: "100%",borderRadius: "8px",borderColor:"#C3CAD9",padding:"10px"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant="h4" style={webStyles.commonfontweight}>Preview</Typography>
                    <Paper style={{border:"1px solid #F8F8F8"}}>
                        <Box style={{ position:"relative"}}>
                            <Box style={{width: "100%"}}>
                                <img src={defaultBackground} style={{ width: "100%", height: "220px"}}/>
                            </Box>
                            <Box>
                                <img src={defaultProfile} style={{ position: "absolute", width: "130px", height: "130px", objectFit:"cover", cursor: 'pointer', top: "31%", left: "44%",
                                        boxShadow: "0 1px 10px -3px",borderRadius: '7px',}}/>
                            </Box>
                            <Box style={{ width: "100%",textAlign: "center",padding: "80px 0"}}>
                                <Typography variant="h3">test has invited you to submit your work via FilmFreeway!</Typography>
                                <Button color="primary" onClick={() => {}} style={webStyles.invitebutton}>Accept Invite</Button>
                            </Box>
                        </Box>
                        <hr/>
                        <Box style={{padding:"30px"}}>
                            <Typography variant="h4" style={webStyles.invitetext}>{this.state.customValue}</Typography>
                        </Box>
                    </Paper>
                    <Grid container spacing={6}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="h4" style={webStyles.commonfontweight}>Add Contacts</Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="h4" style={webStyles.commonfontweight}>Send a Test Invitation To</Typography>
                            </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={4}>
                            <Button color="primary" onClick={() => {}} style={webStyles.commonButton}>+ Add Contacts</Button>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                                <FormControl style={{width:"100%"}}>
                                    <TextField  
                                        id="outlined-disabled"
                                        placeholder="Eg. #14 Days"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.invitationmail}
                                        //@ts-ignore
                                        InputProps={{ className: classes.root }}
                                        name="invitationmail"
                                        //@ts-ignore  
                                        className={classes.inputClass}
                                    />
                                </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Button color="inherit" onClick={() => {}} style={webStyles.commonButton}>Send Test</Button>
                        </Grid>
                    </Grid>
                </Paper>
                <Box style={{marginTop:"15px"}}>
                    <Paper elevation={3}>
                    <Button style={{margin: "15px", width:"170px"}} color="primary" onClick={() => {}}>CREATE NEW EMAIL</Button>
                        <Box>
                        <Grid container style={{alignItems:"center",marginTop:"20px"}}>
                            <Grid item xs={12} sm={3}>
                                <Typography style={{fontWeight:600, fontSize:"1rem",margin:"15px"}}>Email</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography style={{fontWeight:600, fontSize:"1rem", textAlign:"center",margin:"15px"}}>Contacts</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography style={{fontWeight:600, fontSize:"1rem",textAlign:"center",margin:"15px"}}>Sent</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography style={{fontWeight:600, fontSize:"1rem"}}></Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={3}>
                                <Typography variant="h4" style={{margin:"15px",fontWeight:800}}>John@gmail.com</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography variant="h4"  style={{fontWeight:800,margin:"15px",textAlign:"center"}}>00</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Typography variant="h4"  style={{fontWeight:800,textAlign:"center",margin:"15px"}}>Never</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} style={{textAlign:"center"}}>
                                <img src={DeleteIcon} style={{height:"30px", width:"30px",margin:"15px"}}></img>
                            </Grid>
                            <Grid item xs={12} sm={3} style={{marginBottom:"25px"}}>
                                <Typography variant="h4" style={{margin:"15px",fontWeight:800}}>John@gmail.com</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} style={{marginBottom:"25px"}}>
                                <Typography variant="h4"  style={{fontWeight:800,margin:"15px",textAlign:"center"}}>00</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} style={{marginBottom:"25px"}}>
                                <Typography variant="h4"  style={{fontWeight:800,textAlign:"center",margin:"15px"}}>Never</Typography>
                            </Grid>
                            <Grid item xs={12} sm={3} style={{marginBottom:"25px",textAlign:"center"}}>
                                <img src={DeleteIcon} style={{height:"30px", width:"30px",margin:"15px"}}></img>
                            </Grid>
                        </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Container>
        </form>
         )}
        </Formik>
    )
}
}

export default InviteContacts;

const webStyles = {
    commonfontweight: {
      fontWeight:700,
      marginTop:'20px'
    },
    invitebutton:{
        marginTop: "25px",
        width: "130px",
    },
    invitetext:{
        margin: "10px 0 30px",
    },
    commonButton:{
        width:"140px"
    }
  };