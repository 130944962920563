import React from 'react';
import { Box,Typography,Tabs} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

export default class Credits extends React.Component {
      render() {
    return(
        <>
        <Box>
            <Paper elevation={2}>
            <Grid xs={12} style={{padding:"10px"}}>
                {
                    //@ts-ignore
                console.log("fdhgjhhdjhfd--->",this.props.ProjectInfo.attributes.credits)}
                {
                    //@ts-ignore
                    this.props.ProjectInfo.attributes.credits && this.props.ProjectInfo.attributes.credits.map((element, key) => {return(
                <>
               <div style={{display:"flex",alignItems:"center"}}>
                    <Typography variant="h4"  style={{fontWeight:500,minWidth:"200px"}}>First Name:</Typography>
                    <Typography>{element.first_name == null ? "" : element.first_name}</Typography>
                </div> 
                <div style={{display:"flex",alignItems:"center"}}>
                    <Typography variant="h4"  style={{fontWeight:500,minWidth:"200px"}}>Middle Name:</Typography>
                    <Typography>{element.middle_name == null ? "" : element.middle_name}</Typography>
                </div> 
                <div style={{display:"flex",alignItems:"center"}}>
                    <Typography variant="h4" style={{fontWeight:500,minWidth:"200px"}}>Last Name:</Typography>
                    <Typography>{element.last_name == null ? "" : element.last_name}</Typography>
                </div> 
                {
                    //@ts-ignore
                key+1 != this.props.ProjectInfo.attributes.credits.length && <hr></hr>}
                </>
                )})}
            </Grid>
            </Paper>
        </Box>
        </>
    )
}
}
