import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";

import { Dashboard, ViewProject } from "./myProjectDetails.web"

import FavouritesController, {
  Props,
  configJSON
} from "./FavouritesController";
import AppbarHeader from '../../../components/src/AppHeader.web'

export default class Favourites extends FavouritesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    let id = window.location.pathname.trim().split("/")[2]
    this.getProjectInformationData(id)
    this.getLanguageData()
    this.getProductionBudget()
    this.getRoleData()
    this.getCurrencyData()
    this.getFilmographyData();
    this.getHeadShotData(id);
    this.getPosterData(id);
   }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <AppbarHeader accountData={this.state.accountData}/>
      <Dashboard
        handleSubmitNews={this.handleSubmitNews}
        handleSubmitLinks={this.handleSubmitLinks}
        handleFileAttachLinks={this.handleFileAttachLinks}
        handleUploadFileChange={this.handleUploadFileChange}
        handleEditProject={this.handleEditProject}
        handleSubmitTrailer={this.handleSubmitTrailer}
        handleUpdatePhotos={this.handleUpdatePhotos}
        handleScanner={this.handleScanner}
        handlePosterImg={this.handlePosterImg}
        handlePhotoImg={this.handlePhotoImg}
        handleStillPhoto={this.handleStillPhoto}
        handleScennerVid={this.handleScennerVid}
        handleSubtitleFiles={this.handleSubtitleFiles}
        handleHeadshotSubmit={this.handleHeadshotSubmit}
        deleteVideo={this.deleteVideo}
        restoreVideo={this.restoreVideo}
        getProjectInformationData={this.getProjectInformationData}
        deleteAccountPhotos={this.deleteAccountPhotos}
        // handleEditSubmitProject={this.handleEditSubmitProject}
        ProjectInfo={this.state.ProjectInfo}
        currencyData={this.state.currencyData}
        roleData={this.state.roleData}
        languageData={this.state.languageData}
        budgetData={this.state.budgetData}
        filmographyData={this.state.filmographyData}
        headshotData={this.state.headshotData}
        posterData={this.state.posterData}
        loading={this.state.loading}
        loaders={this.state.loaders}
        Videovisible={this.state.Videovisible}
        VideoFlag={this.state.VideoFlag}
        imageloader={this.state.imageloader}
         />
         
        {console.log("ProjectInfo9090909-->", typeof this.state.ProjectInfo?.attributes?.online_screener?.data?.attributes)}
        {console.log(this.state.languageData)}
        {console.log("=========================>",this.state.budgetData)}
        {console.log("this.state.flag-->",this.state.VideoFlag)}
        {console.log("this.state.loaders-->",this.state.loaders)}

      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1
  },
  showHide: {
    alignSelf: "center"
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {}
});
// Customizable Area End
