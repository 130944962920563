import React from "react";

import {
  Typography,
  Button,
  Grid,
  Box,
  Theme,
  createStyles,
  AppBar,
  Toolbar,
  Container
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { withRouter } from "react-router";
import { logo } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { url } from "inspector";

import ResponsiveAppBar from "./header.web"
import StepperView from "./stepper.web"
import AddProjectController from "./AddProjectController";

export default class AddProject extends AddProjectController {
  constructor(props: any) {
    super(props);
  }


  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <ResponsiveAppBar />
        <StepperView />
      </>
      // Customizable Area End
    );
  }
}

const wenStyles = {
    appBar: {
      borderBottom: `1px solid #ccc`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#330a4c",
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: 1,
    },
    navBarButtons: {
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '18px',
      textTransform: 'capitalize'

    },
    landingText:{
      fontSize:'42px',
      color: '#fff',
      fontWeight: 'bold',
    }
  }

