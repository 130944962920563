import * as React from 'react';

import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  makeStyles, 
  Modal,
  Paper,
  ListItem,
  List,
  Container
} from "@material-ui/core";
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import CoverImg from './CoverImg.web'
import Privacy from './Privacy.web';
import ContactInfo from './ContactInfo.web';
import MovieGold from './MovieGold.web';
import Award from './Award.web';
import AboutMe from './AboutMe.web';



const EditProfile = ({
    handleSubmitProject,
    awardData,
    updateAwardsAndFilmography,
    userData,
    accountData,
    handleProfilePic,
    handleCoverPic
}: any) => { 
  const [selectedTab, SetSelectedTab] = React.useState(1);

  const handleTabChange = (value:number) => {
    SetSelectedTab(value)
  }
    return(
        <>
        <CoverImg accountData={accountData} handleProfilePic={handleProfilePic} handleCoverPic={handleCoverPic}/>
        <Box style={{background:"rgb(235, 236, 237)"}}>
          <Container>
              <Box sx={webStyles.bottomSection} >
                  <Box sx={webStyles.bottomLeft}>
                      <List disablePadding>
                          <ListItem divider style={{ background: selectedTab === 1 ? "#320b4c" : "#fff",  }}>
                              <Button fullWidth
                                  onClick={() => handleTabChange(1)}
                                  style={{ color: selectedTab === 1 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                                  About Me
                              </Button>
                          </ListItem>
                          <ListItem divider style={{ background: selectedTab === 2 ? "#320b4c" : "#fff" }}>
                              <Button 
                                  fullWidth 
                                  onClick={() => handleTabChange(2)}
                                  style={{ color: selectedTab === 2 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                                  Contact Info
                              </Button>
                          </ListItem>
                          <ListItem divider style={{ background: selectedTab === 3 ? "#320b4c" : "#fff" }}>
                              <Button 
                                  fullWidth 
                                  onClick={() => handleTabChange(3)}
                                  style={{ color: selectedTab === 3 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                                  Movie Expressway Gold
                              </Button>
                          </ListItem>
                          <ListItem divider style={{ background: selectedTab === 4 ? "#320b4c" : "#fff" }}>
                              <Button 
                                  fullWidth 
                                  onClick={() => handleTabChange(4)}
                                  style={{ color: selectedTab === 4 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                                  Filmography & Awards
                              </Button>
                          </ListItem>
                          <ListItem divider style={{ background: selectedTab === 5 ? "#320b4c" : "#fff" }}>
                              <Button 
                                  fullWidth 
                                  onClick={() => handleTabChange(5)}
                                  style={{ color: selectedTab === 5 ? "#fff" : "#000", justifyContent: "start", textTransform: 'capitalize' }}>
                                  Privacy
                              </Button>
                          </ListItem>
                      </List>
                  </Box>
                    {selectedTab === 1 ?
                        <AboutMe 
                            handleSubmitProject={handleSubmitProject}
                            accountData={accountData}
                            // handleAccountSettings={this.handleAccountSettings}   
                            // accountData={this.state.accountData}
                            // currencyData={this.state.currencyData}
                            // deleteAccount={() => this.deleteAccount()}
                            /> :
                            selectedTab === 2 ?
                            <ContactInfo 
                                handleSubmitProject={handleSubmitProject}
                                userData={userData}
                                /> : 
                            selectedTab === 3 ?
                            <MovieGold /> :
                            selectedTab === 4 ?
                            <Award 
                                awardData={awardData}
                                updateAwardsAndFilmography={updateAwardsAndFilmography}
                             /> :
                            selectedTab === 5 ?
                            <Privacy/> :
                            "" }
                  </Box>
            </Container>
          </Box>
        </>
    )
}

export default EditProfile;

const webStyles = {
  landingText: {
      fontSize: "42px",
      fontWeight: 600,
  },
  headSection: {
      display: "flex",
      justifyContent: "space-between"
  },
  headLeft: {
      display: "flex",
      alignItems: "center"
  },
  avatarSection: {
      position: "relative",
      top: "-50px"
  },
  avatar: {
      height: "150px",
      width: "150px",
      borderRadius: "50%"
  },
  headContent: {
      display: "flex",
      alignItems: "end",
      marginLeft: "50px"
  },
  bottomSection: {
      display: "flex",
      justifyContent: "space-between",
      padding: "50px 0px"
  },
  bottomLeft: {
      background: "#fff",
      width: '22%',
      height: "fit-content"
  },
  cameraPhoto: {
      position: 'absolute',
      top: '135px',
      right: '66px'
  }
}
