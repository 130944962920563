import React from 'react';
import { Box, Container,FormControl,TextField,FormControlLabel,Radio,RadioGroup, Button,Typography,Modal,Paper,Grid,FormLabel,CircularProgress } from '@material-ui/core';
import { makeStyles,withStyles} from '@material-ui/core/styles';
import { Formik } from "formik";
import { stepTwoSchema, customSubmissionInitialValue } from "./validationSchemas"
import CustomSubmissionController, {
    Props
  } from "./CustomSubmissionController";
  import "./style.css"

class CustomSubmissionForm extends CustomSubmissionController{

    useStyle = makeStyles((theme:any) => ({
        root: {
            flexGrow: 1,
            backgroundColor:"#F2F3F5",
            "& .MuiInputBase-input": {
                height: "1.5em",
                backgroundColor:"#fff"
              }
            // padding: theme.spacing(2),
        },
        paperstyle: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
              margin: theme.spacing(1),
              width: "100%",
              height: "100%",
            },
        },
        inputClass: {
            "& .Mui-focused:after": {
              border: "1px solid green",
            },
          },
          icon: {
            color: "#a5259f",
          },
          selectbox: {
            "& .MuiInputBase-input": {
              height: "1.5em",
            }
          },
          card: {
            padding: theme.spacing(1),
            marginTop: '20px',
            zIndex: 1,
            borderRadius: '5px',
            width: '80%',
            marginLeft: '10%',
        },
    }));

    render() {
        const classes = this.useStyle
        const {field_category, submissionData, loader} = this.state
    return(
        <Formik
        enableReinitialize
        initialValues={customSubmissionInitialValue}
        // validationSchema={stepOneSchema}
        onSubmit={(values, actions) => {
          this.handleSubmitEvent(values,field_category)
        }}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {props => (
        <form onSubmit={props.handleSubmit}>
            {console.log("abc-->",submissionData)}
        <Container style={{paddingLeft:"30px", position:"absolute"}}>
        {loader ? 
        <div style={{display:"flex", justifyContent:"center", alignItems:"center", position:'relative', minHeight:'600px'}}>
            <CircularProgress />
            </div> :
            <>
            <Box style={{padding:"0px 0px 0px 7px"}}>
                <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem",marginBottom:"10px"}}>Custom Submission Form</Typography>
            </Box>
            <Box style={{ display: 'flex',flexWrap: 'wrap'}}>
                <Paper style={{padding:"15px 15px 30px 15px"}}>
                    <Typography variant="h4" style={{marginBottom:'10px',fontWeight:800}}>This option allows your festival to require additional, custom information from all submitters.</Typography>
                    <Paper style={{padding:"15px 15px 15px 15px", background:"#F8F8F8"}}>
                        <Typography style={{fontWeight:800}}>
                            Important: <span style={{fontWeight:500}}>Before creating a custom form, please review this list of information that FilmFreeway already collects by default.</span>
                        </Typography>
                        <Typography variant="h4" style={{margin:'15px 0px 10px 0px',fontWeight:800}}>Please do not request any information that we already collect for you.</Typography>
                    </Paper>
                    <Typography variant="h4" style={{margin:'20px 10px 30px 10px',fontWeight:500}}>Submitters will be required to answer any questions you add below.
                         You can access this information under the Custom Form tab on the details page of any submission.</Typography>
                         {submissionData.map((label: any, index: any) => {
                            return (
                            <Paper key={label.id} >
                                <Paper style={{padding:"15px"}}>
                                   <Typography style={{fontSize:"1rem", fontWeight:600}}>{label.attributes.title_question}</Typography>
                                    {label.attributes.field_type}
                                </Paper>
                            </Paper>
                            );
                        })}
                         {/* {submissionData.map((element,number) =>
                            <li key={number}></li> )
                            return (
                                <ul>{element.id}</ul>
                              );
                            )} */}
                    <Paper style={{padding:"15px",background:"#F8F8F8"}}>
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={6} style={{marginBottom:"25px"}}>
                            <Typography variant="h4" style={{marginTop:'20px',fontWeight:800}}>Select a Field Type</Typography>
                                <FormControl  style={{width: '100%'}}>
                                    <TextField
                                        id="standard-select-currency-native"
                                        select
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.field_type}
                                        // InputProps={{ className: classes.selectbox}}
                                        SelectProps={{
                                            //@ts-ignore
                                            classes: { icon: classes.icon },
                                            native:true
                                        }}
                                        variant="standard"
                                        name="field_type"
                                        //   value={initialValues.gender}
                                        >
                                        <option value={""}>select from option</option>
                                        <option value={'text_single_line'}>Test (single line answer)</option>
                                        <option value={'text_paragraph'}>Test (paragraph answer)</option>
                                        <option value={'yes_or_no'}>Yes or No</option>
                                        <option value={'dropdown_menu'}>Dropdown Menu</option>
                                        <option value={'checkboxes'}>Checkboxs</option>
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h4" style={{marginTop:'20px',fontWeight:800}}>Title / Question</Typography>
                                <FormControl style={{width:"100%"}}>
                                    <TextField  
                                        id="outlined-disabled"
                                        placeholder="Ex. Did you attend film school?"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.title_question}
                                        //@ts-ignore
                                        InputProps={{ className: classes.root }}
                                        name="title_question"
                                        // value={initialValues.facebook_link}
                                        //@ts-ignore  
                                        className={classes.inputClass}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <RadioGroup 
                            aria-label="field_category" 
                            name="field_category" 
                            onChange={this.handleSelect}
                            // onBlur={props.handleBlur}
                            value={this.state.field_category}
                            >
                           <Grid container spacing={6}>
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel value="require_all" control={<Radio size="small" color="primary"/>} label="Require for all categories" />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                 <FormControlLabel value="require_specific" control={<Radio size="small" color="primary"/>} label="Require for specific categories only" />
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </Paper>
                </Paper>
            </Box>
            <Box style={{margin:"30px 0px 0px 0px"}}>
                <Button color="primary" type="submit">SAVE QUESTION</Button>
            </Box>
            </>
        }
        </Container>
         </form>
        
         )}
        </Formik>
    )
}
}

export default CustomSubmissionForm;