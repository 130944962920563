export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const awardIcon = require("../assets/award-img.png")
export const selectIcon = require("../assets/select-img.png")
export const DeleteIcon = require("../assets/delete-img.png")
export const ViewIcon = require("../assets/view-img.png")
export const EditIcon = require("../assets/edit-img.png")
export const PrivacyIcon = require("../assets/privacy-img.png")
// export const defaultproject = require("../assets/defaultproject.jpg")

export const shareIcon = require("../assets/shareIcon.png")
export const facebookIcon = require("../assets/facebookIcon.png")
export const twitterIcon = require("../assets/twitterIcon.png")
export const webIcon = require("../assets/webIcon.png")
export const emailIcon = require("../assets/emailIcon.png")
export const locationPinIcon = require("../assets/locationPinIcon.png")
export const phoneIcon = require("../assets/phoneIcon.png")
export const examplePhoto = require("../assets/ExamplePhoto.png")

export const defaultAvatar = require("../assets/defaultAvatar.png")
export const movie_expressway = require ("../assets/movie_expressway.png")
export const movie_expresswayBig = require ("../assets/movie_expressway_big.png")
export const moview_expresswaybg = require ("../assets/festival_profile-pattern_bg.gif")

export const defaultBackground = require ("../assets/background.jpg")
export const defaultProfile = require ("../assets/profileimg.gif")
export const noTrascation = require ("../assets/notranscation.png")

export const flagBlue = require ("../assets/flagBlue.png")
export const flagGreen = require ("../assets/flagGreen.png")
export const flagPurple = require ("../assets/flagPurple.png")
export const flagRed = require ("../assets/flagRed.png")
export const flagYellow = require ("../assets/flagYellow.png")
export const searchIcon = require ("../assets/searchIcon.png")
export const filterLogo = require("../assets/filterLogo.png")
export const defaultproject = require("../assets/noImage.png");




