// Customizable Area Start
import React,{Component} from 'react';
import ResponsiveAppBar from "../../../components/src/AppHeader.web";
import Footer from "../../../components/src/AppFooter.web";
import {Box, Container, makeStyles, Grid, Typography, Button,Divider} from "@material-ui/core";
import {exampleLaurel,laurelSelectionPlaceHolder} from "./assets";

class LaurelEditor extends Component {
    render() {
        return (
            <>
                <ResponsiveAppBar
                    // @ts-ignore
                    accountData={{}}
                />
                <Box sx={{bgcolor: "#ebeced"}}>
                    <Container>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box sx={webStyles.bottomRight}>
                                    <Box sx={webStyles.formCard}>
                                        <Box sx={{bgcolor: "#320b4c", padding: "10px"}}>
                                            <Typography variant="h4" style={{ color: "#FFF"}} >Create a Laurel</Typography>
                                        </Box>
                                        <Box sx={{padding: "20px"}}>
                                            <Box sx={{display: "flex"}}>
                                                <Box sx={{padding: "20px", flexGrow: 1  }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={6} >
                                                            <Typography variant="h6" component="legend" style={{fontWeight:'bold'}}>Choose a Laurel Style</Typography>
                                                            <img src={laurelSelectionPlaceHolder} height="300px" width="450px"/>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} style={{display:'flex',flexDirection:'column',alignItems:'center'}} >
                                                            <img src={exampleLaurel} height="300px" width="450px"/>
                                                        </Grid>
                                                        <Divider/>
                                                        <Grid item xs={12} >
                                                            <Typography variant="body1" component="legend" style={{marginTop:"5px",fontWeight:"bold"}}>This is an example laurel. This image will disappear once you upload or create your first laurel.</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Button variant="contained" style={{ background: "purple", color: "#fff", marginBottom: "100px",fontSize:"20px"}}>CREATE LAUREL</Button>
                        </Grid>
                    </Container>
                </Box>
                <Footer />
            </>
        );
    }
};

export default LaurelEditor;

const webStyles = {
    main: {
        display: "flex",
        justifyContent: "center",
        height: "100px",
        background: "000"
    },
    bottomRight: {
        display: "flex",
        flexDirection: "column",
        width: '100%',
        marginTop:"50px"
    },
    formCard: {
        background: "#fff",
        marginBottom: "30px"
    }
}
// Customizable Area End