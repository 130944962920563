// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Grid,
  CardContent,
  Card,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";
import WorldMap from "react-svg-worldmap";
import UploadFileIcon from "@material-ui/icons/FileCopy";
import { tickets, submission } from "./assets";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

const MiddleCard = () => {
  const data = [
    { country: "in", value: 10 }, // india
    { country: "us", value: 10 }, // united states
    { country: "id", value: 10 }, // indonesia
    { country: "br", value: 10 }, // brazil
    { country: "ng", value: 10 }, // nigeria
    { country: "ru", value: 10 }, // russia
    { country: "mx", value: 10 }, // mexico
  ];

  const chartData = [
    {
      name: "Jan",
      sales: 30,
    },
    {
      name: "Feb",
      sales: 20,
    },
    {
      name: "Mar",
      sales: 10,
    },
    {
      name: "Apr",
      sales: 20,
    },
    {
      name: "May",
      sales: 30,
    },
    {
      name: "Jun",
      sales: 40,
    },
  ];
  return (
    <>
      <Box style={{ backgroundColor: "#fff", marginBottom: "20px" }}>
        <div style={webStyles.cardTopLink}>
          <div style={webStyles.headerTop}>Current Season</div>
          <a href="#" style={webStyles.cardLink}>
            View all
          </a>
        </div>
        <Card style={webStyles.card}>
          <CardContent>
            <LineChart
              width={630}
              height={300}
              data={chartData}
              margin={{
                top: 15,
                right: 30,
                left: -20,
                bottom: 5,
              }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="sales"
                stroke="#CB80C8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                padding: "10px 0",
                textAlign: "left",
              }}
            >
              Movie Expressway
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5px 0",
              }}
            >
              <div style={{ fontSize: "16px" }}>Gross sales</div>
              <div style={{ fontSize: "16px", fontWeight: "bold" }}>$0.00</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5px 0",
              }}
            >
              <div style={{ fontSize: "16px" }}>Film Freeway (6%)</div>
              <div style={{ fontSize: "16px", fontWeight: "bold" }}>$0.00</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5px 0",
              }}
            >
              <div style={{ fontSize: "16px" }}>Payment Processing</div>
              <div style={{ fontSize: "16px", fontWeight: "bold" }}>$0.00</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5px 0",
              }}
            >
              <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                Net Sales
              </div>
              <div style={{ fontSize: "16px", fontWeight: "bold" }}>$0.00</div>
            </div>
          </CardContent>
        </Card>
      </Box>
      <Box style={{ marginBottom: "20px" }}>
        <div style={webStyles.cardTopLink}>
          <div style={webStyles.headerTop}>Submission</div>
          <div style={webStyles.headerTop}>Account balance $0.00</div>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={webStyles.submissionsHeader}>Date</TableCell>
                <TableCell style={webStyles.submissionsHeader} align="center">
                  Method
                </TableCell>

                <TableCell style={webStyles.submissionsHeader} align="center">
                  Status
                </TableCell>
                <TableCell style={webStyles.submissionsHeader} align="right">
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableRow>
              <TableCell align="center" colSpan={4} style={webStyles.noData}>
                Your Payouts will show up here
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Box>

      <Box style={{ marginBottom: "20px" }}>
        <div style={webStyles.cardTopLink}>
          <div style={webStyles.headerTop}>Judging Insights</div>
        </div>
        <Card style={webStyles.card}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={2} sm={4} md={4}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={submission}
                    alt="submission"
                    style={{ padding: "15px" }}
                  />
                  <Divider orientation="vertical" flexItem />
                  <div>
                    <div style={webStyles.submissionsNumber}>0</div>
                    <div style={webStyles.submissionsText}>
                      Submissions Judged
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <UploadFileIcon style={webStyles.fileIcon} /> */}
                  <img
                    src={submission}
                    alt="submission"
                    style={{ padding: "15px" }}
                  />
                  <Divider orientation="vertical" flexItem />
                  <div>
                    <div style={webStyles.submissionsNumber}>0</div>
                    <div style={webStyles.submissionsText}>
                      Submissions Not Judged
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={submission}
                    alt="submission"
                    style={{ padding: "15px" }}
                  />
                  <Divider orientation="vertical" flexItem />
                  <div>
                    <div style={webStyles.submissionsNumber}>0%</div>
                    <div style={webStyles.submissionsText}>
                      Percentage Judged
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div style={webStyles.grayText}>
              Judging data will display here when you{" "}
              <a href="#" style={{color: "#1F5DE2"}}>add judges to your account</a>
            </div>
            <Button style={webStyles.submissionsButton}>SUBMISSIONS</Button>
          </CardContent>
        </Card>
      </Box>

      <Box style={{ marginBottom: "20px" }}>
        <div style={webStyles.cardTopLink}>
          <div style={webStyles.headerTop}>Tickets</div>
        </div>
        <Card style={webStyles.card}>
          <CardContent>
            {/* <UploadFileIcon
              style={{ fill: "#c1c1c1", height: "60px", width: "60px" }}
            /> */}
            <img
              style={{ width: "100px", marginTop: "25px" }}
              src={tickets}
              alt="logo"
            />
            <div style={webStyles.grayTextTickets}>
              Ticket data will display here when you{" "}
              <a href="#">begin selling tickets.</a>
              <div>
                {" "}
                <a href="#">Movie Expressway</a> members can sell tickets for
                free!
              </div>
            </div>
          </CardContent>
        </Card>
      </Box>

      <Box style={{ marginBottom: "20px" }}>
        <div style={webStyles.cardTopLink}>
          <div style={webStyles.headerTop}>Submissions current season</div>
        </div>
        <div style={webStyles.worldmap}>
          <WorldMap
            color="#BC2BB5"
            value-suffix="people"
            size="lg"
            data={data}
            backgroundColor="#fff"
          />
        </div>
      </Box>

      <Box style={{ marginBottom: "20px" }}>
        <div style={webStyles.cardTopLink}>
          <div style={webStyles.headerTop}>
            Entries By Category (Current Season)
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={webStyles.categoryHeader}>Category</TableCell>
                <TableCell style={webStyles.categoryHeader} align="center">
                  Percentage
                </TableCell>
                <TableCell style={webStyles.categoryHeader} align="right">
                  Entries
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell />{" "}
                <TableCell style={webStyles.noData}>No Data found</TableCell>
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default MiddleCard;

const webStyles = {
  worldmap: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff",
  },
  card: {
    borderRadius: "12px",
    minWidth: 256,
    textAlign: "center" as const,
    width: "100%",
  },
  heading: {
    fontSize: 18,
    fontWeight: 700,
    letterSpacing: "0.5px",
    marginTop: 8,
    marginBottom: 0,
  },
  noData: {
    padding: "40px 20px",
    textAlign: "center" as const,
    color: "#B9B9B9",
    fontSize: "18px",
  },
  grayText: {
    padding: "40px 20px",
    textAlign: "center" as const,
    color: "#c1c1c1",
    fontSize: "16px",
  },
  grayTextTickets: {
    padding: "40px 20px 0",
    textAlign: "center" as const,
    color: "#c1c1c1",
    fontSize: "16px",
  },
  fileIcon: {
    fill: "#BC2BB5",
    height: "70px",
    width: "70px",
    padding: "10px",
  },
  submissionsNumber: {
    fontSize: "26px",
    fontWeight: 700,
    color: "#333",
    textAlign: "left" as const,
    paddingLeft: "10px",
  },
  submissionsText: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#333",
    textAlign: "left" as const,
    paddingLeft: "10px",
    paddingTop: "10px",
  },

  headerTop: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#2B282E",
    paddingBottom: "10px",
  },
  submissionsHeader: {
    width: "25%",
    color: "#2B282E",
    fontSize: "16px",
    fontWeight: 700,
  },
  categoryHeader: {
    width: "33.3%",
    color: "#2B282E",
    fontSize: "16px",
    fontWeight: 700,
  },
  cardLink: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#ccc",
    cursor: "poniter",
  },
  cardTopLink: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F2F3F5",
  },
  submissionsLeft: {
    justifyContent: "start",
    width: "100%",
    fontSize: "16px",
    fontWeight: 600,
  },
  submissionsRight: {
    fontSize: "16px",
    fontWeight: 600,
  },
  submissionsButton: {
    border: "2px solid #BC2BB5",
    color: "#333",
    padding: "10px 40px",
    borderRadius: "7px",
  },
};
// Customizable Area End
