// Customizable Area Start
import React from 'react';
import { Box, Tab, Tabs, Button, Typography, Modal, Paper, Grid, FormLabel, CircularProgress, IconButton, Divider, InputBase } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { flagBlue, flagGreen, flagPurple, flagRed, flagYellow, searchIcon } from "./assets"
import "./style.css"

class CustomFlag extends React.Component {

    render() {
        return (
            <>
            <Box className='componentWrapper'>
                <div className='formHeading'>Custom Flags</div>
                <Box className='formInner'>
                    <h3 className="formHeader">Create custom labels for your flags to make managing submissions clearer and easier.</h3>

                    <Paper
                        component="form"
                        className='flagRow'
                    >
                        <div className='flagImageWrapper'>
                            <img src={flagRed} alt={flagRed} />
                        </div>
                        <Divider orientation="vertical" flexItem className="flagDivider" />
                        <InputBase
                            placeholder="Flag Label"
                            inputProps={{ 'aria-label': 'Flag Label' }}
                            className="flagLabel"
                        />
                        <Divider orientation="vertical" flexItem className="flagDivider" />
                        <div className='flagImageWrapper searchImage'><img src={searchIcon} alt={searchIcon} /></div>

                        <button className='disableButton'>Disable</button>
                        <button className='deleteButton'>Delete</button>
                    </Paper>

                    <Paper
                        component="form"
                        className='flagRow'
                    >
                        <div className='flagImageWrapper'>
                            <img src={flagYellow} alt={flagYellow} />
                        </div>
                        <Divider orientation="vertical" flexItem className="flagDivider" />
                        <InputBase
                            placeholder="Flag Label"
                            inputProps={{ 'aria-label': 'Flag Label' }}
                            className="flagLabel"
                        />
                        <Divider orientation="vertical" flexItem className="flagDivider" />
                        <div className='flagImageWrapper searchImage'><img src={searchIcon} alt={searchIcon} /></div>

                        <button className='disableButton'>Disable</button>
                        <button className='deleteButton'>Delete</button>
                    </Paper>

                    <Paper
                        component="form"
                        className='flagRow'
                    >
                        <div className='flagImageWrapper'>
                            <img src={flagGreen} alt={flagGreen} />
                        </div>
                        <Divider orientation="vertical" flexItem className="flagDivider" />
                        <InputBase
                            placeholder="Flag Label"
                            inputProps={{ 'aria-label': 'Flag Label' }}
                            className="flagLabel"
                        />
                        <Divider orientation="vertical" flexItem className="flagDivider" />
                        <div className='flagImageWrapper searchImage'><img src={searchIcon} alt={searchIcon} /></div>

                        <button className='disableButton'>Disable</button>
                        <button className='deleteButton'>Delete</button>
                    </Paper>

                    <Paper
                        component="form"
                        className='flagRow'
                    >
                        <div className='flagImageWrapper'>
                            <img src={flagBlue} alt={flagBlue} />
                        </div>
                        <Divider orientation="vertical" flexItem className="flagDivider" />
                        <InputBase
                            placeholder="Flag Label"
                            inputProps={{ 'aria-label': 'Flag Label' }}
                            className="flagLabel"
                        />
                        <Divider orientation="vertical" flexItem className="flagDivider" />
                        <div className='flagImageWrapper searchImage'><img src={searchIcon} alt={searchIcon} /></div>

                        <button className='disableButton'>Disable</button>
                        <button className='deleteButton'>Delete</button>
                    </Paper>

                    <Paper
                        component="form"
                        className='flagRow'
                    >
                        <div className='flagImageWrapper'>
                            <img src={flagPurple} alt={flagPurple} />
                        </div>
                        <Divider orientation="vertical" flexItem className="flagDivider" />
                        <InputBase
                            placeholder="Flag Label"
                            inputProps={{ 'aria-label': 'Flag Label' }}
                            className="flagLabel"
                        />
                        <Divider orientation="vertical" flexItem className="flagDivider" />
                        <div className='flagImageWrapper searchImage'><img src={searchIcon} alt={searchIcon} /></div>

                        <button className='disableButton'>Disable</button>
                        <button className='deleteButton'>Delete</button>
                    </Paper>

                    <Button className="addButton">Add Flag</Button>
                </Box>
                <Box>
                    <Button color="primary" className="saveButton">SAVE</Button>
                </Box>
            </Box>
            </>
        )
    }
}
export default CustomFlag;
// Customizable Area End