import React, { useState } from 'react';
import { Box, Tab,Typography,Tabs, TextField, FormControl, Input,Modal,FormLabel,CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        // padding: theme.spacing(2),
    },
    cardHeightViewProject: {
        height: '100%'
    },
    cardViewProject: {
        zIndex: 1,
        borderRadius: '5px',
        width: '100%',
    },
    Specifications:{
        display:"flex",
        alignItems:"center"
    },
    fontspacings:{
        fontWeight:500,
        minWidth:"200px"
    }
}));

const Specification = ({
    ProjectInfo
}:any) => {

    const classes = useStyles();
    return(
        <>
        {console.log("ProjectInfo",ProjectInfo)}
        <Box>
            <Paper elevation={2}>
            <Grid xs={6} style={{padding:"10px"}}>
               <div className={classes.Specifications}>
                    <Typography variant="h4"  className={classes.fontspacings}>Runtime:</Typography>
                    <Typography>{ProjectInfo.attributes.specification.data.attributes.runtime == null ? "" : moment(ProjectInfo.attributes.specification.data.attributes.runtime).utc().format('HH:mm:ss')}</Typography>
                </div> 
                <div className={classes.Specifications}>
                    <Typography variant="h4"  className={classes.fontspacings}>Film Color:</Typography>
                    <Typography>{ProjectInfo.attributes.specification.data.attributes.film_color == null ? "" : ProjectInfo.attributes.specification.data.attributes.film_color}</Typography>
                </div> 
                <div className={classes.Specifications}>
                    <Typography variant="h4" className={classes.fontspacings}>First-time Filmmaker:</Typography>
                    <Typography>{ProjectInfo.attributes.specification.data.attributes.first_time_filmmaker == true ? "Yes" : ProjectInfo.attributes.specification.data.attributes.first_time_filmmaker == false ? "No" : ""}</Typography>
                </div> 
                <div className={classes.Specifications}>
                    <Typography variant="h4"  className={classes.fontspacings}>Student Project:</Typography>
                    <Typography>{ProjectInfo.attributes.specification.data.attributes.student_project == true ? "Yes" : ProjectInfo.attributes.specification.data.attributes.student_project == false ? "No" : ""}</Typography>
                </div> 
            </Grid>
            </Paper>
        </Box>
        </>
    )
}

export default Specification;