import React from 'react';
import { Box,Container, Button,Typography,Modal,Paper,Grid,CircularProgress } from '@material-ui/core';
import { makeStyles,withStyles} from '@material-ui/core/styles';
import {noTrascation} from './assets';

class Transactions extends React.Component {

    render() {
    return(
        <Container style={{paddingLeft:"30px"}}>
            <Box style={{padding:"0px 0px 0px 7px"}}>
                <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem",marginBottom:"10px"}}>Transactions</Typography>
            </Box>
            <Box style={{flexWrap: 'wrap'}}>
                <Paper style={{padding:"15px 15px 40px 15px",textAlign:"center"}}>
                    <img src={noTrascation} style={{height:"160px", width:"160px"}}></img>
                    <Typography variant="h2" style={{marginBottom:'10px',fontWeight:700}}>No Transactions</Typography>
                    <Typography variant="h4" style={{fontWeight:500}}>Your festival currently has no transactions.</Typography>
                </Paper>
            </Box>
        </Container>
    )
}
}

export default Transactions;