import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// import StorageProvider from "../../../framework/src/StorageProvider.web";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any;
  // Customizable Area End
}

interface S {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  txtInputValue: string;
  txtSavedValue: string;
  txtOTP: string;
  enableField: boolean;
  loginOpen: boolean;
  signupOpen: boolean;
  userRole: string;
  errorMessage: any;
  successMessage: any;
  opens:boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  navigation: any;
  errorMessage: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class LandingPage2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  loginApiEmailCallId: String = "";
  signupApiEmailCallId: String = "";
  signupApiEmailCallIdEmail: String = "";
  loginApiSocialEmailCallId : String = "";
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // getName(MessageEnum.AlertMessage),
      // Customizable Area Start
      getName(MessageEnum.PostDetailDataMessage),
      // Customizable Area End
    ];

    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      txtInputValue: "",
      txtSavedValue: "A",
      txtOTP: "",
      enableField: false,
      loginOpen: false,
      signupOpen: false,
      userRole: "submitter",
      errorMessage: "",
      successMessage: "",
      opens:false,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if(getName(MessageEnum.PostDetailDataMessage) === message.id){

    }

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.loginApiEmailCallId) {
        console.log('login-enter')
        // this.props.hideLoader();
          if (responseJson && responseJson?.meta && responseJson?.meta?.token) {       
            const token = responseJson.meta.token;
            localStorage.setItem('token', token)
            localStorage.setItem('usertoken', responseJson.account.data.attributes.user_role)
            localStorage.setItem('profile',JSON.stringify(responseJson.account.data.attributes))
            // await StorageProvider.remove("agancyToken");
            // await StorageProvider.remove("adminToken");
            // await StorageProvider.set("authToken",token);
            localStorage.setItem("authToken",token)
            this.setState({
              firstname: "",
              lastname: "",
              email: "",
              password: "",
              errorMessage: "",
              successMessage: responseJson.meta.message,
              // loginOpen:true
            })
            // this.handleClickOpen()
            setTimeout(() => {
              this.setState({
                errorMessage: "",
                successMessage: "",
              })
              if(localStorage.getItem('usertoken') == "submitter"){
                //@ts-ignore Login
                this.props.history.push("/CustomForm2")
              }else{
                //@ts-ignore Login
                this.props.history.push("/myFestival/List")
              }
            }, 1000)

          } else {
            this.setState({
              errorMessage: responseJson.errors[0]['failed_login'],
              successMessage: ""
            })
            // this.parseApiErrorResponse(responseJson);
          } 
      }
      if (apiRequestCallId === this.signupApiEmailCallId) {
        if (responseJson && responseJson?.meta && responseJson?.meta?.token) {  
          const token = responseJson.meta.token;
          // await StorageProvider.set("authToken",token);
            localStorage.setItem("authToken",token)
            localStorage.setItem('token', token)
            localStorage.setItem('usertoken', responseJson.data.attributes.user_role)
            localStorage.setItem('profile',JSON.stringify(responseJson.data.attributes))
          console.log('signup-enter', this.state.loginOpen)

          this.setState({
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            errorMessage: "",
            successMessage: responseJson.meta.message,
          })
          if(responseJson.meta.message === "Login Successfully"){
            setTimeout(() => {
              this.setState({
                errorMessage: "",
                successMessage: "",
              })
              if(localStorage.getItem('usertoken') == "submitter"){
                this.props.history.push("/CustomForm2")
              }else{
                //@ts-ignore Signup
                this.props.history.push("/EventRegistration/add")
              }
            }, 1000)
          }else{
            this.toggleSignupModal()
          }
        } else {
          console.log('signup error', responseJson)
          if(responseJson.errors){
            this.setState({
              errorMessage: responseJson.errors[0]['email'],
              successMessage: ""
            })
          }
          // this.parseApiErrorResponse(responseJson);
        } 
      }
      if (apiRequestCallId === this.signupApiEmailCallIdEmail) {
        if (responseJson && responseJson?.meta && responseJson?.meta?.token) {  
          const token = responseJson.meta.token;
          localStorage.setItem('token', token)

          localStorage.setItem("authToken",token)
          localStorage.setItem('usertoken', responseJson.data.attributes.user_role)
          // await StorageProvider.set("authToken",token);
          this.setState({
            firstname: "",
            lastname: "",
            email: "",
            password: "",
            errorMessage: "",
            successMessage: 'Signup Success',
          })
         
          setTimeout(() => {
            this.setState({
              errorMessage: "",
              successMessage: "",
            })
            if(localStorage.getItem('usertoken') == "submitter"){
              this.props.history.push("/CustomForm2")
            }else{
              //@ts-ignore Signup
              this.props.history.push("/EventRegistration/add")
            }
          }, 2000)
        } else {
          console.log('signup error', responseJson)
          if(responseJson.errors){
            this.setState({
              errorMessage: responseJson.errors,
              successMessage: ""
            })
          }
          // this.parseApiErrorResponse(responseJson);
        } 
      }
    }

    // Customizable Area Start
    // Customizable Area End

  }


  toggleLoginModal = () => {
    this.setState({
      loginOpen: !this.state.loginOpen,
      signupOpen: false,
      errorMessage: "",
      successMessage: ""
    })
  }

  toggleSignupModal = () => {
    this.setState({
      signupOpen: !this.state.signupOpen,
      loginOpen: false,
      errorMessage: "",
      successMessage: ""
    })
  }

  handleFirstnameChange = (value: string) => {
    this.setState({
      firstname: value
    })
  }

  handleLastnameChange = (value: string) => {
    this.setState({
      lastname: value
    })
  }

  handleEmailChange = (value: string) => {
    this.setState({
      email: value
    })
  }

  handlePasswordChange = (value: string) => {
    this.setState({
      password: value
    })
  }

  handleUserRole = (value: string) => {
    this.setState({
      userRole: value
    })
  }
  handleClickOpen = () => {
    this.setState({opens:true,
      loginOpen: false,
      signupOpen:false})
  ;
  };
   handleCloses = () => {
    this.setState({opens:false});
    window.location.href = "/CustomForm2"
  };
 

  handleSocialMediaSignup = (email: string, authID: any, type: string,firstName:any,lastName:any) => {
    if(email === "") {
      this.setState({
        errorMessage: "Unable to Signup"
      })
      return false;
    }else{
      let data = {
        "type": "social_account",
        "attributes": {
            "email": email,
            first_name:firstName,
            last_name :lastName,
            "user_role": 'organizer',
            "unique_auth_id": authID,
            "provider": type // [:google, :facebook]
          }
      }
      this.signupHandler(data)
    }
  }

  handleSignup = () => {
    const {firstname, lastname, email, password, userRole} = this.state;
    if(firstname === "" || lastname === "" || email === "" || password === "") {
      this.setState({
        errorMessage: "Please enter all required fields"
      })
      return false;
    }else{
      let data = {
        "type": "email_account",
        "attributes": {
          "first_name": firstname,
            "last_name": lastname,
            "email": email,
            "password": password,
            "user_role": userRole // ['submitter', 'organizer']
          }
      }
      this.signupHandlerEmail(data)
    }
  }

  signupHandlerEmail = (data: any) => {
    this.setState({
      errorMessage: "",
      successMessage: ""
    })

    let url = ''
    let httpBody = {}
    url = `${configJSON.userSignUpEndpoint}`
    httpBody = {
      data: data,
    }
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signupApiEmailCallIdEmail = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  
  signupHandler = (data: any) => {
    this.setState({
      errorMessage: "",
      successMessage: ""
    })

    let url = ''
    let httpBody = {}
    url = `${configJSON.userSignUpEndpoint}`
    httpBody = {
      data: data,
    }
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signupApiEmailCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleLogin = () => {
    if(this.state.email === "" || this.state.password === "") {
      this.setState({
        errorMessage: "Please enter all required fields"
      })
      return false;
    }
    this.setState({
      errorMessage: "",
      successMessage: ""
    })

    let url = ''
    let httpBody = {}
    // if(this.props.dataToPass.role === 'Agency') {
    //   console.log('Agency Login')
    //   url = `${configJSON.agencyLogin}`
    //   httpBody = {
    //     data: {
    //       email: this.state.email,
    //       password: this.state.password,
    //     //confirm_password: this.state.password
    //   },
    //   }
    // } else if(this.props.dataToPass.role === 'Admin') {
    //   url = `${configJSON.adminloginUrl}`
    //   httpBody = {
    //     data: {
    //       email: this.state.email,
    //       password: this.state.password,
    //     //confirm_password: this.state.password
    //   },
    //   }
    // } else {
      url = `${configJSON.userSignInEndpoint}`
      httpBody = {
        data: {
          "type": "email_account",
          "attributes": {
              "email": this.state.email,
              "password": this.state.password
            }   
        },
      }
    // }

    // this.props.showLoader();
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loginApiEmailCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Mobile Methods
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

    navigateToProject = () => {
      this.props.history.push("/Dashboard")
  }

  goToLogin = () => {
    this.props.history.push("/ForgotPassword")
  }

  // Customizable Area Start
  // Customizable Area End
  
}