// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  CardContent,
  Card,
  List,
  ListItem,
  Avatar,
  Divider,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { profile, location } from "./assets";

const LeftCard = ({ accountData }: any) => {
  const handleEdit = () => {
    window.location.replace('/EventRegistration')
  }
  return (
    <>
      <Box style={{ backgroundColor: "#fff" }}>
        <Card style={webStyles.card}>
          <CardContent style={{ padding: "0px" }}>
            <div style={webStyles.coverImage}>
              <img src= {accountData && accountData.cover_pic
                ? accountData.cover_pic
                : profile} alt="cover-image" height="80px" width="100%" style={{ objectFit: "cover"}} />
              <Avatar
                style={webStyles.avatar}
                src={accountData && accountData.profile_pic
                  ? accountData.profile_pic
                  : ""}
              >
               {(accountData && accountData.first_name && accountData.profile_pic === "") &&  accountData.first_name.slice(0,1)}</Avatar>
            </div>

            <div style={webStyles.heading}>
              {accountData ? accountData.first_name : ""}{" "}
              {accountData ? accountData.last_name : ""}
            </div>
            <img
              src={location}
              style={{ height: "12px", marginRight: "10px" }}
            />
            <span style={webStyles.subheader}>USA</span>
            <div style={webStyles.memberSince}>
              Member since{" "}
              {accountData && accountData.created_at
                ? accountData.created_at.slice(0, 4)
                : ""}
            </div>
          </CardContent>

          <Box display={"flex"}>
            <Box
              p={2}
              flex={"auto"}
              // className={borderedGridStyles.item}
            >
              <p style={webStyles.statLabel}>Event</p>
              <p style={webStyles.statValue}>0</p>
            </Box>
            <Divider light orientation="vertical" style={{height: "75px"}} />
            <Box
              p={2}
              flex={"auto"}
              // className={borderedGridStyles.item}
            >
              <p style={webStyles.statLabel}>Submit</p>
              <p style={webStyles.statValue}>0</p>
            </Box>
          </Box>
        </Card>
      </Box>

      <Box sx={webStyles.bottomSection}>
        <Box sx={webStyles.bottomLeft}>
          <List disablePadding style={webStyles.listItem}>
            <ListItem>
              <Button
                fullWidth
                style={{
                  justifyContent: "space-between",
                  textTransform: "capitalize",
                  padding: "10px 20px"
                }}
              >
                View
                <ChevronRightIcon style={{ fill: "#BC2BB5" }} />
              </Button>
            </ListItem>
            <ListItem>
              <Button
                fullWidth
                style={{
                  justifyContent: "space-between",
                  textTransform: "capitalize",
                  padding: "10px 20px"
                }}
                onClick={handleEdit}
              >
                Edit
              <ChevronRightIcon style={{ fill: "#BC2BB5" }} />
              </Button>
            </ListItem>
            <ListItem>
              <Button
                fullWidth
                style={{
                  justifyContent: "space-between",
                  textTransform: "capitalize",
                  padding: "10px 20px"
                }}
              >
                Manage
              <ChevronRightIcon style={{ fill: "#BC2BB5" }} />
              </Button>
            </ListItem>
          </List>
        </Box>
      </Box>
    </>
  );
};
export default LeftCard;

const webStyles = {
  coverImage: {
    position: "relative" as const
  },
  card: {
    borderRadius: "12px",
    minWidth: 256,
    textAlign: "center" as const,
    width: "100%",
  },
  avatar: {
    width: 80,
    height: 80,
    margin: "auto",
    position: "absolute" as const,
    top:"37px",
    left: "37%",
  },
  heading: {
    fontSize: 18,
    fontWeight: 700,
    letterSpacing: "0.5px",
    marginTop: 40,
    marginBottom: 0,
  },
  subheader: {
    fontSize: 14,
    color: "#ccc",
    marginBottom: "0.875em",
  },
  memberSince:{
    fontSize: 14,
    color: "#ACACAC",
    margin: "16px 0 10px",
  },
  statLabel: {
    fontSize: 12,
    color: "#ccc",
    fontWeight: 500,
    margin: 0,
  },
  listItem: {},
  statValue: {
    fontSize: 20,
    fontWeight: 700,
    marginTop: 8,
    marginBottom: 4,
    letterSpacing: "1px",
    color: "#BC2BB5",
  },
  landingText: {
    fontSize: "42px",
    fontWeight: 600,
  },
  headSection: {
    display: "flex",
    justifyContent: "space-between",
  },
  headLeft: {
    display: "flex",
    alignItems: "center",
  },
  avatarSection: {
    position: "relative",
    top: "-50px",
  },
  headContent: {
    display: "flex",
    alignItems: "end",
    marginLeft: "50px",
  },
  bottomSection: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 0px",
  },
  bottomLeft: {
    background: "#fff",
    width: "100%",
    height: "fit-content",
  },
  headerTop: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#333",
  },
  cardLink: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#ccc",
    cursor: "poniter",
  },
  cardTopLink: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F2F3F5",
  },
  submissionsLeft: {
    justifyContent: "start",
    width: "100%",
    fontSize: "16px",
    fontWeight: 600,
  },
  submissionsRight: {
    fontSize: "16px",
    fontWeight: 600,
  },
  submissionsButton: {
    backgroundColor: "#BC2BB5",
    color: "#fff",
    width: "100%",
    padding: "15px",
  },
};
// Customizable Area End
