// Customizable Area Start
import {IBlock} from "../../../framework/src/IBlock";
import {Message} from "../../../framework/src/Message";
import {BlockComponent} from "../../../framework/src/BlockComponent";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import {runEngine} from "../../../framework/src/RunEngine";


import {toast} from "react-toastify";
import React from "react";


export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    showHeader?:boolean;

}

interface S {
    accountAPIData:Object;
    profilepic:Object;
    previewprofile:String;
    coverpic:Object;
    coverpreview:String;
    updateType:String;
    isLoading:boolean;
    festivalDetails:Object;
    value:any;
    isModalOpen:boolean;
    isModal1Open:boolean;
    isModal2Open:boolean;
    isSharingModalOpen:boolean;
    isSubmitNowModalOpen:boolean;
    isPhotoUploading:boolean;
    id:any;

}

interface SS {
    id: any;
}

export default class FestivalViewController extends BlockComponent<
    Props,
    S,
    SS
    > {

    ProfileAndCoverPhotoApiCall:string = ""
    getFestivalDataApiCall:string = ""
    UploadPhotoApiCall:string = ""
    emailReg: RegExp;
    labelTitle: string = "";

    constructor(props: Props) {

        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.PostDetailDataMessage)
        ]

        this.state = {
            id:"",
            accountAPIData: {
                cover_pic:"",
                profile_pic:"",
                first_name:"",
                last_name:"",
                title:"",
                created_at:"2022-05-25T07:20:31.222Z"
            },
            coverpic:{cover_pic:"" as any},
            coverpreview:"",
            profilepic:{ profile_pic:"" as any},
            previewprofile:"",
            updateType:"",
            isLoading:true,
            festivalDetails:{
                event_organizers:[]
            },
            value:0,
            isModalOpen:false,
            isModal1Open:false,
            isModal2Open:false,
            isPhotoUploading:false,
            isSharingModalOpen:false,
            isSubmitNowModalOpen:false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleOpenModal1 = this.handleOpenModal1.bind(this)
        this.handleCloseModal1 = this.handleCloseModal1.bind(this)
        this.handleOpenModal2 = this.handleOpenModal2.bind(this)
        this.handleCloseModal2 = this.handleCloseModal2.bind(this)
        this.manageRouting = this.manageRouting.bind(this)
        this.handleOpenSharingModal = this.handleOpenSharingModal.bind(this)
        this.handleCloseSharingModal = this.handleCloseSharingModal.bind(this)
        this.handleOpenSubmitModal = this.handleOpenSubmitModal.bind(this)
        this.handleCloseSubmitModal = this.handleCloseSubmitModal.bind(this)

        // @ts-ignore
        this.myRef = React.createRef();
        // @ts-ignore
        this.myRef1 = React.createRef();
        this.emailReg = new RegExp("");
        this.labelTitle = configJSON.labelTitle;

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        if(newValue === 1 && this.state.value === 0){
            // @ts-ignore
            this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }else if(newValue === 2 && this.state.value === 0){
            // @ts-ignore
            this.myRef1.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }else{
            if(newValue === 0 || newValue === 3){
                this.setState({
                    value:newValue
                })
            }else{
                this.setState({
                    value:0
                })
            }
        }
    };

    async componentDidMount() {
        this.getFestivalData()
        const id = window.location.href.split("/")[5]
        if(id){
            this.setState({
                id:id
            })
        }
    }

    async receive(from: string, message: Message) {

        if(getName(MessageEnum.PostDetailDataMessage) === message.id){
            if(message.properties.text?.type === "FESTIVAL_OBJECT"){
                console.log("DID I CAME HERE ???")
                this.setState({
                    festivalDetails:message.properties.text,
                    isLoading:false
                })
            }
        }

        if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            if(this.UploadPhotoApiCall === apiRequestCallId) {
                if(responseJson?.hasOwnProperty('data')){
                    toast.success("Congratulations, Photo added successfully")
                    this.sentMessage("REFRESH_PHOTO_LIST")
                }else{
                    toast.error("Something went wrong!")
                }
                this.setState({
                    isPhotoUploading:false
                })
            }
        }
    }

    handleOpenModal() {
        this.setState({
            isModalOpen:true
        })
    }

    handleCloseModal() {
        this.setState({
            isModalOpen:false
        })
    }

    handleOpenModal1() {
        this.setState({
            isModal1Open:true
        })
    }

    handleCloseModal1() {
        this.setState({
            isModal1Open:false
        })
    }

    handleOpenModal2() {
        this.setState({
            isModal2Open:true
        })
    }

    handleCloseModal2() {
        this.setState({
            isModal2Open:false
        })
    }


    handleOpenSharingModal() {
        this.setState({
            isSharingModalOpen:true
        })
    }

    handleCloseSharingModal() {
        this.setState({
            isSharingModalOpen:false
        })
    }

    handleOpenSubmitModal(){
        this.setState({
            isSubmitNowModalOpen:true
        })
    }

    handleCloseSubmitModal (){
        this.setState({
            isSubmitNowModalOpen:false
        })
    }

    manageRouting(event: React.ChangeEvent<{}>, newValue: any) {
        if(newValue === 1){
            // @ts-ignore
            this.props.history.push(`/EventRegistration/edit/${this.state.id}`)
        }
        if(newValue === 2){
            // @ts-ignore
            this.props.history.push(`/myFestival/manage/${this.state.id}`)
        }
    }

    handleUploadImages = (e:any) => {
        if(e.target.files.length > 0){
            this.setState({
                isPhotoUploading:true
            })
            const arrayOfObj = Object.entries(e.target.files).map((e) => ( { [e[0]]: e[1] } ));
            let formData = new FormData()
            formData.append('festival_id', this.state.id)
            arrayOfObj.forEach((image:any,key) => {
                console.log("RESPONSE", image[key],image[key].name)
                formData.append("festival_photos[]", image[key],image[key].name);
            });
            this.uploadPhoto(formData)
        }else{
            toast.error("Please Select at least one Image")
        }

        // if(e.target.files[0]){
        //     this.setState({
        //         coverpic: {
        //             "cover_pic": e.target.files[0]
        //         },
        //         coverpreview:(URL.createObjectURL(e.target.files[0]) as any)
        //     });
        //     var formData = new FormData();
        //     formData.append('account[cover_pic]', e.target.files[0])
        //     this.SetProfileAndCoverPhoto(formData)
        // }else{
        //     toast.error("Error while uploading Profile Image")
        // }
    }

    getFestivalData(): boolean {
        const header = {
            "Content-Type": configJSON.festivalDataApiContentType,
            token: localStorage.getItem('authToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFestivalDataApiCall  = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.festivalDataApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalDataApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    uploadPhoto = (data: any) => {
        const token = localStorage.getItem('authToken')
        const headers = {
            token : token,
            'x-amz-acl': 'public-read'
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.UploadPhotoApiCall = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.festivalPhotoUploadApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            data
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalPhotoUploadApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true
    }

    sentMessage (data:any) {
        const msg : Message = new Message(getName(MessageEnum.PostDetailDataMessage))
        msg.properties['text'] = data
        this.send(msg)
    }
}
// Customizable Area End