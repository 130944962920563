import React, {useEffect, useState} from "react";

import {
  Typography,
  Button,
  Grid,
  Box,
  AppBar,
  Toolbar,
  Container,
  IconButton,
  Menu,
  Avatar,
  Tooltip,
  MenuItem
} from "@material-ui/core";
import {NavLink ,Link,withRouter} from 'react-router-dom';
import {RouteComponentProps} from 'react-router'
const logo = require("./logo.png");
const downArrow = require("./down-arrow.png");
const imgNotification = require("./ic_notification.png");
const noNotification = require("./no-alarm.png");
import './assets/css/header.css';
interface HeaderProps {
  toggleLoginModal?: any;
  toggleSignupModal?: any;
  accountData?: any;
  history?:any;
  match?:any;
  location?:any;
}

const pages = ['Browse Festivals', 'Dashboard', 'My Projects', 'Submissions'];

const AppHeader: React.FC<HeaderProps> = (props:any) => {
  // @ts-ignore
  const {toggleLoginModal,toggleSignupModal,accountData} = props[0]
  const {history} = props
  const [authToken, setAuthToken] = useState("")
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [userInfo,setUserInfo] = React.useState<Object>({})
  const [open, setOpen] = React.useState(false);
  const [userRole, setUserRole] = useState("")

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenNotofication = (event: React.MouseEvent<HTMLElement>) => {
    setOpen((prev) => !prev);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleDashboard = () => {
    setAnchorElNav(null);
    userRole === 'organizer' && window.location.replace('/Dashboard');
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNavigateAccountSettings = () => {
    handleCloseNavMenu();
    userRole !== 'organizer' && history.push('/UserProfileBasicBlock')
  }

  const handleNavigateMyProject = () => {
    handleCloseNavMenu();
    window.location.replace('/myProject');
  }

  const handleMyEvent = () => {
    handleCloseNavMenu();
    window.location.replace('/myFestival/List')
  }
  const handleViewProfile = ()=> {
    history.push('/Dashboard')
    // window.location.replace('/Dashboard');
  }

  const handleLogout = () => {
    localStorage.removeItem("authToken")
    localStorage.removeItem("token")
    localStorage.removeItem("usertoken")
    localStorage.removeItem("profile")
    history.push('/')
    // window.location.replace('/')
  }

  useEffect(() => {
    const token = localStorage.getItem("authToken")
    if(token){
      setAuthToken(token)
      let usersRole = localStorage.getItem('usertoken')
      if(usersRole){
        setUserRole(usersRole)
      }
      // @ts-ignore
      const userInfo = JSON.parse(localStorage.getItem('profile'))
      if(userInfo){
        setUserInfo(userInfo)
      }
    }
  }, [])

  return (
        <AppBar
          position="sticky"
          color="default"
          elevation={1}
          style={wenStyles.appBar}
        >
          <Container style={{ maxWidth:'1400px'  }} maxWidth={false} >
          {authToken ?
          <Toolbar disableGutters>
            {/*<Box style={wenStyles.toolbarLogo}>
              <img src={logo} alt="logo" style={{ width: "15%" }} />
            </Box>*/}

            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              {/*<IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>*/}
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography color="primary" >Browse Festivals</Typography>
                </MenuItem>
                {/* <MenuItem onClick={handleCloseNavMenu}>
                  <Typography color="primary" >Dashboard</Typography>
                </MenuItem> */}
                {userRole === "organizer" ? 
                 <MenuItem onClick={handleMyEvent}>
                 <Typography color="primary" >My Festival</Typography>
                </MenuItem> :  <MenuItem onClick={handleNavigateMyProject}>
                    <Typography color="primary" >My Projects</Typography>
                  </MenuItem>
                }
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography color="primary" >Submissions</Typography>
                </MenuItem>
              </Menu>
            </Box>
            <Box sx={wenStyles.toolbarLogo}>
              <img src={logo} alt="logo" />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {
                    userRole === "organizer" ?
                        <NavLink exact className="routes" activeClassName="active_route" to="#">
                          Submission
                        </NavLink> :
                        <NavLink exact className="routes" activeClassName="active_route" to="#">
                          Browse Festivals
                        </NavLink>
                }
              {
                userRole === "organizer" ?
                    <NavLink exact className="routes" activeClassName="active_route" to="/Dashboard">
                      Dashboard
                    </NavLink>
                    :
                    <NavLink exact className="routes" activeClassName="active_route" to="#">
                      Dashboard
                    </NavLink>
                }
                {userRole === "organizer" ?
                    <NavLink exact className="routes" activeClassName="active_route" to="/myFestival/list">
                      My Festival
                    </NavLink> :
                    <NavLink exact className="routes" activeClassName="active_route" to="/myProject">
                      My Projects
                    </NavLink>
                }
                {
                   userRole === "organizer" ?
                        <NavLink exact className="routes" activeClassName="active_route" to="#">
                          Tickets
                        </NavLink>
                       :
                       <NavLink exact className="routes" activeClassName="active_route" to="#">
                         Submissions
                       </NavLink>
                }
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <IconButton style={{padding: "0px"}} onClick={handleOpenNotofication}>
                <Avatar src={imgNotification} alt="logo" style={{height:"27px", width:"27px", marginRight: "7px"}} />
              </IconButton>
                {open ? (
              <Box sx={styles} boxShadow={3}>
                <div style={{textAlign:"center"}}>
                  <img style={{width:"25px", marginRight:"10px"}} src={noNotification} alt="logo" />
                  <Typography color="primary" >You, have no notifications.</Typography>
                </div>
              </Box>
              ) : null}
          </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} style={{ padding: '0px' }}>
                  <Avatar 
                    src={accountData &&
                    (accountData.profile_pic ? accountData.profile_pic : "")}
                  >
                    {(accountData  && accountData.profile_pic == "") ?
                     accountData?.first_name?.charAt(0).toUpperCase()  : "R"}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                style={{ marginTop: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {userRole !== 'organizer' && <MenuItem component={Link} to="/Dashboard" onClick={handleViewProfile}>
                  <Typography >View Profile</Typography>
                </MenuItem>}
                {
                    userRole === 'organizer' &&
                    <MenuItem component={Link} to="/UserProfileBasicBlock" onClick={handleNavigateAccountSettings}>
                      <Typography >Account settings</Typography>
                    </MenuItem>
                }
                {/* <MenuItem onClick={handleCloseNavMenu}>
                  <Typography >Dashboard</Typography>
                </MenuItem> */}
                <MenuItem onClick={() =>{handleLogout()}}>
                  <Typography >Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} style={{ padding: '0px' }}>
                <img style={{width:"25px", marginLeft:"10px"}} src={downArrow} alt="logo" />
                </IconButton>
              </Tooltip>
              <Menu
                style={{ marginTop: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {userRole !== 'organizer' && <MenuItem component={Link} to="/Dashboard" onClick={handleCloseNavMenu}>
                  <Typography >View Profile</Typography>
                </MenuItem>
                }
                <MenuItem 
                component={Link} to="/UserProfileBasicBlock" onClick={handleNavigateAccountSettings}
                >
                      <Typography >Account settings</Typography>
                </MenuItem>
                { userRole !== 'organizer' && userRole !== 'submitter' && <MenuItem component={Link} to="/Dashboard" onClick={handleCloseNavMenu}>
                  <Typography >Dashboard</Typography>
                </MenuItem>}
                <MenuItem onClick={handleLogout}>
                  <Typography >Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
          :
          <Toolbar >
            <Box sx={wenStyles.toolbarLogo } >
              <img
                onClick={() => {
                  // this.props.history.push("/");
                }}
                src={logo}
                alt="logo"
                style={{ width: "21%",marginLeft:"60px" }}
              />
            </Box>
            <div style={{ marginRight:"120px",display:'flex',alignItems:'center' }}>
              <NavLink exact className="routes" activeClassName="active_route" to="/BrowseFestival"  style={{color: "#fff", fontWeight: "bold", fontSize: "18px", textTransform: "capitalize", width: 'fit-content',height: 'fit-content',background: 'none',border: 'none',marginRight:"75px"}}>
                  Browse Festivals
              </NavLink>
              <Button
                color="primary"
                style={{color: "#fff", fontWeight: "bold", fontSize: "18px", textTransform: "capitalize", width: 'fit-content',height: 'fit-content',background: 'none',border: 'none',right:"50px"}}
                onClick={() => toggleLoginModal()}
              >
                Log in
              </Button>
              <Button
                color="primary"
                style={{color: "#fff", fontWeight: "bold", fontSize: "18px", textTransform: "capitalize", width: 'fit-content',height: 'fit-content',background: 'none',border: 'none',right:"25px"}}
                onClick={() => toggleSignupModal()}
              >
                Sign Up
              </Button>
            </div>
          </Toolbar>
           }
          </Container>
        </AppBar>
  )
}

export default (...props:any) => {
  const WithRouteAppHeader = withRouter(AppHeader)
  return (
      <WithRouteAppHeader {...props} />
  )
};

// Customizable Area Start
const wenStyles = {
  appBar: {
    borderBottom: `1px solid #000`,
    width: "100%",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "#330a4c",
    
    // height: theme.spacing(8.75),
  },

  toolbar: {
    flexWrap: "nowrap",
    // padding: theme.spacing(0, 10),
  },
  toolbarLogo: {
    flexGrow: 1,
  },
  toolbarLogoToken: {
    flexGrow: "0.5",
  },
  navBarButtons: {
    color: "#fff",
    fontSize: "18px",
    textTransform: "capitalize",
    width: 'fit-content',
    height: 'fit-content',
    background: 'none',
    border: 'none'
  },
  landingText:{
    fontSize:'42px',
    color: '#fff',
    fontWeight: 'bold',
  }
}

const styles = {
  position:'absolute',
  top: 57,
  right: 0,
  width:300,
  height:300,
  zIndex: 1,
  p: 1,
  bgcolor: 'background.paper',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
// Customizable Area End