import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
//#345E7D - blue
//#54A4A6 - primary green
//#FFD88A - secondary yellow

// Lato
// Helvetica

export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#A5259F',
      contrastText: '#fff'
    },
    secondary: {
      main: '#567de9',
      contrastText: '#fff'
    },
    background: {
      default: '#fff',
      paper: '#fff'
    },
    // text: {
    //   primary :'',
    //   secondary: '',
    // },
    error : {
      main: '#FEA075' // orange
    },
    warning : {
      main : '#FCC65A' //'yellow'
    },
    info : {
      main : '#345E7D' // blue
    },
    success :{
      main : '#54A4A6' // cyan
    }
    
  }
};

const theme = createTheme({
  props: {
    MuiDivider: {
      light: false
    },
  },
  ...themeOptions,
  typography: {
    fontFamily: ['Open Sans'].join(','),
    textTransform: 'none',
    h1: {
      fontWeight: 'bold',
      fontSize: '36px',
      lineHeight: 1.25,
      letterSpacing: '0.27px'
    },
    h2: {
      fontSize: '34px',
      lineHeight: 1.33,
      letterSpacing: '0.2px'
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: 1.2,
      letterSpacing: '0.2px'
    },
    h4: {
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: 1.78,
      letterSpacing: 'normal'
    },
    h5: {
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: 1.38,
      letterSpacing: '0.2px'
    },
    subtitle1: {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: 1,
      letterSpacing: '0.2px'
    },
    subtitle2: {
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: 1,
      letterSpacing: '0.2px'
    }
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        fontSize: '12px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: 'normal',
        color: '#000000'
      }
    },
    MuiTab: {
      root: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: '0.2px',
        color: '#183B56',
        textTransform: 'capitalize'
      }
    },
    MuiButton: {
      containedPrimary: {
        height: '42px',
        borderRadius: '4px',
        padding: '8px 20px',
        fontSize: '12px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#FFFFFF',
        textTransform: 'capitalize'
      },
      outlinedPrimary: {
        height: '34px',
        borderRadius: '8px',
        border: '1.5px solid #A5259F',
        padding: '8px 20px',
        fontSize: '12px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.25,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#A5259F'
      },
      endIcon: {
        marginLeft: '12px'
      },
      colorInherit: {
      	width: '232px',
        height: '52px',
        border: '1px solid #a5259f',
        borderColor: '#a5259f',
        color: '#a5259f'
      },
      textPrimary: {
      	width: '232px',
        height: '52px',
        border: '1px solid #a5259f',
        backgroundColor: '#a5259f',
        color: '#fff',
        "&:hover": {
          backgroundColor: '#a5259f',
          color: '#fff'
        },
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        '&&&[class*="MuiOutlinedInput-root"]': {
          padding: '5px 2px 5px 0px'
        }
      }
    },
    MuiSelect: {
      outlined: {
        borderRadius: '8px'
      }
    },
    MuiRadio: {
    	colorPrimary: {
    		color: '#a5259f',
    	}
    },
    MuiOutlinedInput: {
      input: {
        borderRadius: '8px',
        position: 'relative',
        backgroundColor: '#FFF',
        fontSize: '12px',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        padding: '10px 14px',
      },
      root: {
        borderRadius: '8px',
        borderColor: '#C3CAD9',
      },
      multiline: {
        padding: 0
      },
      inputMultiline: {
        position: 'relative',
        backgroundColor: '#FFF',
        border: 'none',
        fontSize: '16px',
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.19,
        letterSpacing: 'normal',
        padding: '14px 14px'
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '18px',
        fontWeight: 600,
        color: '#000',
        paddingBottom: '10px'
      }
    },
    MuiInputBase: {
    	input: {
    		fontSize: '12px',
    		paddingLeft: '10px',
    		color: '#000',
    	}
    },
    MuiListItem: {
      root: {
        padding: 0
      },
      gutters: {
        paddingLeft: 0,
        paddingRight: 0
      },
    },
    MuiMenuItem: {
      gutters: {
        paddingLeft: '16px',
        paddingRight: '16px',
      }
    },
    MuiStepIcon: {
    	root: {
    		border: '1px solid #000',
    		borderRadius: '50%',
    		fill: 'none',
    		height: '30px',
    		width: '30px',
    		color: '#000'
    	},
    	active: {
    		border: 'none',
    		fill: '#a5259f',
    	},
    	completed: {
    		border: 'none',
    		fill: '#a5259f',
    	}
    },
    MuiDialog: {
      paperWidthXs: {
        maxWidth: '400px'
      }
    }
  }
});

export default responsiveFontSizes(theme);
