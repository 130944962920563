import * as React from 'react';

import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  makeStyles, 
  Modal,
  Paper
} from "@material-ui/core";


const Privacy = ({
}: any) => { 
    return(
      <Box sx={webStyles.bottomRight}> 
        <Typography style={{textAlign:"center"}}>Development in Progress.....</Typography>
      </Box>
    )
}

export default Privacy;

const webStyles = {
  main: {
      display: "flex",
      justifyContent: "center",
      height: "100px",
      background: "000"
  },
  bottomRight: {
      display: "flex",
      flexDirection: "column",
      width: '75%',
  },
}