// Customizable Area Start
import {IBlock} from "../../../framework/src/IBlock";
import {Message} from "../../../framework/src/Message";
import {BlockComponent} from "../../../framework/src/BlockComponent";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import {runEngine} from "../../../framework/src/RunEngine";


import {toast} from "react-toastify";
import React, {ReactHTML, ReactHTMLElement} from "react";

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
}

interface S {
    isLoading:boolean;
    festivalPhotos:Array<Object>;
    value:any;
    isChangeOrder:boolean;
    isModalOpen:boolean;
    albumList:Array<Object>;
    id:any;
    currentTab:string;
    selectedAlbum:any;
    anchorEl:any;
    isDeleteModalOpen:boolean;
    isDeleteAlbumModalOpen:boolean;
    isAlbumNameEdit:boolean;
    updatedAlbumName:String;
    photoId:any;
    isUploading:boolean;
}

interface SS {
    id: any;
}

export default class FestivalViewController extends BlockComponent<
    Props,
    S,
    SS
    > {

    ProfileAndCoverPhotoApiCall:string = "";
    getFestivalDataApiCall:string = "";
    getAlbumDataApiCallId:string ="";
    getAlbumPhotoListApiCallId:string = "";
    updatePhotoOrderApiCall:string="";
    reOrderPhotosAlbumApiCallId:String="";
    movePhotoToAlbumApiCall:string="";
    removePhotoFromAlbumApiCall:string="";
    deletePhotoApiCall:String="";
    renameAlbumApiCallId:String="";
    deleteAlbumApiCall:String="";
    UploadPhotoToAlbumApiCall:String="";
    emailReg: RegExp;
    labelTitle: string = "";

    constructor(props: Props) {

        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.PostDetailDataMessage)
        ]
        this.state = {
            isLoading:true,
            festivalPhotos:[],
            value:0,
            isModalOpen:false,
            isChangeOrder:false,
            id:"",
            albumList:[],
            currentTab:"Photos",
            selectedAlbum: null,
            anchorEl:null,
            isDeleteModalOpen:false,
            isAlbumNameEdit:false,
            updatedAlbumName:"",
            isDeleteAlbumModalOpen:false,
            photoId:"",
            isUploading:false,
        };
        this.handleChange = this.handleChange.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleChangeOrder = this.handleChangeOrder.bind(this)
        this.reorderList = this.reorderList.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleMoveToAlbum = this.handleMoveToAlbum.bind(this)
        this.handleCloseMenu = this.handleCloseMenu.bind(this)
        this.handleDeleteAlbum = this.handleDeleteAlbum.bind(this)
        this.handleOpenMenu = this.handleOpenMenu.bind(this)
        this.handleSelectAlbum = this.handleSelectAlbum.bind(this)
        this.handleRemoveFromAlbum = this.handleRemoveFromAlbum.bind(this)
        this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this)
        this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this)
        this.manageRenameAlbumFlag = this.manageRenameAlbumFlag.bind(this)
        this.handleAlbumName = this.handleAlbumName.bind(this)
        this.manageSubmitRename = this.manageSubmitRename.bind(this)
        this.handleOpenDeleteAlbumModal = this.handleOpenDeleteAlbumModal.bind(this)
        this.handleCloseDeleteAlbumModal = this.handleCloseDeleteAlbumModal.bind(this)
        this.emailReg = new RegExp("");
        this.labelTitle = configJSON.labelTitle;

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        const id = window.location.href.split("/")[5]
        this.getPhotosListData(id)
        this.getAlbumListData(id)
        if(id){
            this.setState({
                id:id
            })
        }
    }

    async receive(from: string, message: Message) {
        if(getName(MessageEnum.PostDetailDataMessage) === message.id){
            if(message.properties.text === "REFRESH_PHOTO_LIST"){
                this.getPhotosListData(this.state.id)
            }else if(message.properties.text === "ALBUM_CREATED_SUCCESSFULLY"){
                this.getAlbumListData(this.state.id)
            }
        }
        if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            if(this.getFestivalDataApiCall === apiRequestCallId) {
                if(responseJson?.hasOwnProperty('data')){
                     this.setState({
                        festivalPhotos:responseJson.data,
                    })
                }else{
                    responseJson?.errors?.map((item:any)=>{
                        if(item.token === "Token has Expired"){
                            localStorage.removeItem("authToken")
                            localStorage.removeItem("profile")
                            localStorage.removeItem("token")
                            localStorage.removeItem("usertoken")
                            this.props.navigation.navigate("/")
                            toast.error("Token Expired")
                        }else{
                            console.log("GET FESTIVAL DATA ERROR",item)
                        }
                    })
                }
                this.setState({
                    isLoading:false
                })
            }else if(this.updatePhotoOrderApiCall === apiRequestCallId){
                if(responseJson?.hasOwnProperty('data')) {
                    toast.success("Congratulations, Order Updated Successfully..")
                }
            }else if(this.deletePhotoApiCall === apiRequestCallId){
                if(responseJson.message = "Successfully deleted the record"){
                    toast.info("Photo Deleted Successfully..")
                    if(this.state.festivalPhotos.length > 1){
                        this.getPhotosListData(this.state.id)
                    }else{
                        this.setState({
                            festivalPhotos:[],
                        })
                    }
                    if(this.state.selectedAlbum){
                        this.getAlbumPhotoList()
                    }
                }else{
                    toast.error("ERROR")
                }
            }else if(this.getAlbumDataApiCallId === apiRequestCallId){
                if(responseJson.hasOwnProperty('data')){
                    this.setState({
                        albumList:responseJson.data,
                        isLoading:false
                    })
                }
            }else if(this.movePhotoToAlbumApiCall === apiRequestCallId){
                if(responseJson.message === "Photo Successfully moved to album"){
                    toast.success("Congratulations, Photo Moved Successfully.")
                    this.getAlbumListData(this.state.id)
                    if(this.state.selectedAlbum){
                        this.getAlbumPhotoList()
                        this.getAlbumListData(this.state.id)
                    }
                }else{
                    toast.error("Something went wrong!")
                }
            }else if(this.UploadPhotoToAlbumApiCall === apiRequestCallId){
                if(responseJson?.hasOwnProperty('data')){
                    let updatedArray:any = this.state.selectedAlbum.festival_photos.data.concat(responseJson.data)
                    this.setState({
                        selectedAlbum:{
                            ...this.state.selectedAlbum,
                            festival_photos:{
                                data:updatedArray
                            }
                        },
                        isUploading:false
                    })
                    toast.success("Congratulations, Album Photo Uploaded Successfully.")
                }
            }else if(this.getAlbumPhotoListApiCallId === apiRequestCallId){
                if(responseJson?.hasOwnProperty('data')){
                    this.setState({
                        selectedAlbum:{
                            ...this.state.selectedAlbum,
                            festival_photos:{
                                data:responseJson.data
                            }
                        }
                    })
                }else{
                    if(responseJson.message === "Album Photos not found"){
                        this.setState({
                            selectedAlbum:{
                                ...this.state.selectedAlbum,
                                festival_photos:{
                                    data:[]
                                }
                            }
                        })
                    }else{
                        toast.error("Something went wrong!")
                    }
                }
            }else if(this.removePhotoFromAlbumApiCall === apiRequestCallId){
                if(responseJson.message === "Successfully removed album photo"){
                    toast.success("Photo removed from album successfully")
                    if(this.state.selectedAlbum.festival_photos.data.length > 1){
                        this.getAlbumPhotoList()
                    }else{
                        this.setState({
                            selectedAlbum:null
                        })
                        this.getAlbumListData(this.state.id)
                    }

                }else{
                    toast.error("Something went wrong!")
                }
            }else if(this.deleteAlbumApiCall === apiRequestCallId){
                if(responseJson.message === "Album Successfully deleted"){
                    toast.info("Album Deleted Successfully")
                    if(this.state.albumList.length > 1){
                        this.getAlbumListData(this.state.id)
                    }else{
                        this.setState({
                            albumList:[],
                        })
                    }
                    this.setState({
                        selectedAlbum:null,
                    })
                }else{
                    toast.error("Something went wrong!")
                }
            }else if(this.reOrderPhotosAlbumApiCallId === apiRequestCallId){
                if(responseJson?.hasOwnProperty('data')) {
                    toast.success("Congratulations, Album Photos Order Updated Successfully..")
                }else{
                    toast.error("Something went wrong!")
                }
            }else if(this.renameAlbumApiCallId === apiRequestCallId){
                if(responseJson.hasOwnProperty('data')){
                    toast.success("Congratulations, Album Renamed Successfully..")
                    this.setState({
                        selectedAlbum: {
                            ...this.state.selectedAlbum,
                            name:responseJson.data.attributes.name
                        },
                        isAlbumNameEdit:false,
                    })
                    this.getAlbumListData(this.state.id)
                }else{
                    toast.error("Something went wrong!")
                }
            }
        }
    }

    reorderList = (sourceIndex:any, destinationIndex:any) => {
        if (destinationIndex === sourceIndex) {
            return;
        }
        // @ts-ignore
        let list = this.state.festivalPhotos;
        let formData = new FormData()
        formData.append('festival_id',this.state.id)
        // @ts-ignore
        formData.append('festival_photo[festival_photo_id]',this.state.festivalPhotos[sourceIndex].id)
        // @ts-ignore
        formData.append('festival_photo[photo_order]',this.state.festivalPhotos[destinationIndex].attributes.photo_order)
        this.doUpdatePhotoOrder(formData)
        if (sourceIndex >= list.length) {
            let k = sourceIndex - list.length + 1;
            while (k--) {
                // @ts-ignore
                list.push(undefined);
            }
        }
        list.splice(destinationIndex, 0, list.splice(sourceIndex, 1)[0]);
        this.setState({
            ...this.state,
            // @ts-ignore
            festivalPhotos: list,
        });
    };

    reorderListOfAlbum = (sourceIndex:any, destinationIndex:any) => {
        if (destinationIndex === sourceIndex) {
            return;
        }
        // @ts-ignore
        let list = this.state.selectedAlbum.festival_photos?.data;
        let formData = new FormData()
        formData.append('festival_id',this.state.id)
        // @ts-ignore
        formData.append('festival_album_id',this.state.selectedAlbum.id)
        // @ts-ignore
        formData.append('festival_photo_id',this.state.selectedAlbum.festival_photos?.data[sourceIndex].id)
        // @ts-ignore
        formData.append('new_album_photo_order',this.state.selectedAlbum.festival_photos?.data[destinationIndex].attributes.album_photo_order)

        this.doUpdatePhotoOrderOfAlbum(formData)
        if (sourceIndex >= list.length) {
            let k = sourceIndex - list.length + 1;
            while (k--) {
                // @ts-ignore
                list.push(undefined);
            }
        }
        list.splice(destinationIndex, 0, list.splice(sourceIndex, 1)[0]);
        this.setState({
            selectedAlbum: {
                ...this.state.selectedAlbum,
                festival_photos:{
                    data:list
                }
            },
        });
    };

    handleChangeOrder () {
        this.setState({
            // @ts-ignore
            isChangeOrder:!this.state.isChangeOrder
        })
    }

    handleDeleteAlbum () {
        let formData = new FormData()
        formData.append('festival_id',this.state.id)
        formData.append('festival_album_id',this.state.selectedAlbum.id)
        this.manageDeleteAlbum(formData)
        this.setState({
            anchorEl:null,
            isDeleteAlbumModalOpen:false,
        })
    }

    handleChangeTab (tabName:any) {
        if(tabName === "Albums"){
            this.setState({
                // @ts-ignore
                currentTab:tabName,
                selectedAlbum:null,
                isChangeOrder:false
            })
        }else{
            this.setState({
                // @ts-ignore
                currentTab:tabName
            })
        }

    }

    handleMoveToAlbum (albumId:any,photoId:any) {
        let formData = new FormData()
        formData.append('festival_id',this.state.id)
        formData.append('festival_album_id',albumId)
        formData.append('festival_photo_id',photoId)
        this.doMovePhotoToAlbum(formData)
    }

    handleRemoveFromAlbum (photoId:any){
        let formData = new FormData()
        formData.append('festival_id',this.state.id)
        formData.append('festival_album_id',this.state.selectedAlbum.id)
        formData.append('festival_photo_id',photoId)
        this.doRemovePhotoFromAlbum(formData)
    }

    handleOpenModal() {
        this.setState({
            isModalOpen:true
        })
    }

    handleCloseModal() {
        this.setState({
            isModalOpen:false
        })
    }

    handleOpenDeleteModal(photoId:any) {
        this.setState({
            isDeleteModalOpen:true,
            photoId
        })
    }

    handleCloseDeleteModal() {
        this.setState({
            isDeleteModalOpen:false
        })
    }

    handleOpenDeleteAlbumModal() {
        this.setState({
            isDeleteAlbumModalOpen:true
        })
    }

    handleCloseDeleteAlbumModal() {
        this.setState({
            isDeleteAlbumModalOpen:false
        })
    }

    handleDelete() {
        let formData = new FormData()
        formData.append('festival_id',this.state.id)
        formData.append('festival_photo[festival_photo_id]',this.state.photoId)
        this.manageDeletePhoto(formData)
        this.setState({
            isDeleteModalOpen:false
        })
    }

    handleSelectAlbum(album:any) {
        console.log("SELECTED ALBUM",album)
        this.setState({
            selectedAlbum: {
                ...album.attributes,
                id:album.id
            }
        })
    }


    handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        if(newValue === 1){
            // @ts-ignore
            this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }else if(newValue === 2){
            // @ts-ignore
            this.myRef1.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        } else{
            this.setState({
                value:newValue
            })
        }
    };

    handleCloseMenu(){
        this.setState({
            anchorEl:null
        })
    }

    manageRenameAlbumFlag () {
        this.setState({
            isAlbumNameEdit:true,
            anchorEl:null,
            updatedAlbumName:this.state.selectedAlbum.name,
        })
    }

    handleAlbumName(e:any) {
        this.setState({
            updatedAlbumName:e.target.value
        })
    }

    manageSubmitRename(){
        let formData = new FormData()
        formData.append('festival_id',this.state.id)
        formData.append('festival_album_id',this.state.selectedAlbum.id)
        // @ts-ignore
        formData.append('album_name',this.state.updatedAlbumName)
        this.doRenameAlbum(formData)
    }

    handleOpenMenu(event:any){
        this.setState({
            anchorEl:event.currentTarget
        })
    }


    handleUploadAlbumImages = (e:any) => {
        this.setState({
            isUploading:true
        })
        if(e.target.files.length > 0){
            const arrayOfObj = Object.entries(e.target.files).map((e) => ( { [e[0]]: e[1] } ));
            let formData = new FormData()
            formData.append('festival_id', this.state.id)
            formData.append('festival_album_id', this.state.selectedAlbum.id)
            arrayOfObj.forEach((image:any,key) => {
                formData.append("festival_photos[]", image[key],image[key].name);
            });
            this.doUploadPhotoToAlbum(formData)
        }else{
            toast.error("Please Select at least one Image")
        }
    }

    getPhotosListData(id:any): boolean {
        this.setState({
            isLoading:true
        })
        const header = {
            "Content-Type": configJSON.festivalPhotosApiContentType,
            token: localStorage.getItem('authToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFestivalDataApiCall  = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.festivalPhotosDataApiEndPoint}/?festival_id=${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalPhotosDataApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    getAlbumListData(id:any): boolean {
        this.setState({
            isLoading:true
        })
        const header = {
            "Content-Type": configJSON.festivalPhotosAlbumApiContentType,
            token: localStorage.getItem('authToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAlbumDataApiCallId  = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.festivalPhotosAlbumApiEndPoint}${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalPhotosAlbumApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    getAlbumPhotoList(): boolean {
        this.setState({
            isLoading:true
        })
        const header = {
            "Content-Type": configJSON.festivalAlbumPhotosGETApiContentType,
            token: localStorage.getItem('authToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAlbumPhotoListApiCallId  = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.festivalAlbumPhotosGETApiEndPoint}festival_id=${this.state.id}&festival_album_id=${this.state.selectedAlbum.id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalPhotosAlbumApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    manageDeletePhoto (data: any) {
        const token = localStorage.getItem('authToken')
        const headers = {
            token : token,
            'x-amz-acl': 'public-read'
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deletePhotoApiCall = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.festivalPhotosDeleteApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            data
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalPhotosDeleteApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    doRenameAlbum (data: any) {
        const token = localStorage.getItem('authToken')
        const headers = {
            token : token,
            'x-amz-acl': 'public-read'
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.renameAlbumApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.festivalRenameAlbumApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            data
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalRenameAlbumApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    manageDeleteAlbum (data: any) {
        const token = localStorage.getItem('authToken')
        const headers = {
            token : token,
            'x-amz-acl': 'public-read'
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteAlbumApiCall = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.festivalDeleteAlbumApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            data
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalDeleteApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    doUpdatePhotoOrder = (data: any) => {
        const token = localStorage.getItem('authToken')
        const headers = {
            token : token,
            'x-amz-acl': 'public-read'
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updatePhotoOrderApiCall = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.festivalPhotosOrderApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            data
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalPhotosOrderApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true
    }

    doUpdatePhotoOrderOfAlbum = (data: any) => {
        const token = localStorage.getItem('authToken')
        const headers = {
            token : token,
            'x-amz-acl': 'public-read'
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.reOrderPhotosAlbumApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.festivalReOrderAlbumApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            data
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalReOrderApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true
    }

    doMovePhotoToAlbum = (data: any) => {
        const token = localStorage.getItem('authToken')
        const headers = {
            token : token,
            'x-amz-acl': 'public-read'
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.movePhotoToAlbumApiCall = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.festivalPhotosMoveToAlbumApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            data
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalPhotosMoveToAlbumApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true
    }

    doRemovePhotoFromAlbum = (data: any) => {
        const token = localStorage.getItem('authToken')
        const headers = {
            token : token,
            'x-amz-acl': 'public-read'
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.removePhotoFromAlbumApiCall = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.festivalPhotosRemoveFromAlbumApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            data
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalPhotosRemoveFromAlbumApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true
    }

    doUploadPhotoToAlbum = (data: any) => {
        const token = localStorage.getItem('authToken')
        const headers = {
            token : token,
            'x-amz-acl': 'public-read'
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.UploadPhotoToAlbumApiCall = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.festivalAddPhotosToAlbumApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            data
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalAddPhotosToAlbumApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true
    }
}


// Customizable Area End