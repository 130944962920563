import * as Yup from 'yup';

const phoneRegExp = /^[6-9]\d{9}$/
const postalRegExp = /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/
const urlRegExp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/

export const stepOneSchema = () => {
  return Yup.object().shape({
    eventName: Yup.string().required('Event name required'),
    description: Yup.string().required('Description required'),
    awards: Yup.string().required('Awards & Prizes required'),
    keyStats: Yup.string().required('Key Stats is required'),
    submissions: Yup.string().required('Submissions is required'),
    project: Yup.string().required('Project is required'),
    awardsPresented: Yup.string().required('Presented awards required'),
  })
};

export const stepTwoSchema = () => {
  return Yup.object().shape({
    website: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!'
    ).required('Website required'),
    email: Yup.string().email('Invalid email').required('Email required'),
    country: Yup.string().required('Country required'),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10, "to short").max(10, "to long"),
    postal: Yup.string().matches(postalRegExp, 'Postal code is not valid')
  })
};

export const stepThreeSchema = () => {
  return Yup.object().shape({
    openingDate: Yup.string().required('Opening date required'),
    NotiDate: Yup.string().required('Notification date required'),
    EventStart: Yup.string().required('Event start date required'),
    EventEnd: Yup.string().required('Event end date required'),
  })
}

export const stepFourSchema = () => {
  return Yup.object().shape({
    // currency: Yup.string().required('Currency required'),
  })
};

export const stepFiveSchema = () => {
  return Yup.object().shape({
    category_search: Yup.string().required('Search words required'),
    listingUrl: Yup.string().matches(urlRegExp,'Enter correct url!').required('Listing Url required'),
    listingVisibility: Yup.string().required('Listing visibility required'),
    // eventVenue: Yup.string().required('Submissions is required'),
  })
};

export const formInitialValues = {
  eventName: "",
  description: "",
  awards: "",
  keyStats: "",
  submissions: "",
  project: "",
  awardsPresented: "",
  event_organizers_attributes: [
    { id: Math.random(), name: "", title: "" },
  ],
  website: "",
  currency: '',
  email: "",
  phone: "",
  address: "",
  country: "",
  state: "",
  city: "",
  postal: "",
  twitter: "",
  fb: "",
  insta: "",
  imdb: "",
  category_search: "",
  search_terms: "",
  max_minutes: "",
  min_minutes: "",
  openingDate: "",
  EBDate: "",
  RegDate: "",
  LateDate: "",
  ExtDate: "",
  NotiDate: "",
  EventStart: "",
  EventEnd: "",
  listingVisibility: "",
  listingUrl: "",
}