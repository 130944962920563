// Customizable Area Start
import React from "react";
import {
  Box,
  TextField,
  FormLabel,
  FormControl,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Country, State, City } from "country-state-city";

const useStyles = makeStyles({
  icon: {
    color: "#a5259f",
  },
  root: {
    "& .MuiInputBase-input": {
      height: "1.5em",
    },
  },
});

const AddCompany = ({ closeCompany, passCompanyData, userData, buttonDisable }: any) => {
  const [allStates, setAllStates] = React.useState(new Array());
  const [allCities, setAllCities] = React.useState(new Array());
  const [countryOrigin, setCountryOrigin] = React.useState("");
  const [btnDisabled, setBtnDisabled] = React.useState(true) 

  const classes = useStyles();
  const styled ={color: 'red'};
  const countries = Country.getAllCountries();
  const initialValues = {
    nameCompany: userData? userData.name: "",
    emailCompany: userData? userData.email : "",
    phonenoCompany: userData? userData.full_phone_number : "",
    address1Company: userData? userData.address1 : "",
    address2Company: userData? userData.address2 : "",
    cityCompany: userData ? userData.city : "",
    stateCompany: userData ? userData.state : "",
    postal_codeCompany: userData ? userData.postal_code : "",
    country_of_originCompany: userData ? userData.country : "",
  };
  const [formVal, dispatch] = React.useReducer(
    (curVal, newVal) => ({ ...curVal, ...newVal }),
    initialValues
  );

  const {
    nameCompany,
    emailCompany,
    phonenoCompany,
    address1Company,
    address2Company,
    cityCompany,
    stateCompany,
    postal_codeCompany,
    country_of_originCompany,
  } = formVal;

  React.useEffect(() => {
    passCompanyData(formVal);
    buttonDisable(nameCompany ? !nameCompany :btnDisabled)
  });

  React.useEffect(() => {
    if(userData){
      setAllStates(State.getStatesOfCountry(userData.country))
      setAllCities(City.getCitiesOfState(userData.country, userData.state ))
    }
  }, [userData]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    dispatch({ [name]: value });
  };

  const handleCountryOfOrigin = (event: any) => {
    const { name, value } = event.target;
    setCountryOrigin(value);
    setAllStates(State.getStatesOfCountry(value));
    dispatch({ [name]: value });
  };

  const handleStateChange = (event: any) => {
    const { name, value } = event.target;
    setAllCities(City.getCitiesOfState(countryOrigin, value));
    dispatch({ [name]: value });
  };

  const handleCityChange = (event: any) => {
    const { name, value } = event.target;
    initialValues.cityCompany = value;
    dispatch({ [name]: value });
  };

  return (
    <div style={{ margin: "20px", border: "1px solid #ccc" }}>
      <Box
        sx={{
          bgcolor: "#ccc",
          padding: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">{nameCompany}</Typography>
        <CloseIcon
          style={{ fill: "#aaa", cursor: "pointer" }}
          onClick={closeCompany}
        />
      </Box>
      <Box style={{ padding: "20px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl
            component="div"
            style={{ padding: "10px 0px", width: "100%" }}
          >
            <FormLabel component="legend">Company{" "}<span style={styled}>*</span></FormLabel>
            <TextField
              id="outlined-disabled"
              placeholder="My Production Company, LLC"
              onChange={handleChange}
              name="nameCompany"
              value={nameCompany}
              required
              onInput={(e:any) => setBtnDisabled(!e.target.value)}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl
            component="div"
            style={{ padding: "10px 0px", width: "100%" }}
          >
            <FormLabel component="legend">Address</FormLabel>
            <TextField
              id="outlined-disabled"
              placeholder="Address"
              name="address1Company"
              value={address1Company}
              onChange={handleChange}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl
            component="div"
            style={{ padding: "10px 0px", width: "100%" }}
          >
            <TextField
              id="outlined-disabled"
              placeholder="Address Line 2"
              name="address2Company"
              value={address2Company}
              onChange={handleChange}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl
            component="div"
            style={{ padding: "10px 0px", width: "45%" }}
          >
            <FormLabel component="legend">Country of origin</FormLabel>
            <TextField
              id="standard-select-currency-native"
              select
              onChange={handleCountryOfOrigin}
              SelectProps={{
                classes: { icon: classes.icon },
                native: true,
              }}
              InputProps={{ className: classes.root }}
              variant="standard"
              name="country_of_originCompany"
              value={country_of_originCompany}
            >
              <option value={""}>Select from option</option>
              {countries &&
                countries.map((cur: any) => (
                  <option value={cur.isoCode}>{cur.name}</option>
                ))}
            </TextField>
          </FormControl>
          <FormControl
            component="div"
            style={{ padding: "10px 0px", width: "45%" }}
          >
            <FormLabel component="legend">State</FormLabel>
            <TextField
              id="standard-select-currency-native"
              select
              onChange={handleStateChange}
              SelectProps={{
                classes: { icon: classes.icon },
                native: true,
              }}
              InputProps={{ className: classes.root }}
              variant="standard"
              name="stateCompany"
              value={stateCompany}
            >
              <option value={""}>Select from option</option>
              {allStates &&
                allStates.map((stateCompany: any) => (
                  <option value={stateCompany.isoCode}>
                    {stateCompany.name}
                  </option>
                ))}
            </TextField>
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl
            component="div"
            style={{ padding: "10px 0px", width: "45%" }}
          >
            <FormLabel component="legend">City</FormLabel>
            <TextField
              id="standard-select-currency-native"
              select
              onChange={handleCityChange}
              SelectProps={{
                classes: { icon: classes.icon },
                native: true,
              }}
              InputProps={{ className: classes.root }}
              variant="standard"
              name="cityCompany"
              value={cityCompany}
            >
              <option value={""}>Select from option</option>
              {allCities &&
                allCities.map((cityCompany: any) => (
                  <option value={cityCompany.name}>{cityCompany.name}</option>
                ))}
            </TextField>
          </FormControl>
          <FormControl
            component="div"
            style={{ padding: "10px 0px", width: "45%" }}
          >
            {" "}
            <FormLabel component="legend">Postal Code</FormLabel>
            <TextField
              type="number"
              id="standard-select-role"
              placeholder="Enter pin code"
              onChange={handleChange}
              InputProps={{ className: classes.root }}
              name="postal_codeCompany"
              value={postal_codeCompany}
              //  onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
            />
          </FormControl>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl
            component="div"
            style={{ padding: "10px 0px", width: "100%" }}
          >
            <FormLabel component="legend">Email</FormLabel>
            <TextField
              id="outlined-disabled"
              placeholder="john@example.ca"
              variant="standard"
              onChange={handleChange}
              InputProps={{ className: classes.root }}
              name="emailCompany"
              value={emailCompany}
            />
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormControl
            component="div"
            style={{ padding: "10px 0px", width: "100%" }}
          >
            <FormLabel component="legend">Phone</FormLabel>
            <TextField
              id="outlined-disabled"
              placeholder="(604) 229-0600"
              type="number"
              onChange={handleChange}
              InputProps={{ className: classes.root }}
              name="phonenoCompany"
              value={phonenoCompany}
            />
          </FormControl>
        </Box>
      </Box>
    </div>
  );
};

export default AddCompany;
// Customizable Area end
