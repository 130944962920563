import * as React from 'react';

import {
  Box,
  Link,
  Breadcrumbs
} from "@material-ui/core";

const footerLinks = ['How it Works', 'Browse Festivals', 'Testimonials', 'Benefits', 'Blog', 'Help', 'Contact'];

const AppFooter = () => {
  return (
    <Box style={{backgroundColor:"white",marginBottom:"0px"}}>
        <Breadcrumbs style={webStyles.main} separator="|" aria-label="breadcrumb">
            {footerLinks.map((item, key) => 
               <Link underline="hover" key={key} style={{fontWeight: 700}} color="inherit">
                {item}
             </Link> 
            )}
        </Breadcrumbs>
    </Box>
  );
};
export default AppFooter;


const webStyles = {
    main: {
        display: "flex",
        justifyContent: "center",
        // height: "100px",
        padding:"18px 0",
        background: "000",
        color:"#000"
    }
}