import * as React from "react";

import {
  Box,
  Button,
  Typography,
  FormLabel,
  FormControl,
  TextareaAutosize,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Rating from "@material-ui/lab/Rating";
import { useFormik } from "formik";
import * as Yup from "yup";

const reviewSchema = Yup.object({
  rating: Yup.string()
    .min(1, "Too Short!")
    .max(5, "Too Long!")
    .required("Rating required"),
  communication_rating: Yup.string().required("Rating required"),
  value_rating: Yup.string().required("Rating required"),
  quality_rating: Yup.string().required("Rating required"),
  hospitality_rating: Yup.string().required("Rating required"),
  networking_rating: Yup.string().required("Rating required"),
  comment: Yup.string().required("Comment is required"),
});

const UpdateReviews = ({
  userReviewsData,
  catalogueId,
  handleUserReview,
  handleClose,
}: any) => {
  const [catalogueName, setCatalougeName] = React.useState("");

  const formik = useFormik({
    initialValues: {
      rating: 0,
      communication_rating: 0,
      value_rating: 0,
      quality_rating: 0,
      hospitality_rating: 0,
      networking_rating: 0,
      comment: "",
      radioButtons: "public",
    },
    validationSchema: reviewSchema,
    onSubmit: async (values, { setSubmitting }) => {
      handleUserReview(values)
    },
  });

  React.useEffect(() => {
    if (userReviewsData) {
      userReviewsData.map((review: any) => {
        const { attributes } = review;
        let id = attributes.catalogue_id;

        if (id === catalogueId) {
          formik.setFieldValue("rating", attributes.rating);
          formik.setFieldValue(
            "communication_rating",
            attributes.communication_rating
          );
          formik.setFieldValue("value_rating", attributes.value_rating);
          formik.setFieldValue("quality_rating", attributes.quality_rating);
          formik.setFieldValue(
            "hospitality_rating",
            attributes.hospitality_rating
          );
          formik.setFieldValue(
            "networking_rating",
            attributes.networking_rating
          );
          formik.setFieldValue("comment", attributes.comment);
          setCatalougeName(attributes.catalogue_name);
        }
      });
    }
  }, []);

  return (
    <Box sx={webStyles.bottomRight}>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Box sx={webStyles.formCard}>
          <Box sx={webStyles.header}>
            <Typography variant="h4" style={{ color: "#FFF" }}>
              Edit Review for {catalogueName}
            </Typography>
            <CloseIcon style={{ color: "#ffffff" }} onClick={handleClose} />
          </Box>
          <Box sx={{ padding: "20px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <FormControl
                component="div"
                style={{ padding: "0px 0px 20px", width: "33%" }}
              >
                <FormLabel component="legend" style={webStyles.legendStyle}>
                  Overall Rating
                </FormLabel>
                <Rating
                  name="rating"
                  value={formik.values.rating}
                  size="small"
                  onChange={(event, newValue) => {
                    formik.setFieldValue("rating", newValue);
                  }}
                />
                 {formik.errors.rating && formik.touched.rating ? (<p style={webStyles.formError}>{formik.errors.rating}</p>) : null}
              </FormControl>
              <FormControl
                component="div"
                style={{ padding: "0px 0px 20px", width: "33%" }}
              >
                <FormLabel component="legend" style={webStyles.legendStyle}>
                  Quality
                </FormLabel>
                <Rating
                  name="qualityRating"
                  value={formik.values.quality_rating}
                  size="small"
                  onChange={(event, newValue) => {
                    formik.setFieldValue("quality_rating", newValue);
                  }}
                />
                 {formik.errors.quality_rating && formik.touched.quality_rating ? (<p style={webStyles.formError}>{formik.errors.quality_rating}</p>) : null}
              </FormControl>
              <FormControl
                component="div"
                style={{ padding: "0px 0px 20px", width: "33%" }}
              >
                <FormLabel component="legend" style={webStyles.legendStyle}>
                  Value
                </FormLabel>
                <Rating
                  name="valueRating"
                  value={formik.values.value_rating}
                  size="small"
                  onChange={(event, newValue) => {
                    formik.setFieldValue("value_rating", newValue);
                  }}
                />
                 {formik.errors.value_rating && formik.touched.value_rating ? (<p style={webStyles.formError}>{formik.errors.value_rating}</p>) : null}
              </FormControl>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <FormControl
                component="div"
                style={{ padding: "0px 0px 20px", width: "33%" }}
              >
                <FormLabel component="legend" style={webStyles.legendStyle}>
                  Communication
                </FormLabel>
                <Rating
                  name="communication_rating"
                  value={formik.values.communication_rating}
                  size="small"
                  onChange={(event, newValue) => {
                    formik.setFieldValue("communication_rating", newValue);
                  }}
                />
                 {formik.errors.communication_rating && formik.touched.communication_rating ? (<p style={webStyles.formError}>{formik.errors.communication_rating}</p>) : null}
              </FormControl>
              <FormControl
                component="div"
                style={{ padding: "0px 0px 20px", width: "33%" }}
              >
                <FormLabel component="legend" style={webStyles.legendStyle}>
                  Hospitality
                </FormLabel>
                <Rating
                  name="hospitality_rating"
                  value={formik.values.hospitality_rating}
                  size="small"
                  onChange={(event, newValue) => {
                    formik.setFieldValue("hospitality_rating", newValue);
                  }}
                />
                 {formik.errors.hospitality_rating && formik.touched.hospitality_rating ? (<p style={webStyles.formError}>{formik.errors.hospitality_rating}</p>) : null}
              </FormControl>
              <FormControl
                component="div"
                style={{ padding: "0px 0px 20px", width: "33%" }}
              >
                <FormLabel component="legend" style={webStyles.legendStyle}>
                  Networking
                </FormLabel>
                <Rating
                  name="networking_rating"
                  value={formik.values.networking_rating}
                  size="small"
                  onChange={(event, newValue) => {
                    formik.setFieldValue("networking_rating", newValue);
                  }}
                />
                 {formik.errors.networking_rating && formik.touched.networking_rating ? (<p style={webStyles.formError}>{formik.errors.networking_rating}</p>) : null}
              </FormControl>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <FormControl
                component="div"
                style={{ padding: "0px 0px 20px", width: "100%" }}
              >
                <TextareaAutosize
                  name="comment"
                  minRows={3}
                  placeholder="Comment"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.comment}
                />
                 {formik.errors.comment && formik.touched.comment ? (<p style={webStyles.formError}>{formik.errors.comment}</p>) : null}
              </FormControl>
            </Box>
            <Box>
              <FormControl>
                <FormLabel id="radio-buttons" style={{ fontSize: "14px" }}>
                  Who should see this review?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="radio-buttons"
                  defaultValue="public"
                  name="radioButtons"
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value="public"
                    control={
                      <Radio
                        size="small"
                        color="primary"
                        style={webStyles.radioStyle}
                      />
                    }
                    label={
                      <span style={{ fontSize: "14px" }}>
                        Public. Display review on festival's profile next to my
                        name.
                      </span>
                    }
                  />
                  <FormControlLabel
                    value="private"
                    control={
                      <Radio
                        size="small"
                        color="primary"
                        style={webStyles.radioStyle}
                      />
                    }
                    label={
                      <span style={{ fontSize: "14px" }}>
                        Private. Only the festival can see my review.
                      </span>
                    }
                  />
                  <FormControlLabel
                    label={
                      <span style={{ fontSize: "14px" }}>
                        The festival can see my review but I will remain
                        anonymous.
                      </span>
                    }
                    value="anonymous"
                    control={
                      <Radio
                        size="small"
                        color="primary"
                        style={webStyles.radioStyle}
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          <Box sx={webStyles.footer}>
            <Button
              variant="contained"
              style={{
                marginLeft: "10px",
                background: "#fff",
                color: "#A5259F",
                border: "2px solid",
              }}
            >
              Delete Review
            </Button>
            <Button
              variant="contained"
              type="submit"
              style={{
                background: "#A5259F",
                color: "#fff",
                margin: "0 20px 0 10px",
                border: "2px solid #A5259F",
              }}
            >
              Save Changes
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};
export default UpdateReviews;

const webStyles = {
  bottomRight: {
    display: "flex",
    flexDirection: "column",
  },
  formCard: {
    background: "#fff",
    marginBottom: "20px",
  },

  legendStyle: {
    fontSize: "14px",
  },
  footer: {
    display: "flex",
    justifyContent: "End",
  },
  header: {
    bgcolor: "#320b4c",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  radioStyle: { paddingTop: 5, paddingBottom: 0 },
  formError: {
    margin: "0px",
    color: "#ef2525"
}
};
