// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  formik?: any;
  addCustomFile?: any;
  deleteRow?: any;
  handleChangeInput?: any;
  customRow?: any;
  checkedValues?: any;
  checkboxError?: any;
  countryError?: any;
  handleSelect?: any;
  formikProps?: any;
  handleNextStep?: any;
  handleBack?: any;
  root?: any;
}

interface S {
  deadline_waiver_days:number
}

interface SS {
  id: any;
}

export default class CoverImageController extends BlockComponent<
  Props,
  S,
  SS
> {
  discountperiodDataCall: any;
  getCurrencyDataAPICall: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      deadline_waiver_days:14
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // async componentDidMount() {
  //   this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
  // }

  async receive(from: string, message: Message) {


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.discountperiodDataCall) {
        if (responseJson != null && responseJson.error === undefined) {
          toast.success("Congratulations, Your changes have been saved")
        } else {
          toast.error(responseJson.error)
          this.parseApiErrorResponse(responseJson);
        }
      }

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          var arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }
      }
    }
  }


  handleSubmitEvent = (values: any) => {
    let formData = new FormData();
    formData.append("update_discount_coupon_waiver_periods", values.deadline_waiver_days);
    this.discountperiodData(formData)
    console.log("formData-->", values)
  }

  discountperiodData(values: any) {
    const token = localStorage.getItem('token')
    const header = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.discountperiodDataCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.DiscountwaiverPeriod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.discountwaiverdataApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      values
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


}
 // Customizable Area End
