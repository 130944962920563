// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  formik?: any;
  addCustomFile?: any;
  deleteRow?: any;
  handleChangeInput?: any;
  customRow?: any;
  checkedValues?: any;
  checkboxError?: any;
  countryError?: any;
  handleSelect?: any;
  formikProps?: any;
  handleNextStep?: any;
  handleBack?: any;
  root?: any;
  messageData?: any;
}

interface S {
    confirmation_message: string,
    messageData: string
}

interface SS {
  id: any;
}

export default class CoverImageController extends BlockComponent<
  Props,
  S,
  SS
> {
  submisionConform: any;
  getCurrencyDataAPICall: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  submissionConformMessageApi: string = "";

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
        confirmation_message: "",
        messageData:""
      };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    this.getsubmissionConformMessage()
}

async receive(from: string, message: Message) {


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.submissionConformMessageApi) {
        console.log("responseJson.data",responseJson.data)
        this.setState({
          confirmation_message: responseJson.data.attributes.confirmation_message
        })
      }

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          var arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }
      }
    }
  }


  handleMessageSubmitEvent = (confirmation_message:any) => {
    let formData = new FormData();
    formData.append("confirmation_message",confirmation_message);
    this.submissionConformMessage(formData)
    console.log("submissionData-->", confirmation_message)
    toast.success("Congratulations, Your changes have been saved")
  }

  submissionConformMessage(values: any) {
    const token = localStorage.getItem('token')
    const header = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submisionConform = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submissionConformMessageendpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.submissionConformMessageApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      values
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getsubmissionConformMessage(): boolean {
    const header = {
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.submissionConformMessageApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submissionConformMessageendpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getdiscountwaiverdataApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }

  //   createMarkup = () => {
  //   return {__html: this.state.confirmation_message};
  // }
  
  //  MyComponent = () => {
  //   return <div dangerouslySetInnerHTML={this.createMarkup()} />;
  // }       

  handleQuillChange  = (e:any) => {
    console.log("evalues-->",e)
    this.setState({confirmation_message:e})
  }

}
 // Customizable Area End
