Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "POST";
exports.methodTypeApiGetUserProfile = "GET";
exports.methdTypeApiGetRole = "GET";
exports.getCountryApimethodType = "GET";
exports.exampleAPiMethod = "POST";

exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomForm2";
exports.labelBodyText = "CustomForm2 Body";
exports.addProjectEndpoint = "bx_block_posts/project_informations";
exports.getAllCountryURL = "/bx_block_posts/specifications/select_countries";
exports.endPointApiGetCurrency = "/bx_block_posts/specifications/get_currencies";
exports.endPointApiGetRole = "bx_block_roles_permissions/roles"
exports.endPointApiGetLanguage = "/bx_block_posts/specifications/get_languages";
exports.endPointApiGetBudget = "/bx_block_posts/specifications/get_production_budget";
exports.endPointApiUpdateAccountDetails = "account_block/accounts/3";
exports.methodTypeApiGetUserProfile = "GET";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End