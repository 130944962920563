// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


import {toast} from "react-toastify";
import React from "react";


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    showHeader?:boolean;

}

interface S {
    accountAPIData:Object;
    profilepic:Object;
    previewprofile:String;
    coverpic:Object;
    coverpreview:String;
    updateType:String;
    isLoading:boolean;
    festivalDetails: {
        entry_deadlines:Array<any>;
    };
    reviewDetails:Object;
    id:any;
}

interface SS {
    id: any;
}

export default class AboutFestivalController extends BlockComponent<
    Props,
    S,
    SS
    > {

    ProfileAndCoverPhotoApiCall:string = ""
    getFestivalDataApiCall:string = ""
    getFestivalReviewDataApiCall:string = ""
    manageMarkReviewApiCallId:string = ""
    emailReg: RegExp;
    labelTitle: string = "";

    constructor(props: Props) {

        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ]

        this.state = {
            accountAPIData: {
                cover_pic:"",
                profile_pic:"",
                first_name:"",
                last_name:"",
                title:"",
                created_at:"2022-05-25T07:20:31.222Z"
            },
            coverpic:{cover_pic:"" as any},
            coverpreview:"",
            profilepic:{ profile_pic:"" as any},
            previewprofile:"",
            updateType:"",
            isLoading:true,
            festivalDetails:{
                entry_deadlines:[]
            },
            id:"",
            reviewDetails:{}
        };

        // @ts-ignore
        this.myRef = React.createRef();
        // @ts-ignore
        this.myRef1 = React.createRef();

        this.emailReg = new RegExp("");
        this.labelTitle = configJSON.labelTitle;

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    async componentDidMount() {
        const id = window.location.href.split("/")[5]
        this.getFestivalData(id)
        this.getFestivalReviewData(id)
        if(id){
            this.setState({
                id
            })
        }
    }
    async receive(from: string, message: Message) {
        if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            if(this.getFestivalDataApiCall === apiRequestCallId) {
                if(responseJson?.hasOwnProperty('data')){
                    this.setState({
                        festivalDetails:responseJson.data.attributes,
                        isLoading:false,
                    })
                    this.sentMessage({
                        ...responseJson.data.attributes,
                        type:"FESTIVAL_OBJECT"
                    })
                }else{
                    responseJson.errors?.map((item:any)=>{
                        if(item.token === "Token has Expired"){
                            localStorage.removeItem("authToken")
                            localStorage.removeItem("profile")
                            localStorage.removeItem("token")
                            localStorage.removeItem("usertoken")
                            this.props.navigation.navigate("/")
                            toast.error("Token Expired")
                        }else{
                            console.log("GET FESTIVAL DATA ERROR",item)
                        }
                    })
                }
            }if(this.getFestivalReviewDataApiCall === apiRequestCallId){
                if(responseJson?.hasOwnProperty('data')){
                    this.setState({
                        reviewDetails:responseJson
                    })
                }
            }if(this.manageMarkReviewApiCallId === apiRequestCallId){
                if(responseJson.hasOwnProperty('data')){
                    this.getFestivalReviewData(this.state.id)
                }else{
                    toast.error("Something went wrong")
                }
            }
        }

    }

    handleMark(markedFlag:boolean,reviewId:any) {
        let formData = new FormData()
        // @ts-ignore
        formData.append('marked_as_helpful', markedFlag)
        this.manageMarkReview(formData,reviewId)
    }

    manageMarkReview (data: any,id:any) {
        const token = localStorage.getItem('authToken')
        const headers = {
            token : token,
            'x-amz-acl': 'public-read'
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.manageMarkReviewApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.festivalHelpFulReviewApiEndPoint}${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            data
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalHelpFulReviewMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getFestivalData(id:any): boolean {
        const header = {
            "Content-Type": configJSON.festivalDataApiContentType,
            token: localStorage.getItem('authToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFestivalDataApiCall  = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.festivalDataApiEndPoint}/${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalDataApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    getFestivalReviewData(id:any): boolean {
        const header = {
            "Content-Type": configJSON.festivalReviewDataApiContentType,
            token: localStorage.getItem('authToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFestivalReviewDataApiCall  = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.festivalReviewDataApiEndPoint}${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.festivalReviewDataApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }


    sentMessage (data:any) {
        const msg : Message = new Message(getName(MessageEnum.PostDetailDataMessage))
        msg.properties['text'] = data
        this.send(msg)
    }
}

// Customizable Area End