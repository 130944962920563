// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  formik?: any;
  addCustomFile?: any;
  deleteRow?: any;
  handleChangeInput?: any;
  customRow?: any;
  checkedValues?: any;
  checkboxError?: any;
  countryError?: any;
  handleSelect?: any;
  formikProps?: any;
  handleNextStep?: any;
  handleBack?: any;
  root?: any;
}

interface S {
    customValue: string,
    invitationmail:string
    // previewValue: string
}

interface SS {
  id: any;
}

export default class CoverImageController extends BlockComponent<
  Props,
  S,
  SS
> {
  editEventCall: any;
  getCurrencyDataAPICall: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
    ]

    this.state = {
        customValue: "The call for entries for test is now open! Here's a special code that you can use for 20% off your entry fee: [Auto Generate Code] We can't wait to see what you've been working on, and we hope to see you at this year's festival!Cheers,test" ,
        invitationmail: "",
        // previewValue: "The call for entries for test is now open! Here's a special code that you can use for 20% off your entry fee: [Auto Generate Code] We can't wait to see what you've been working on, and we hope to see you at this year's festival!Cheers,test"
      };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    //@ts-ignore
    const profileData = JSON.parse(localStorage.getItem('profile' as any))
    this.setState({invitationmail: profileData.email})
    // invitationmail = JSON.parse(localStorage.getItem('profile'));
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    console.log("profileData",this.state.invitationmail, profileData);
}

async receive(from: string, message: Message) {


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          var arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }
      }
    }
  }


  handleSubmitEvent = (values: any) => {
    let formData = new FormData();
    formData.append("discount_coupon[deadline_waiver_days]", values.deadline_waiver_days);
    // customRow.forEach((element: any, id: any) => {
    //   formData.append(`event[event_organizers_attributes]${[id]}[name]`, element.name);
    //   formData.append(`event[event_organizers_attributes]${[id]}[title]`, element.title);
    // })
    this.discountperiodData(formData)
    console.log("formData-->", values)
    toast.success("Congratulations, Your changes have been saved")
  }

  discountperiodData(values: any) {
    const token = localStorage.getItem('token')
    const header = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editEventCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.discountwaiverDataendpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.discountwaiverdataApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      values
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


}
 // Customizable Area End
