import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  TextField,
  FormControl,
  Typography,
  Grid,
  CircularProgress,
  Paper
} from "@material-ui/core";
import Footer from "../../../components/src/AppFooter.web"
import {imageIcon} from "./assets";
// import ViewProject from './myProjectDetails.web';

const useStyles = makeStyles(theme => ({
    addPhotos:{
        position: "absolute",
        maxWidth: "120px",
        cursor: "pointer",
        textAlign: "center",
        left: 0,
        right: 0,
        margin: "auto",
        top: "38%",
        },
    uploadStill:{
        width: "100%",
        height: "100%",
        border: '2px dashed black',
        position: 'relative',
        minHeight:238,
        maxWidth:428,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        textAlign:"center",
        margin:"10px"
    },
    addDirHeadshot:{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        width: "100%",
        height: "100%", 
        border: '2px dashed black', 
        position: 'relative', 
        maxHeight:"50px",
        marginTop:"10px"
    },
        headshotInput:{
        opacity: 0,
        width: "100%",
        height: "100%",
        cursor: 'pointer',
        position: "absolute",
        zIndex: 1, 
        top: 0, 
        left: 0
    },
    uploadButton: {
        width:"100px",
        display:"flex",
        alignItem:"center",
        justifyContent:"center",
        margin:"0 auto",
        backgroundColor:"#F1F1F1",
        color:"#000"
    },
    musicPaper:{
        padding:"24px"
    },
    uploadTextFile: {
        display:"flex",
        justifyContent:"center",
        margin:"10px 0px"
    },
    submitButton:{
        marginTop:"30px",
        width:"100%",
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    musicHeader:{
        display:"flex",
        alignItem:'center',
        justifyContent:"center",
        marginBottom:"5px"
    },
    hideVideo:{
        display:"none"
        },
    icon: {
        color: '#a5259f',
        },
        root: {
            '& .MuiInputBase-input': {
            height: '1.5em'
        },
    }
}));

export interface accountPhotosType {
    id: number, url:string,
  }
const ProjectFiles = (props:any,{
    handleAccountSettings,
    accountData,
    currencyData,
    delvideo,
    Videovisible,
    imageloader
    // VideoFlag

}: any) => {
  const [radiovalue, setRadioValue] = React.useState("1"); 
  const [id, setId] = React.useState(window.location.pathname.trim().split("/")[2]) 
  const [stillFrames, setStillFrame] = React.useState("");
  const [previewVideo, setPreviewVideo] = React.useState("");
  const [ProjectInfo, setProjectInfo] = React.useState(props.ProjectInfo);
  const [VideoFlag, setVideoFlag] = React.useState(props.VideoFlag);
  const [loaders, setLoaders] = React.useState(props.loaders)
  const [languageData, setLanguageData] = React.useState(props.languageData);
  const [language, setLanguage] = React.useState("");
  const [stillPhoto, setStillPhoto] = React.useState({still_frame:"" as any});
  const [screenervideo, setScreenerVideo] = React.useState({video_file:"" as any, id: id});
  const [subtitleFile, setSubtitleFile] = React.useState({subtitle_file:"" as any})
  const [subtitllelan, setSubtitleLan] = React.useState<any>({subtitle_language_id:"" as any,id: id})
  const [multiselectPhoto, setMultiselectPhoto] = React.useState<Array<accountPhotosType>>();
  const [inputKey, setInputKey] = React.useState(0);
  const [files, setFiles] = React.useState<File[]>([]);
  const [selectedFilename, SetSelectedFileName] = React.useState("")
  const [screener, setScreener] = React.useState(
    {video_url: props.ProjectInfo ? ProjectInfo?.attributes?.online_screener?.data.attributes?.video_url: "", 
    password: props.ProjectInfo ? ProjectInfo?.attributes?.online_screener?.data.attributes?.password: "", 
    video_file:"" as any, id: id});

    const stillFrameHnadler = (e:any) => {
        setStillPhoto({
        ...stillPhoto,
        "still_frame" : e.target.files[0],
        });
        setStillFrame(URL.createObjectURL(e.target.files[0]) as any)
    }

    React.useEffect(() => {
        if(props.ProjectInfo){
          setMultiselectPhoto(props.ProjectInfo?.attributes?.online_screener?.data?.attributes?.photography_photos)
        }
      }, [props.ProjectInfo])

    React.useEffect(() => {
        if(stillPhoto.still_frame !== ""){
            setTimeout(() => {
                props.handleStillPhoto(stillPhoto)
              }, 2000);
        }     
    }, [stillPhoto]) 
    
    React.useEffect(() => {
          
    }, [VideoFlag, ProjectInfo, loaders]) 

    const handleRemoveVideo = async (id:any) => {
        let shouldDelete = confirm('Do you really want to delete this Video?');
        if (shouldDelete) {
            props.deleteVideo(id)
            setVideoFlag(false)
        }
    }

    const handleRestoreVideo = async (id:any) => {
        let shouldRestore = confirm("Do you really want to restore this video?");
        if(shouldRestore){
            props.restoreVideo(id)
            setVideoFlag(true)
        }
    }

     const handleScreenerVideo = (e:any) => {   
        setScreenerVideo({
                ...screenervideo,
               "video_file" : e.target.files[0],
            });
            setPreviewVideo(URL.createObjectURL(e.target.files[0]) as any)
        
        }
    
    const handleSubtitleFile = (e:any) => {
            setSubtitleFile({
                ...subtitleFile,
                "subtitle_file" : e.target.files[0]
            })
    }

    React.useEffect(() => {
        if(subtitleFile.subtitle_file !== "" && subtitllelan.subtitle_language_id !== ""){
            setTimeout(() => {                                                                             
                props.handleSubtitleFiles(subtitleFile, subtitllelan)
              }, 2000);
        }
    },[subtitleFile,subtitllelan])
    
    React.useEffect(() => {
        if(screenervideo.video_file !== ""){
            setTimeout(() => {                                                                             
                props.handleScennerVid(screenervideo)
              }, 2000);
        }     
    }, [screenervideo]) 

//============================= MULTIPLE PHOTOS ===============================
const changeHandler = ({
    currentTarget: { files },
}: React.ChangeEvent<HTMLInputElement>) => {
    if (files && files.length) {
        setFiles((existing) => existing.concat(Array.from(files)));
    }
    setInputKey((key) => key + 1);
    props.handleUpdatePhotos(files);
  };

  const handleAttachment = (e:any) =>{
    if(e.target.name == "video_url" || e.target.name == "password") {
        setScreener({
        ...screener,
        [e.target.name]: e.target.value,
        });
    }else{  
        setScreener({
            ...screener,
           "video_file" : e.target.files[0]
        });
        setPreviewVideo(URL.createObjectURL(e.target.files[0]) as any)
        SetSelectedFileName(e.target.files[0].name)
    }
}

const handleScreenerUpload = () => {
    props.handleScanner(screener)
 }

React.useEffect(() => {
    if(props.ProjectInfo){
        setScreener( {video_url: ProjectInfo != null ? ProjectInfo?.attributes?.online_screener?.data.attributes?.video_url: "", 
        password:ProjectInfo != null ? ProjectInfo?.attributes?.online_screener?.data.attributes?.password: "", 
        video_file:"" as any, id: id})
    }
},[props.ProjectInfo])

const handleDeleteImage = (id: any) => {
    props.deleteAccountPhotos(id)
  };


  const classes = useStyles();
  return (
      <Box sx={{margin:"-24px"}}>
        <Box sx={webStyles.bottomRight}>
            <Grid style={{maxWidth:1600, paddingLeft:200}}>
            <form autoComplete="off">
                {ProjectInfo.attributes.project_type ==  "music/song_writing" || ProjectInfo.attributes.project_type ==  "script" ?
                <> 
                    <Box sx={webStyles.musicBox}>
                        <Paper className={classes.musicPaper}>
                            <Typography className={classes.musicHeader}>Upload your file for this Project.</Typography> 
                            <input  
                            type='file'
                            onChange={handleAttachment}
                            name="video_file" 
                            //  className={previewVideo == "" ? classes.zindexUp : classes.zindexDown}
                            style={{width: "100%",cursor: 'pointer', position: "absolute", top: 0, left: 0, display:"none"}}
                            id="contained-button-file"
                            placeholder="Upload Files"
                            accept="application/pdf,application/msword,.mp3,audio/"
                            />
                            <>
                            <label htmlFor="contained-button-file">
                                <Button variant="contained" className={classes.uploadButton} component="span">
                                    Upload
                                </Button>
                                <div className={classes.uploadTextFile}>{selectedFilename == "" ? props.ProjectInfo?.attributes?.online_screener?.data.attributes?.filename : selectedFilename} |
                                    <a href={props.ProjectInfo?.attributes?.online_screener?.data.attributes.video_file ? props.ProjectInfo?.attributes?.online_screener?.data.attributes.video_file : "#"} download target='_blank'>Download</a>
                                </div>
                            </label>
                            </>
                                        
                            <Grid className={classes.submitButton}>
                                <Button variant="contained" color="primary" style={{width:"25%"}} disabled={previewVideo != "" ? false : true} onClick={handleScreenerUpload}>Submit</Button>
                            </Grid> 
                        </Paper> 
                    </Box>
                </>
                :
                (ProjectInfo.attributes.project_type ==  "photography/design") ?
                <>
                <Box sx={webStyles.formCard}>
                    <Box sx={{bgcolor: "#320b4c", padding: "10px", display: "flex", justifyContent: "space-between"}}>
                            {multiselectPhoto ? (
                            <Typography
                            variant="h4"
                            style={{
                                color: "#FFF",
                                cursor: "pointer",
                                paddingRight: "20px",
                                position: "relative",
                            }}
                            >
                            Add Photos
                            <input
                                key={inputKey}
                                type="file"
                                onChange={changeHandler}
                                name="account_photo"
                                style={webStyles.addPhotos}
                                multiple
                                accept="image/*"
                            />
                            </Typography>
                        ) : (
                            ""
                        )}
                    </Box>
                        {multiselectPhoto && multiselectPhoto.length > 0 && (
                        <Box sx={{ padding: "20px" }}>
                        <Box
                            style={{
                            position: "relative",
                            height: `${Math.ceil(multiselectPhoto.length / 4) * 205}px`,
                            border: "2px solid #ccc",
                            borderRadius: "10px",
                            }}
                        >
                            {imageloader && <Box sx={webStyles.dragZonOverlay}>
                                <Box sx={webStyles.dragZonLoader}>
                                    <CircularProgress />
                                </Box>
                            </Box> }
                            <div
                            style={{
                                width: "100%",
                                height: `${Math.ceil(multiselectPhoto.length / 4) * 205}px`,
                                position: "absolute",
                                top: "0",
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center",
                            }}
                            >
                            {multiselectPhoto.map((file, index) => {
                                let width = multiselectPhoto.length <= 4
                                ? `${100 / multiselectPhoto.length}%`
                                : "25%";
                                return (
                                <>
                                    <div
                                    key={index}
                                    style={{
                                        flex: "0 0 auto",
                                        width: width,
                                        position: "relative",
                                    }}
                                    >
                                    <span
                                        style={webStyles.closeButton}
                                        onClick={() => handleDeleteImage(file.id)}
                                    >
                                        X
                                    </span>
                                    <img
                                        src={file.url}
                                        style={webStyles.imageContainer}
                                    />
                                    </div>
                                </>
                                );
                            })}
                            </div>
                        </Box>
                        </Box>
                        ) }
                    {!multiselectPhoto && <Box style={{ background: "white" }}>
                            <Box sx={webStyles.dragzon}>
                            {imageloader && <Box sx={webStyles.dragZonOverlay}>
                                <Box sx={webStyles.dragZonLoader}>
                                    <CircularProgress />
                                </Box>
                            </Box> }
                                <input
                                key={inputKey}
                                type="file"
                                onChange={changeHandler}
                                name="account_photo"
                                multiple
                                accept="image/*"
                                style={{
                                    opacity: 0,
                                    width: "100%",
                                    height: "200px",
                                    cursor: "pointer",
                                    position: "absolute",
                                    zIndex: 1,
                                    top: 0,
                                    left: 0,
                                }}
                                />
                                <Box sx={webStyles.dragzonCard}>
                                    <img src={imageIcon} width={40} style={{marginBottom: "10px"}} />
                                    <Typography variant="body2" style={webStyles.dragNDrop} >Drag & Drop image/s to upload</Typography>
                                </Box>
                            </Box>
                        </Box>
                    }
                </Box>
                </> 
                :
                <>
                    <Box sx={{padding: "10px"}}>
                        <Typography variant="h4" style={{ color: "#000", fontWeight:"bold"}} >This Video is Archived</Typography>
                    </Box>
                    <Box sx={webStyles.formCard}>
                        {VideoFlag ?
                        <>
                        {loaders == true ?
                        <> 
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center", width: "100%", height: 200}}>
                                <CircularProgress />
                            </div>
                        </>:
                        <>
                            {previewVideo == "" && ProjectInfo?.attributes?.online_screener?.data.attributes?.video_file == null
                            ? ""
                            : <video controls src={previewVideo == "" ? ProjectInfo?.attributes?.online_screener?.data.attributes?.video_file : previewVideo} style={{width: "100%", height: "100%",cursor: 'pointer'}}/>
                            }
                        </>
                        }
                        </> 
                        : 
                        <>
                        <Box sx={{padding: "20px"}}>
                            <Typography>We noticed this project has not been submitted in quite a while so we archived the video file. Don't worry, you can restore your video with one click.</Typography>
                        </Box>
                        <Box sx={{padding: "20px"}}>
                            <Button color="primary" data-id={ProjectInfo?.id} onClick={() => {handleRestoreVideo(ProjectInfo.id)}}>RESTORE</Button>
                        </Box>
                        </>
                        }
                    </Box>
                </>
                }
              
                <Box>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2} style={{marginBottom:100}}>
                            <Grid item xs={8}>
                                <Typography variant="h4" style={{ color: "#000", fontWeight:"bold"}} >Still Frame</Typography>
                                <Box sx={webStyles.stillFrame}>
                                    <Typography variant="body2" style={{ color: "#000", marginTop:"20px", marginBottom:"10px",padding:"10px"}} >Upload a still frame to represent your video.</Typography>
                                    <Box sx={{display:"flex", height:"80%", justifyContent:"space-evenly"}}>
                                        <div className={classes.uploadStill}>
                                                    {stillFrames == "" && ProjectInfo?.attributes?.still_frame == "" 
                                                        ?
                                                        <>
                                                        <Typography>add a still</Typography>
                                                        </>
                                                        : <img src={stillFrames == "" ? ProjectInfo?.attributes?.still_frame : stillFrames} style={{width: "100%", height: "100%", objectFit:"cover", cursor: 'pointer'}}/>
                                                    }
                                        </div>
                                        <div className={classes.uploadStill}>
                                            <div>
                                                <img style={{width:"50px"}} src={imageIcon} alt="logo"/> 
                                                <Typography style={{textDecoration: 'underline',color:'blue', cursor:"pointer"}}>Upload still</Typography>
                                            </div>
                                            <input  
                                                type='file'
                                                onChange={stillFrameHnadler} 
                                                // value={selectedFile.headshot || ""}
                                                name="media_file" 
                                                style={{opacity: 0,width: "100%", height: "100%",cursor: 'pointer', position: "absolute", zIndex: 1, top: 0, left: 0}}
                                                placeholder="Link or Upload your Video. Only Fastivals you submit to can view your Photo"
                                                accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"         
                                            />
                                        </div>
                                    </Box>
                                </Box>
                            </Grid>
                            {ProjectInfo.attributes.project_type ==  "photography/design" || ProjectInfo.attributes.project_type ==  "music/song_writing" || ProjectInfo.attributes.project_type ==  "script" ? ""
                            :
                            <Grid item xs={4}>
                                <Typography variant="h4" style={{ color: "#000", fontWeight:"bold"}} >Replace / Remove Video</Typography>
                                <Typography variant="body2" style={{ color: "#000", marginTop:"15px"}} >Replace this project file anytime. Your Submission Status will not be affected and 
                                festivals can see your updated project immediately.</Typography>
                                <React.Fragment>
                                    <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', pt: 3}}>
                                        <Button color="inherit" style={{marginRight:10}}>
                                            <label htmlFor="icon-button-cover">
                                                REPLACE 
                                                <input
                                                    type="file"
                                                    id="icon-button-cover"
                                                    hidden
                                                    onChange={handleScreenerVideo}
                                                    name="video_file"
                                                    style={{display: 'none'}}
                                                    accept="video/mp4,video/x-m4v,video/*" 
                                                />
                                            </label>
                                        </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                        <Button color="primary"  style={{marginRight:10}} data-id={ProjectInfo?.id} onClick={() => {handleRemoveVideo(ProjectInfo.id)}}> REMOVE </Button>   
                                    </Box>
                                </React.Fragment>
                                 {ProjectInfo.attributes.project_type ==  "music/song_writing" || ProjectInfo.attributes.project_type ==  "script" ? "" 
                                 :
                                 <>
                                <Typography variant="h4" style={{ color: "#000", fontWeight:"bold", marginTop:"15px"}} >Subtitle and Captions</Typography>
                                <Typography variant="body2" style={{ color: "#000", marginTop:"15px"}} >Upload your subtitle or caption files. Add as many languages as you like.</Typography>
                                <FormControl  style={{width: '100%', backgroundColor:"#fff", marginTop:"10px"}}>
                                  <TextField
                                    id="standard-select-role"
                                    placeholder="Select from OPtion"
                                    // onChange={handleChange}
                                    select
                                    SelectProps={{
                                      classes: { icon: classes.icon },
                                      native:true
                                    }}
                                    InputProps={{ className: classes.root }}
                                    variant="standard"
                                    name="subtitle_language_id"
                                    value={subtitllelan}
                                    onChange={(e) => setSubtitleLan(e.target.value)}
                                  >
                                    <option value="">Select Language</option>
                                     {languageData && languageData.map((lan: any) =>
                                        <option value={lan.id}>{lan.attributes.name}</option>
                                    )}
                                  </TextField>
                                </FormControl>
                                <div className={classes.addDirHeadshot}>
                                    <Typography>Subtitle File</Typography>
                                <input  
                                    type='file'
                                    onChange={handleSubtitleFile} 
                                    name="subtitle_file" 
                                    accept="text/plain"
                                    className={classes.headshotInput}
                                    placeholder="Link or Upload your Video. Only Fastivals you submit to can view your Photo"
                                    />
                                </div>
                                </>
                                }
                            </Grid>
                            }
                        </Grid>
                    </Box>
                </Box>
                {/* <React.Fragment>
                    <Box sx={{mb: 2}}>
                        <Button color="primary" type="submit" style={{width: '20%'}}>SAVE</Button>
                    </Box>
                </React.Fragment> */}
            </form>
            </Grid>
        </Box>
            <Footer />
        </Box>
  );
};
export default ProjectFiles;


const webStyles = {
    main: {
        display: "flex",
        justifyContent: "center",
        height: "100px",
        background: "000"
    },
    bottomRight: {
        display: "flex",
        flexDirection: "column",
        width: '90%',
    },
    musicBox:{
        width:"50%",
        display: "flex",
        flexDirection: "column",
        margin:'15px 0px'
    },
    formCard: {
        background: "#fff",
        marginBottom: "25px",
        // display:"flex"
    },
    formError: {
        margin: "0px",
        color: "#ef2525"
    },
    stillFrame: {
        bgcolor:"#fff", 
        width:"100%",
        height:"90%",
    },
    dragzon: {
        height: "200px",
        border: "2px solid #ccc",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "30px 50px",
        position: "relative" as const,
    },
    dragZonOverlay: {
        position: "absolute",
        width: "100%",
        height: "100%",
        background:" rgba(0,0,0,0.2)",
        zIndex: 3
    },
    dragZonLoader: {
        top: "50%",
        left:" 50%",
        transform: "translate(-50%, -50%)",
        position: "absolute"
    },
    dragzonCard: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    filmCard: {
        width: "50%",
        display: "flex",
        padding: "10px",
        marginBottom: "20px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        boxShadow: "2px 2px 6px 5px #eee",
        justifyContent: "space-between",
        gap: "15px"
    },
    dragNDrop: {
        color: "#4684EB", textDecoration: "underline", fontWeight: 600
    },
    addPhotos: {
      position: "absolute" as const,
      opacity: 0,
      top: "-9px",
      left: "0",
      height: "53px",
      width: "120px",
      cursor: "pointer",
  },
  imageContainer: {
      width: "-webkit-fill-available",
      objectFit: "cover" as const,
      height: "200px",
    //   position: "absolute" as const,
      border: "3px solid white",
      borderRadius: "10px",
  },
  closeButton: {
      position: "absolute" as const,
      top: "5px",
      right: "5px",
      background: "#fff",
      borderRadius: "50%",
      padding: "0 6px",
      zIndex: 2 as const,
      cursor: "pointer",
  },
  dragzonImage: {
      position: "relative" as const,
      height: "200px",
      border: "2px solid #ccc",
      borderRadius: "10px",
  }
}