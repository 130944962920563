// Customizable Area Start
import * as React from 'react';

import {
  Box,
  Button,
  TextField,
  FormLabel,
  FormControl,
  Typography,
  makeStyles
} from "@material-ui/core";
import { useFormik } from 'formik';
import moment from "moment";
import * as Yup from 'yup';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const SignupSchema = (openPassword: any) => {
    return Yup.object().shape({
    // firstName: Yup.string()
    //   .min(2, 'Too Short!')
    //   .max(50, 'Too Long!')
    //   .required('firstname required'),
    // lastName: Yup.string()
    //   .min(2, 'Too Short!')
    //   .max(50, 'Too Long!')
    //   .required('lastname required'),
    email: Yup.string().email('Invalid email').required('email required'),
    // phone: Yup.string()
    // .matches(/^[6-9]\d{9}$/, {message: "Please enter valid number.", excludeEmptyString: true})
    // .required('phone no is required'),
    // dob: Yup.string().required('date of birth is required'),
    // gender: Yup.string().required('select gender'),
    // timezone: Yup.string().required('select timeZone'),
    // currency: Yup.string().required('select currency'),
    password: openPassword && Yup.string().required('Please enter your password')
    .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, 
        'Password must contain at least 8 characters, one uppercase, one number and one special case character'),
    passwordConfirmation: openPassword && Yup.string().required('Confirm Password is required').oneOf([Yup.ref('password')], 'Passwords must match'),
})};

const useStyles = makeStyles({
    icon: {
      color: "#a5259f",
    },
    root: {
      "& .MuiInputBase-input": {
        height: "1.5em",
      },
      "& .MuiSelect-icon" : {
        color: "#a5259f",
      }
    },
    inputClass: {
        "& .Mui-focused:after": {
          border: "1px solid green",
        },
      },
});

const AboutMe = ({
    handleAccountSettings,
    accountData,
    currencyData,
    deleteAccount
}: any) => {
    const [ openPassword, setOpenPassword ] = React.useState(false)
    const classes = useStyles();
    const togglePasswordArea = () => {
        setOpenPassword(!openPassword)
    }
    const formik = useFormik({
        initialValues:{
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            dob: '',
            gender: '',
            timezone: '',
            currency: '',
            password: '',
            passwordConfirmation: ''
        },
        validationSchema:SignupSchema(openPassword),
        onSubmit:async (values, { setSubmitting }) => {
            // same shape as initial formik.values
            console.log(values);
            handleAccountSettings(values)
        }
    })

    React.useEffect(() => {
        if(accountData) {
            formik.setFieldValue('firstName', accountData.first_name);
            formik.setFieldValue('lastName', accountData.last_name);
            formik.setFieldValue('email', accountData.email);
            formik.setFieldValue('phone', accountData.full_phone_number);
            formik.setFieldValue('dob', accountData.dob);
            formik.setFieldValue('gender', accountData.gender);
            formik.setFieldValue('timezone', accountData.timezone);
            formik.setFieldValue('currency', accountData.currency_id);
        }
    }, [accountData])

    const handleAccountDelete = async () => {
        var shouldDelete = confirm('Do you really want to delete this Account ?');
        if (shouldDelete) {
            deleteAccount()
        }
    }

  return (
        <Box sx={webStyles.bottomRight}>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <Box sx={webStyles.formCard}>
                    <Box sx={{bgcolor: "#320b4c", padding: "10px"}}>
                        <Typography variant="h4" style={{ color: "#FFF"}} >My Account</Typography>
                    </Box>
                    <Box sx={{padding: "20px"}}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between'}} >
                            <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                                <FormLabel component="legend">First Name</FormLabel>
                                <TextField
                                    name="firstName"
                                    id="outlined-disabled"
                                    placeholder="John Doe"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.firstName}
                                    className={classes.inputClass}
                                />
                                {formik.errors.firstName && formik.touched.firstName ? (<p style={webStyles.formError}>{formik.errors.firstName}</p>) : null}
                            </FormControl>
                            <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                                <FormLabel component="legend">Last Name</FormLabel>
                                <TextField
                                    name="lastName"
                                    id="outlined-disabled"
                                    placeholder="Doe"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.lastName}
                                    className={classes.inputClass}
                                />
                                {formik.errors.lastName && formik.touched.lastName ? (<p style={webStyles.formError}>{formik.errors.lastName}</p>) : null}
                            </FormControl>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between'}} >
                            <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                                <FormLabel component="legend">Email</FormLabel>
                                <TextField
                                    name="email"
                                    id="outlined-disabled"
                                    placeholder="jhon@gmail.com"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    className={classes.inputClass}
                                />
                                {formik.errors.email && formik.touched.email ? (<p style={webStyles.formError}>{formik.errors.email}</p>) : null}
                            </FormControl>
                            <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                                <FormLabel component="legend">Phone No</FormLabel>
                                <TextField
                                    id="outlined-disabled"
                                    placeholder="eg: +91-999-768-867"
                                    name="phone"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.phone}
                                    className={classes.inputClass}
                                />
                                {formik.errors.phone && formik.touched.phone ? (<p style={webStyles.formError}>{formik.errors.phone}</p>) : null}
                            </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between'}} >
                            <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                                <FormLabel component="legend">DOB</FormLabel>
                                <TextField
                                    InputProps={{inputProps: { max: moment().format("YYYY-MM-DD")} }}
                                    className={classes.inputClass}
                                    id="outlined-disabled"
                                    type="date"
                                    placeholder="www.xyz.com"
                                    name="dob"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={moment(formik.values.dob).format("YYYY-MM-DD")}
                                />
                                {formik.errors.dob && formik.touched.dob ? (<p style={webStyles.formError}>{formik.errors.dob}</p>) : null}
                            </FormControl>
                            <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                                <FormLabel component="legend">Gender</FormLabel>
                                <TextField
                                    id="standard-select-currency-native"
                                    select
                                    SelectProps={{
                                        native: true,
                                    }}
                                    // helperText="Please select your currency"
                                    variant="standard"
                                    name="gender"
                                    InputProps={{ className: classes.root }}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.gender}
                                    className={classes.inputClass}
                                >
                                    <option value={''}>Select Gender</option>
                                    <option value={'male'}>Male</option>
                                    <option value={'female'}>Female</option>
                                    <option value={'others'}>Others</option>
                                </TextField>
                                {formik.errors.gender && formik.touched.gender ? (<p style={webStyles.formError}>{formik.errors.gender}</p>) : null}
                            </FormControl>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between'}} >
                            <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                                <FormLabel component="legend">Time Zone</FormLabel>
                                <TextField
                                    id="standard-select-currency-native"
                                    select
                                    InputProps={{ className: classes.root }}
                                    className={classes.inputClass}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    // helperText="Please select your currency"
                                    variant="standard"
                                    name="timezone"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.timezone}
                                >
                                    <option value={''}>Select TimeZone</option>
                                    <option value="America/Adak">(GMT-10:00) Hawaii-Aleutian</option>
                                    <option value="Etc/GMT+10">(GMT-10:00) Hawaii</option>
                                    <option value="Pacific/Marquesas">(GMT-09:30) Marquesas Islands</option>
                                    <option value="Pacific/Gambier">(GMT-09:00) Gambier Islands</option>
                                    <option value="America/Anchorage">(GMT-09:00) Alaska</option>
                                    <option value="America/Ensenada">(GMT-08:00) Tijuana, Baja California</option>
                                    <option value="Etc/GMT+8">(GMT-08:00) Pitcairn Islands</option>
                                    <option value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
                                    <option value="America/Denver">(GMT-07:00) Mountain Time (US & Canada)</option>
                                    <option value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                                    <option value="America/Dawson_Creek">(GMT-07:00) Arizona</option>
                                    <option value="America/Belize">(GMT-06:00) Saskatchewan, Central America</option>
                                    <option value="America/Cancun">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                                    <option value="Chile/EasterIsland">(GMT-06:00) Easter Island</option>
                                    <option value="America/Chicago">(GMT-06:00) Central Time (US & Canada)</option>
                                    <option value="America/New_York">(GMT-05:00) Eastern Time (US & Canada)</option>
                                    <option value="America/Havana">(GMT-05:00) Cuba</option>
                                    <option value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                                    <option value="America/Caracas">(GMT-04:30) Caracas</option>
                                    <option value="America/Santiago">(GMT-04:00) Santiago</option>
                                    <option value="America/La_Paz">(GMT-04:00) La Paz</option>
                                    <option value="Atlantic/Stanley">(GMT-04:00) Faukland Islands</option>
                                    <option value="America/Campo_Grande">(GMT-04:00) Brazil</option>
                                    <option value="America/Goose_Bay">(GMT-04:00) Atlantic Time (Goose Bay)</option>
                                    <option value="America/Glace_Bay">(GMT-04:00) Atlantic Time (Canada)</option>
                                    <option value="America/St_Johns">(GMT-03:30) Newfoundland</option>
                                    <option value="America/Araguaina">(GMT-03:00) UTC-3</option>
                                    <option value="America/Montevideo">(GMT-03:00) Montevideo</option>
                                    <option value="America/Miquelon">(GMT-03:00) Miquelon, St. Pierre</option>
                                    <option value="America/Godthab">(GMT-03:00) Greenland</option>
                                    <option value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires</option>
                                    <option value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                                    <option value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                                    <option value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                                    <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                                    <option value="Europe/Belfast">(GMT) Greenwich Mean Time : Belfast</option>
                                    <option value="Europe/Dublin">(GMT) Greenwich Mean Time : Dublin</option>
                                    <option value="Europe/Lisbon">(GMT) Greenwich Mean Time : Lisbon</option>
                                    <option value="Europe/London">(GMT) Greenwich Mean Time : London</option>
                                    <option value="Africa/Abidjan">(GMT) Monrovia, Reykjavik</option>
                                    <option value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                                    <option value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                                    <option value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                                    <option value="Africa/Algiers">(GMT+01:00) West Central Africa</option>
                                    <option value="Africa/Windhoek">(GMT+01:00) Windhoek</option>
                                    <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                                    <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                                    <option value="Asia/Gaza">(GMT+02:00) Gaza</option>
                                    <option value="Africa/Blantyre">(GMT+02:00) Harare, Pretoria</option>
                                    <option value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                                    <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                                    <option value="Asia/Damascus">(GMT+02:00) Syria</option>
                                    <option value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                                    <option value="Africa/Addis_Ababa">(GMT+03:00) Nairobi</option>
                                    <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                                    <option value="Asia/Dubai">(GMT+04:00) Abu Dhabi, Muscat</option>
                                    <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                                    <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                                    <option value="Asia/Yekaterinburg">(GMT+05:00) Ekaterinburg</option>
                                    <option value="Asia/Tashkent">(GMT+05:00) Tashkent</option>
                                    <option value="Asia/Kolkata">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                                    <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                                    <option value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                                    <option value="Asia/Novosibirsk">(GMT+06:00) Novosibirsk</option>
                                    <option value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                                    <option value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                                    <option value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                                    <option value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                                    <option value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                                    <option value="Australia/Perth">(GMT+08:00) Perth</option>
                                    <option value="Australia/Eucla">(GMT+08:45) Eucla</option>
                                    <option value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                                    <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                                    <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                                    <option value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                                    <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                                    <option value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                                    <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                                    <option value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                                    <option value="Australia/Lord_Howe">(GMT+10:30) Lord Howe Island</option>
                                    <option value="Etc/GMT-11">(GMT+11:00) Solomon Is., New Caledonia</option>
                                    <option value="Asia/Magadan">(GMT+11:00) Magadan</option>
                                    <option value="Pacific/Norfolk">(GMT+11:30) Norfolk Island</option>
                                    <option value="Asia/Anadyr">(GMT+12:00) Anadyr, Kamchatka</option>
                                    <option value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                                    <option value="Etc/GMT-12">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                                    <option value="Pacific/Chatham">(GMT+12:45) Chatham Islands</option>
                                    <option value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
                                    <option value="Pacific/Kiritimati">(GMT+14:00) Kiritimati</option>
                                </TextField>
                                {formik.errors.timezone && formik.touched.timezone ? (<p style={webStyles.formError}>{formik.errors.timezone}</p>) : null}
                            </FormControl>
                            <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                                <FormLabel component="legend">Currency</FormLabel>
                                <TextField
                                    id="standard-select-currency-native"
                                    select
                                    InputProps={{ className: classes.root }}
                                    className={classes.inputClass}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    // helperText="Please select your currency"
                                    variant="standard"
                                    name="currency"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.currency}
                                >
                                    <option value={''}>Select Currency</option>
                                    {currencyData && currencyData.map((cur: any) =>
                                        <option value={cur.id}>{cur.attributes.name} - {cur.attributes.code}</option>
                                    )}
                                </TextField>
                                {formik.errors.currency && formik.touched.currency ? (<p style={webStyles.formError}>{formik.errors.currency}</p>) : null}
                            </FormControl>
                        </Box>
                    </Box>
                </Box>
                <Box sx={webStyles.formCard}>
                    <Box sx={{bgcolor: "#320b4c", padding: "10px", display: "flex", justifyContent: "space-between" }} onClick={togglePasswordArea}>
                        <Typography variant="h4" style={{ color: "#FFF"}}>My Account</Typography>
                        <div style={openPassword ? webStyles.toggleArrow : { marginBottom: "2px"}}>
                        <KeyboardArrowDownIcon style={{ fill: "#fff", marginBottom: "-12px"}}/>
                        </div>
                    </Box>
                    {openPassword && 
                    <Box sx={{padding: "20px"}}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between'}} >
                            <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                            <FormLabel component="legend">Password</FormLabel>
                            <TextField
                                id="outlined-disabled"
                                type="password"
                                placeholder="********"
                                name="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                className={classes.inputClass}
                            />
                            {formik.errors.password && formik.touched.password ? (<p style={webStyles.formError}>{formik.errors.password}</p>) : null}
                            </FormControl>
                            <FormControl component="div" style={{ padding: '20px 0px', width: '45%'}}>
                            <FormLabel component="legend">Password Confirmation</FormLabel>
                            <TextField
                                id="outlined-disabled"
                                type="password"
                                placeholder="Welcome@1223"
                                name="passwordConfirmation"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.passwordConfirmation}
                                className={classes.inputClass}
                            />
                            {formik.errors.passwordConfirmation && formik.touched.passwordConfirmation ? (<p style={webStyles.formError}>{formik.errors.passwordConfirmation}</p>) : null}
                            </FormControl>
                        </Box>
                    </Box>
                    }
                </Box>
                <Box sx={webStyles.formCard}>
                    <Box sx={{bgcolor: "#320b4c", padding: "10px"}}>
                        <Typography variant="h4" style={{ color: "#FFF"}} >Account Deletion</Typography>
                    </Box>
                    <Box sx={{display: 'flex', justifyContent: "space-between", padding: "20px"}}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: "center"}} >
                            <Typography variant="h4" >{accountData && `${accountData.first_name} ${accountData.last_name}`}</Typography>
                            <Typography variant="h4" >Filmmaker (Currently Signed In)</Typography>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "column", alignContent: "center"}}>
                            <Button variant="contained" style={{ background: "#A5259F", color: "#fff", marginTop: "10px"}} onClick={handleAccountDelete}>delete account</Button>
                            {/* <Button variant="outlined" style={{ border: "1px solid #A5259F", color: "red", marginTop: "10px"}}>button</Button> */}
                        </Box>
                    </Box>
                </Box>
                <Box >
                    <Button variant="contained" type="submit" style={{ background: "#A5259F", color: "#fff", marginTop: "10px"}}>Save Changes</Button>
                </Box>
            </form>
        </Box>
  );
};
export default AboutMe;


const webStyles = {
    main: {
        display: "flex",
        justifyContent: "center",
        height: "100px",
        background: "000"
    },
    toggleArrow: {
        transform: "rotate(180deg)"
    },
    bottomRight: {
        display: "flex",
        flexDirection: "column",
        width: '75%'
    },
    formCard: {
        background: "#fff",
        marginBottom: "50px"
    },
    formError: {
        margin: "0px",
        color: "#ef2525"
    }
}
// Customizable Area End