import * as React from 'react';

import {
  Box,
  Link,
  Typography,
  Breadcrumbs
} from "@material-ui/core";
import { logo } from "./assets";

const footerLinks = ['How It Works', 'Browse Festivals', 'Testimonials', 'Benefits', 'Blog', 'Help', 'Contact'];

const Footer = () => {
  return (
    <Box >
        <Breadcrumbs style={webStyles.main} separator="|" aria-label="breadcrumb">
            {footerLinks.map((item, key) => 
               <Link underline="hover" key={key} style={{fontWeight: 700}} color="inherit" href="/" >
                {item}
             </Link> 
            )}
        </Breadcrumbs>
    </Box>
  );
};
export default Footer;


const webStyles = {
    main: {
        display: "flex",
        justifyContent: "center",
        height: "75px",
        background: "#FFF"
    }
}