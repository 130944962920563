// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  TextField,
  FormControlLabel,
  Checkbox,
  withStyles,
  FormGroup,
  Button,
  Popover
} from "@material-ui/core";
import "./style.css";

import AdditionalSettingsController, {
  Props
} from "./AdditionalSettingsController";
import { Formik } from "formik";
import { stepFiveSchema } from "./validationSchemas";
import Loader from "../../../components/src/Loader.web";
import {withRouter} from "react-router-dom";

class AdditionalSettings extends AdditionalSettingsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { checkedValues, checkboxError, isReinitailize, festivalData, anchorEl, isChecked } = this.state;
    const open = Boolean(anchorEl);
    return (
      <>
      
      { //@ts-ignore
      this.props[0].loading && <Loader loading={this.props[0].loading} /> }
      <Formik
        enableReinitialize={festivalData && isReinitailize}
        initialValues={{
          category_search: festivalData?.category_search || "",
          search_terms: festivalData?.search_term || "",
          min_minutes: festivalData?.runtime_search_minimum || "",
          max_minutes: festivalData?.runtime_search_maximum || "",
          listingVisibility: festivalData?.listing_visibility || "",
          listingUrl: festivalData?.listing_url || "",
          rules_terms: festivalData?.rules_terms || "",
        }}
        validationSchema={stepFiveSchema}
        onSubmit={(values, actions) => {
          this.handleSubmitEvent(values)
        }}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {props => (
          <form onSubmit={props.handleSubmit}>
            <Box sx={{ padding: "50px 0px 0px" }} bgcolor="#f2f3f5">
              <Typography variant="h2" component="h2">
                Accept Submissions to Your Event
              </Typography>
            </Box>

            <Box sx={{ padding: "20px 0px" }}>
              <Box className="formTitle">
                <Typography variant="h4" component="h4">
                  Categories & Entry Fees
                </Typography>
              </Box>
              <Box bgcolor="#FFF">
                <Box component="form" sx={{ padding: "20px 20px 150px" }}>
                  <div>
                    <Box border={0.2} borderColor="#f5f5f5" borderRadius={4}>
                      <FormControl style={{ width: "100%", padding: "20px 0px" }}>
                        <FormLabel component="legend" className="label">
                          Category Search{" "}<span className="astrick">*</span>
                          {" "}<span
                            className="labelLink"
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={this.handlePopoverOpen}
                            onMouseLeave={this.handlePopoverClose}
                          >
                            What is this?
                          </span>
                          <Popover
                            id="mouse-over-popover"
                            className="popover"
                            style={{ pointerEvents: 'none' }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            onClose={this.handlePopoverClose}
                            disableRestoreFocus
                          >
                            <Typography style={{ padding: "10px" }}>Information about field</Typography>
                          </Popover>

                        </FormLabel>
                        <TextField
                          id="standard-select-currency-native"
                          className="inputClass"
                          variant="standard"
                          name="category_search"
                          placeholder="Student"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.category_search}
                        >
                        </TextField>
                        {props.errors.category_search && props.touched.category_search ? (<p className="formError">{props.errors.category_search}</p>) : null}
                      </FormControl>

                      <FormControl
                        component="div"
                        style={{ padding: "20px 0px", width: "100%" }}
                      >
                        <FormLabel component="legend" className="label">
                          Event Type{" "}<span className="astrick">*</span>{" "}
                          <span className="labelText">
                            Select all that apply
                          </span>
                        </FormLabel>
                        <FormGroup row aria-label="event_type" style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "90%",
                          justifyContent: "space-between",
                        }}>
                          {["Action/ Adventure", "Asian", "Black/ African", "Children", "Comedy",
                            "Dance", "Environmental / Outdoor", "Indigenous/ Native peoples", "Human Rights", "Horror",
                            "Latino/ Hispanic", "Sci-fi/ Fantacy/ Thriller", "Religious", "Underground", "Women",
                          ].map(name => (
                            <FormControlLabel
                              control={<CustomColorCheckbox />}
                              checked={checkedValues.includes(name)}
                              name="names"
                              onChange={() => this.handleSelect(name)}
                              key={name}
                              label={name}
                            />
                          ))}
                        </FormGroup>
                        {checkboxError && <div className="formError">Please select atleast one</div>}
                      </FormControl>
                      <FormControl style={{ width: "100%", padding: "20px 0px" }}>
                        <FormLabel component="legend" className="label">
                          Search Terms{" "}
                          <span
                            className="labelLink"
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={this.handlePopoverOpen}
                            onMouseLeave={this.handlePopoverClose}
                          >
                            What is this?
                          </span>
                          <Popover
                            id="mouse-over-popover"
                            className="popover"
                            style={{ pointerEvents: 'none' }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            onClose={this.handlePopoverClose}
                            disableRestoreFocus
                          >
                            <Typography style={{ padding: "10px" }}>Information about field</Typography>
                          </Popover>
                        </FormLabel>
                        <TextField
                          id="standard-select-currency-native"
                          className="inputClass"
                          variant="standard"
                          name="search_terms"
                          placeholder="Zombies, Western, Sports, etc"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.search_terms}
                        >
                        </TextField>
                        {props.errors.search_terms && props.touched.search_terms ? (<p className="formError">{props.errors.search_terms}</p>) : null}
                      </FormControl>

                      <Box sx={{ width: "100%" }} >
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                            <FormLabel component="legend" className="label">Runtime Search{" "}
                              <span
                                className="labelLink"
                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={this.handlePopoverOpen}
                                onMouseLeave={this.handlePopoverClose}
                              >
                                What is this?
                              </span>
                              <Popover
                                id="mouse-over-popover"
                                className="popover"
                                style={{ pointerEvents: 'none' }}
                                open={open}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                onClose={this.handlePopoverClose}
                                disableRestoreFocus
                              >
                                <Typography style={{ padding: "10px" }}>Information about field</Typography>
                              </Popover></FormLabel>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <TextField
                                name="min_minutes"
                                id="outlined-disabled"
                                placeholder="Minimum (Minutes)"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={isChecked ? "0" : props.values.min_minutes}
                                className="inputClass"
                                disabled={isChecked}
                                style={isChecked ? { width: "45%", background: "#ddd" } : { width: "45%" }}
                              />
                              <TextField
                                name="max_minutes"
                                id="outlined-disabled"
                                placeholder="Maximum (Minutes)"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={isChecked ? "500" : props.values.max_minutes}
                                disabled={isChecked}
                                className="inputClass"
                                style={isChecked ? { width: "45%", background: "#ddd" } : { width: "45%" }}
                              />
                            </div>
                          </FormControl>

                          <FormControl component="div" style={{ padding: '25px 0px', width: '45%' }}>
                            <FormControlLabel
                              // value={Object.keys(item)[0]}
                              control={<CustomColorCheckbox />}
                              label={"All Lengths Accepted"}
                              onChange={this.handleCheckBoxEvent}
                            />
                          </FormControl>
                        </div>
                      </Box>

                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                          <FormLabel component="legend" className="label">Listing URL{" "}<span className="astrick">*</span>{" "}
                          <span
                            className="labelLink"
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={this.handlePopoverOpen}
                            onMouseLeave={this.handlePopoverClose}
                          >
                            What is this?
                          </span>
                            <Popover
                              id="mouse-over-popover"
                              className="popover"
                              style={{ pointerEvents: 'none' }}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={this.handlePopoverClose}
                              disableRestoreFocus
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            >
                              <Typography style={{ padding: "10px" }}>Information about field</Typography>
                            </Popover>
                          </FormLabel>
                          <TextField
                            name="listingUrl"
                            id="outlined-disabled"
                            placeholder="Movieexpress.com/"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.listingUrl}
                            className="inputClass"
                          />
                          {props.errors.listingUrl && props.touched.listingUrl ? (<p className="formError">{props.errors.listingUrl}</p>) : null}
                        </FormControl>
                        <FormControl component="div" style={{ padding: '20px 0px', width: '45%' }}>
                          <FormLabel component="legend" className="label">Listing Visibility{" "}<span className="astrick">*</span> {" "}
                          <span
                            className="labelLink"
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={this.handlePopoverOpen}
                            onMouseLeave={this.handlePopoverClose}
                          >
                            What is this?
                          </span>
                            <Popover
                              id="mouse-over-popover"
                              className="popover"
                              style={{ pointerEvents: 'none' }}
                              open={open}
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              onClose={this.handlePopoverClose}
                              disableRestoreFocus
                            >
                              <Typography style={{ padding: "10px" }}>Information about field</Typography>
                            </Popover>
                          </FormLabel>
                          <TextField
                            id="standard-select-currency-native"
                            select
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            SelectProps={{
                              native: true,
                              classes: { icon: "icon" },
                            }}
                            InputProps={{ className: "root" }}
                            variant="standard"
                            name="listingVisibility"
                            value={props.values.listingVisibility}
                          >
                            <option value={""}>Select from option</option>
                            <option value={'public'}>Public</option>
                            <option value={'private'}>Private</option>
                          </TextField>
                          {props.errors.listingVisibility && props.touched.listingVisibility ? (<p className="formError">{props.errors.listingVisibility}</p>) : null}
                        </FormControl>
                      </Box>

                      <FormControl style={{ width: "100%", padding: "20px 0px" }}>
                        <FormLabel component="legend" className="label">
                          Rules and Terms{" "}
                        </FormLabel>
                        <TextField
                          id="standard-select-currency-native"
                          className="inputClass"
                          variant="standard"
                          name="rules_terms"
                          placeholder="Type here"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.rules_terms}
                        >
                        </TextField>
                      </FormControl>
                    </Box>
                  </div>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', width: '43%', flexDirection: 'row', pt: 2, marginBottom: "20px" }}>
              <Button
                color="inherit"
                //@ts-ignore
                onClick={this.props[0].handleBack}
                style={{ marginRight: "20px" }}
              >
                BACK
              </Button>
               {/* @ts-ignore */}
              <Button color="primary" type="submit" ref={this.callRef} onClick={() => this.handleSubmit()}>
                LIST YOUR EVENT
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      </>
    );
  }
}
const CustomColorCheckbox = withStyles({
  root: {
    color: "#a5259f",
    "&$checked": {
      color: "#a5259f",
    },
  },
})((props) => <Checkbox color="default" {...props} />);

export default (...props:any) => {
  // @ts-ignore
  const WithRouteAppHeader = withRouter(AdditionalSettings)
  return (
      <WithRouteAppHeader {...props} />
  )
};
// Customizable Area End