import React, {Component} from 'react';
import {examplePhoto} from "./assets";
import {Grid, Typography,Box,IconButton,CircularProgress,Divider} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { withStyles } from '@material-ui/core/styles';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import CreateAlbumModal from "./CreateAlbumModal.web";
import AddIcon from '@material-ui/icons/Add';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// @ts-ignore
import { ListManager } from 'react-beautiful-dnd-grid';
import PhotoTabController, {Props} from "./PhotoTabController";

import "./photosStyle.css"
import {purple} from "@material-ui/core/colors";


class PhotosTab extends PhotoTabController {
    render() {
        return (
            <div style={{padding:"-24px"}}>
                <Grid container >
                    {
                        this.state.isLoading ?
                            <Grid xs={12} style={{height:"50vh",display:'flex',alignItems:'center',justifyContent:"center"}}>
                                <CircularProgress />
                            </Grid> :
                            <Grid item xs={12}>
                                <Box style={{display:'flex',flexDirection:'row'}}>
                                    <Typography
                                        onClick={()=>this.handleChangeTab("Photos")}
                                        variant="body1"
                                        style={this.state.currentTab === "Photos" ? {marginBottom:"15px",color:"purple",fontWeight:"bold",marginRight:"10px",cursor:"pointer"} :
                                            {marginBottom:"15px",color:"black",fontWeight:"bold",marginRight:"10px",cursor:"pointer"}
                                        }
                                    >
                                        All Photos ({this.state.festivalPhotos.length})
                                    </Typography>
                                    <Typography variant="body1" style={{marginBottom:"15px",color:"black",fontWeight:"bold"}}>|</Typography>
                                    <Typography
                                        onClick={()=>this.handleChangeTab("Albums")}
                                        variant="body1"
                                        style={this.state.currentTab === "Albums"? {marginBottom:"15px",color:"purple",fontWeight:"bold",marginLeft:"10px",cursor:"pointer"} :
                                            {marginBottom:"15px",color:"black",fontWeight:"bold",marginLeft:"10px",cursor:"pointer"}
                                        }
                                    >
                                        Albums ({this.state.albumList.length})
                                    </Typography>
                                </Box>
                                {
                                    this.state.currentTab === "Photos" ?
                                        <Box>
                                            {
                                                // @ts-ignore
                                                this.state.isChangeOrder ?
                                                    <Grid container spacing={1}>
                                                        <ListManager
                                                            // @ts-ignore
                                                            items={this.state.festivalPhotos}
                                                            direction="horizontal"
                                                            maxItems={4}
                                                            // @ts-ignore
                                                            render={(item:any,key:any) =>{
                                                                console.log("THIS IS ITEM",item)
                                                                return(
                                                                    <Grid item xs={12}>
                                                                        <Box className="dragMainBox">
                                                                            <Box className="dragBox">
                                                                                <OpenWithIcon/>
                                                                                <Typography style={{marginLeft:"10px"}}>Drag to Move</Typography>
                                                                            </Box>
                                                                            <PhotoCustom item={item} key={key} handleChangeOrder={this.handleChangeOrder} handleDelete={this.handleOpenDeleteModal} albumList={this.state.albumList} handleMoveToAlbum={this.handleMoveToAlbum}/>
                                                                        </Box>
                                                                    </Grid>
                                                                )
                                                                }}
                                                            onDragEnd={this.reorderList}
                                                        />
                                                    </Grid> :
                                                    <Grid container spacing={1}>
                                                        {
                                                            this.state.festivalPhotos?.map((item,key)=>{
                                                                return(
                                                                    <Grid item xs={12} sm={6} md={3} key={key}>
                                                                        <PhotoCustom item={item} current={key} handleChangeOrder={this.handleChangeOrder} handleDelete={this.handleOpenDeleteModal} albumList={this.state.albumList} handleMoveToAlbum={this.handleMoveToAlbum}/>
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                            }
                                        </Box> :
                                        <Box>
                                            {
                                                this.state.selectedAlbum ?
                                                    <Grid container style={{minHeight:"70vh"}}>
                                                        <Grid xs={12} style={{marginTop:"10px"}}>
                                                            <Box style={{marginBottom:"25px",display:'flex',flexDirection:"row",justifyContent:"space-between"}}>
                                                                {
                                                                    this.state.isAlbumNameEdit ?
                                                                        <>
                                                                            <Box style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                                                <Box>
                                                                                    <TextField id="outlined-basic" variant="outlined" value={this.state.updatedAlbumName} onChange={this.handleAlbumName} />
                                                                                    <ColorButton variant="outlined" name="rename" onClick={this.manageSubmitRename} style={{height:"50px",marginLeft:"15px"}}>Rename</ColorButton>
                                                                                </Box>
                                                                                <Box>
                                                                                    <Typography variant="h4" style={{marginLeft:"5px",color:"gray"}}>
                                                                                        ({this.state.selectedAlbum.festival_photos?.data?.length} Photos)
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </>
                                                                        :
                                                                        <Box style={{display:'flex',flexDirection:'row',alignItems:"center"}}>
                                                                            <Typography variant="h3" style={{color:'purple',marginRight:"10px"}}>
                                                                                {this.state.selectedAlbum.name || "ALBUM NAME"}
                                                                            </Typography>
                                                                            <Typography variant="h4" style={{color:"gray"}}>
                                                                                ({this.state.selectedAlbum.festival_photos?.data?.length} Photos)
                                                                            </Typography>
                                                                            <IconButton onClick={this.manageRenameAlbumFlag} aria-haspopup="true" className="btn">
                                                                                <CreateIcon style={{color:"black",opacity:'1'}} fontSize="small"/>
                                                                            </IconButton>
                                                                        </Box>
                                                                }
                                                                <Box style={{display:'flex',alignItems:'center'}}>
                                                                    {
                                                                        this.state.isUploading &&
                                                                        <CircularProgress size={30}/>
                                                                    }
                                                                    <ColorButton disabled={this.state.isUploading} variant="outlined" style={{height:"40px",marginLeft:"15px"}}>
                                                                        <label htmlFor="icon-button-add-image-to-Album" style={{display:'flex',alignItems:'left'}}>
                                                                            <b>+ Add Photos To This Album </b>
                                                                            <input
                                                                                type="file"
                                                                                accept="image/*"
                                                                                id="icon-button-add-image-to-Album"
                                                                                onChange={this.handleUploadAlbumImages}
                                                                                hidden
                                                                                name="album_photos"
                                                                                style={{display: 'none'}}
                                                                                multiple
                                                                            />
                                                                        </label>
                                                                    </ColorButton>
                                                                    <IconButton onClick={this.handleOpenDeleteAlbumModal} aria-haspopup="true" className="btn">
                                                                        <DeleteIcon style={{color:"black",opacity:'1'}} fontSize="small"/>
                                                                    </IconButton>
                                                                </Box>
                                                            </Box>
                                                            <Grid container spacing={1}>
                                                                {
                                                                    this.state.isChangeOrder ?
                                                                        <Grid container spacing={1}>
                                                                            <ListManager
                                                                                // @ts-ignore
                                                                                items={this.state.selectedAlbum.festival_photos?.data || []}
                                                                                direction="horizontal"
                                                                                maxItems={4}
                                                                                render={(item) =>
                                                                                    <Box>
                                                                                        <Box className="dragMainBox">
                                                                                            <Box className="dragBox">
                                                                                                <OpenWithIcon/>
                                                                                                <Typography style={{marginLeft:"10px"}}>Drag to Move</Typography>
                                                                                            </Box>
                                                                                            <PhotoCustom item={item} key={item.id} handleChangeOrder={this.handleChangeOrder} handleDelete={this.handleOpenDeleteModal} albumList={this.state.albumList} handleMoveToAlbum={this.handleMoveToAlbum}/>
                                                                                        </Box>
                                                                                    </Box>}
                                                                                onDragEnd={this.reorderListOfAlbum}
                                                                            />
                                                                        </Grid>
                                                                        :
                                                                        this.state.selectedAlbum.festival_photos?.data?.map((item:any,key:any)=>{
                                                                            return(
                                                                                <Grid item xs={12} sm={6} md={3} key={key}>
                                                                                    <PhotoCustom item={item} current={9} handleChangeOrder={this.handleChangeOrder} handleDelete={this.handleOpenDeleteModal} albumList={this.state.albumList} handleMoveToAlbum={this.handleMoveToAlbum} isInAlbum={true} handleRemoveFromAlbum={this.handleRemoveFromAlbum}/>
                                                                                </Grid>
                                                                            )
                                                                        })
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid> :
                                                    <Grid container spacing={1} style={{minHeight:"70vh"}}>
                                                        {
                                                            this.state.albumList?.map((item,key)=>{
                                                                return(
                                                                    <Grid item xs={12} sm={6} md={4} key={key}>
                                                                        <AlbumCustom item={item} albumList={this.state.albumList} selectAlbum={this.handleSelectAlbum} />
                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                            }
                                        </Box>
                                }
                            </Grid>
                    }
                </Grid>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modalStyle"
                    // @ts-ignore
                    open={this.state.isModalOpen}
                    onClose={this.handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    {/*@ts-ignore*/}
                    <Fade in={this.state.isModalOpen}>
                        <div>
                            <CreateAlbumModal/>
                        </div>
                    </Fade>
                </Modal>
                <StyledMenu
                    id="fade-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleCloseMenu}
                    TransitionComponent={Fade}
                >
                    <StyledMenuItem onClick={this.handleCloseMenu}></StyledMenuItem>
                    <StyledMenuItem onClick={this.manageRenameAlbumFlag}>Rename Album</StyledMenuItem>
                    <StyledMenuItem onClick={this.handleOpenDeleteAlbumModal}>Delete Album</StyledMenuItem>
                </StyledMenu>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modalStyle"
                    // @ts-ignore
                    open={this.state.isDeleteAlbumModalOpen}
                    onClose={this.handleCloseDeleteAlbumModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    {/*@ts-ignore*/}
                    <Fade in={this.state.isDeleteAlbumModalOpen}>
                            <Box style={{width:"30vw",marginTop:'15px',backgroundColor:"white",padding:'20px',borderRadius:"20px"}}>
                                <Typography variant="h4" style={{color:"purple",fontWeight:"bold",marginBottom:"10px"}}>
                                    Are you sure you want to delete {this.state.selectedAlbum?.name}?
                                </Typography>
                                <Box style={{display:'flex',justifyContent:'flex-end',marginTop:"15px"}}>
                                    {/*@ts-ignore*/}
                                    <CancelButton variant="outlined" onClick={this.handleCloseDeleteAlbumModal}>Cancel</CancelButton>
                                    <OkButton variant="contained" onClick={this.handleDeleteAlbum} >Ok</OkButton>
                                </Box>
                            </Box>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modalStyle"
                    // @ts-ignore
                    open={this.state.isDeleteModalOpen}
                    onClose={this.handleCloseDeleteModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    {/*@ts-ignore*/}
                    <Fade in={this.state.isDeleteModalOpen}>
                        <Box style={{width:"30vw",marginTop:'15px',backgroundColor:"white",padding:'20px',borderRadius:"20px"}}>
                            <Typography variant="h4" style={{color:"purple",fontWeight:"bold",marginBottom:"10px"}}>
                                Are you sure you want to delete photo?
                            </Typography>
                            <Box style={{display:'flex',justifyContent:'flex-end',marginTop:"15px"}}>
                                {/*@ts-ignore*/}
                                <CancelButton variant="outlined" onClick={this.handleCloseDeleteModal}>Cancel</CancelButton>
                                <OkButton variant="contained" onClick={this.handleDelete} >Ok</OkButton>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        );
    }
};

export default PhotosTab;


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props:any) => (
    <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme:any) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            color:"white",
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            transitionTimingFunction:'linear',
            color:"white",
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const CancelButton = withStyles((theme) => ({
    root: {
        color: purple[500],
        marginRight:"20px",
        '&:hover': {
            color: purple[700],
        },
    },
}))(Button);

const OkButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: purple[500],
        '&:hover': {
            backgroundColor: purple[700],
        },
    },
}))(Button);

const StyledMenu1 = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        marginTop:"60px"
    },
})((props:any) => (
    <Menu
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
));

const StyledMenuItem1 = withStyles((theme:any) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            color:"white",
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            transitionTimingFunction:'linear',
            color:"white",
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
        '&:focused':{
            backgroundColor: theme.palette.primary.main,
            color:"white",
        }
    },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

const ColorButton = withStyles((theme) => ({
    root: {
        color: purple[500],
        borderColor: purple[500],
        '&:hover': {
            borderColor: purple[700],
            color: purple[700],
        },
    },
}))(Button);

const PhotoCustom = (props:any) => {
    const classes = useStyles();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [isLoading, setImageLoading] = React.useState(true)
    const open = Boolean(anchorEl);

    const open1 = Boolean(anchorEl1);

    const handleClick = (event:any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClick1 = (event:any) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleModalOpen = () => {
        setModalOpen(true);
        // setAnchorEl(null);
        // setAnchorEl1(null);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setAnchorEl(null);
        setAnchorEl1(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const handleChangeOrder = () => {
        props.handleChangeOrder()
        setAnchorEl(null);
    }

    const handleRemove = (id:any) => {
        console.log("REMOVE ???",id)
        props.handleRemoveFromAlbum(id)
        setAnchorEl(null);
    }

    const handledelete = (id:any) => {
        props.handleDelete(id)
        setAnchorEl(null);
    }

    const manageImageLoading = () => {
        setImageLoading(false)
    }

    const movePhotoAlbum = (albumId:any,photoId:any) => {
        props.handleMoveToAlbum(albumId,photoId)
        setAnchorEl(null);
        setAnchorEl1(null);
    }

    return(
        <Box className="container">
            {
                isLoading &&
                    <Box className="loaderBox">
                        <CircularProgress/>
                    </Box>
            }
            <img src={props.item.attributes.festival_photo} width="100%" onLoad={manageImageLoading} style={isLoading ? {display:"none"} :{display:"block"}}/>
            <IconButton aria-haspopup="true" onClick={handleClick} className="btn">
                <CreateIcon style={{color:"black",opacity:'1'}} fontSize="small"/>
            </IconButton>
            {
                props.current < 8 &&
                <Box className="featureBox">
                    <Typography variant="subtitle2">Featured on Profile</Typography>
                </Box>
            }

            <StyledMenu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {
                    props.isInAlbum ?
                        <div>
                            <StyledMenuItem onClick={handleChangeOrder}>Change Order</StyledMenuItem>
                            <StyledMenuItem onClick={handleClick1}>Move to Album <ChevronRightIcon/></StyledMenuItem>
                            <StyledMenuItem onClick={()=>handleRemove(props.item.id)}>Remove From Album</StyledMenuItem>
                            <StyledMenuItem onClick={()=>handledelete(props.item.id)}>Delete</StyledMenuItem>
                        </div> :
                        <div>
                            <StyledMenuItem onClick={handleChangeOrder}>Change Order</StyledMenuItem>
                            <StyledMenuItem onClick={handleClick1}>Move to Album <ChevronRightIcon/></StyledMenuItem>
                            <StyledMenuItem onClick={()=>handledelete(props.item.id)}>Delete</StyledMenuItem>
                        </div>
                }
            </StyledMenu>
            <StyledMenu1
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open1}
                onClose={handleClose1}
                TransitionComponent={Fade}
            >
                <StyledMenuItem1 onClick={handleModalOpen}>Create Album</StyledMenuItem1>
                <Divider/>
                {
                    props.albumList?.length > 0 &&
                        props.albumList?.map((item:any,key:any) => {
                            return(
                                <StyledMenuItem1 onClick={()=>movePhotoAlbum(item.id,props.item?.id)} key={key}>{item.attributes.name}</StyledMenuItem1>
                            )
                        })
                }
            </StyledMenu1>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalOpen}
                onClose={handleModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalOpen}>
                    <div>
                        {/*// @ts-ignore*/}
                        <CreateAlbumModal handleCloseModal={handleModalClose}/>
                    </div>
                </Fade>
            </Modal>
        </Box>
    )
}

const AlbumCustom = (props:any) => {
    const classes = useStyles();
    const [isLoading, setImageLoading] = React.useState(false)

    const manageImageLoading = () => {
        setImageLoading(false)
    }

    return(
        <Box className="container_album" onClick={()=>props.selectAlbum(props.item)}>
            {
                isLoading &&
                <Box className="loaderBox">
                    <CircularProgress/>
                </Box>
            }
            {
                props.item?.attributes?.festival_photos?.data?.length > 0 &&
                    props.item?.attributes?.festival_photos?.data?.map((item:any,key:any)=>{
                        if(key < 1){
                            return(
                                    <img src={item.attributes.festival_photo} key={key} width="100%" height="100%" onLoad={manageImageLoading} style={isLoading ? {display:"none"} :{display:"block"}}/>
                            )
                        }
                    })
            }
            <Box className="featureBoxAlbum">
                <Typography variant="body1" style={{marginRight:"5px",fontWeight:"bold"}}>{props.item.attributes.name}</Typography>
                <Typography variant="body2" style={{color:"#c0c0c0"}}>({props.item?.attributes?.festival_photos?.data?.length} Photos)</Typography>
            </Box>
        </Box>
    )
}
