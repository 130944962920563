import React, {Component} from 'react';
import {Box, Typography} from "@material-ui/core";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import "./timeline.css"
import moment from 'moment'

class Timeline extends Component {
    constructor(props:any) {
        super(props);
        this.state={
            newArray:[]
        }
    }

    render() {
        let yourDate = new Date()
        yourDate.toISOString().substring(0,10)
        let latestDateFound = false;
        return (
            <Box className="container">
                <Box className="steps">
                    {
                        // @ts-ignore
                        this.props?.otherDeadlines?.opening_date &&
                        // @ts-ignore
                        <Box className={this.props?.otherDeadlines?.opening_date >= yourDate.toISOString().substring(0,10) ?"steps-container steps-container_current" :"steps-container"}>
                            <Box className="content">
                                {/*@ts-ignore*/}
                                <Typography variant="h4" className="date_header">{moment(this.props?.otherDeadlines?.opening_date).format('MMMM DD, YYYY')}</Typography>
                                <Typography variant="subtitle1" className="details">Opening Date</Typography>
                            </Box>
                            <i className="step-line"></i>
                        </Box>
                    }
                    {
                        // @ts-ignore
                        this.props.deadline?.length > 0 &&
                        // @ts-ignore
                        this.props.deadline?.map((item:any,key:any)=>{
                            // @ts-ignore
                            return (
                                // @ts-ignore
                                <Box key={key} className={item?.date >= yourDate.toISOString().substring(0,10) ?"steps-container steps-container_current" :"steps-container"}>
                                    <Box className="content">
                                        <Typography variant="h4" className="date_header">{moment(item?.date).format('MMMM DD, YYYY')}</Typography>
                                        <Typography variant="subtitle1" className="details">{item?.deadline_name}</Typography>
                                    </Box>
                                    <i className="step-line"></i>
                                    <RadioButtonUncheckedIcon className="dot"/>
                                </Box>
                            )
                        })
                    }
                    {
                        // @ts-ignore
                        this.props?.otherDeadlines?.notification_date &&

                        // @ts-ignore
                        <Box className={this.props?.otherDeadlines?.notification_date >= yourDate.toISOString().substring(0,10) ?"steps-container steps-container_current" :"steps-container"}>
                            <Box className="content">
                                {/*@ts-ignore*/}
                                <Typography variant="h4" className="date_header">{moment(this.props?.otherDeadlines?.notification_date).format('MMMM DD, YYYY')}</Typography>
                                <Typography variant="subtitle1" className="details">Notification Date</Typography>
                            </Box>
                            <i className="step-line"></i>
                            <RadioButtonUncheckedIcon className="dot"/>
                        </Box>
                    }
                    {
                        // @ts-ignore
                        this.props?.otherDeadlines?.event_start_date &&
                        // @ts-ignore
                        <Box className={this.props?.otherDeadlines?.event_start_date >= yourDate.toISOString().substring(0,10) ?"steps-container steps-container_current" :"steps-container"}>
                            <Box className="content">
                                {/*@ts-ignore*/}
                                <Typography variant="h4" className="date_header">{moment(this.props?.otherDeadlines?.event_start_date).format('MMMM DD')} - {moment(this.props?.otherDeadlines?.event_end_date).format('MMM DD, YYYY')}</Typography>
                                <Typography variant="subtitle1" className="details">Event Date</Typography>
                            </Box>
                            <i className="step-line"></i>
                        </Box>
                    }

                    {/*<Box className="steps-container">*/}
                    {/*    <Box className="content">*/}
                    {/*        <Typography variant="h4" className="date_header_current">July 15, 2021</Typography>*/}
                    {/*        <Typography variant="subtitle1" className="details_current">Extended Deadline</Typography>*/}
                    {/*    </Box>*/}
                    {/*    <i className="step-line"></i>*/}
                    {/*    <RadioButtonUncheckedIcon className="dot_selected"/>*/}
                    {/*</Box>*/}

                </Box>
            </Box>
        );
    }
}

export default Timeline;
