import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {toast} from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  formik?:any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  festivalData: any;
  errorMsg: any;
  loading: boolean;
  // Customizable Area Start
  activeStep: number;
  value: number;
  festivalID: any;
  callFestivalAPI: boolean;
  listButton: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EventRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  editEventCall: any;
  getFestivalAPICall: string = '';
  getCurrencyDataAPICall: String = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      festivalData: null,
      errorMsg: "",
      loading: false,
      // Customizable Area Start
      activeStep: 0,
      value: 1,
      festivalID: null,
      callFestivalAPI: false,
      listButton: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleNextStep = this.handleNextStep.bind(this)
    this.handleChange = this.handleChange.bind(this);
    // Customizable Area End

  }

  async componentDidMount() {
    const url = window.location.href.split("/")[4]
    if(url === "add"){
      this.setState({ callFestivalAPI : true})
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (apiRequestCallId === this.getFestivalAPICall) {
        this.setState({
          festivalData: responseJson.data,
        })
      }
    }
    // Customizable Area Start
    // Customizable Area End

  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  handleNextStep = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  }
  handleBack = () => {
    if(this.state.activeStep === 1 && this.state.callFestivalAPI){
      this.setState({callFestivalAPI: true})
    }
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleLoadingTrue = () => {
    this.sentMessage("START_LOAD_FORM_DATA")
    this.setState({ loading: true });
  }
  handleLoadingFalse = () => {
    this.sentMessage("STOP_LOAD_FORM_DATA")
    this.setState({ loading: false });
  };

  handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    if (newValue === 0) {
      // @ts-ignore
      this.props.history.push("/myFestival/list")
    }
    if (newValue === 1) {
      // @ts-ignore
      this.props.history.push("/EventRegistration")
    }
    if (newValue === 2) {
      // @ts-ignore
      this.props.history.push(`/myFestival/manage/${this.state.id}`)
    }
    this.setState({
      value: newValue
    })
  };

  handleSave = () => {
    this.sentMessage("SAVE_FESTIVAL_FORM_DATA")
  }
  handleList = () => {
    this.sentMessage("LIST_FESTIVAL_FORM_DATA")
  }

  sentMessage (data:any) {
    const msg : Message = new Message(getName(MessageEnum.PostDetailDataMessage))
    msg.properties['text'] = data
    this.send(msg)
  }
  // Customizable Area End
  
}
