import React from 'react';
import { Box,Container,Link,Button,Typography,TextField,Paper,FormControl,Grid,Modal,FormLabel,CircularProgress } from '@material-ui/core';
import { makeStyles,withStyles} from '@material-ui/core/styles';
import {movie_expresswayBig, movie_expressway} from './assets';
import SubmissionButtonController, {
    Props
  } from "./SubmissionButtonController";

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border:'none',
    borderRadius:"4px",
    boxShadow: 24,
    padding:"0px 0px 32px 0px"
  };

  const inputstyle = { 
    "& .Mui-focused:after": {
      border: "1px solid green"
    },
  }

class SubmissionButton extends SubmissionButtonController{
    constructor(props:any) {
        super(props); 
        // Customizable Area Start
        // Customizable Area End
      }

      useStyle = makeStyles((theme:any) => ({
        root: {
            flexGrow: 1,
            backgroundColor:"#F2F3F5",
            "& .MuiInputBase-input": {
                height: "1em",
                backgroundColor:"#fff"
              }
            // padding: theme.spacing(2),
        },
        paperstyle: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
              margin: theme.spacing(1),
              width: "100%",
              height: "100%",
            },
        },
        inputClass: {
            "& .Mui-focused:after": {
              border: "1px solid green",
            },
          },
          icon: {
            color: "#a5259f",
          },
          selectbox: {
            "& .MuiInputBase-input": {
              height: "1.5em",
            }
          },
    }));

    render() {
        const {onOpen, imgid }: any = this.state;
        const classes = this.useStyle 

        const smallButtonLink = ["https://filmplatformvevek-76464-ruby.b76464.dev.eastus.az.svc.builder.cafe/assets/small_button_1.png",
                                "https://filmplatformvevek-76464-ruby.b76464.dev.eastus.az.svc.builder.cafe/assets/small_button_2.png",
                                "https://filmplatformvevek-76464-ruby.b76464.dev.eastus.az.svc.builder.cafe/assets/small_button_3.png",
                                "https://filmplatformvevek-76464-ruby.b76464.dev.eastus.az.svc.builder.cafe/assets/small_button_4.png",
                                "https://filmplatformvevek-76464-ruby.b76464.dev.eastus.az.svc.builder.cafe/assets/small_button_5.png",
                                "https://filmplatformvevek-76464-ruby.b76464.dev.eastus.az.svc.builder.cafe/assets/small_button_6.png",
                                "https://filmplatformvevek-76464-ruby.b76464.dev.eastus.az.svc.builder.cafe/assets/small_button_7.png",
                                "https://filmplatformvevek-76464-ruby.b76464.dev.eastus.az.svc.builder.cafe/assets/small_button_8.png",
                                "https://filmplatformvevek-76464-ruby.b76464.dev.eastus.az.svc.builder.cafe/assets/small_button_9.png"]

        const mediumButtonLink =["https://filmplatformvevek-76464-ruby.b76464.dev.eastus.az.svc.builder.cafe/assets/medium_button_1.png",
                                "https://filmplatformvevek-76464-ruby.b76464.dev.eastus.az.svc.builder.cafe/assets/medium_button_2.png",
                                "https://filmplatformvevek-76464-ruby.b76464.dev.eastus.az.svc.builder.cafe/assets/medium_button_3.png",
                                "https://filmplatformvevek-76464-ruby.b76464.dev.eastus.az.svc.builder.cafe/assets/medium_button_4.png",
                                "https://filmplatformvevek-76464-ruby.b76464.dev.eastus.az.svc.builder.cafe/assets/medium_button_5.png",
                                "https://filmplatformvevek-76464-ruby.b76464.dev.eastus.az.svc.builder.cafe/assets/medium_button_6.png",
                                "https://filmplatformvevek-76464-ruby.b76464.dev.eastus.az.svc.builder.cafe/assets/medium_button_7.png",
                                "https://filmplatformvevek-76464-ruby.b76464.dev.eastus.az.svc.builder.cafe/assets/medium_button_8.png",
                                "https://filmplatformvevek-76464-ruby.b76464.dev.eastus.az.svc.builder.cafe/assets/medium_button_9.png"]
                        
    return(
        <Container style={{paddingLeft:"30px"}}>
            
            {
                //@ts-ignore
            console.log("logoid-->", this.state.imgid)}
            <Box style={{padding:"0px 0px 0px 7px"}}>
                <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem", marginBottom:"10px"}}>Submission Buttons & Logos</Typography>
            </Box>
            <Box style={{display: 'flex',flexWrap: 'wrap'}}>
                <Paper style={{padding:"15px 15px 30px 15px"}}>
                    <Typography variant="h4" style={{marginBottom:'10px',fontWeight:800}}>Festival Listing URL</Typography>
                    <Link variant="inherit" style={{fontWeight:600}}>https://filmfreeway.com/festivals</Link>
                    <Typography style={{fontSize:"1rem",marginTop:'10px', fontWeight:500}}>This is the URL for your event listing on Movie Expressway . Link on your website and social media pages to direct people to your listing.</Typography>
                </Paper>
            </Box>
            <Box style={{marginTop:"30px"}}>
                <Typography style={{fontWeight:800, fontSize:"0.875rem",padding:"0px 0px 0px 7px", marginBottom:"10px"}}>Submission Buttons</Typography>
            </Box>
            <Box style={{display: 'flex',flexWrap: 'wrap'}}>
                <Paper elevation={3} style={{padding:"15px 15px 30px 15px"}}>
                    <Typography style={{fontSize:"1rem",fontWeight:500}}>Choose from the below buttons and add the corresponding code to your website's submissions page, or link the image to your Festial Listing URL. You can 
                        downloadd all submission buttons here.</Typography>
                    <Box style={{marginTop:"20px"}}>
                    <Typography variant="h4" style={{marginBottom:'10px',fontWeight:800}}>Select a Color</Typography>
                    <Grid container style={{flexWrap:"unset"}} spacing={3}>
                        {["#1389D4", "#129D91", "#9013D4", "#EA1292", "#FB2F2F", "#2FC4FB", "#FB992F", "#7C3803", "#000000"].map(
                            (item, i:any) => (
                                <Grid item xs={6} sm={2}>
                                <Paper style={{backgroundColor:`${item}`,display: 'flex',flexWrap: 'wrap',height:"75px"}} key={i} onClick={() => this.handleLogoChange(i)}></Paper>
                                </Grid>
                            )
                        )}
                    </Grid>
                    </Box>
                    <Grid container spacing={3} style={{marginTop:"20px"}}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h4" style={{fontWeight:800}}>Small Button</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h4" style={{fontWeight:800}}>Small Button Code</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <img
                            //@ts-ignore 
                            src={smallButtonLink[this.state.logoid]} id="img-id" alt="small logo" style={{height:"100%", width:"100%"}} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <textarea  
                                id="outlined-basic"
                                //@ts-ignore
                                value={document.getElementById("img-id")?.innerHTML}
                                style={{ resize: "none",height: "100%",width: "100%",borderRadius: "8px",borderColor:"#C3CAD9",padding:"10px"}}/>
                        </Grid>
                        {console.log("myid-->", document.getElementById("img-id"))}
                    </Grid>
                    <Grid container spacing={3} style={{marginTop:"20px"}}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h4" style={{fontWeight:800}}>Medium Logo</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h4" style={{fontWeight:800}}>Medium Logo Code</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <img
                            //@ts-ignore 
                            src={mediumButtonLink[this.state.logoid]} alt="small logo" style={{height:"100%", width:"100%"}} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <textarea  
                                id="outlined-basic"
                                //@ts-ignore
                                value={mediumButtonLink[this.state.logoid]}
                                style={{ resize: "none",height: "100%",width: "100%",borderRadius: "8px",borderColor:"#C3CAD9",padding:"10px"}}/>
                        </Grid>
                    </Grid>
                    <Box style={{marginTop:"20px"}}>
                        <Typography variant="h4" style={{fontWeight:800}}>Small Button</Typography>
                        <Typography style={{fontSize:"1rem",fontWeight:500}}>Grandevent proudly accepts entries on FilmFreeway, the world's #1 way to enter film festivals and creative contests.</Typography>
                    </Box>
                    <Box style={{marginTop:"20px"}}>
                        <Typography variant="h4" style={{fontWeight:800}}>Ad Creator</Typography>
                        <Typography style={{marginBottom:"20px", fontSize:"1rem",fontWeight:500}}>Try our <Link style={{color:"#A5259F"}}>Ad Creator</Link> for beautiful, custom graphics to advertise your festival on social.</Typography>
                    </Box>
                    <Box style={{margin:"15px 0px 0px 0px"}}>
                        <Button color="primary" onClick={this.handleOpen}>CREATE NEW ADD</Button>
                    </Box>

                    <Modal
                    //@ts-ignore
                        open={onOpen}
                        onClose={this.handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                                <Grid container style={{backgroundColor:"#000", alignItems:"center", height:"50px"}}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography style={{color:"#fff", marginLeft:"15px"}}>CREATED ADD</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} style={{textAlign:"end"}}>
                                        <Button style={{color: "#fff"}} onClick={this.handleClose}>X</Button>
                                    </Grid>
                                </Grid>
                                <FormLabel component="legend" style={{fontWeight:500, marginTop:"20px", padding:"0px 32px 0px 32px"}}>Select Ad Template</FormLabel>
                                <FormControl  style={{width: '100%',padding:"0px 32px 0px 32px"}}>
                                    <TextField
                                      id="standard-select-currency-native"
                                      select
                                    //   onChange={(e) => setIntialValue({...initialValues,gender:e.target.value})}
                                      variant="standard"
                                      name="gender"
                                      //@ts-ignore
                                      InputProps={{ className: classes.selectbox}}
                                      SelectProps={{
                                        //@ts-ignore
                                        classes: { icon: classes.icon },
                                        native:true
                                      }}
                                    //   value={initialValues.gender}
                                    >
                                        <option value={""}>select from option</option>
                                        <option value={'bulk action'}>Bulk Actions</option>
                                        <option value={'single action'}>Single Action</option>
                                    </TextField>
                                </FormControl>
                                <FormLabel component="legend" style={{fontWeight:500,marginTop:"15px",padding:"0px 32px 0px 32px"}}>Ad Name</FormLabel>
                                <FormControl style={{width:"100%",padding:"0px 32px 0px 32px"}}>
                                        <TextField  
                                            id="outlined-disabled"
                                            placeholder="Type Here"
                                            // onChange={(e) => setIntialValue({...initialValues,facebook_link:e.target.value})}
                                            //@ts-ignore
                                            InputProps={{ className: classes.root }}
                                            name="facebook_link"
                                            // value={initialValues.facebook_link}
                                            //@ts-ignore  
                                            sx={inputstyle}
                                        />
                                </FormControl>
                                <FormLabel component="legend" style={{fontWeight:500,marginTop:"15px",padding:"0px 32px 0px 32px"}}>Ad Description</FormLabel>
                                <FormControl style={{width:"100%",padding:"0px 32px 0px 32px"}}>
                                        <TextField  
                                            id="outlined-disabled"
                                            placeholder="Type Here"
                                            // onChange={(e) => setIntialValue({...initialValues,facebook_link:e.target.value})}
                                            //@ts-ignore
                                            InputProps={{ className: classes.root }}
                                            name="facebook_link"
                                            // value={initialValues.facebook_link}
                                            //@ts-ignore  
                                            sx={inputstyle}
                                        />
                                </FormControl>
                                <FormLabel component="legend" style={{fontWeight:500,marginTop:"15px",padding:"0px 32px 0px 32px"}}>Ad Domain</FormLabel>
                                <FormControl style={{width:"100%",padding:"0px 32px 0px 32px"}}>
                                        <TextField  
                                            id="outlined-disabled"
                                            placeholder="Enter URL"
                                            // onChange={(e) => setIntialValue({...initialValues,facebook_link:e.target.value})}
                                            //@ts-ignore
                                            InputProps={{ className: classes.root }}
                                            name="facebook_link"
                                            // value={initialValues.facebook_link}
                                            //@ts-ignore  
                                            sx={inputstyle}
                                        />
                                </FormControl>
                            <div style={{textAlign:"center"}}>
                            {/* <Button variant="contained" style={{ background: "#fff", color: "#A5259F", marginTop: "20px", width:"100%", maxWidth: "300px"}} onClick={this.handleClose}>cancel</Button> */}
                            <Button variant="contained" style={{ background: "#A5259F", color: "#fff", marginTop: "20px",marginBottom:"20px", width:"100%", maxWidth: "200px",height:"50px"}}>SUBMIT NOW</Button>
                            </div>
                        </Box>
                    </Modal>

                </Paper>
            </Box>
            <Box style={{marginTop:"30px"}}>
                <Typography style={{fontWeight:800, fontSize:"0.875rem",padding:"0px 0px 0px 7px",marginBottom:"10px"}}>Logo</Typography>
            </Box>
            <Box style={{display: 'flex',flexWrap: 'wrap'}}>
                <Paper style={{padding:"15px 15px 30px 15px"}}>
                    <Typography style={{fontSize:"1rem",fontWeight:500}}>You are welcome to list Movie Expressway as a sponsor of your event and use the Movie Expressway logo in your festival's 
                        promotional materials (website, social media, programs, posters, step and repeat etc).</Typography>
                    <Grid container spacing={3}  style={{marginTop:"20px"}}>
                        <Grid item xs={12} sm={6} style={{height:'100%'}}>
                            <img src={movie_expresswayBig} alt="small logo" style={{height:"130px", width:"100%"}} />
                            <Typography style={{fontSize:"1rem",fontWeight:500, textAlign:"center"}}>Download: <Button><Link>JPG</Link></Button> | <Button><Link>EPS</Link></Button> | <Button><Link>PNG</Link></Button></Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{height:'100%'}}>
                            <img src={movie_expresswayBig} alt="small logo" style={{height:"130px", width:"100%"}} />
                            <Typography style={{fontSize:"1rem",fontWeight:500,textAlign:"center"}}>Download: <Button><Link>JPG</Link></Button> | <Button><Link>EPS</Link></Button> | <Button><Link>PNG</Link></Button></Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} style={{height:'100%'}}>
                            <img src={movie_expresswayBig} alt="small logo" style={{height:"130px", width:"100%"}} />
                            <Typography style={{fontSize:"1rem",fontWeight:500, textAlign:"center"}}>Download: <Button><Link>JPG</Link></Button> | <Button><Link>EPS</Link></Button> | <Button><Link>PNG</Link></Button></Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{height:'100%'}}>
                            <img src={movie_expresswayBig} alt="small logo" style={{height:"130px", width:"100%"}} />
                            <Typography style={{fontSize:"1rem",fontWeight:500,textAlign:"center"}}>Download: <Button><Link>JPG</Link></Button> | <Button><Link>EPS</Link></Button> | <Button><Link>PNG</Link></Button></Typography>
                        </Grid>
                    </Grid>
                    {/* <Grid container spacing={3} style={{marginTop:"20px"}}>
                        <Grid item xs={12} sm={6} style={{padding:"0px 12px 12px 12px",display:"flex", alignItems:"center", justifyContent:"center"}}>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{padding:"0px 12px 12px 12px",display:"flex", alignItems:"center", justifyContent:"center"}}>
                        </Grid>
                    </Grid> */}
                </Paper>
            </Box>
           
        </Container>
    )
}
}

export default SubmissionButton;

const webStyles = {
    modalstyle: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }
  };