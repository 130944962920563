// Customizable Area Start
import {IBlock} from "../../framework/src/IBlock";
import {Message} from "../../framework/src/Message";
import {BlockComponent} from "../../framework/src/BlockComponent";
import MessageEnum, {getName} from "../../framework/src/Messages/MessageEnum";
import {runEngine} from "../../framework/src/RunEngine";


import {toast} from "react-toastify";
import React from "react";

export const configJSON = require("./config");

export interface Props {
    navigation?: any;
    id?: string;
    showHeader?:boolean;
}

interface S {
    accountData:Object;
    authToken:String;
    userRole:String;
    userInfo:Object;
    anchorElNav:null | HTMLElement;
    anchorElUser:null | HTMLElement;
    open:boolean;
    loginOpen: boolean;
    signupOpen: boolean;
    errorMessage: any;
    successMessage: any;
}

interface SS {
    id: any;
}

export default class FestivalViewController extends BlockComponent<
    Props,
    S,
    SS
    > {

    getAccountDetailsAPICall:string = ""
    emailReg: RegExp;
    labelTitle: string = "";

    constructor(props: Props) {

        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ]

        this.state = {
            accountData:{
                profile_pic:"",
                first_name:"",
            },
            authToken:"",
            userRole:"",
            userInfo:"",
            anchorElNav:null,
            anchorElUser:null,
            open:false,
            loginOpen: false,
            signupOpen: false,
            errorMessage: "",
            successMessage: "",
        };


        this.emailReg = new RegExp("");
        this.labelTitle = configJSON.labelTitle;

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.handleCloseNavMenu = this.handleCloseNavMenu.bind(this)
        this.handleOpenNotofication = this.handleOpenNotofication.bind(this)
        this.handleOpenUserMenu = this.handleOpenUserMenu.bind(this)
        this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this)
        this.toggleLoginModal = this.toggleLoginModal.bind(this)
        this.toggleSignupModal = this.toggleSignupModal.bind(this)
    }

    async componentDidMount() {
        this.getAccountData()
        const token = localStorage.getItem("authToken")
        if(token){
            this.setState({
                authToken:token
            })
            let usersRole = localStorage.getItem('usertoken')
            if(usersRole){
                this.setState({
                    userRole:usersRole
                })
            }
            // @ts-ignore
            const userInfo = JSON.parse(localStorage.getItem('profile'))
            if(userInfo){
                this.setState({
                    userInfo:userInfo
                })
            }
        }
    }

    async receive(from: string, message: Message) {

        if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            if(this.getAccountDetailsAPICall === apiRequestCallId) {
                if(responseJson?.hasOwnProperty('data')){
                    console.log("THIS IS ACCOUNT DATA",responseJson.data)
                     this.setState({
                         accountData:responseJson.data.attributes,
                    })
                }else{
                    responseJson.errors.map((item:any)=>{
                        if(item.token === "Token has Expired"){
                            localStorage.removeItem("authToken")
                            localStorage.removeItem("profile")
                            localStorage.removeItem("token")
                            localStorage.removeItem("usertoken")
                            this.props.navigation.navigate("/")
                            toast.error("Token Expired")
                        }else{
                            console.log("GET FESTIVAL DATA ERROR",item)
                        }
                    })
                }
            }
        }
    }

    handleCloseNavMenu() {
        this.setState({
            anchorElNav:null
        })
    };

    handleOpenNotofication () {
        this.setState({
            open:!this.state.open
        })
    };

    handleMyEvent () {
        this.handleCloseNavMenu();
        this.props.navigation.navigate("/myFestival/List")
    }

    handleCloseUserMenu = () => {
        this.setState({
            anchorElUser:null
        })
    };

    handleNavigateMyProject() {
        this.props.navigation.navigate('/myProject');
    }

    handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            anchorElUser:event.currentTarget
        })
    };

    handleViewProfile (){
        // history.push('/Dashboard')
        this.props.navigation.navigate('/Dashboard')
        // window.location.replace('/Dashboard');
    }

    handleNavigateAccountSettings () {
        this.handleCloseNavMenu();
        this.state.userRole !== 'organizer' && this.props.navigation.navigate('/UserProfileBasicBlock')
    }

    handleLogout () {
        localStorage.removeItem("authToken")
        localStorage.removeItem("token")
        localStorage.removeItem("usertoken")
        localStorage.removeItem("profile")
        // this.props.navigation.navigate('/')
        window.location.replace('/')
    }

    toggleLoginModal = () => {
        this.setState({
            loginOpen: !this.state.loginOpen,
            signupOpen: false,
            errorMessage: "",
            successMessage: ""
        })
    }

    toggleSignupModal = () => {
        this.setState({
            signupOpen: !this.state.signupOpen,
            loginOpen: false,
            errorMessage: "",
            successMessage: ""
        })
    }

    getAccountData(): boolean {
        const header = {
            "Content-Type": configJSON.accountDetailsApiContentType,
            token: localStorage.getItem('authToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAccountDetailsAPICall = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.urlAccountDetails
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.accountDetailsApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }
}
// Customizable Area End