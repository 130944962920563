Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.editProfileAPiMethod = "PATCH";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.methodTypeApiGetUserProfile = "GET";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings2";
exports.labelBodyText = "Settings2 Body";
exports.editAboutmeEndpoint = "account_block/accounts/3";  
exports.endPointApiAwardsAndFilmography = "bx_block_posts/index_visibility";
exports.endPointApiUpdateAwardsAndFilmography = "/bx_block_posts/update_visibility";
exports.patchAPiMethod = "PATCH";
exports.APiContentType = "application/json";
exports.btnExampleTitle = "CLICK ME";
exports.endPointApiUpdateAccountDetails = "account_block/accounts/3";
exports.patchAPiMethod = "PATCH";
// Customizable Area End