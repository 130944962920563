import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import ResponsiveAppBar from "../../CustomForm2/src/header.web";

import Events2Controller, {
  Props,
  configJSON
} from "./Events2Controller";
import MyProjectList from "./myProjectList.web";
import MyFestival from "./MyFestival.web";

export default class Events2 extends Events2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getAllProjects(),
    this.getAccountData();
    this.getUserRole();
    console.log("COMPOANT DID MOUNT")
    console.log("DATA IN MOUNT ---->", this.state.allProjectData)
  }
  // Customizable Area End

  render() {
    console.log("DATA ---->", this.state.allProjectData)
    return (
      // Customizable Area Start
      <>
        <ResponsiveAppBar accountData={this.state.accountData}/>
        {this.state.userRole != "organizer" ? 
        <MyProjectList
         data={this.state.allProjectData}
         deleteProjects={this.deleteProjects}
         isLoading={this.state.isLoading}
          /> 
          :
        <MyFestival />
    }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1
  },
  showHide: {
    alignSelf: "center"
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {}
});
// Customizable Area End
