import * as React from 'react';

import {
  Typography,
  Button,
  Grid,
  Box,
  Theme,
  createStyles,
  AppBar,
  Toolbar,
  Container,
  IconButton,
  Menu,
  Avatar,
  Tooltip,
  MenuItem
} from "@material-ui/core";
import { logo } from "./assets";

const pages = ['Browse Festivals', 'Dashboard', 'My Projects', 'Submissions'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar 
      position="static"
      color="default"
      style={wenStyles.appBar}
    >
      <Container style={{ maxWidth:'1400px'  }} maxWidth={false} >
        <Toolbar disableGutters>
          {/*<Box style={wenStyles.toolbarLogo}>
            <img src={logo} alt="logo" style={{ width: "15%" }} />
          </Box>*/}

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            {/*<IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>*/}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography color="primary" >{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={wenStyles.toolbarLogo}>
            <img src={logo} alt="logo" />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                style={{color:'white', display:'block', textTransform:'none', fontSize:'16px', fontWeight:600, padding: '6px 20px' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} style={{ padding: '0px' }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              style={{ marginTop: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseNavMenu}>
                  <Typography >{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;


const wenStyles = {
    appBar: {
      borderBottom: `1px solid #ccc`,
      width: "100%",
      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
      backgroundColor: "#330a4c",
    },
    toolbar: {
      flexWrap: "nowrap",
    },
    toolbarLogo: {
      flexGrow: '0.5'
    },
    navBarButtons: {
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '18px',
      textTransform: 'capitalize'

    },
    landingText:{
      fontSize:'42px',
      color: '#fff',
      fontWeight: 'bold',
    }
}