// Customizable Area Start
import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";


import {toast} from "react-toastify";


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    showHeader?:boolean;

}

interface S {
    accountAPIData:Object;
    profilepic:Object;
    previewprofile:String;
    coverpic:Object;
    coverpreview:String;
    updateType:String;
}

interface SS {
    id: any;
}

export default class CoverImageController extends BlockComponent<
    Props,
    S,
    SS
    > {

    ProfileAndCoverPhotoApiCall:string = ""
    getAccountDetailsAPICall:string = ""
    emailReg: RegExp;
    labelTitle: string = "";

    constructor(props: Props) {

        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ]

        this.state = {
            accountAPIData: {
                cover_pic:"",
                profile_pic:"",
                first_name:"",
                last_name:"",
                title:"",
                created_at:"2022-05-25T07:20:31.222Z"
            },
            coverpic:{cover_pic:"" as any},
            coverpreview:"",
            profilepic:{ profile_pic:"" as any},
            previewprofile:"",
            updateType:"",

        };

        this.emailReg = new RegExp("");
        this.labelTitle = configJSON.labelTitle;

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    async componentDidMount() {
        this.getAccountData()
    }
    async receive(from: string, message: Message) {

        if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            if(this.getAccountDetailsAPICall === apiRequestCallId) {
                if(responseJson.hasOwnProperty('data')){
                    this.setState({
                        accountAPIData:responseJson.data.attributes,
                    })
                }else{
                    console.log(responseJson.errors)
                    responseJson.errors.map((item:any)=>{
                        if(item.token === "Token has Expired"){
                            localStorage.removeItem("authToken")
                            localStorage.removeItem("profile")
                            localStorage.removeItem("token")
                            localStorage.removeItem("usertoken")
                            this.props.navigation.navigate("/")
                            toast.error("Token Expired")
                        }
                    })
                }
            }else if(this.ProfileAndCoverPhotoApiCall ===apiRequestCallId){
                if(responseJson.hasOwnProperty('data')){
                    toast.success(`Congratulations, ${this.state.updateType} Updated Successfully.`)
                    this.getAccountData()
                    this.setState({
                        updateType:""
                    })
                }else{
                    toast.error("Error!!")
                }
            }
        }
    }

    handleUpdateProfileImage = (e: any) => {
        if(e.target.files[0]){
            this.setState({
                profilepic: {
                    profile_pic: e.target.files[0]
                },
                previewprofile:(URL.createObjectURL(e.target.files[0]) as any)
            });
            let formData = new FormData();
            formData.append('account[profile_pic]', e.target.files[0])
            this.SetProfileAndCoverPhoto(formData)
            this.setState({
                updateType:"Profile Picture"
            })
        }else{
            toast.error("Error while uploading Profile Image")
        }
    }

    handleUpdateCoverImage = (e:any) => {
        if(e.target.files[0]){
            this.setState({
                coverpic: {
                    "cover_pic": e.target.files[0]
                },
                coverpreview:(URL.createObjectURL(e.target.files[0]) as any)
            });
            var formData = new FormData();
            formData.append('account[cover_pic]', e.target.files[0])
            this.SetProfileAndCoverPhoto(formData)
            this.setState({
                updateType:"Cover Image"
            })
        }else{
            toast.error("Error while uploading Profile Image")
        }
    }

    SetProfileAndCoverPhoto = (data: any) => {
        const token = localStorage.getItem('authToken')
        const headers = {
            token : token,
            'x-amz-acl': 'public-read'
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.ProfileAndCoverPhotoApiCall = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.urlProfileAndCoverPic
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            data
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.profileAndCoverPicApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true
    }

    onClickNavigate (){
        // @ts-ignore
        this.props.navigation.navigate("/Settings2")
    }

    getAccountData(): boolean {
        const header = {
            "Content-Type": configJSON.accountDetailsApiContentType,
            token: localStorage.getItem('authToken')
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAccountDetailsAPICall = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.urlAccountDetails
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.accountDetailsApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }
}
// Customizable Area End