// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  formik?: any;
  addCustomFile?: any;
  deleteRow?: any;
  handleChangeInput?: any;
  customRow?: any;
  checkedValues?: any;
  checkboxError?: any;
  countryError?: any;
  handleSelect?: any;
  formikProps?: any;
  handleNextStep?: any;
  handleBack?: any;
  root?: any;
  reviewData?: any;
}

interface S {
    review_visibility_type: string,
    reviewData: string
}

interface SS {
  id: any;
}

export default class CoverImageController extends BlockComponent<
  Props,
  S,
  SS
> {
  submisionConform: any;
  getCurrencyDataAPICall: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  getReviewtypeMessageApi: string = "";

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
        review_visibility_type: "public_review ",
        reviewData:""
      };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    this.getReviewData()
}

async receive(from: string, message: Message) {


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getReviewtypeMessageApi) {
        console.log("aabb-->",responseJson.data)
        console.log("Hellooo")
        this.setState({
            review_visibility_type: responseJson.data.attributes.review_visibility_type
        })
      }

      if (apiRequestCallId === this.submisionConform) {
        if (responseJson != null && responseJson.error === undefined) {
          toast.success("Congratulations, Your changes have been saved")
        } else {
          toast.error(responseJson.error)
          this.parseApiErrorResponse(responseJson);
        }
      }

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          var arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }
      }
    }
  }


  handleSubmitEvent = (review_visibility_type:any) => {
    let formData = new FormData();
    formData.append("review_visibility_type",review_visibility_type );
    this.ReviewTypeSubmit(formData)
    console.log("submissionData-->", review_visibility_type )
  }

  ReviewTypeSubmit(values: any) {
    const token = localStorage.getItem('token')
    const header = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submisionConform = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reviewTypeendpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.reviewTypeApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      values
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getReviewData(): boolean {
    const header = {
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReviewtypeMessageApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reviewTypeendpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReviewApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }
     
  handleSelect = (e:any)  => {
    console.log("values-->",e.target.value)
    this.setState({review_visibility_type: e.target.value});
  };

}
 // Customizable Area End
