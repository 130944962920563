import React from 'react';
import { Box, Tab, Tabs, Container,Paper,Grid} from '@material-ui/core';
import { makeStyles,withStyles} from '@material-ui/core/styles';
import ManageFestival from './ManageFestival.web';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor:"#F2F3F5"
        // padding: theme.spacing(2),
    },
    tab: {
        padding: theme.spacing(3),
        boxShadow:"none",
        backgroundColor:"#F2F3F5"
    },
}));

let customizeTabText: React.CSSProperties = {
    fontWeight: 800,
    textTransform: 'capitalize',
    color:"#000"
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>{children}</Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

const Navigations = () => {
    const classes = useStyles();
    const [tabValue, setTabValue] = React.useState<any>(0);
    const [value, setValue] = React.useState<any>(tabValue);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
        setValue(newValue);
      };

    React.useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        if(params.has("step")){
            var step = params.get("step") == null ? "0" : params.get("step");
            let setStep = parseInt(step == null ? "0" : step);
            if(setStep == 1){
                setValue(1)
                setTabValue(1)
            }
        }
        else{
            setTabValue(0)
        }
    },[tabValue])


    return(
        <Container>
            <Grid className={classes.root}>
                <Paper elevation={1} className={classes.tab}>
                    <Box sx={{ width: '100%'}}>
                        <Tabs 
                            value={value}
                            onChange={handleChange}
                            textColor="primary"
                            indicatorColor="primary"
                            aria-label="primary tabs example"
                            >
                            <Tab label="View" {...a11yProps(0)} style={customizeTabText} />
                            <Tab label="Edit" {...a11yProps(1)} style={customizeTabText} />
                            <Tab label="Manage" {...a11yProps(2)} style={customizeTabText} />
                            <Tab label="Marketing" {...a11yProps(4)} style={customizeTabText} />
                        </Tabs> 
                    </Box>
                </Paper>
                <TabPanel value={value} index={2}><ManageFestival /></TabPanel>
            </Grid> 
        </Container>
    )
}

export default Navigations;