// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  FormLabel,
  TextField,
  FormControlLabel,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  FormGroup,
  withStyles,
  Checkbox,
  Popover,
} from "@material-ui/core";
import "./style.css";

import EventDetailsController, {
  Props
} from "./EventDetailsController";
import { Delete, dragDrop } from "./assets";
import "./style.css"
import { Formik } from "formik";
import Loader from "../../../components/src/Loader.web";
import { stepOneSchema, formInitialValues } from "./validationSchemas"

const steps = [1, 2, 3, 4, 5];

export default class EventDetails extends EventDetailsController {

  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { checkboxError, imageError, customRow, checkedValues, previewImage, file, activeStep, anchorEl, festivalData, isReinitailize } = this.state;
    const open = Boolean(anchorEl);
    const { loading } = this.props;
    return (
      <>
      {loading && <Loader loading={loading} /> }
      <Formik
        enableReinitialize={festivalData && isReinitailize} 
        initialValues={{
          eventName: festivalData?.title || "",
          description: festivalData?.event_description || "",
          awards: festivalData?.award_prize || "",
          keyStats: festivalData?.audience_attendance || "",
          submissions: festivalData?.estimated_submissions||  "",
          project: festivalData?.projects_selected  || "",
          awardsPresented: festivalData?.awards_presented || "",
          years_running: festivalData?.years_running || "",
          event_organizers_attributes: [
            { id: Math.random(), name: "", title: "" },
          ],
        }}
        validationSchema={stepOneSchema}
        onSubmit={(values, { setSubmitting }) => {
          (checkedValues.length >= 1 && (file.name ||  previewImage)) && this.handleSubmitEvent(values, customRow, file, checkedValues)
        }}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {props => (
          <form onSubmit={props.handleSubmit}>
            <Box sx={{ padding: "50px 0px 0px" }} bgcolor="#f2f3f5">
              <Typography variant="h2" component="h2">
                Accept Submissions to Your Event
              </Typography>
            </Box>

            <Box sx={{ padding: "20px 0px" }}>
              <Box className="formTitle">
                <Typography variant="h4" component="h4">
                  Event Details
                </Typography>
              </Box>
              <Box bgcolor="#FFF">
                <Box component="form" sx={{ padding: "20px 20px 150px" }}>
                  <div>
                    <Box border={0.2} borderColor="#f5f5f5" borderRadius={4}>
                      <FormControl style={{ width: "100%", padding: "20px 0px" }}>
                        <FormLabel component="legend" className="label">
                          Event Name <span className="astrick">*</span>
                        </FormLabel>
                        <TextField
                          name="eventName"
                          id="outlined-disabled"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.eventName}
                          placeholder="Enter your event name"
                          required
                          InputProps={{ className: "root" }}
                          className="inputClass"
                        />
                        {props.errors.eventName && props.touched.eventName ? (<p className="formError">{props.errors.eventName}</p>) : null}
                      </FormControl>

                      <FormControl
                        component="div"
                        style={{ padding: "20px 0px", width: "100%" }}
                      >
                        <FormLabel component="legend" className="label">
                          Event Type <span className="astrick">*</span>{" "}
                          <span className="labelText">
                            Select all that apply
                          </span>
                        </FormLabel>
                        <FormGroup row aria-label="event_type" style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "90%",
                          justifyContent: "space-between",
                        }}>
                          {["Film Festival", "Screenwriting Contest", "Music Contest", "Photography Contest", "Online Festival / Awards Event"].map(name => (
                            <FormControlLabel
                              control={<CustomColorCheckbox />}
                              checked={checkedValues?.includes(name)}
                              name="names"
                              onChange={() => this.handleSelect(name)}
                              key={name}
                              label={name}
                            />
                          ))}
                        </FormGroup>
                        {checkboxError && <div className="formError">Please select atleast one</div>}
                      </FormControl>

                      <FormControl style={{ width: "100%", padding: "20px 0px" }}>
                        <FormLabel component="legend" className="label">
                          Upload Logo<span className="astrick">*</span>
                        </FormLabel>
                        <Grid container spacing={3} style={{ paddingTop: "20px" }}>
                          <Grid item xs={3}>
                            <Paper
                              elevation={2}
                              className="addTrailerSection"
                            >
                              {previewImage ?
                                <img src={previewImage} alt="logo" style={{
                                  height: "186px",
                                  width: "304px",
                                  borderRadius: "6px",
                                  objectFit: "cover", marginTop: "-60px"
                                }} /> :
                                <>
                                  <Grid>
                                    <img src={dragDrop} width={40} style={{ marginBottom: "10px" }} />
                                  </Grid>
                                  <div className="uploadStyle">Upload Logo</div>
                                  <input
                                    onChange={this.uploadLogoHandler}
                                    type="file"
                                    accept="image/*"
                                    name="file"
                                    style={{
                                      opacity: 0,
                                      width: "305px",
                                      height: "180px",
                                      top: 0, left: 0,
                                      cursor: "pointer",
                                      position: "absolute"
                                    }}
                                  />
                                </>
                              }

                            </Paper>
                          </Grid>

                          <Grid item xs={4}>
                            <div style={{ paddingTop: "40px" }}>
                              Upload a square image to represent your event. It will
                              be resized to 330px by 330px.
                            </div>
                            <Button
                              className="submissionsButton"
                            >
                              Upload File
                              <input
                                type="file"
                                onChange={this.uploadLogoHandler}
                                name="file"
                                accept="image/png, image/gif, image/jpeg"
                                style={{ opacity: "0", position: "absolute", height: "40px", width: "180px", cursor: "pointer" }}
                              />
                            </Button>

                          </Grid>
                        </Grid>
                        {imageError && <div className="formError">Please Upload Image</div>}
                      </FormControl>

                      <FormControl style={{ width: "100%", padding: "20px 0px" }}>
                        <FormLabel component="legend" className="label">
                          Event Description <span className="astrick">*</span>{" "}
                          <span
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={this.handlePopoverOpen}
                            onMouseLeave={this.handlePopoverClose}
                            className="labelLink"
                          >
                            Helpful Tips
                          </span>
                          <Popover
                            id="mouse-over-popover"
                            className="popover"
                            style={{ pointerEvents: 'none' }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            onClose={this.handlePopoverClose}
                            disableRestoreFocus
                          >
                            <Typography style={{ padding: "10px" }}>Information about field</Typography>
                          </Popover>
                        </FormLabel>
                        <TextField
                          name="description"
                          id="outlined-disabled"
                          placeholder="Enter your event description"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.description}
                          InputProps={{ className: "root" }}
                          className="inputClass"
                        />
                        {props.errors.description && props.touched.description ? (<p className="formError">{props.errors.description}</p>) : null}
                      </FormControl>

                      <FormControl style={{ width: "100%", padding: "20px 0px" }}>
                        <FormLabel component="legend" className="label">
                          Awards & Prizes <span className="astrick">*</span>{" "}
                          <span
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={this.handlePopoverOpen}
                            onMouseLeave={this.handlePopoverClose}
                            className="labelLink"
                          >
                            What is this?
                          </span>
                          <Popover
                            id="mouse-over-popover"
                            className="popover"
                            style={{ pointerEvents: 'none' }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            onClose={this.handlePopoverClose}
                            disableRestoreFocus
                          >
                            <Typography style={{ padding: "10px" }}>Information about field</Typography>
                          </Popover>

                        </FormLabel>
                        <TextField
                          name="awards"
                          id="outlined-disabled"
                          placeholder="Enter Awards & Prizes Details"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.awards}
                          InputProps={{ className: "root" }}
                          className="inputClass"
                        />
                        {props.errors.awards && props.touched.awards ? (<p className="formError">{props.errors.awards}</p>) : null}
                      </FormControl>

                      <FormControl style={{ width: "100%", padding: "20px 0px" }}>
                        <FormLabel component="legend" className="label">
                          Years Running{"  "}
                          <span
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={this.handlePopoverOpen}
                            onMouseLeave={this.handlePopoverClose}
                            className="labelLink"
                          >
                            What is this?
                          </span>
                          <Popover
                            id="mouse-over-popover"
                            className="popover"
                            style={{ pointerEvents: 'none' }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            onClose={this.handlePopoverClose}
                            disableRestoreFocus
                          >
                            <Typography style={{ padding: "10px" }}>Information about field</Typography>
                          </Popover>

                        </FormLabel>
                        <TextField
                          name="years_running"
                          id="outlined-disabled"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.years_running}
                          InputProps={{ className: "root" }}
                          className="inputClass"
                          type="number"
                        />
                      </FormControl>

                      <FormControl style={{ width: "100%", padding: "20px 0px" }}>
                        <FormLabel component="legend" className="label">
                          Event Organizers{" "}
                          <span
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={this.handlePopoverOpen}
                            onMouseLeave={this.handlePopoverClose}
                            className="labelLink"
                          >
                            (Optional)
                          </span>
                          <Popover
                            id="mouse-over-popover"
                            className="popover"
                            style={{ pointerEvents: 'none' }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            onClose={this.handlePopoverClose}
                            disableRestoreFocus
                          >
                            <Typography style={{ padding: "10px" }}>Information about field</Typography>
                          </Popover>
                        </FormLabel>
                        <TableContainer
                          component={Paper}
                          style={{ padding: "20px" }}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className="submissionsHeader">
                                  Name
                                </TableCell>
                                <TableCell
                                  className="submissionsHeader"
                                  align="left"
                                >
                                  Title
                                </TableCell>

                                <TableCell
                                  className="submissionsHeader"
                                  align="right"
                                >
                                  Action
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {customRow && customRow.filter((newRow:any) => !newRow._destroy).map((newRow:any, id: any) => {
                                return <TableRow className="overrides" key={newRow.id}>
                                  <TableCell>
                                    <TextField
                                      name="name"
                                      value={newRow.name}
                                      id="outlined-disabled"
                                      placeholder="John Doe"
                                      style={{ width: "100%" }}
                                      className="inputClass"
                                      onChange={event => this.handleChangeInput(newRow.id, event)}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      name="title"
                                      value={newRow.title}
                                      id="outlined-disabled"
                                      placeholder="Festival Director"
                                      style={{ width: "100%" }}
                                      className="inputClass"
                                      onChange={event => this.handleChangeInput(newRow.id, event)}
                                    />
                                  </TableCell>
                                  <TableCell align="right">
                                    <IconButton style={{ padding: '0px' }} onClick={() => this.deleteRow(newRow.id)}>
                                      <img style={{ width: "50px", marginLeft: "25px" }} src={Delete} alt="logo" />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              })}
                            </TableBody>
                            <Button
                              className="submissionsButton"
                              onClick={() => this.addCustomFile()}
                            >
                              ADD ANOTHER ORGANIZER
                            </Button>
                          </Table>
                        </TableContainer>
                      </FormControl>

                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <FormControl
                            style={{ padding: "20px 0px", width: "100%" }}
                          >
                            <FormLabel component="legend" className="label">
                              Key Stats <span className="astrick">*</span>{" "}
                              <span
                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={this.handlePopoverOpen}
                                onMouseLeave={this.handlePopoverClose}
                                className="labelLink"
                              >
                                What is this?
                              </span>
                              <Popover
                                id="mouse-over-popover"
                                className="popover"
                                style={{ pointerEvents: 'none' }}
                                open={open}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                onClose={this.handlePopoverClose}
                                disableRestoreFocus
                              >
                                <Typography style={{ padding: "10px" }}>Information about field</Typography>
                              </Popover>
                            </FormLabel>
                            <TextField
                              name="keyStats"
                              id="outlined-disabled"
                              placeholder="Audience Attendance"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.keyStats}
                              InputProps={{ className: "root" }}
                              className="inputClass"
                              type="number"
                            />
                            {props.errors.keyStats && props.touched.keyStats ? (<p className="formError">{props.errors.keyStats}</p>) : null}
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <FormControl
                            style={{
                              padding: "20px 0px",
                              width: "100%",
                              margin: "19px 0px 0px",
                            }}
                          >
                            <FormLabel component="legend" className="label" />
                            <TextField
                              name="submissions"
                              id="outlined-disabled"
                              placeholder="Estimated Submissions"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.submissions}
                              InputProps={{ className: "root" }}
                              className="inputClass"
                              type="number"
                            />
                            {props.errors.submissions && props.touched.submissions ? (<p className="formError">{props.errors.submissions}</p>) : null}
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <FormControl
                            style={{
                              padding: "20px 0px",
                              width: "100%",
                              margin: "19px 0px 0px",
                            }}
                          >
                            <FormLabel component="legend"> </FormLabel>
                            <TextField
                              name="project"
                              id="outlined-disabled"
                              placeholder="Projects Selected"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.project}
                              InputProps={{ className: "root" }}
                              className="inputClass"
                              type="number"
                            />
                            {props.errors.project && props.touched.project ? (<p className="formError">{props.errors.project}</p>) : null}
                          </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                          <FormControl
                            style={{
                              padding: "20px 0px",
                              width: "100%",
                              margin: "19px 0px 0px",
                            }}
                          >
                            <FormLabel component="legend"> </FormLabel>
                            <TextField
                              name="awardsPresented"
                              id="outlined-disabled"
                              placeholder="Awards Presented"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.awardsPresented}
                              InputProps={{ className: "root" }}
                              className="inputClass"
                              type="number"
                            />
                            {props.errors.awardsPresented && props.touched.awardsPresented ? (<p className="formError">{props.errors.awardsPresented}</p>) : null}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', width: '43%', flexDirection: 'row', pt: 2, marginBottom: "20px" }}>
               {/* @ts-ignore */}
              <Button color="primary" ref={this.callRef} onClick={() => this.handleSubmit()} type="submit">
                {activeStep === steps.length - 1 ? 'LIST YOUR EVENT' : 'NEXT'}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      </>
    );
  }
}

const CustomColorCheckbox = withStyles({
  root: {
    color: "#a5259f",
    "&$checked": {
      color: "#a5259f",
    },
  },
})((props) => <Checkbox color="default" {...props} />);
// Customizable Area End
