import * as React from 'react';

import {
  Box,
  Grid,
  Button,
  TextField,
  Typography
} from "@material-ui/core";

const MergeAccount = () => {

  return (
        <Box sx={webStyles.bottomRight}>
            <Box sx={webStyles.formCard}>
                <Box sx={{bgcolor: "#320b4c", padding: "10px"}}>
                    <Typography variant="h4" style={{ color: "#FFF"}} >Merge Accounts</Typography>
                </Box>
                <Box sx={{padding: "20px" }}>
                    <Typography variant="body2" style={{fontWeight: 600, marginBottom: "15px"}} >
                        project with credits that match color accounts name xactly will appear accounts name xactly will appear here doller metha sit that match color accounts.
                    </Typography>
                    <Typography variant="body2" style={{fontWeight: 600, marginBottom: "15px"}} >project with credits that accounts name xactly will appear accounts name xactly will appear  match color accounts.</Typography>
                    <Typography variant="body2" style={{fontWeight: 600, marginBottom: "15px"}} >project with credits that accounts name xactly will appear  match color.</Typography>
                </Box>
                <Box sx={webStyles.formDesign}>
                    <Grid item xs={6}>
                          <TextField
                            type="email"
                            fullWidth
                            id="outlined-disabled"
                            placeholder="Email Address"
                          />
                    </Grid>
                    <Grid item xs={6}>
                          <TextField
                            type="password"
                            fullWidth
                            id="outlined-disabled"
                            placeholder="Password"
                          />
                    </Grid>
                </Box>
            </Box>
            <Box >
                <Button variant="contained" style={{ background: "#A5259F", color: "#fff", marginTop: "10px"}}>Merge Account</Button>
            </Box>
        </Box>
  );
};
export default MergeAccount;


const webStyles = {
    main: {
        display: "flex",
        justifyContent: "center",
        height: "100px",
        background: "000"
    },
    bottomRight: {
        display: "flex",
        flexDirection: "column",
        width: '75%'
    },
    formCard: {
        background: "#fff",
        marginBottom: "20px"
    },
    formDesign: {
        display: "flex",
        padding: "40px 20px",
        gap: "30px"
    }
}