import React from "react";
import AppHeader from "../../../components/src/AppHeader.web"
import ViewProfile from "./ViewProfile.web"
import MiddleCard from "./MiddleCard.web"
import RightCard from "./RightCard.web"
import LeftCard from "./LeftCard.web"
// Customizable Area Start
import Footer from "../../../components/src/AppFooter.web"
// Customizable Area End
import {
  Box,
  Grid,
  Container,
} from "@material-ui/core";


import DashboardController, {
  Props,
  configJSON
} from "./DashboardController";

export default class Dashboard extends DashboardController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }

  // Customizable Area Start
  async componentDidMount() {
    this.getUserRole();
    this.getAccountData();
    this.getFilmographyData();
    this.getRoleData();
   }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <AppHeader
            // @ts-ignore
            accountData={this.state.accountData}
        />
        {this.state.userRole === "organizer" ? 
        <>
         <div style={{ backgroundColor: "#F2F3F5" }}>
         <Container
           style={{ maxWidth: "1400px", backgroundColor: "#F2F3F5" }}
           maxWidth={false}
         >
           <Box sx={{ flexGrow: 1 }} style={{ padding: "20px" }}>
             <Grid container spacing={3}>
               <Grid item xs>
                 <LeftCard accountData={this.state.accountData} />
               </Grid>
               <Grid item xs={6}>
                <MiddleCard  />
               </Grid>
               <Grid item xs>
                 <RightCard />
               </Grid>
             </Grid>
           </Box>
         </Container>
         </div>
         <Footer />
         </>
        :  <ViewProfile handleSubmitNews = {this.handleSubmitNews}
                        handleSubmitLinks = {this.handleSubmitLinks} 
                        handleFileAttachLinks = {this.handleFileAttachLinks}
                        handleProfilePic = {this.handleProfilePic}
                        handleCoverPic = {this.handleCoverPic}
                        accountData={this.state.accountData}
                        filmographyData = {this.state.filmographyData}
                        handleUpdatePhotos={this.handleUpdatePhotos}
                        handleDragImg = {this.handleDragImg}
                        deleteAccountPhotos={this.deleteAccountPhotos}
                        loader={this.state.loader}
                        />
                        
                      }
                      {console.log("film data ===>",this.state.filmographyData)}
                      {console.log("role Data ====>", this.state.accountData)}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
