// Customizable Area Start

import React,{Component} from 'react';
import {Box, Button, Grid, Typography} from "@material-ui/core";
import {exampleLaurel} from './assets'
import {withRouter} from 'react-router-dom'

class LaurelDisplay extends Component {
    constructor(props:any) {
        super(props);
    }
    render(){
        return (
            <Box sx={webStyles.bottomRight}>
                <Box sx={webStyles.formCard}>
                    <Box sx={{bgcolor: "#320b4c", padding: "10px"}}>
                        <Typography variant="h4" style={{ color: "#FFF"}} >Laurel Creation Tool</Typography>
                    </Box>
                    <Box sx={{padding: "20px"}}>
                        <Box sx={{display: "flex"}}>
                            <Box sx={{padding: "20px", flexGrow: 1  }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <Typography variant="h6" component="legend">This tool allows you to easily create your own custom laurel images for use on your website, social media and promotional materials.</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/*@ts-ignore*/}
                                        <Button variant="contained" style={{ background: "purple", color: "#fff", marginTop: "20px",fontSize:"20px"}} onClick={()=>this.props.history.push("/UserProfileBasicBlock/create-laurel")}>CREATE LAUREL</Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <img src={exampleLaurel} height="300px" width="450px" style={{marginTop:"15px"}}/>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography variant="body1" component="legend" style={{marginTop:"5px",fontWeight:"bold"}}>This is an example laurel. This image will disappear once you upload or create your first laurel.</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }
};

export default (...props:any) => {
    // @ts-ignore
    const WithRouteLaurelDisplay = withRouter(LaurelDisplay)
    return (
        <WithRouteLaurelDisplay {...props} />
    )
};

const webStyles = {
    main: {
        display: "flex",
        justifyContent: "center",
        height: "100px",
        background: "000"
    },
    bottomRight: {
        display: "flex",
        flexDirection: "column",
        width: '75%'
    },
    formCard: {
        background: "#fff",
        marginBottom: "50px"
    }
}
// Customizable Area End