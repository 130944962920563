import React from 'react';
import { Box,Container,RadioGroup,FormControlLabel,Radio,FormControl,Link,Button,Typography,Modal,Paper,Grid} from '@material-ui/core';
import { makeStyles,withStyles} from '@material-ui/core/styles';
import { Formik } from "formik";
import { stepTwoSchema, formInitialValues } from "./validationSchemas"
import ReviewController, {
    Props
  } from "./ReviewController";

class Reviews extends ReviewController {
    constructor(props:any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End   
      }

    render() {
        const {review_visibility_type} = this.state
    return(
        <Formik
        enableReinitialize
        initialValues={formInitialValues}
        // validationSchema={stepOneSchema}
        onSubmit={() => {
          this.handleSubmitEvent(review_visibility_type)
        }}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {props => (
        <form onSubmit={props.handleSubmit} style={{width:"100%"}}>
            <Container style={{paddingLeft:"30px"}}>
                <Box style={{padding:"0px 0px 0px 7px"}}>
                    <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem"}}>Review</Typography>
                </Box>
                {/* {console.log("reviewData-->",this.state.reviewData)}     */}
                <Box style={{flexWrap: 'wrap'}}>
                    <Paper style={{padding:"15px 15px 30px 25px"}}>
                        <Typography variant="h4" style={{marginBottom:'10px',fontWeight:800}}>Review Visibility</Typography>
                        <Box>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="public_review"
                                    // name="review_visibility_type"
                                    onChange={this.handleSelect}
                                    value={review_visibility_type}
                                >
                                    <div style={{display:"flex", alignItems:"center"}}>
                                        <FormControlLabel value="public_review" control={<Radio size="small" color="primary"/>} label="Allow reviews to be publicly visible on festival listing page." />
                                        <FormControlLabel value="private_review" control={<Radio size="small" color="primary"/>} label="Keep all reviews private between festival and the reviewer." />
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box style={{margin:"15px 0px 0px 0px"}}>
                            <Button color="inherit" type="submit">SAVE</Button>
                        </Box>
                        <Typography style={{marginTop:'20px',fontSize:'1rem'}}>Tip: Review visibility must be set to public to qualify for <Link> Top 100 Best Reviewed Festivals.</Link></Typography> 
                    </Paper>
                </Box>
                    <Paper style={{padding:"15px 15px 30px 15px", marginTop:"20px"}}>
                        <Box style={{padding:"0px 0px 0px 7px"}}>
                            <Typography variant="h4" style={{marginBottom:'10px',fontWeight:800}}>Review</Typography>
                            <Typography style={{fontSize:'1rem'}}>Your festival has not yet received any reviews.</Typography>
                        </Box>
                    </Paper>
            </Container>
        </form>
    )}
   </Formik>
    )
}
}

export default Reviews;