// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  formik?: any;
  addCustomFile?: any;
  deleteRow?: any;
  handleChangeInput?: any;
  customRow?: any;
  checkedValues?: any;
  checkboxError?: any;
  countryError?: any;
  handleSelect?: any;
  formikProps?: any;
  handleNextStep?: any;
  handleBack?: any;
  root?: any;
}

interface S {
  deadline_waiver_days:number;
  title_question: string;
  field_type: string;
  field_category:any;
  submissionData: any;
  loader: boolean;
}

interface SS {
  id: any;
}

export default class CoverImageController extends BlockComponent<
  Props,
  S,
  SS
> {
  editEventCall: any;
  getCurrencyDataAPICall: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  customSubmissonEventCall: string = "";
  getcustomSubmissonDataApi: string = "";


  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      deadline_waiver_days:14,
      field_type: '',
      title_question:'',
      field_category:'require_all',
      submissionData:'',
      loader: true
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    this.getcustomSubmissonData();
  }

  async receive(from: string, message: Message) {


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.customSubmissonEventCall) {
        console.log("responseJson???", responseJson)
        if (responseJson.data.type != 'error' && responseJson.error === undefined) {
          toast.success("Congratulations, Your changes have been saved")
        } else {
          toast.error("Your Title / Question has already been taken.")
          this.parseApiErrorResponse(responseJson);
        }
      }

      if (apiRequestCallId === this.getcustomSubmissonDataApi) {
        console.log("responseJson.data",responseJson.data)
        this.setState({
          submissionData: responseJson.data,
          loader: false
        })
      }

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          var arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }
      }
    }
  }


  handleSubmitEvent = (values: any, field_category:any) => {
    let formData = new FormData();
    formData.append("field_type", values.field_type);
    formData.append("field_category", field_category);
    formData.append("title_question", values.title_question);
    this.customSubmissonData(formData)
    console.log("formData-->", values)
  }

  customSubmissonData(values: any) {
    const token = localStorage.getItem('token')
    const header = {
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.customSubmissonEventCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customSubmissonDataendpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.discountwaiverdataApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      values
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getcustomSubmissonData(): boolean {
    const header = {
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getcustomSubmissonDataApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getcustomSubmissonDataendpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getcustomSubmissonDataApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }

  handleSelect = (e:any)  => {
    console.log("values-->",e.target.value)
    this.setState({field_category: e.target.value});
  };


}
 // Customizable Area End
