import React from 'react';
import { Box,Container,Link, Tab, Tabs, Button,Typography,Modal,Paper,Grid,FormLabel,CircularProgress } from '@material-ui/core';
import { makeStyles,withStyles} from '@material-ui/core/styles';

class CheckAttendees extends React.Component {

    render() {
    return(
        <Container style={{paddingLeft:"30px"}}>
            <Box style={{padding:"0px 0px 0px 7px"}}>
                <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem",marginBottom:"10px"}}>Email Judges & Staff</Typography>
            </Box>
            <Box style={{flexWrap: 'wrap'}}>
                <Paper style={{padding:"15px 15px 30px 15px"}}>
                    <Typography variant="h4" style={{marginBottom:'10px',fontWeight:800}}>Send a custom email to your judges and/or staff.</Typography>
                    <Typography style={{fontWeight:800}}>
                   This tool will appear here when you <Link style={webStyles.commonfontweight}>add sub-users to your account.</Link>
                    </Typography>
                </Paper>
            </Box>
        </Container>
    )
}
}

export default CheckAttendees;


const webStyles = {
    commonfontweight: {
      fontWeight:400
    }
  };
  