import * as Yup from 'yup';

export const stepOneSchema = () => {
  return Yup.object().shape({
    discount_type: Yup.string().required('Event name required'),
    code: Yup.string().required('Description required'),
    label: Yup.string().required('Awards & Prizes required'),
    discount_value: Yup.string().required('Key Stats is required'),
    discount_per: Yup.string().required('Submissions is required'),
    max_uses: Yup.string().required('Project is required'),
    valid_from: Yup.string().required('Presented awards required'),
    valid_to: Yup.string().required('Presented awards required'),
    deadline_waiver_days: Yup.string().required('Presented awards required'),
    

    // attachment: Yup.mixed()
    //   .nullable()
    //   .required()
    //   .test("FILE_FORMAT", "Uploaded file has unsupported format.",
    //     value => !value || (value && ["png", "jpg", "jpeg"].includes(value.type)))
  })
};

export const stepTwoSchema = () => {
  return Yup.object().shape({
    website: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!'
    ).required('Website required'),
    email: Yup.string().email('Invalid email').required('Email required'),
    country: Yup.string().required('Country required'),
    // eventVenue: Yup.string().required('Submissions is required'),
  })
};

export const inviteContact = () => {
  return Yup.object().shape({
    invitationmail: Yup.string().required('Presented awards required'),
  })
};

export const formInitialValues = {
    discount_type: "",
    code: "",
    label: "",
    discount_value: 0,
    discount_per: 0,
    max_uses: 0,
    valid_from: "",
    valid_to: "",
    deadline_waiver_days:14,
    invitationmail:"",
    radiovalue:"flat_discount",
    confirmation_message:""
}

export const manageUserInitialValue = {
  checked:true,
  fname:"",
  lname:"",
  email:"",
  internal_note:"",
}

export const customJudgeInitialValue = {
  judgeFormType:"default",
  starvalue:3 
}

export const customSubmissionInitialValue = {
  title_question:'',
  field_category:'require_all',
  field_type:'',
  submissionData:'',
  loader:true
}