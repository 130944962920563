import * as React from 'react';

import {
  Box,
  Button,
  Typography
} from "@material-ui/core";

import { Gold } from './assets'

const MovieExpressGold = () => {

  return (
        <Box sx={webStyles.bottomRight}>
            <Box sx={webStyles.formCard}>
                <Box sx={{bgcolor: "#320b4c", padding: "10px"}}>
                    <Typography variant="h4" style={{ color: "#FFF"}} >Movie Expressway Gold</Typography>
                </Box>
                <Box sx={{padding: "20px"}}>
                    <Typography variant="body1" style={{fontWeight: 700}} >
                        You have saved $25.38 using MovieExpress Gold.
                    </Typography> 
                    <Box sx={{display: "flex", justifyContent: "space-evenly"}}>
                        <Box sx={webStyles.card}>
                            <h2>Monthly <span style={{color: "#A5259F"}}> $10.99 / Month</span></h2>
                            <Box sx={webStyles.cardImage}>
                                <img src={Gold} height={150} width={250} style={{ borderRadius: "10px", boxShadow: "#ccc 2px 2px 11px 4px"}} />
                            </Box>
                            <Box sx={webStyles.discount}>
                                <Typography variant="body1" style={{fontWeight: 400}} >10% to 50% Off entry fee</Typography>
                            </Box>
                            <Button 
                                variant="contained"
                                fullWidth
                                style={{ background: "#A5259F", color: "#fff", marginTop: "10px"}}
                            >SELECT GOLD MONTHLY</Button>
                        </Box>
                        <Box sx={webStyles.card}>
                            <h2>Monthly <span style={{color: "#A5259F"}}> $10.99 / Month</span></h2>
                            <Box sx={webStyles.cardImage}>
                                <img src={Gold} height={150} width={250} style={{ borderRadius: "10px", boxShadow: "#ccc 2px 2px 11px 4px"}} />
                            </Box>
                            <Box sx={webStyles.discount}>
                                <Typography variant="body1" style={{fontWeight: 400}} >10% to 50% Off Entry Fee</Typography>
                            </Box>
                            <Button 
                                variant="contained"
                                fullWidth
                                style={{ background: "#A5259F", color: "#fff", marginTop: "10px"}}
                            >SELECT GOLD MONTHLY</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
  );
};
export default MovieExpressGold;


const webStyles = {
    main: {
        display: "flex",
        justifyContent: "center",
        height: "100px",
        background: "000"
    },
    bottomRight: {
        display: "flex",
        flexDirection: "column",
        width: '75%'
    },
    formCard: {
        background: "#fff",
        marginBottom: "50px"
    },
    card: {
        width: "40%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        margin: "20px",
        padding: "20px",
        boxShadow: "2px 2px 11px 4px #eee",
        borderRadius: "10px",
        transition: 'transform .2s',
        '&:hover': {
            transform: 'scale(1.05)'
        },
    },
    cardImage: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    discount: {
        margin: "20px 0px 10px 0px;"
    }
}