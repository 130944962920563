import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {toast} from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  errorMessage: string;
  successMessage: string;
  txtInputValue: string;
  dob:any;
  gender:any;
  title:string;
  intro:string;
  bio: string;
  nickname: string;
  birth_city:string;
  current_city:string;
  hometown: string;
  hight: string;
  ethnicity: string;
  eye_color:string;
  zodiac_sign: string;
  married_to: string;
  children: string;
  trivia: string;
  favourite_quote: string;
  start_date:any;
  school:string;
  major:string;
  end_date:any;
  college:any;
  college_major:string;
  college_sDate:any;
  college_eDate:any;
  awardData: any;
  userData: any;
  accountData: any;
  coverImage: any;
  profileImage:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start  
  AllProjectSubmitApiCall: String = "";
  getAwardsAPICall: String = '';
  updateAwardsAPICall: string = '';
  getUserDataAPICall: string = '';
  getAccountDetailsAPICall:String = '';
  ProfilecoverphotoApiCall: String = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      errorMessage: "",
      successMessage:"",
      dob:"",
      gender:"",
      title:"",
      intro:"",
      bio: "",
      nickname: "",
      birth_city:"",
      current_city:"",
      hometown: "",
      hight: "",
      ethnicity: "",
      eye_color:"",
      zodiac_sign: "",
      married_to: "",
      children: "",
      trivia: "",
      favourite_quote: "",
      start_date:"",
      school:"",
      major:"",
      end_date:"",
      college:"",
      college_major:"",
      college_sDate:"",
      college_eDate:"",
      awardData: null,
      userData: null,

      accountData:"",
      coverImage:"",
      profileImage:""
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

     var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getAwardsAPICall) {
        this.setState({
          awardData: responseJson,
        })
      }
      if (apiRequestCallId === this.getUserDataAPICall) {
        this.setState({
          userData: responseJson.data.attributes
        })
      }
      if (apiRequestCallId === this.updateAwardsAPICall) {
        if (responseJson != null && !responseJson.error) {
          // this.showAlert("Success","Your changes have been saved");
          toast.success("Congratulations, Your changes have been saved")
        } else {
          // this.showAlert("Error", responseJson.error);
          toast.error(responseJson.error)
          this.parseApiErrorResponse(responseJson);
        }
        this.getFilmographyAndAwards();
      }
      if (apiRequestCallId === this.getAccountDetailsAPICall) {
        this.setState({
          accountData: responseJson.data.attributes,
          coverImage: responseJson.data.attributes.cover_pic,
          profileImage: responseJson.data.attributes.profile_pic
        })
      }
    }

    // if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
    //   let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

    //   this.showAlert(
    //     "Change Value",
    //     "From: " + this.state.txtSavedValue + " To: " + value
    //   );

    //   this.setState({ txtSavedValue: value });
    // }

    // Customizable Area Start
    // Customizable Area End

  }

  handleSubmitProject = (values :any, companyInfo: any, companyId:any, talentInfo:any, talentId:any, represenatativeInfo: any ) => {
    let educations_attributes = []
    var schoolData = { 
      school:values.school,
      major:values.major,
      start_date:values.start_date,
      end_date:values.end_date,
      education_type:"school",
    }
   
    var collageData = { 
      school:values.college,
      major:values.college_major,
      start_date:values.college_sDate,
      end_date:values.college_eDate,
      education_type:"college",
    }
    var companyData = {
      name: companyInfo ? companyInfo.nameCompany : "",
      email: companyInfo ? companyInfo.emailCompany : "",
      full_phone_number: companyInfo ? companyInfo.phonenoCompany : "",
      address1: companyInfo ? companyInfo.address1Company : "",
      address2:  companyInfo ? companyInfo.address2Company : "",
      city:  companyInfo ? companyInfo.cityCompany : "",
      state:  companyInfo ? companyInfo.stateCompany : "",
      postal_code:  companyInfo ? companyInfo.postal_codeCompany : "",
      country:  companyInfo ? companyInfo.country_of_originCompany : "",
      id: companyId !== 0 ? companyId : ''
    }
    var talentData = {
      name: talentInfo ? talentInfo.nameTalent : "",
      email: talentInfo ? talentInfo.emailTalent : "",
      full_phone_number: talentInfo ? talentInfo.phonenoTalent : "",
      address1: talentInfo ? talentInfo.address1Talent : "",
      address2: talentInfo ? talentInfo.address2Talent : "",
      city: talentInfo ? talentInfo.cityTalent : "",
      state: talentInfo ? talentInfo.stateTalent : "",
      postal_code: talentInfo ? talentInfo.postal_codeTalent : "",
      country: talentInfo ? talentInfo.country_of_originTalent : "",
      id: talentId !== 0 ? talentId : '',
      representatives_attributes: represenatativeInfo
    }
   
    educations_attributes.push(schoolData)
    educations_attributes.push(collageData)
    let data = {
          "account":{  
              "dob": values.dob,
              "gender":values.gender,
              "title":values.title,
              "intro":values.intro,
              "bio":values.bio,
              "nickname":values.nickname,
              "birth_city":values.birth_city,
              "current_city":values.current_city,
              "hometown":values.hometown,
              "hight":values.hight,
              "ethnicity":values.ethnicity,
              "eye_color":values.eye_color,
              "zodiac_sign":values.zodiac_sign,
              "married_to":values.married_to,
              "children":values.children,
              "trivia":values.trivia,
              "favourite_quote":values.favourite_quote,
              "educations_attributes":educations_attributes,
              "full_phone_number" : values.phoneno,
              "email": values.email,
              "address": values.address,
              "country_of_origin" : values.country_of_origin,
              "country_of_residence": values.country_of_residence,
              "city": values.city,
              "state": values.state,
              "postal_code": values.postal_code,
              "company_attributes": companyData,
              "talent_agent_attributes": talentData
           },
    }
    this.AllProjectHandler(data)
    // this.showAlert("Congratulations", "your changes have been saved");
    toast.success("Congratulations, Your changes have been saved")
    this.getUserData();
  }

  handleProfilePic = (profilepic:any, coverpic:any) => {
    var formData = new FormData();
    // if (profilepic != ""){
      console.log("profile picture API",profilepic)
      formData.append('account[profile_pic]', profilepic.profile_pic)
    // }
    // if(coverpic != ""){
    //   console.log("COVER picture API", coverpic.cover_pic)
    //   formData.append('account[cover_pic]', coverpic.cover_pic)
    // }
    console.log("value ====>>", profilepic.profile_pic)
    this.SetProfileandCoverPhoto(formData)

    console.log(formData)
    // this.showAlert("Congratulations", "your profile was updated!");
  }

  handleCoverPic = (coverpic:any) => {
    var formData = new FormData();
    // if (profilepic != ""){
      console.log("profile picture API",coverpic)
      formData.append('account[cover_pic]', coverpic.cover_pic)
    // }
    // if(coverpic != ""){
    //   console.log("COVER picture API", coverpic.cover_pic)
    //   formData.append('account[cover_pic]', coverpic.cover_pic)
    // }
    console.log("value ====>>", coverpic.cover_pic)
    this.SetCoverPhoto(formData)

    console.log(formData)
    // this.showAlert("Congratulations", "your profile was updated!");
  }

  SetProfileandCoverPhoto = (data: any) => {
    this.setState({
      errorMessage: "ggfgfgfgfgf",
      successMessage: "success"
    })

    let url = ''
    let httpBody = {}
    url = `${configJSON.endPointApiUpdateAccountDetails}`
    httpBody = {account: data}
    const token = localStorage.getItem('authToken')
    const headers = {
      // "Content-Type": configJSON.exampleApiContentType,
      "token" : token,
      'x-amz-acl': 'public-read'
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ProfilecoverphotoApiCall = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editProfileAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  SetCoverPhoto = (data: any) => {
    this.setState({
      errorMessage: "ggfgfgfgfgf",
      successMessage: "success"
    })

    let url = ''
    let httpBody = {}
    url = `${configJSON.endPointApiUpdateAccountDetails}`
    httpBody = {account: data}
    const token = localStorage.getItem('authToken')
    const headers = {
      // "Content-Type": configJSON.exampleApiContentType,
      "token" : token,
      'x-amz-acl': 'public-read'
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ProfilecoverphotoApiCall = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editProfileAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  AllProjectHandler = (data: any) => {
    this.setState({
      errorMessage: "ggfgfgfgfgf",
      successMessage: "success"
    })

    let url = ''
    let httpBody = {}
    url = `${configJSON.editAboutmeEndpoint}`
    httpBody = data
    const token = localStorage.getItem('authToken')
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token" : token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.AllProjectSubmitApiCall = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editProfileAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserData(): boolean {
    const header = {
      // "Content-Type": configJSON.urlGetValidations,
      token: localStorage.getItem('authToken') 
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserDataAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editAboutmeEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getFilmographyAndAwards(): boolean {
    const header = {
      // "Content-Type": configJSON.urlGetValidations,
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAwardsAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiAwardsAndFilmography
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }

  updateAwardsAndFilmography = (awardData: any) => {
    const httpBody = {
      data:{
        attributes: awardData,
      }
    };
    const headers = {
      "Content-Type": configJSON.APiContentType,
      token:localStorage.getItem('authToken'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateAwardsAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiUpdateAwardsAndFilmography
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //=====================================================GET ACCOUNT DATA=======================================
  getAccountData(): boolean {
    const header = {
      "Content-Type": configJSON.urlGetValidations,
      token: localStorage.getItem('authToken')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAccountDetailsAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiUpdateAccountDetails
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    
    return true;
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  // Customizable Area End
  
}
