  // Customizable Area Start
  import * as React from 'react';

import {
  Box,
  Button,
  TextField,
  Typography
} from "@material-ui/core";
import { awardImage, dragDrop } from "./assets"

const HelpCenter = () => {

  return (
        <Box sx={webStyles.bottomRight}>
            <Box sx={webStyles.formCard}>
                <Box sx={{bgcolor: "#320b4c", padding: "10px"}}>
                    <Typography variant="h4" style={{ color: "#FFF"}} >Help Center</Typography>
                </Box>
                <Box sx={{padding: "20px", display: "flex", justifyContent: "center"}}>
                    <Box sx={{ width: 500, maxWidth: '100%', color: "#eee" }} >
                        <TextField 
                            hiddenLabel
                            fullWidth
                            defaultValue="search our help articles here"
                            id="fullWidth" 
                            variant="filled" />
                    </Box>
                </Box>
            </Box>
            <Box sx={webStyles.formCard}>
                <Box sx={{bgcolor: "#320b4c", padding: "10px"}}>
                    <Typography variant="h4" style={{ color: "#FFF"}} >General</Typography>
                </Box>
                <Box sx={{padding: "20px"}}>
                    <Box sx={{marginBottom: "40px"}}>
                        <Typography variant="h4" style={{fontWeight: 700, marginBottom: "10px",}} >Payout Policy</Typography>
                        <Typography variant="body2" style={{fontWeight: 600, marginBottom: "3px"}} >
                            project with credits that match color accounts name xactly will appear here doller metha sit that match color accounts.
                        </Typography>
                        <Typography variant="body2" style={{fontWeight: 600, marginBottom: "3px"}} >project with credits that match color accounts.</Typography>
                        <Typography variant="body2" style={{fontWeight: 600, marginBottom: "3px"}} >project with credits that match color.</Typography>
                        <Typography variant="body2" style={{fontWeight: 600, marginBottom: "3px"}} >project with credits that match color accounts credits.</Typography>
                    </Box>
                    <Box sx={{marginBottom: "20px"}}>
                        <Typography variant="h4" style={{fontWeight: 700, marginBottom: "10px",}} >Payout Options</Typography>
                        <Typography variant="body2" style={{fontWeight: 600, marginBottom: "3px"}} >
                            project with credits that match color accounts name xactly will appear here doller metha sit that match color accounts.
                            that match color accounts name xactly will appear here doller metha sit that match color accounts.
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {/* <Box >
                <Button variant="contained" style={{ background: "#A5259F", color: "#fff", marginTop: "10px"}}>Save Changes</Button>
            </Box> */}
        </Box>
  );
};
export default HelpCenter;


const webStyles = {
    main: {
        display: "flex",
        justifyContent: "center",
        height: "100px",
        background: "000"
    },
    bottomRight: {
        display: "flex",
        flexDirection: "column",
        width: '75%'
    },
    formCard: {
        background: "#fff",
        marginBottom: "20px"
    },
    dragzon: {
        height: "200px",
        border: "2px solid #ccc",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "30px 50px"
    },
    dragzonCard: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    filmCard: {
        width: "50%",
        display: "flex",
        padding: "10px",
        marginBottom: "20px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        boxShadow: "2px 2px 6px 5px #eee",
        justifyContent: "space-between",
        gap: "15px"
    }
}
  // Customizable Area End