import * as React from 'react';

import {
  Box,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  TextField,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Grid,
  makeStyles, 
  withStyles,
  IconButton,
  CircularProgress
} from "@material-ui/core";
import { Delete, arrowUp } from "./assets";
import Footer from "../../../components/src/AppFooter.web"
import moment from "moment";
import { Country, State, City }  from 'country-state-city';
//@ts-ignore
import Data from '../../CustomForm2/src/countries.json';


const steps = [1, 2, 3, 4, 5];

interface AddProjectProps {
  handleEditProject:any;
  handleProjectType:any;
  handlenameChange: any;
  handledescriptionChange:any;
  handledewebsiteChange:any;
  handledetwitterChange:any;
  handledefacebookChange:any;
  handledeinstagramChange: any;
  handledeimdbChange:any;
  handledeemailChange:any;
  handledephoneChange:any;
  handledeDobChange:any;
  handledeGenderChange:any;
  handledeCountryofOriginChange:any;
  handledeCountryofResidenceChange:any;
  handledeAddressChange:any;
  handledeCityChange:any;
  handledeStateChange:any;
  handledePostalCodeChange:any;
  handledeRoleChange:any;
  handledeFirstNameChange:any;
  handledeMiddleNameChange:any;
  handledeLastNameChange:any;
  handledePriorCreditChange:any;
  handledeScreenigAwardChange:any;
  handledeGenreChange:any;
  handledeHrsChange:any;
  handledeCompletionDateChange:any;
  handledeBudgetChange:any;
  handledeCurrencyChange:any;
  handledeSpecificationCountryChange:any;
  handledeCountryofFilmingChange:any;
  handledeLanguageChange:any;
  handledeShootingFormateChange:any;
  handledeAspectRatioChange:any;
  handledeFilmColorChange:any;
  handledeStudentProjectChange:any;
  handledeFirsttimeFilmChange:any;
  handledeEventChange:any;
  handledePremierChange:any;
  handledeScreeningCityChange:any;
  handledeScreeningCountryChange:any;
  handledeScreeningDateChange:any;
  handledeAwardSelectionChange:any;
  handledeSalesAgentChange:any;
  getAllCountries:any;
  getStatesOfCountry:any;
  sx?:any;
  currencyData:any;
  countryData:any;
  handleCountryOfResidence:any;
  handleStateChange:any;
  handleCityChange:any;
}


type Props = {
  someProps: any
};

const useStyles = makeStyles({
  icon: {
    color: "#a5259f",
  },
  root: {
    "& .MuiInputBase-input": {
      height: "1.5em",
    }
  },
  inputClass: {
      "& .Mui-focused:after": {
        border: "1px solid green",
      },
    },
    card: {
      marginTop: '20px',
      zIndex: 1,
      borderRadius: '5px',
      width: '80%',
      marginLeft: '10%',
  },
});

const CustomColorCheckbox = withStyles({
  root: {
    color: "#a5259f",
    "&$checked": {
      color: "#a5259f"
    } 
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

const EditProject = (props:any,{
  handledeRoleChange, 
  handledeGenreChange,
}: any) => {
  const [data, setData] = React.useState(props.demodata)
  const [budgetData, setBudgetData] = React.useState(props.budgetData)
  const [currencyData, setCurrencyData] = React.useState(props.currencyData)
  const [roleData, setRoleData] = React.useState(props.roleData)
  const [languageData, setLanguageData] = React.useState(props.languageData)
  const [activeStep, setActiveStep] = React.useState(0)
  const [radiovalue, setRadioValue] = React.useState("1")
  const [btnDisabled, setBtnDisabled] = React.useState(false) 
  const [allStates, setAllStates] = React.useState(State.getStatesOfCountry(data?.attributes?.submitter_info?.country_of_origin)) 
  const [allCities, setAllCities] = React.useState(City.getCitiesOfState(data?.attributes?.submitter_info?.country_of_origin, data?.attributes?.submitter_info?.state)) 
  const [countryOrigin, setCountryOrigin] = React.useState("")
  const [id, setId] = React.useState(window.location.pathname.trim().split("/")[2])
  const [initialValues, setIntialValue] = React.useState<any>({})
  const [loading, setIsLoading] = React.useState(true)
  const [error, setError] = React.useState("");
  const [openscreen, setOpenScreen] = React.useState(false);
  const [openscreenid, setOpenscreenId] = React.useState("");
                                             
    const [screening_and_awards_attributes, setScreening_and_awards_attributes] = React.useState(props.demodata?.attributes?.screening_and_awards); 
    const [distributor, setDistributor] = React.useState(props.demodata?.attributes?.distributors.data);
    const [credits_attributes, setCredits_attributes] = React.useState(props.demodata?.attributes?.credits);

    const [formVal, dispatch] = React.useReducer(
      (curVal:any, newVal:any) => ({ ...curVal, ...newVal }),
      initialValues,
    )

  const {project_type, name, description, website_link,facebook_link, twitter_link, instagram_link,imdb_link, email,phoneno, date_of_birth,gender,country_of_origin,country_of_residence,
          address,city,state,postal_code,role_id,genres,currency, screeningandaward,  runtime,hours,min,sec,completiondate,productionbudget,countryorigin,countryfilming,language,shooting_format,
          aspectration,filmcolour,studentproject,firstfilm} = formVal;

  const handleDisable = () => {
    setBtnDisabled(false)
    if(initialValues.name == ""){
      setBtnDisabled(true)
    }
  }

  const handleChange = (event:any) => {
    const { name, value } = event.target
    if(name == "project_type"){
      setRadioValue(value)
    }
    setCountryOrigin(value)
    setAllStates(State.getStatesOfCountry(value))
    dispatch({ [name]: value })
  }

  const handleCountryOfResidence = (event:any) => {
    const { name, value } = event.target
    initialValues.country_of_origin = value
    setCountryOrigin(value)
    setAllStates(State.getStatesOfCountry(value))
    dispatch({ [name]: value })
  }
  
  const handleStateChange = (event:any) => {
    const { name, value } = event.target
    initialValues.state = value
    setAllCities(City.getCitiesOfState(countryOrigin, value))
    dispatch({ [name]: value })
  }
  
  const handleCityChange = (event:any) => {
    const { name, value } = event.target
    initialValues.city = value
    dispatch({ [name]: value })
  }

  const handleSubmit = (e:any) => {
    e.preventDefault()
    initialValues.genres = initialValues.genres == "" ? "" : initialValues.genres.split(",")
    initialValues.shooting_format = initialValues.shooting_format == "" ? "" : initialValues.shooting_format.split(",")
    initialValues.runtime = initialValues.hours+":"+initialValues.min+":"+initialValues.sec
    let values = initialValues
    // values.screening_and_awards_attributes = screening_and_awards_attributes
    // values.distributor = distributor
    // values.credits_attributes = credits_attributes
    values.state = state
    values.city = city
    props.handleEditProject(values, id)
  } 

  // React.useEffect(() => {
  //   setDistributor(props.demodata?.attributes?.distributors.data)
  // }, [distributor])

  React.useEffect(() => {
    console.log("distributor-->", distributor, props.demodata?.attributes?.distributors.data);
    
    setIntialValue({
      project_type: props.demodata?.attributes?.project_type == "film/video" ? "0" : props.demodata?.attributes?.project_type == "script" ? "1" : props.demodata?.attributes?.project_type =="documentary" ? "2" : props.demodata?.attributes?.project_type == "music/song_writing" ? "3" : props.demodata?.attributes?.project_type == "photography/design" ? "4" : props.demodata?.attributes?.project_type == "vr/xr/immersive_beta" ? "5" : "",
      name: props.demodata?.attributes?.name,
      description: props.demodata?.attributes?.description,
      website_link: props.demodata?.attributes?.website_link,
      facebook_link: props.demodata?.attributes?.facebook_link,
      twitter_link: props.demodata?.attributes?.twitter_link,
      instagram_link: props.demodata?.attributes?.instagram_link,
      imdb_link: props.demodata?.attributes?.imdb_link,
      email: props.demodata?.attributes?.submitter_info?.email,
      phoneno:props.demodata?.attributes?.submitter_info?.phone_number,
      date_of_birth: props.demodata?.attributes?.submitter_info?.dob,
      gender: props.demodata?.attributes?.submitter_info?.gender,
      country_of_origin: props.demodata?.attributes?.submitter_info?.country_of_origin,
      country_of_residence: props.demodata?.attributes?.submitter_info?.country_of_residence,
      address: props.demodata?.attributes?.submitter_info?.address,
      city: props.demodata?.attributes?.submitter_info?.city,
      state: props.demodata?.attributes?.submitter_info?.state,
      postal_code: props.demodata?.attributes?.submitter_info?.postal_code,
      role_id: props.demodata?.attributes?.credits[0]?.role_id,  
      genres:props.demodata?.attributes?.specification?.data?.attributes?.genres.toString(), 
      screeningandaward:[], 
      runtime:moment(props.demodata?.attributes?.specification?.data?.attributes?.runtime).format('hh:mm:ss'),
      hours:props.demodata?.attributes?.specification?.data?.attributes?.runtime == null ? "" : moment(props.demodata?.attributes?.specification?.data?.attributes?.runtime).utc().format('HH'),
      min:props.demodata?.attributes?.specification?.data?.attributes?.runtime == null ? "" : moment(props.demodata?.attributes?.specification?.data?.attributes?.runtime).utc().format('mm'),
      sec:props.demodata?.attributes?.specification?.data?.attributes?.runtime == null ? "" : moment(props.demodata?.attributes?.specification?.data?.attributes?.runtime).utc().format('ss'),
      completiondate: moment(props.demodata?.attributes?.specification?.data?.attributes?.completion_date).format('YYYY-MM-DD'),
      productionbudget:props.demodata?.attributes?.specification?.data?.attributes?.production_budget,
      currency:props.demodata?.attributes?.specification?.data?.attributes?.currency == null ? "" : props.demodata?.attributes?.specification?.data?.attributes?.currency,
      countryorigin: props.demodata?.attributes?.specification?.data?.attributes?.country_of_origin,
      countryfilming: props.demodata?.attributes?.specification?.data?.attributes?.country_of_residence,
      language: props.demodata?.attributes?.specification?.data?.attributes?.language == null ? "" : props.demodata?.attributes?.specification?.data?.attributes?.language?.id,
      shooting_format: props.demodata?.attributes?.specification?.data?.attributes?.shooting_format?.length > 0 ? props.demodata?.attributes?.specification?.data?.attributes?.shooting_format.toString() : '',
      aspectration: props.demodata?.attributes?.specification?.data?.attributes?.aspect_ratio,
      filmcolour: props.demodata?.attributes?.specification?.data?.attributes?.film_color,
      studentproject:props.demodata?.attributes?.specification?.data?.attributes?.student_project,
      firstfilm: props.demodata?.attributes?.specification?.data?.attributes?.first_time_filmmaker,
      screening_and_awards_attributes: data?.attributes?.screening_and_awards,
      distributor: data?.attributes?.distributors.data,
      credits_attributes:data?.attributes?.credits,
    })
    setDistributor(props.demodata?.attributes?.distributors.data)
    setScreening_and_awards_attributes(props.demodata?.attributes?.screening_and_awards)
    setCredits_attributes(props.demodata?.attributes?.credits)
    setIsLoading(false)
}, [props.demodata])

function isValidEmail(email: any) {
  return /\S+@\S+\.\S+/.test(email);
}

const handleemailChange = (event: any) => {
  if (!isValidEmail(event.target.value)) {
    setError("Email is invalid");
  } else {
    setError("");
  }
};

  const handleChangeInput = (id:any, event:any) => {
    //  for screening_and_awards_attributes
    const newInputFields = screening_and_awards_attributes.map((i:any) => {
      if(id === i.id) {
        i[event.target.name as any] = event.target.value
      }
      return i;
    })

    // for distributor
    const newDistributor = distributor.map((i:any) => {
      if(id === i.id) {
        i.attributes[event.target.name] = event.target.value
      }
      return i;
    })

    // for credits_attributes
    const newCredit = credits_attributes.map((i:any) => {
      if(id === i.id) {
        i[event.target.name] = event.target.value
      }
      return i;
    })

    setScreening_and_awards_attributes(newInputFields);
    setDistributor(newDistributor);
    setCredits_attributes(newCredit);
  }

   // =============================================== ADD FIELD ======================================================

  // add distributor
  const handleDistributorAddFields = () => {
    setDistributor([...distributor, {id: Math.random(),name: ""}])
  }

  // add credits_attributes
  const handleCreditAddFields = () => {
    setCredits_attributes([...credits_attributes, {id: Math.random(), first_name:"", last_name:"", middle_name:"", description:""}])
  }
  
  //add screening_and_awards_attributes
  const handleScreeningAddFields = () => {
    setScreening_and_awards_attributes([...screening_and_awards_attributes, {id: Math.random(), event:"", city:"",country:"", screeing_date:"", premiere:"", award_selection:"" }])
  }

  // =============================================== REMOVE FIELD ======================================================

  // remove distributor
  const handleDistributorRemoveFields = (id:any) => {
    const values  = [...distributor];
    values.splice(values.findIndex(value => value.id === id), 1);
    setDistributor(values);
  }

  // remove credits_attributes
  const handleCreditRemoveFields = (id:any) => {
    const values  = [...credits_attributes];
    values.splice(values.findIndex(value => value.id === id), 1);
    setCredits_attributes(values);
  }

  // remove screening_and_awards_attributes
  const handleScreeningRemoveFields = (id:any) => {
    const values  = [...screening_and_awards_attributes];
    values.splice(values.findIndex(value => value.id === id), 1);
    setScreening_and_awards_attributes(values);
  }
  


  const handleNext = (values:any) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  const handleProjectType = (e:any) => {
    setRadioValue(e.target.value)
  }
  
  const handleForm = (e:any) => {
    e.preventDefault()
    return false
  }

  const handleScreenopen = (id: any) => {
    setOpenscreenId(id)    
    screening_and_awards_attributes.map((element: any) => {
        if(element.id == id){
          setOpenScreen(!openscreen)
        }
    })
  }

  const countries =  Country.getAllCountries()
  const classes = useStyles();
  const styled ={color: 'red'};
  return (
    <Box sx={{margin:"-24px"}}>
     {loading ? 
             <Grid container className={classes.card} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                <CircularProgress />
             </Grid> 
                :
                <Box sx={{ width: '100%' }}>
          <form onSubmit={handleForm} autoComplete="off">
              <Box sx={styles.StepperCard}>
              <Stepper activeStep={activeStep} className='editProjectStepper' style={{ width: '75%', backgroundColor:"#F2F3F5"}}>
                {steps.map((label, index) => {
                  return (
                    <Step key={label} >
                      <StepLabel icon={`${"0" + (index + 1) }`}/>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
            <Box sx={{ padding: '50px 200px'}} bgcolor="#f2f3f5">
              
              <Typography variant="h2" component="h2">Edit Project</Typography>

              {activeStep === 0 &&
                <Box sx={{padding: '30px 0px'}}>
                  <Box sx={styles.formTitle}>
                    <Typography variant="h4" component="h4">Project Information</Typography>
                  </Box>
                  <Box bgcolor="#FFF">
                    <Box
                      component="form"
                      sx={{ padding: '30px'}}
                    >
                      <div>
                        <FormControl component="div" style={{ padding: '20px 0px'}}>
                          <FormLabel component="legend">Project Type</FormLabel>
                          <RadioGroup row aria-label="gender" name="project_type" value={initialValues.project_type} onChange={(e) => setIntialValue({...initialValues,project_type:e.target.value})}>
                            <FormControlLabel 
                              value="0" 
                              control={<Radio size="small" color="primary"/>} 
                              label="Film/Video" 
                            />
                            <FormControlLabel 
                              value="1" 
                              control={<Radio size="small" color="primary"/>}  
                              label="Script" 
                            />
                            <FormControlLabel 
                              value="2" 
                              control={<Radio size="small" color="primary"/>}  
                              label="Documentary" 
                            />
                            <FormControlLabel 
                              value="3" 
                              control={<Radio size="small" color="primary"/>}  
                              label="Music / Songwriting" 
                            />
                            <FormControlLabel 
                              value="4" 
                              control={<Radio size="small" color="primary"/>}  
                              label="Photography / Design" 
                            />
                            <FormControlLabel 
                              value="5" 
                              control={<Radio size="small" color="primary"/>}  
                              label="VR / XR / Immersive BETA" 
                            />
                          </RadioGroup>
                        </FormControl>
              
                        <Grid container spacing={6}>
                              <Grid item xs={6} style={{paddingBottom:"0px"}}>
                                <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                    <FormLabel component="legend">Project Title ( English ) <span style={styled}>*</span></FormLabel>
                                      <TextField
                                        id="outlined-required"
                                        placeholder="Enter your name"
                                        onChange={(e) => setIntialValue({...initialValues,name:e.target.value})}
                                        onKeyUp={handleDisable}
                                        required
                                        InputProps={{ className: classes.root }}
                                        name="name"
                                        value={initialValues.name} 
                                        // inputRef={valueRef}
                                        className={classes.inputClass}
                                      //   onInput={(e:any) => setBtnDisabled(!e.target.value)}
                                      />
                                  </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                  {radiovalue === "0" || radiovalue === "1" || radiovalue === "2" || radiovalue === "5" ?
                                  <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                      <FormLabel component="legend">Brief Synopsis (English)</FormLabel>
                                        <TextField
                                            id="standard-select-currency-native"
                                            placeholder="Enter your sypnosis"
                                            onChange={(e) => setIntialValue({...initialValues,description:e.target.value})}

                                            InputProps={{ className: classes.root }}
                                            name="description"
                                            value={initialValues.description}  
                                            className={classes.inputClass}
                                          />
                                  </FormControl> 
                                  : radiovalue === "3" ?
                                  <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                  <FormLabel component="legend">Lyrics</FormLabel>
                                    <TextField
                                        id="standard-select-currency-native"
                                        placeholder="Enter your Lyrics"
                                        onChange={(e) => setIntialValue({...initialValues,description:e.target.value})}

                                        InputProps={{ className: classes.root }}
                                        name="description"
                                        value={initialValues.description}  
                                        className={classes.inputClass}
                                      />
                                  </FormControl> 
                                :  radiovalue === "4" ?
                                <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                  <FormLabel component="legend">Brief Description</FormLabel>
                                    <TextField
                                        id="standard-select-currency-native"
                                        placeholder="Enter your description"
                                        onChange={(e) => setIntialValue({...initialValues,description:e.target.value})}
                                        InputProps={{ className: classes.root }}
                                        name="description"
                                        value={initialValues.description}  
                                        className={classes.inputClass}
                                      />
                                </FormControl> 
                              : ""}
                                </Grid>
                                <FormControlLabel style={{padding:'0px 0px 0px 20px', marginTop:"-30px"}} control={<CustomColorCheckbox />} label="My Project also has a non-English Title and Synopsis" />
                          </Grid>


                        <Grid container spacing={6}>
                              <Grid item xs={6}>
                                <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                    <FormLabel component="legend">Website</FormLabel>
                                      <TextField
                                        id="outlined-disabled"
                                        placeholder="www.xyz.com"
                                        onChange={(e) => setIntialValue({...initialValues,website_link:e.target.value})}

                                        InputProps={{ className: classes.root }}
                                        name="website_link"
                                        value={initialValues.website_link}  
                                        className={classes.inputClass}
                                      />
                                  </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                  <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                      <FormLabel component="legend">Twitter</FormLabel>
                                        <TextField
                                            id="standard-select-currency-native"
                                            placeholder="twitter.com/xyz"
                                            onChange={(e) => setIntialValue({...initialValues,twitter_link:e.target.value})}

                                            InputProps={{ className: classes.root }}
                                            name="twitter_link"
                                            value={initialValues.twitter_link}  
                                            className={classes.inputClass}
                                          />
                                  </FormControl>
                                </Grid>
                          </Grid>

                          <Grid container spacing={6}>
                              <Grid item xs={6}>
                                <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                    <FormLabel component="legend">Facebook</FormLabel>
                                      <TextField
                                        id="outlined-disabled"
                                        placeholder="facebook.com/xyz"
                                        onChange={(e) => setIntialValue({...initialValues,facebook_link:e.target.value})}

                                        InputProps={{ className: classes.root }}
                                        name="facebook_link"
                                        value={initialValues.facebook_link}  
                                        className={classes.inputClass}
                                      />
                                  </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                  <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                      <FormLabel component="legend">Instagram</FormLabel>
                                        <TextField
                                            id="standard-select-currency-native"
                                            placeholder="instagram.com/xyz"
                                            onChange={(e) => setIntialValue({...initialValues,instagram_link:e.target.value})}

                                            InputProps={{ className: classes.root }}
                                            name="instagram_link"
                                            value={initialValues.instagram_link}  
                                            className={classes.inputClass}
                                          />
                                  </FormControl>
                                </Grid>
                          </Grid>

                          <Box sx={{ flexGrow: 1 }} >
                                <Grid container spacing={6}>
                                  <Grid item xs={6}>
                                    <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                        <FormLabel component="legend">IMDB</FormLabel>
                                          <TextField
                                            id="standard-select-role"
                                            placeholder="http://www.imdb.com/"  
                                            onChange={(e) => setIntialValue({...initialValues,imdb_link:e.target.value})}

                                            InputProps={{ className: classes.root }}                      
                                            name="imdb_link"
                                            value={initialValues.imdb_link}  
                                            className={classes.inputClass}
                                          />
                                      </FormControl>
                                  </Grid>
                                </Grid>
                            </Box>
                      </div>
                    </Box>
                  </Box> 
                </Box>
              }

          {activeStep === 1 &&
                <Box sx={{padding: '30px 0px'}}>
                  <Box sx={styles.formTitle}>
                    <Typography variant="h4" component="h4">Submitter Information</Typography>
                  </Box>
                  <Box bgcolor="#FFF">
                    <Box
                      component="form"
                      sx={{ padding: '30px'}}
                    >
                      <div>
                        <FormControl component="div" style={{ padding: '20px 0px'}}>
                          <FormLabel component="legend">Contact Information</FormLabel>
                        </FormControl>

                        <Grid container spacing={6}>
                              <Grid item xs={6}>
                                <FormControl  style={{ padding: '20px 20px 0px 0px', width: '100%'}}>
                                    <FormLabel component="legend">Email</FormLabel>
                                      <TextField
                                        id="outlined-disabled"
                                        placeholder="Enter your Email ID"
                                        onChange={(e) => setIntialValue({...initialValues,email:e.target.value})}
                                        onKeyUp={handleemailChange}
                                        InputProps={{ className: classes.root }}
                                        name="email"
                                        value={initialValues.email}  
                                        className={classes.inputClass}
                                      />
                                      {error && <h5 style={{color: 'red'}}>{error}</h5>}
                                  </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                  <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                      <FormLabel component="legend">Phone No.</FormLabel>
                                        <TextField
                                            type="number"
                                            id="standard-select-currency-native"
                                            placeholder="eg. +91-999-768-867"
                                            onChange={(e) => setIntialValue({...initialValues,phoneno:e.target.value})}
                                            InputProps={{ className: classes.root }}
                                            name="phoneno"
                                            value={initialValues.phoneno} 
                                            className={classes.inputClass} 
                                          />
                                  </FormControl>
                                </Grid>
                          </Grid>

                          <Grid container spacing={6}> 
                            <Grid item xs={6}>
                            <Grid container spacing={2}>  
                              <Grid item xs={6}>
                                <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                  <FormLabel component="legend">DOB</FormLabel>
                                    <TextField
                                      id="outlined-disabled"
                                      type="date"
                                      placeholder="DD / MM/ YYYY"
                                      onChange={(e) => setIntialValue({...initialValues,date_of_birth:e.target.value})}
                                      InputProps={{inputProps: { max: moment().format("YYYY-MM-DD")},className: classes.root}}
                                      name="date_of_birth"
                                      value={initialValues.date_of_birth}  
                                      inputProps={{max: "2022-03-20"}}
                                      className={classes.inputClass}
                                    />
                                </FormControl>
                                
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl  style={{ padding: '20px ', width: '100%'}}>
                                  <FormLabel component="legend">Gender</FormLabel>
                                    <TextField
                                      id="standard-select-currency-native"
                                      select
                                      onChange={(e) => setIntialValue({...initialValues,gender:e.target.value})}
                                      className={classes.inputClass}
                                      InputProps={{ className: classes.root }}
                                      SelectProps={{
                                        classes: { icon: classes.icon },
                                        native:true
                                      }}
                                      variant="standard"
                                      name="gender"
                                      value={initialValues.gender}
                                    >
                                        <option value={""}>select from option</option>
                                        <option value={'male'}>Male</option>
                                        <option value={'female'}>Female</option>
                                        <option value={'other'}>Others</option>
                                    </TextField>
                                  </FormControl>
                              </Grid>
                              </Grid>
                              </Grid>
                            
                              <Grid item xs={6}>
                              <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                    <FormLabel component="legend">Country of Origin</FormLabel>
                                      <TextField
                                          id="standard-select-currency-native"
                                          select
                                          onChange={handleCountryOfResidence}
                                          SelectProps={{
                                            classes: { icon: classes.icon },
                                            native:true
                                          }}
                                          InputProps={{ className: classes.root }}
                                          variant="standard"
                                          name="country_of_origin"
                                          value={initialValues.country_of_origin}
                                          className={classes.inputClass}
                                        >
                                        <option value={''}>Select from option</option>
                                          {countries && countries.map((cur: any) =>
                                              <option value={cur.isoCode}>{cur.name}</option>
                                          )}
                                        </TextField>
                                  </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                    <FormLabel component="legend">Country of Residence</FormLabel>
                                        <TextField
                                          id="standard-select-currency-native"
                                          select
                                          onChange={(e) => setIntialValue({...initialValues,country_of_residence:e.target.value})}
                                          SelectProps={{
                                            classes: { icon: classes.icon },
                                            native:true
                                          }}
                                          InputProps={{ className: classes.root }}
                                          variant="standard"
                                          name="country_of_residence"
                                          value={initialValues.country_of_residence}
                                          className={classes.inputClass}
                                        >
                                        <option value={''}>Select from option</option>
                                          {countries && countries.map((cur: any) =>
                                              <option value={cur.isoCode}>{cur.name}</option>
                                          )}
                                        </TextField>
                                      </FormControl>
                              </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container spacing={6}>
                          <Grid item xs={6}>
                              <FormControl  style={{ padding: '20px 20px 0px 0px', width: '100%'}}>
                                  <FormLabel component="legend">Address</FormLabel>
                                      <TextField
                                        id="outlined-disabled"
                                        placeholder="Enter your Full Address"
                                        onChange={(e) => setIntialValue({...initialValues,address:e.target.value})}
                                        InputProps={{ className: classes.root }}
                                        name="address"
                                        value={initialValues.address}  
                                        className={classes.inputClass}
                                      />
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                              <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                <FormLabel component="legend">State / Province</FormLabel>
                                      <TextField
                                          id="standard-select-currency-native"
                                          select
                                          onChange={handleStateChange}
                                          SelectProps={{
                                            classes: { icon: classes.icon },
                                            native:true
                                          }}
                                          InputProps={{ className: classes.root }}
                                          variant="standard"
                                          name="state"
                                          // onBlur={handleBlur}
                                          value={initialValues.state}
                                          className={classes.inputClass}
                                        >
                                          <option value={''}>Select from option</option>
                                          {allStates && allStates.map((state: any) =>
                                              <option value={state.isoCode}>{state.name}</option>
                                          )}
                                        </TextField>
                                    
                                    </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                              <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                  <FormLabel component="legend">City</FormLabel>
                                        <TextField
                                            id="standard-select-currency-native"
                                            select
                                            onChange={handleCityChange}
                                            SelectProps={{
                                              classes: { icon: classes.icon },
                                              native:true
                                            }}
                                            InputProps={{ className: classes.root }}
                                            variant="standard"
                                            name="city"
                                            value={initialValues.city}
                                            className={classes.inputClass}
                                          >
                                            <option value={''}>Select from option</option>
                                            {allCities && allCities.map((city: any) =>
                                                <option value={city.name}>{city.name}</option>
                                            )}
                                        </TextField>
                                  </FormControl>
                              </Grid>
                              </Grid>
                              </Grid>
                              </Grid>

                          <Box sx={{ flexGrow: 1 }} >
                                <Grid container spacing={6}>
                                  <Grid item xs={6}>
                                    <FormControl  style={{ padding: '20px 20px 0px 0px', width: '100%'}}>
                                        <FormLabel component="legend">Postal Code</FormLabel>
                                          <TextField
                                            type="number"
                                            id="standard-select-role"
                                            placeholder="Enter pin code"
                                            onChange={(e) => setIntialValue({...initialValues,postal_code:e.target.value})}
                                            InputProps={{ className: classes.root }}                        
                                            name="postal_code"
                                            value={initialValues.postal_code} 
                                            className={classes.inputClass} 
                                            onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                          />
                                      </FormControl>
                                  </Grid>
                                </Grid>
                          </Box>
                      </div>
                    </Box>
                  </Box> 
                </Box>
              }

              {activeStep == 2 &&
                <Box sx={{padding: '30px 0px'}}>
                  <Box sx={styles.formTitle}>
                    <Typography variant="h4" component="h4">Credits</Typography>
                  </Box>
                  <Box bgcolor="#FFF">
                    <Box
                      component="form"
                      sx={{ padding: '30px'}}
                    >
                      <div>
                      <FormControl component="div" style={{ padding: '20px 0px', width: '100%'}}>
                            <FormLabel component="legend">Select Role</FormLabel>
                            <TextField
                              id="standard-select-role"
                              placeholder="Select from OPtion"
                              select
                              name="role_id"
                              value={initialValues.role_id}
                              onChange={(e) => setIntialValue({...initialValues,role_id:e.target.value})}
                              className={classes.inputClass}
                              SelectProps={{
                                classes: { icon: classes.icon },
                                native:true
                              }}
                              InputProps={{ className: classes.root }}
                              // helperText="Select from OPtion"
                              variant="standard"
                            >
                              <option value="">Select from Option</option>
                                      {roleData && roleData.map((role: any) =>
                                          <option value={role.id}>{role.attributes.name}</option>
                                      )}
                            </TextField>
                            <div>
                            <Box border={0.2} borderColor="#f5f5f5" borderRadius={4} style={{marginTop:"20px", marginBottom:"20px"}}>
                            <Box sx={{ flexGrow: 1 }} >

                            {credits_attributes?.map((creditsData:any) => (
                              <div key={creditsData.id}>
                              <Grid container spacing={6}>
                                  <Grid item xs={6}>
                                  
                                  <FormControl  style={{ padding: '20px', width: '100%'}}>
                                      <FormLabel component="legend">First Name</FormLabel>
                                          <TextField
                                          id="outlined-disabled"
                                          placeholder="Enter your first name"
                                          name="first_name"
                                          value={creditsData.first_name}
                                          onChange={event => handleChangeInput(creditsData.id, event)}
                                          InputProps={{ className: classes.root }}
                                          className={classes.inputClass}
                                          />
                                      </FormControl>
                                  </Grid>
                              
                                  <Grid item xs={6}>
                                      <Grid container spacing={2}>
                                          <Grid item xs={6}>
                                          <FormControl  style={{ padding: '20px', width: '100%'}}>
                                              <FormLabel component="legend">Middle Name</FormLabel>
                                              <TextField
                                                  id="standard-select-role"
                                                  placeholder="Enter your middle name" 
                                                  name="middle_name"  
                                                  value={creditsData.middle_name}
                                                  onChange={event => handleChangeInput(creditsData.id, event)}
                                                  InputProps={{ className: classes.root }}
                                                  className={classes.inputClass}
                                              />
                                              
                                          </FormControl>
                                          </Grid>
                                          <Grid item xs={6}>
                                          <FormControl  style={{ padding: '20px', width: '100%'}}>
                                              <FormLabel component="legend">Last Name</FormLabel>
                                              <TextField
                                                  id="standard-select-role"
                                                  placeholder="Enter your last name" 
                                                  name="last_name"
                                                  value={creditsData.last_name}
                                                  onChange={event => handleChangeInput(creditsData.id, event)}
                                                  InputProps={{ className: classes.root }}
                                                  className={classes.inputClass}
                                              />
                                              
                                          </FormControl>
                                      </Grid>
                                      </Grid>
                                  </Grid>
                              </Grid>
                            
                              <Box sx={{ flexGrow: 1 }} >
                                <Grid container spacing={6}>
                                <Grid item xs={6}>
                                  <FormControl  style={{ padding: '20px', width: '100%'}}>
                                      <FormLabel component="legend">Prior Credits (Optional)</FormLabel>
                                        <TextField
                                          id="standard-select-role"
                                          placeholder="eg. Jaws, Raiders of the lost Ark"  
                                          name="description"
                                          value={creditsData.description}
                                          onChange={event => handleChangeInput(creditsData.id, event)}
                                          InputProps={{ className: classes.root }}    
                                          className={classes.inputClass}             
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} style={{marginTop:"30px"}}>                                
                                  <IconButton style={{ padding: '0px' }}  onClick={() => handleCreditRemoveFields(creditsData.id)}>
                                    <img style={{width:"50px", marginLeft:"25px"}} src={Delete} alt="logo" />
                                  </IconButton>     
                                </Grid>
                                </Grid>
                              </Box>
                                <hr style={{margin:"0 20px"}}></hr>
                              </div>
                            )) }
                            
                            <Grid container spacing={2} style={{marginTop:"20px", padding:"20px"}}>
                              <Grid item xs={3} md={3}>
                              <Button
                                  color="inherit"
                                  onClick={handleCreditAddFields}
                                >
                                  ADD A PERSON
                                </Button>
                              </Grid>
                              {/* <Grid item xs={3} md={3}>
                                  <Button
                                    color="inherit"
                                    onClick={() => handleCreditRemoveFields(credits_attributes[0].id)}
                                  >
                                    REMOVE A PERSON
                                  </Button>
                              </Grid> */}
                            </Grid> 
                              </Box>
                              
                              </Box>
                              </div>
                                
                        </FormControl>
                      </div>
                    </Box>
                  </Box>
                </Box>
              }

              {activeStep == 3 &&
                <Box sx={{padding: '30px 0px'}}>
                  <Box sx={styles.formTitle}>
                    <Typography variant="h4" component="h4">Specifications</Typography>
                  </Box>
                  <Box bgcolor="#FFF">
                    <Box
                      component="form"
                      sx={{ padding: '30px'}}
                    >
                    <FormControl component="div" style={{ padding: '20px 0px', width: '100%'}}>
                        <FormLabel component="legend">Screenings & Awards</FormLabel>
                        <FormControl style={{display: "flex",  flexDirection: 'row', width: '90%', justifyContent:"space-between"}}>
                            <FormControlLabel control={<CustomColorCheckbox/>}label="Animation"/>
                              <FormControlLabel control={<CustomColorCheckbox />} label="Documentary" />
                              <FormControlLabel control={<CustomColorCheckbox />} label="Experimental" />
                              <FormControlLabel control={<CustomColorCheckbox />} label="Feature" />                         
                              <FormControlLabel control={<CustomColorCheckbox />} label="Music Video" />               
                              <FormControlLabel control={<CustomColorCheckbox />} label="Short" />
                        </FormControl>
                        <FormControl style={{display: "flex",  flexDirection: 'row', width: '60%', justifyContent:"space-between"}}>
                              <FormControlLabel control={<CustomColorCheckbox />} label="Student" />                  
                              <FormControlLabel control={<CustomColorCheckbox />} label="Web / New Media" />                
                              <FormControlLabel control={<CustomColorCheckbox />} label="Televison" />
                              <FormControlLabel control={<CustomColorCheckbox />} label="Other" />
                        </FormControl>   
                    </FormControl>
                    
                      <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={6}>
                              <Grid item xs={6}>
                              <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                  <FormLabel component="legend">Genres</FormLabel>
                                    <TextField
                                      name="genres"
                                      value={initialValues.genres}
                                      id="outlined-disabled"
                                      placeholder="Sci-fi, Romance, Drama, etc."
                                      onChange={(e) => setIntialValue({...initialValues,genres:e.target.value})} 
                                      InputProps={{ className: classes.root }}
                                      className={classes.inputClass}
                                    />
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                              <Grid container spacing={2}>
                              <Grid item xs={4}>
                              <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                  <FormLabel component="legend">Runtime</FormLabel>
                                    <TextField
                                      id="outlined-disabled"
                                      placeholder="Hrs."
                                      onChange={(e) => setIntialValue({...initialValues,hours:e.target.value})}
                                      InputProps={{ className: classes.root }}
                                      name="hours"
                                      value={initialValues.hours}  
                                      className={classes.inputClass}
                                    />
                                </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                              <FormControl  style={{ padding: '20px 0px', width: '100%', margin:"19px 0px 0px"}}>
                                  <FormLabel component="legend"> </FormLabel>
                                    <TextField
                                      id="outlined-disabled"
                                      placeholder="Min."
                                      onChange={(e) => setIntialValue({...initialValues,min:e.target.value})}
                                      name="min"
                                      value={initialValues.min}  
                                      InputProps={{ className: classes.root }}
                                      className={classes.inputClass}
                                    />
                                </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                              <FormControl  style={{ padding: '20px 0px', width: '100%', margin:"19px 0px 0px"}}>
                                  <FormLabel component="legend"> </FormLabel>
                                    <TextField
                                      id="outlined-disabled"
                                      placeholder="Sec."
                                      onChange={(e) => setIntialValue({...initialValues,sec:e.target.value})}
                                      name="sec"
                                      value={initialValues.sec}  
                                      InputProps={{ className: classes.root }}
                                      className={classes.inputClass}
                                    />
                                </FormControl>
                                </Grid>
                              </Grid>
                              </Grid>
                              <Grid item xs={6}>
                              <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                  <FormLabel component="legend">Completion Date</FormLabel>
                                    <TextField
                                      id="outlined-disabled"
                                      type="date"
                                      placeholder="DD / MM / YYYY"
                                      onChange={(e) => setIntialValue({...initialValues,completiondate:e.target.value})} 
                                      InputProps={{ className: classes.root }}
                                      name="completiondate"
                                      value={initialValues.completiondate}
                                      className={classes.inputClass}
                                    />
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                              <Grid container spacing={2}>
                              <Grid item xs={6}>
                              <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                  <FormLabel component="legend">Production Budget</FormLabel>
                                    <TextField
                                     id="outlined-disabled"
                                     type="number"
                                     placeholder="Enter Production Budget"
                                     onChange={(e) => setIntialValue({...initialValues,productionbudget:e.target.value})}
                                     InputProps={{ className: classes.root }}
                                     name="productionbudget"
                                     value={initialValues.productionbudget}
                                     className={classes.inputClass}

                                      // id="standard-select-role"
                                      // placeholder="Select from OPtion"
                                      // select
                                      // onChange={(e) => setIntialValue({...initialValues,productionbudget:e.target.value})}
                                      // InputProps={{ className: classes.root }}
                                      // SelectProps={{
                                      //   classes: { icon: classes.icon },
                                      //   native:true
                                      // }}
                                      // variant="standard"
                                      // name="productionbudget"
                                      // value={initialValues.productionbudget}
                                      // className={classes.inputClass}
                                    >
                                        {/* <option value="">Select from Option</option>
                                      {budgetData && budgetData.map((role: any) =>
                                          <option value={role.id}>{role.attributes.amount}</option>
                                      )} */}
                                    </TextField>

                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                              <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                  <FormLabel component="legend">Currency</FormLabel>
                                    <TextField
                                      id="standard-select-currency-native"
                                      select
                                      onChange={(e) => setIntialValue({...initialValues,currency:e.target.value})}
                                      SelectProps={{
                                        classes: { icon: classes.icon },
                                        native:true
                                      }}
                                      InputProps={{ className: classes.root }}
                                      variant="standard"
                                      name="currency"
                                      // onBlur={handleBlur}
                                      value={initialValues.currency}
                                      className={classes.inputClass}
                                    >
                                    <option value={''}>Select Currency</option>
                                    {Data.countries.country.map((curr: any) => 
                                      <option value={curr.currencyCode}>{curr.countryName} - {curr.currencyCode} </option>
                                    )}
                              </TextField>
                                </FormControl>
                              </Grid>
                              </Grid>
                              </Grid>
                            </Grid>

                            
                            <Grid container spacing={6}> 
                            <Grid item xs={6}>
                            <Grid container spacing={2}>  
                            <Grid item xs={6}>
                              <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                  <FormLabel component="legend">Country of Origin</FormLabel>
                                  <TextField
                                      id="standard-select-currency-native"
                                      select
                                      onChange={(e) => setIntialValue({...initialValues,countryorigin:e.target.value})}

                                      SelectProps={{
                                        classes: { icon: classes.icon },
                                        native:true
                                      }}
                                      InputProps={{ className: classes.root }}
                                      variant="standard"
                                      name="countryorigin"
                                      value={initialValues.countryorigin}
                                      className={classes.inputClass}
                                    >
                                    <option value={''}>Select from option</option>
                                          {countries && countries.map((cur: any) =>
                                              <option value={cur.name}>{cur.name}</option>
                                          )}
                                  </TextField>

                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                    <FormLabel component="legend">Country of Filming</FormLabel>
                                    <TextField
                                        id="standard-select-currency-native"
                                        select
                                        onChange={(e) => setIntialValue({...initialValues,countryfilming:e.target.value})}
                                        SelectProps={{
                                          classes: { icon: classes.icon },
                                          native:true
                                        }}
                                        InputProps={{ className: classes.root }}
                                        variant="standard"
                                        name="countryfilming"
                                        value={initialValues.countryfilming}
                                        className={classes.inputClass}
                                      >
                                    <option value={''}>Select from option</option>
                                          {countries && countries.map((cur: any) =>
                                              <option value={cur.name}>{cur.name}</option>
                                          )}
                                    </TextField>

                                </FormControl>
                              </Grid>
                              </Grid>
                              </Grid>
                            
                              <Grid item xs={6}>
                              <Grid container spacing={2}>
                              <Grid item xs={6}>
                              <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                  <FormLabel component="legend">Language</FormLabel>
                                    <TextField
                                      id="standard-select-role"
                                      placeholder="Select from OPtion"
                                      onChange={(e) => setIntialValue({...initialValues,language:e.target.value})}
                                      select
                                      SelectProps={{
                                        classes: { icon: classes.icon },
                                        native:true
                                      }}
                                      InputProps={{ className: classes.root }}
                                      variant="standard"
                                      name="language"
                                      value={initialValues.language}
                                      className={classes.inputClass}
                                    >
                                      <option value="">Select from Option</option>
                                      {languageData && languageData.map((lan: any) =>
                                          <option value={lan.id}>{lan.attributes.name}  {lan.attributes.country_name}</option>
                                      )}
                                    </TextField>
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                    <FormLabel component="legend">Shooting Format</FormLabel>
                                    <TextField
                                        id="standard-select-role"
                                        placeholder="Digital, 35mm. RED etc"
                                        onChange={(e) => setIntialValue({...initialValues,shooting_format:e.target.value})}
                                        InputProps={{ className: classes.root }}
                                        name="shooting_format"
                                        value={initialValues.shooting_format}
                                        className={classes.inputClass}
                                        >
                                      </TextField>
                                </FormControl>
                              </Grid>
                              </Grid>
                              </Grid>
                              </Grid>

                              <Grid container spacing={6}> 
                                <Grid item xs={6}>
                                <Grid container spacing={2}>  
                                <Grid item xs={6}>
                                  <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                      <FormLabel component="legend">Aspect Ratio</FormLabel>
                                      <TextField
                                          id="standard-select-currency-native"
                                          placeholder="16:9"
                                          onChange={(e) => setIntialValue({...initialValues,aspectration:e.target.value})}
                                          InputProps={{ className: classes.root }}
                                          name="aspectration"
                                          value={initialValues.aspectration}
                                          className={classes.inputClass}
                                          >
                                        </TextField>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={6}>
                                  <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                      <FormLabel component="legend">Film Color</FormLabel>
                                      <TextField
                                          id="standard-select-role"
                                          placeholder="Select from OPtion"
                                          onChange={(e) => setIntialValue({...initialValues,filmcolour:e.target.value})}
                                          select
                                          SelectProps={{
                                            classes: { icon: classes.icon },
                                            native:true
                                          }}
                                          InputProps={{ className: classes.root }}
                                          variant="standard"
                                          name="filmcolour"
                                          value={initialValues.filmcolour}
                                          className={classes.inputClass}
                                        >
                                            <option value={""}>Select from option</option>
                                            <option value={'Black & White'}>Black & White</option>
                                            <option value={'Black & White and Color'}>Black & White and Color</option>
                                            <option value={'Color'}>Color</option>
                                            <option value={'Color 3D'}>Color 3D</option>
                                        </TextField>

                                    </FormControl>
                                  </Grid>
                                  </Grid>
                                  </Grid>
                                
                                  <Grid item xs={6}>
                                  <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                  <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                      <FormLabel component="legend">Student Project</FormLabel>
                                        <TextField
                                          id="standard-select-role"
                                          placeholder="Select from OPtion"
                                          onChange={(e) => setIntialValue({...initialValues,studentproject:e.target.value})}
                                          select
                                          SelectProps={{
                                            classes: { icon: classes.icon },
                                            native:true
                                          }}
                                          InputProps={{ className: classes.root }}
                                          variant="standard"
                                          name="studentproject"
                                          value={initialValues.studentproject ? 'Yes' : 'No'}
                                          className={classes.inputClass}
                                        >
                                            <option value={""}>Select from option</option>
                                            <option value={'Yes'}>Yes</option>
                                            <option value={'No'}>No</option>
                                        </TextField>

                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={6}>
                                  <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                      <FormLabel component="legend">First-time Filmmaker</FormLabel>
                                      <TextField
                                          id="standard-select-role"
                                          placeholder="Select from OPtion"
                                          onChange={(e) => setIntialValue({...initialValues,firstfilm:e.target.value})}
                                          select
                                          SelectProps={{
                                            classes: { icon: classes.icon },
                                            native:true
                                          }}
                                          InputProps={{ className: classes.root }}
                                          variant="standard"
                                          name="firstfilm"
                                          value={initialValues.firstfilm ? "Yes" : "No"}
                                          className={classes.inputClass}
                                        >
                                            <option value={""}>Select from option</option>
                                            <option value={'Yes'}>Yes</option>
                                            <option value={'No'}>No</option>
                                        </TextField>

                                    </FormControl>
                                  </Grid>
                                  </Grid>
                                  </Grid>
                              </Grid>
                          </Box>
                      </Box>
                </Box>
                </Box>
              }

              {activeStep == 4 && 
                  <Box sx={{padding: '20px 0px'}}>
                        <Box sx={styles.formTitle}>
                          <Typography variant="h4" component="h4">Screenings/ Distribution</Typography>
                        </Box>
                        <Box bgcolor="#FFF">
                            <Box
                              component="form"
                              sx={{ padding: '20px'}}
                            > 
                                <div>
                                  <FormLabel style={{margin:"10px"}} component="legend">Screenings & Awards</FormLabel>
                                  <Box border={0.2} borderColor="#f5f5f5" borderRadius={4}>
                                    {screening_and_awards_attributes?.map((screening_and_awards_attributes:any) => (
                  
                                <div key={screening_and_awards_attributes.id}>
                                  <Grid container spacing={6}>
                                  <Grid item xs={10}>
                                  <FormControl  style={{ padding: '20px', width: '100%'}}>
                                  <FormLabel component="legend">Event</FormLabel>
                                    <TextField
                                      name="event"
                                      id="outlined-disabled"
                                      placeholder="Eg. Austin film festival"
                                      value={screening_and_awards_attributes.event}
                                      onChange={event => handleChangeInput(screening_and_awards_attributes.id, event)}
                                      InputProps={{ className: classes.root }}
                                      className={classes.inputClass}
                                    />
                                  </FormControl>
                                </Grid>
                  
                                <Grid item xs={2} style={{marginTop:"30px"}}>
                                  <IconButton onClick = {() => handleScreenopen(screening_and_awards_attributes.id)} style={{ padding: '0px', border:"1px solid #A5259F", height:"50px", width: '50px', borderRadius:"4px"}}>
                                  {
                                    //@ts-ignore
                                  (openscreenid == screening_and_awards_attributes.id) && openscreen ?
                                  <img style={{width:"25px", height:"auto"}} src={arrowUp} alt="logo" /> :
                                  <img style={{width:"25px", height:"auto", transform:"rotate(180deg)"}} src={arrowUp} alt="logo" />}
                                  </IconButton>
                                
                                  <IconButton style={{ padding: '0px' }}  onClick={() => handleScreeningRemoveFields(screening_and_awards_attributes.id)}>
                                    <img style={{width:"50px", marginLeft:"25px"}} src={Delete} alt="logo" />
                                  </IconButton>     
                                </Grid>
                                </Grid>
                                {
                                //@ts-ignore
                              (openscreenid == screening_and_awards_attributes.id) && openscreen ? 
                            <div>
                              <>
                              <Grid container spacing={6}>
                              <Grid item xs={6}>
                              <FormControl  style={{ padding: '20px', width: '100%'}}>
                                  <FormLabel component="legend">City</FormLabel>
                                    <TextField
                                      name="city"
                                      id="outlined-disabled"
                                      placeholder="Austin, Texas"
                                      value={screening_and_awards_attributes.city}
                                      onChange={event => handleChangeInput(screening_and_awards_attributes.id, event)}
                                      InputProps={{ className: classes.root }}
                                      className={classes.inputClass}
                                    />

                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                            
                              <FormControl  style={{ padding: '20px', width: '100%'}}>
                                  <FormLabel component="legend">Country</FormLabel>
                                    <TextField
                                        name="country"
                                        id="standard-select-currency"
                                        select
                                        value={screening_and_awards_attributes.country}
                                        SelectProps={{
                                          classes: { icon: classes.icon },
                                          native:true
                                        }}
                                        InputProps={{ className: classes.root }}
                                        variant="standard"
                                        onChange={event => handleChangeInput(screening_and_awards_attributes.id, event)}
                                        className={classes.inputClass}
                                      >
                                      {/* <option value={""}>Select from option</option> */}
                                      <option value={''}>Select from option</option>
                                      {countries && countries.map((cur: any) =>
                                          <option value={cur.name}>{cur.name}</option>
                                      )}
                                    </TextField>
                              </FormControl>
                                </Grid>
                              </Grid>

                              <Grid container spacing={6}>
                              <Grid item xs={6}>
                              <FormControl  style={{ padding: '20px', width: '100%'}}>
                                  <FormLabel component="legend">Screening Date</FormLabel>
                                    <TextField
                                      type="date"
                                      name="screeing_date"
                                      id="outlined-disabled"
                                      value={moment(screening_and_awards_attributes.screeing_date).format('YYYY-MM-DD')}
                                      placeholder="DD / MM/ YYYY"
                                      SelectProps={{
                                        classes: { icon: classes.icon },
                                        native:true
                                      }}
                                      InputProps={{ className: classes.root }}
                                      onChange={event => handleChangeInput(screening_and_awards_attributes.id, event)}
                                      className={classes.inputClass}
                                    />
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                            
                              <FormControl  style={{ padding: '20px', width: '100%'}}>
                                  <FormLabel component="legend">Premiere</FormLabel>
                                    <TextField
                                        name="premiere"
                                        id="standard-select-currency-native" 
                                        placeholder="Eg. North American Premiere"
                                        value={screening_and_awards_attributes.premiere}
                                        onChange={event => handleChangeInput(screening_and_awards_attributes.id, event)}
                                        InputProps={{ className: classes.root }}
                                        className={classes.inputClass}
                                      >
                                    </TextField>
                              </FormControl>
                                </Grid>
                              </Grid>

                              <Box sx={{ flexGrow: 1 }} >
                                <Grid container spacing={6}>
                                <Grid item xs={6}>
                                <FormControl  style={{ padding: '20px', width: '100%'}}>
                                    <FormLabel component="legend">Award / Selection</FormLabel>
                                      <TextField
                                        name="award_selection"
                                        id="standard-select-role"
                                        placeholder="Eg. Official Selection"  
                                        value={screening_and_awards_attributes.award_selection}
                                        onChange={event => handleChangeInput(screening_and_awards_attributes.id, event)}
                                        InputProps={{ className: classes.root }}   
                                        className={classes.inputClass}                  
                                      >
                                      </TextField>
                                  </FormControl>
                                </Grid>
                                </Grid>
                              </Box>
                              <hr style={{margin:"10px 20px 10px 20px"}}></hr>
                              </>
                            </div> 
                            : "" }
                                </div>
                              )) }
                              </Box>
                            
                              <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop:"20px", padding:"20px"}}>
                                <Button
                                  color="inherit"
                                  onClick={handleScreeningAddFields}
                                >
                                  ADD A SCREENING
                                </Button>
                              </Box>
                                </div>
                                  <div>   
                                  <Box border={0.2} borderColor="#f5f5f5" borderRadius={4} style={{marginTop:"30px"}}>
                              {distributor?.map((distributor:any) => (
                                <div key={distributor.id}>
                              <Grid container spacing={2}>
                              <Grid item xs={11}>
                              <FormControl  style={{ padding: '20px', width: '100%'}}>
                                  <FormLabel component="legend">Sales Agent / Distributor</FormLabel>
                                    <TextField
                                      id="outlined-disabled"
                                      placeholder="Eg. Netflix"
                                      name="name"
                                      value={distributor?.attributes?.name}
                                      onChange={event => handleChangeInput(distributor.id, event)}
                                      InputProps={{ className: classes.root }}
                                      className={classes.inputClass}
                                    />
                                </FormControl>
                              </Grid>
                              <Grid item xs={1} style={{marginTop:"30px"}}>                              
                                <IconButton style={{ padding: '0px' }} onClick={() => handleDistributorRemoveFields(distributor.id)}>
                                  <img style={{width:"50px", marginLeft:"25px"}} src={Delete} alt="logo" />
                                </IconButton>     
                              </Grid>
                              </Grid>
                              <hr style={{margin:"10px 20px 10px 20px"}}></hr>
                              </div>
                                )) }
                              </Box>

                              <Grid container style={{margin:"20px"}}>
                              <Grid item xs={3} md={3}>
                                <Button
                                  color="inherit"
                                  onClick={handleDistributorAddFields}
                                  style={{minWidth:"270px"}}
                                >
                                  + ADD A DISTRIBUTOR / SALES AGENT
                                </Button>
                              </Grid>
                              </Grid>
                              </div>
                            </Box>
                          </Box>
                          <div>
                          </div>
                  </Box>
                  
              }
              <React.Fragment>
                
                <Box sx={{ display: 'flex', width: '43%', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    style={{color:"#a5259f"}}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto', marginLeft:"10px"}} />
                    {activeStep === steps.length - 1 ? <Button color="primary"  type="submit" onClick={handleSubmit}>Submit </Button> :  <Button color="primary" disabled={btnDisabled} onClick={handleNext}>Next </Button>}    
                </Box>
              </React.Fragment>
            </Box>
          </form> 
      </Box>
      }
      <Footer />
    </Box>
  );
}

export default EditProject;

const styles = {
  StepperCard: {
    width: '100%', 
    display: 'flex',
    justifyContent: 'center',
  },
  formTitle: {
    background: '#330a4c',
    padding: '20px',
    color: '#fff'
  },
  formError: {
    margin: "0px",
    color: "#ef2525"
}
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400, 
  bgcolor: '#330a4c',
  border: '2px solid #000',
  boxShadow: 24,
  color:"#fff",
};