import React from "react";

import AppbarHeader from '../../../components/src/AppHeader.web'

import ResponsiveAppBar from "./header.web"
import StepperView from "./stepper.web"

import CustomForm2Controller, {
  Props,
  configJSON
} from "./CustomForm2Controller";

export default class CustomForm2 extends CustomForm2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
   this.getAccountData();
   this.getRoleData();
   this.getLanguageData();
  }

  // Customizable Area handleSubmitProject
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <AppbarHeader
            // @ts-ignore
            accountData={this.state.accountData}
        />
        <StepperView 
          handleSubmitProject={this.handleSubmitProject}
          handleProjectType={this.handleProjectType}
          handlenameChange={this.handlenameChange}
          handledescriptionChange={this.handledescriptionChange}
          handledewebsiteChange={this.handledewebsiteChange}
          handledetwitterChange={this.handledetwitterChange}
          handledefacebookChange={this.handledefacebookChange}
          handledeinstagramChange={this.handledeinstagramChange}
          handledeimdbChange={this.handledeimdbChange}
          handledeemailChange={this.handledeemailChange}
          handledephoneChange={this.handledephoneChange}
          handledeDobChange={this.handledeDobChange}
          handledeGenderChange={this.handledeGenderChange}
          handledeCountryofOriginChange={this.handledeCountryofOriginChange}
          handledeCountryofResidenceChange={this.handledeCountryofResidenceChange}
          handledeAddressChange={this.handledeAddressChange}
          handledeCityChange={this.handledeCityChange}
          handledeStateChange={this.handledeStateChange}
          handledePostalCodeChange={this.handledePostalCodeChange}
          handledeRoleChange={this.handledeRoleChange} 
          handledeFirstNameChange={this.handledeFirstNameChange}
          handledeMiddleNameChange={this.handledeMiddleNameChange}
          handledeLastNameChange={this.handledeLastNameChange}
          handledePriorCreditChange={this.handledePriorCreditChange}
          handledeScreenigAwardChange={this.handledeScreenigAwardChange}
          handledeGenreChange={this.handledeGenreChange}
          handledeHrsChange={this.handledeHrsChange}
          handledeCompletionDateChange={this.handledeCompletionDateChange}
          handledeBudgetChange={this.handledeBudgetChange}
          handledeCurrencyChange={this.handledeCurrencyChange}
          handledeSpecificationCountryChange={this.handledeSpecificationCountryChange}
          handledeCountryofFilmingChange={this.handledeCountryofFilmingChange}
          handledeLanguageChange={this.handledeLanguageChange}
          handledeShootingFormateChange={this.handledeShootingFormateChange}
          handledeAspectRatioChange={this.handledeAspectRatioChange}
          handledeFilmColorChange={this.handledeFilmColorChange}
          handledeStudentProjectChange={this.handledeStudentProjectChange}
          handledeFirsttimeFilmChange={this.handledeFirsttimeFilmChange}
          handledeEventChange={this.handledeEventChange}
          handledeScreeningCityChange={this.handledeScreeningCityChange}
          handledeScreeningCountryChange={this.handledeScreeningCountryChange}
          handledeScreeningDateChange={this.handledeScreeningDateChange}
          handledePremierChange={this.handledePremierChange}
          handledeAwardSelectionChange={this.handledeAwardSelectionChange}
          handledeSalesAgentChange={this.handledeSalesAgentChange}
          currencyData={this.state.currencyData}
          roleData={this.state.roleData}
          languageData={this.state.languageData}
          budgetData={this.state.budgetData}
          />
          {console.log('languageData=-=>', this.state.languageData)}
          {console.log('roleData=-=>', this.state.roleData)}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
