import React from "react";

import {
  Typography,
  Button,
  Grid,
  Box,
  Divider,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import AppHeader from "../../../components/src/AppHeader.web"

import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    const { navigation } = this.props;

    return (
      <Box sx={webStyles.imgContainer}>
          <AppHeader
            // @ts-ignore
            toggleLoginModal={() => this.goToHome()}
            toggleSignupModal={() => this.goToHome()}
          />
          {this.state.apiResponseError &&
          <Box sx={webStyles.msgBarError}>
            <span>Error: {this.state.apiResponseError} </span>
          </Box> }
          {this.state.apiResponseMsg &&
          <Box sx={webStyles.msgBar}>
            <span>SUCCESS  {this.state.apiResponseMsg}.</span>
          </Box> }
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            // style={webStyles.imgContainer}
          >
            <Grid item md={6} xs={8}>
              <Box py={4} >
                <Grid container spacing={2}>
                  <Grid item xs={9} >
                    <Typography variant="h3" >
                      Reset Your Password
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{bgcolor: '#fff'}}>
              <Formik
                  initialValues={{ accountType: "email_account", email: "" }}
                  validationSchema={Yup.object().shape(this.state.emailSchema)}
                  validateOnMount={true}
                  validateOnChange={true}
                  onSubmit={(values, actions) => {
                    this.goToOtpAfterEmailValidation(values);
                    actions.setSubmitting(false);
                  }}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    errors,
                    setFieldTouched,
                    touched
                  }) => (
                    <Box py={4} >
                      <Grid container justify="center" spacing={2}>
                        <Grid item xs={10}>
                          <TextField
                            type="email"
                            fullWidth
                            variant="outlined" 
                            id="outlined-disabled"
                            placeholder={this.firstInputPlaceholder}
                            onChange={handleChange("email")}
                            onBlur={() => setFieldTouched("email")}
                          />
                          {touched.email && errors.email ? (
                            <span style={{fontSize: '12px', color: 'red'}}>{errors.email}</span>
                          ) : null}
                        </Grid>
                        <Grid item xs={10}>
                          <Button
                              color="primary"
                              variant="contained"
                              fullWidth
                              onClick={() => handleSubmit()}
                          >
                            Reset Password
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Formik>
                <Grid container justify="center" spacing={2}>
                  <Grid xs={10} style={{padding: '8px'}}>
                    <Divider />
                  </Grid>
                </Grid>
                <Box py={3} >
                  <Grid container justify="center" spacing={2}>
                    <Grid item xs={6} justify="center">
                      <Typography variant="subtitle1" >
                          Not a member yet?
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                          color="primary"
                          variant="outlined"
                          fullWidth
                          onClick={() => this.goToHome()}
                      >
                        Sign Up
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
      </Box>
    );
  }
}


// Customizable Area Start
const webStyles = {
  imgContainer: {
    bgcolor: '#eee',
    height: '100vh'
  },
  msgBar: {
    width: '100%', 
    height: '50px', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    background: '#2db482',
    color: "#fff"
  },
  msgBarError: {
    width: '100%', 
    height: '50px', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    background: '#c43731',
    color: "#fff"
  }
}
// Customizable Area End

