// Customizable Area Start
import React,{Component} from 'react';
import CoverImg from '../../../components/src/CoverImage.web'
import Footer from "../../../components/src/AppFooter.web";
import {Container,CircularProgress, Grid, Box, Typography, Button, IconButton, Divider, Tabs, Tab, Input} from "@material-ui/core";
import {shareIcon,facebookIcon,twitterIcon,emailIcon,phoneIcon,locationPinIcon,webIcon} from './assets'
import AddIcon from '@material-ui/icons/Add';
import { purple } from '@material-ui/core/colors';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import AboutFestival from "./AboutFestival.web";
import PhotosTab from "./PhotosTab.web"
import {toast} from "react-toastify";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CreateAlbumModal from "./CreateAlbumModal.web";
import Modal from "@material-ui/core/Modal";
// @ts-ignore
import FestivalViewController, {Props} from "./FestivalViewController";
import {withRouter} from "react-router-dom";
import {FacebookShareButton,TwitterShareButton} from 'react-share'
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import CloseIcon from '@material-ui/icons/Close';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

let customizeTabText: React.CSSProperties = {
    fontWeight: 800,
    textTransform: 'capitalize',
    color:"#000",
    minWidth:"100px"
}

class FestivalView extends FestivalViewController{
    render(){
        return (
            <>
            <Box style={{backgroundColor: "#ebeced",paddingBottom:"50px"}}>
                {/*@ts-ignore*/}
                <CoverImg showHeader={true} />
                <Container style={{backgroundColor: "#ebeced",marginTop:"20px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{width: '100%',marginBottom:"50px"}}>
                                <StyledTabs
                                    value={0}
                                    onChange={this.manageRouting}
                                    textColor="primary"
                                    indicatorColor="primary"
                                    aria-label="primary tabs example"
                                    centered={false}>
                                    <Tab label="View" {...a11yProps(0)} style={customizeTabText} />
                                    <Tab label="Edit" {...a11yProps(1)} style={customizeTabText} />
                                    <Tab disabled label="Manage" {...a11yProps(2)} style={customizeTabText} />
                                    <Tab disabled label="Marketing" {...a11yProps(3)} style={customizeTabText} />
                                </StyledTabs>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{marginTop:"-10px"}}>
                        <Grid xs={12} sm={12} md={10}>
                            <Grid container spacing={3} style={{marginLeft:"8px"}}>
                                <Grid xs={6} sm={2}>
                                    <Box>
                                        <Typography variant="body1" style={{fontWeight:'bold'}} >Events Type:</Typography>
                                        {/*@ts-ignore*/}
                                        <Typography variant="body2" style={{marginTop: '5px',color:"gray"}}>{this.state.festivalDetails?.event_type}</Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={6} sm={2}>
                                    <Box>
                                        <Typography variant="body1" style={{fontWeight:'bold'}} >Years Running</Typography>
                                        {/*@ts-ignore*/}
                                        <Typography variant="body2" style={{marginTop: '5px',color:"gray"}}>{this.state.festivalDetails?.years_running}</Typography>
                                    </Box>
                                </Grid>
                                <Grid xs={6} sm={2}>
                                    <Box>
                                        <Typography variant="body1" style={{fontWeight:'bold'}} >Venue</Typography>

                                        {
                                            // @ts-ignore
                                            this.state.festivalDetails?.event_vanues?.data?.length > 0 &&
                                            <>
                                                {/*@ts-ignore*/}
                                                <Typography variant="body2" style={{marginTop: '5px',color:"gray"}}>{this.state.festivalDetails?.event_vanues?.data[0]?.attributes?.vanue_name}</Typography>
                                                <Typography variant="body2" onClick={this.handleOpenModal1} style={{marginTop: '5px',color:"Purple", cursor:"pointer"}}>View More</Typography>
                                            </>
                                        }
                                    </Box>
                                </Grid>
                                <Grid xs={6} sm={2}>
                                    <Box>
                                        <Typography variant="body1" style={{fontWeight:'bold'}} >Organizers</Typography>
                                        {
                                            // @ts-ignore
                                            this.state.festivalDetails?.event_organizers?.length > 0 &&
                                            <>
                                                {/*@ts-ignore*/}
                                                <Typography variant="body2" style={{marginTop: '5px',color:"gray"}}>{this.state.festivalDetails?.event_organizers[0]?.name}</Typography>
                                                <Typography variant="body2" onClick={this.handleOpenModal2} style={{marginTop: '5px',color:"Purple", cursor:"pointer"}}>View More</Typography>
                                            </>
                                        }

                                    </Box>
                                </Grid>
                                <Grid xs={6} sm={2}>
                                    {/*<Box>*/}
                                    {/*    <Typography variant="body1" style={{fontWeight:'bold'}} >Transparency</Typography>*/}
                                    {/*    <Typography variant="body2" style={{marginTop: '5px',color:"gray"}}>Lorem Ipsum</Typography>*/}
                                    {/*</Box>*/}
                                </Grid>
                                <Grid xs={6} sm={2}>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} sm={12} md={2} style={{display:'flex',marginTop:"-20px"}}>
                            <Box style={{width:"100%"}}>
                                <Button variant="contained" onClick={this.handleOpenSubmitModal}  component="label" style={{ background: "#A5259F", color: "#fff", marginTop: "10px",width:"95%"}}>
                                    Submit Now
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} style={{marginTop:"30px"}}>
                            <Typography variant="body1" style={{fontWeight:'bold'}} >Contact</Typography>
                            <Grid container>
                                <Grid item xs={12} sm={3}>
                                    <Box style={{display:'flex',flexDirection:'row'}}>
                                        <img src={locationPinIcon} width="20px" height="20px" style={{marginRight:"10px", marginTop:"10px"}} />
                                        <Typography variant="body1" style={{marginTop: '5px',color:"gray"}}>
                                            {/*@ts-ignore*/}
                                            {this.state.festivalDetails?.contact_and_vanue_info?.address}{this.state.festivalDetails?.contact_and_vanue_info?.address && this.state.festivalDetails?.contact_and_vanue_info?.city && ","}
                                            {/*@ts-ignore*/}
                                            {this.state.festivalDetails?.contact_and_vanue_info?.city}{this.state.festivalDetails?.contact_and_vanue_info?.city && this.state.festivalDetails?.contact_and_vanue_info?.country && ","}
                                            {/*@ts-ignore*/}
                                            {this.state.festivalDetails?.contact_and_vanue_info?.country}{this.state.festivalDetails?.contact_and_vanue_info?.country && this.state.festivalDetails?.contact_and_vanue_info?.postal_code &&","}
                                            {/*@ts-ignore*/}
                                            {this.state.festivalDetails?.contact_and_vanue_info?.postal_code}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                    <Box style={{display:'flex',flexDirection:'row'}}>
                                        <img src={emailIcon} width="20px" height="20px" style={{marginRight:"10px", marginTop:"10px"}} />
                                        {/*@ts-ignore*/}
                                        <Typography noWrap variant="body1" style={{marginTop: '5px',color:"gray"}}>{this.state.festivalDetails?.contact_and_vanue_info?.email}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={3} md={2}>
                                    <Box style={{display:'flex',flexDirection:'row'}}>
                                        <img src={phoneIcon} width="20px" height="20px" style={{marginRight:"10px", marginTop:"10px"}} />
                                        {/*@ts-ignore*/}
                                        <Typography variant="body1" style={{marginTop: '5px',color:"gray"}}>{this.state.festivalDetails?.contact_and_vanue_info?.phone}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={3} md={2}>
                                    <Box style={{display:'flex',flexDirection:'row'}}>
                                        <img src={webIcon} width="20px" height="20px" style={{marginRight:"10px", marginTop:"10px"}} />
                                        {/*@ts-ignore*/}
                                        <Typography variant="body1" style={{marginTop: '5px',color:"gray"}}>{this.state.festivalDetails?.contact_and_vanue_info?.website}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} sm={12} md={2} style={{display:'flex',alignItems:'center',marginTop:"30px"}}>
                            <Grid container spacing={2} style={{display:'flex',alignItems:'center'}}>
                                <Grid xs={4} style={{display:'flex',justifyContent:'center'}}>
                                    <a onClick={this.handleOpenSharingModal} style={{cursor:"pointer"}}>
                                        <img src={shareIcon} width="35px" height="35px" style={{marginTop:"10px"}} />
                                    </a>
                                </Grid>
                                <Grid xs={4} style={{display:'flex',justifyContent:'center'}}>
                                    {/*@ts-ignore*/}
                                    <a href={this.state.festivalDetails?.contact_and_vanue_info?.twitter || "#"} target="_blank">
                                        <img src={twitterIcon} width="40px" height="40px" style={{marginTop:"10px"}} />
                                    </a>
                                </Grid>
                                <Grid xs={4} style={{display:'flex',justifyContent:'center'}}>
                                    {/*@ts-ignore*/}
                                    <a href={this.state.festivalDetails?.contact_and_vanue_info?.facebook || "#"} target="_blank">
                                        <img src={facebookIcon} width="40px" height="40px" style={{marginTop:"10px"}} />
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box style={{marginTop:"30px",width:"100%"}} >
                        <Divider/>
                    </Box>
                    <Grid container style={{display:'flex',alignItems:'center',}}>
                        <Grid item xs={12} sm={7}>
                            <Box sx={{ width: '100%',marginTop:"5px"}}>
                                <StyledTabs
                                    value={this.state.value}
                                    onChange={this.handleChange}
                                    textColor="primary"
                                    indicatorColor="primary"
                                    aria-label="primary tabs example"
                                    centered={false}>
                                    <Tab label="About" {...a11yProps(0)} style={customizeTabText} />
                                    <Tab label="Rules" {...a11yProps(1)} style={customizeTabText} />
                                    <Tab label="Reviews" {...a11yProps(2)} style={customizeTabText} />
                                    <Tab label="Photos" {...a11yProps(3)} style={customizeTabText} />
                                </StyledTabs>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            {
                                this.state.value === 3 &&
                                <Box style={{marginTop:"10px",display:'flex',justifyContent:'flex-end'}}>
                                    <Box style={{display:'flex',alignItems:'center'}}>
                                        {
                                            this.state.isPhotoUploading &&
                                                <CircularProgress size={30} />
                                        }
                                        <ColorButton disabled={this.state.isPhotoUploading} style={{marginLeft:"5px"}} variant="outlined">
                                            <label htmlFor="icon-button-add-image" style={{display:'flex',alignItems:'center'}}>
                                                <AddIcon/> <b> Add Photos </b>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    id="icon-button-add-image"
                                                    hidden
                                                    onChange={this.handleUploadImages}
                                                    name="cover_pic"
                                                    style={{display: 'none'}}
                                                    multiple
                                                />
                                            </label>
                                        </ColorButton>
                                    </Box>

                                    <ColorButton onClick={this.handleOpenModal} variant="outlined" style={{marginLeft:"10px",fontWeight:'bold'}}><AddIcon/>Create Album </ColorButton>
                                </Box>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <TabPanel value={this.state.value} index={0}>
                                {/*// @ts-ignore*/}
                                <AboutFestival refProp={this.myRef} refProp1={this.myRef1}/>
                            </TabPanel>
                            <TabPanel value={this.state.value} index={3}>
                                <PhotosTab />
                            </TabPanel>
                        </Grid>
                    </Grid>
                </Container>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modalStyle"
                    // @ts-ignore
                    open={this.state.isModalOpen}
                    onClose={this.handleCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    {/*@ts-ignore*/}
                    <Fade in={this.state.isModalOpen}>
                        <div>
                            {/*// @ts-ignore*/}
                            <CreateAlbumModal handleCloseModal={this.handleCloseModal}/>
                        </div>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modalStyle"
                    // @ts-ignore
                    open={this.state.isModal1Open}
                    onClose={this.handleCloseModal1}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    {/*@ts-ignore*/}
                    <Fade in={this.state.isModal1Open}>
                        <div>
                            <Box style={{width:"35vw",marginTop:'15px',backgroundColor:"white",padding:'20px',borderRadius:"20px"}}>
                                <Box style={{margin:"15px"}}>
                                    <Typography variant="h4" style={{fontWeight:'bold',color:"purple"}} >Venue List :</Typography>
                                    <Box style={{marginTop:"10px"}}>
                                        {
                                            // @ts-ignore
                                            this.state.festivalDetails?.event_vanues?.data?.length > 0 &&
                                                // @ts-ignore
                                                this.state.festivalDetails?.event_vanues?.data?.map((item,key)=>{
                                                    return(
                                                        <Box style={{display:'flex',flexDirection:'row',marginBottom:'10px'}} key={key}>
                                                            <Typography style={{marginRight:'5px'}}>
                                                                {item.attributes.vanue_name}
                                                            </Typography>
                                                            <Typography>
                                                               -
                                                            </Typography>
                                                            <Typography style={{marginLeft:'5px'}}>
                                                                {item.attributes?.online_event ? "Online Event":`${item.attributes?.vanue_address.address},${item.attributes?.vanue_address.city},${item.attributes?.vanue_address.postal_code}` || "ADDRESS DISPLAY HERE"}
                                                                {console.log("VENUE",item.attributes?.vanue_address)}
                                                            </Typography>
                                                        </Box>
                                                    )
                                                })
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </div>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modalStyle"
                    // @ts-ignore
                    open={this.state.isSubmitNowModalOpen}
                    onClose={this.handleCloseSubmitModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    {/*@ts-ignore*/}
                    <Fade in={this.state.isSubmitNowModalOpen}>
                        <div>
                            <Box style={{width:"35vw",marginTop:'15px',backgroundColor:"white",padding:'20px',borderRadius:"20px"}}>
                                <Box style={{margin:"15px"}}>
                                    <Typography variant="h4" style={{fontWeight:'bold'}} >You are logged in with a organizer account. You must log in with a submitter account to submit to festivals.</Typography>
                                </Box>
                                <Box style={{width:"100%",display:'flex',justifyContent:'flex-end'}}>
                                    <ColorButton onClick={this.handleCloseSubmitModal} variant="outlined" style={{marginRight:"15px"}}>Close</ColorButton>
                                </Box>
                            </Box>
                        </div>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modalStyle"
                    // @ts-ignore
                    open={this.state.isSharingModalOpen}
                    onClose={this.handleCloseSharingModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    {/*@ts-ignore*/}
                    <Fade in={this.state.isSharingModalOpen}>
                        <div>
                            <Box style={{width:"35vw",marginTop:'10px',backgroundColor:"white",padding:'20px',borderRadius:"20px"}}>
                                <Box style={{display:'flex',justifyContent:'flex-end'}}>
                                    <IconButton onClick={this.handleCloseSharingModal}><CloseIcon/></IconButton>
                                </Box>
                                <Box style={{marginTop:"-40px",marginBottom:"10px"}}>
                                    <Typography variant="h4" style={{fontWeight:'bold',color:"purple"}} >Share Festival</Typography>
                                    <Grid container style={{marginTop:"10px"}}>
                                        <Grid item xs={6} className="iconGrid">
                                            <FacebookShareButton style={{width:"100%"}} url="https://filmplatformvevek-76464-react-native.b76464.dev.eastus.az.svc.builder.cafe/" quote="SHARE TO FACEBOOK" hashtag="#FLIMFESTIVAL" translate="">
                                                <div className="shareIcon">
                                                    Share on <FacebookIcon fontSize="large" style={{marginLeft:'5px',color:"#4267B2"}}/>
                                                </div>
                                            </FacebookShareButton>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TwitterShareButton style={{width:"100%"}} title="Movie-Express way Platform" url="https://filmplatformvevek-76464-react-native.b76464.dev.eastus.az.svc.builder.cafe/" translate="">
                                                <div className="shareIcon">
                                                    Post on <TwitterIcon fontSize="large" style={{marginLeft:'5px',color:"#00acee"}}/>
                                                </div>
                                            </TwitterShareButton>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </div>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modalStyle"
                    // @ts-ignore
                    open={this.state.isModal2Open}
                    onClose={this.handleCloseModal2}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    {/*@ts-ignore*/}
                    <Fade in={this.state.isModal2Open}>
                        <div>
                            <Box style={{width:"35vw",marginTop:'15px',backgroundColor:"white",padding:'20px',borderRadius:"20px"}}>
                                <Box style={{margin:"15px"}}>
                                    <Box style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                        <Typography variant="h4" style={{fontWeight:'bold',color:"purple"}} >Organizer List :</Typography>
                                        <IconButton onClick={this.handleCloseModal2}><CloseIcon/></IconButton>
                                    </Box>
                                    <Box style={{marginTop:"10px"}}>
                                        {
                                            // @ts-ignore
                                            this.state.festivalDetails?.event_organizers?.length > 0 &&
                                            // @ts-ignore
                                            this.state.festivalDetails?.event_organizers?.map((item,key)=>{
                                                return(
                                                    <Box style={{display:'flex',flexDirection:'row',marginBottom:'10px'}} key={key}>
                                                        <Typography style={{marginRight:'5px'}}>
                                                            {item?.name}
                                                        </Typography>
                                                        <Typography>
                                                            -
                                                        </Typography>
                                                        <Typography style={{marginLeft:'5px'}}>
                                                            {item?.title}
                                                        </Typography>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </div>
                    </Fade>
                </Modal>
            </Box>
            <Footer />
            </>
        );
    }
};

export default (...props:any) => {
    // @ts-ignore
    const WithRouteAppHeader = withRouter(FestivalView)
    return (
        <WithRouteAppHeader {...props} />
    )
};

const StyledTabs = withStyles({
    indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        "& > span": {
            maxWidth: 70,
            width: "100%",
            height:"20px",
            backgroundColor: "maroon"
        }
    }
})((props:any) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{paddingTop:"24px"}}>{children}</Box>
            )}
        </div>
    );
}

const ColorButton = withStyles((theme) => ({
    root: {
        color: purple[500],
        borderColor: purple[500],
        '&:hover': {
            borderColor: purple[700],
            color: purple[700],
        },
    },
}))(Button);
// Customizable Area End