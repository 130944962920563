import * as React from "react";

import { Box, Grid, Button, Typography, Modal } from "@material-ui/core";
import LocationOnOutlined from "@material-ui/icons/LocationOn";
import Rating from "@material-ui/lab/Rating";
import { profileAvatar } from "./assets";
import UpdateReviews from "./updateReview.web";

const Reviews = ({
  userReviewsData,
  handleUserReview,
  userReviewSaved,
}: any) => {
  const [open, setOpen] = React.useState(false);
  const [catalogueId, setCatalogueId] = React.useState(0);
  const handleOpen = (id: number) => {
    setOpen(true);
    setCatalogueId(id);
  };
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    width: 500,
  };

  React.useEffect(() => {
    if (userReviewSaved) {
      handleClose();
    }
  }, [userReviewSaved]);

  return (
    <Box sx={webStyles.bottomRight}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <UpdateReviews
            userReviewsData={userReviewsData}
            catalogueId={catalogueId}
            handleUserReview={handleUserReview}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
      <Box sx={webStyles.formCard}>
        <Box sx={{ bgcolor: "#320b4c", padding: "10px" }}>
          <Typography variant="h4" style={{ color: "#FFF" }}>
            Reviews
          </Typography>
        </Box>
        <Box sx={{ padding: "20px", display: "flex" }}>
          <Box sx={{ borderRight: "1px solid #999" }}>
            <img src={profileAvatar} style={webStyles.avatar} />
          </Box>
          <Box sx={{ marginLeft: "25px", width: "100%" }}>
            <Typography variant="h3">John Doe</Typography>
            <span style={{ fontWeight: 600, color: "#B6B6B6" }}>
              Member since August 2017
            </span>
            <LocationOnOutlined style={{ width: "20px", height: "14px" }} />
            <span style={{ fontWeight: 600, color: "#B6B6B6" }}>USA</span>
          </Box>
        </Box>
        <Box
          sx={{ padding: "0 20px 20px", display: "flex", marginTop: "-120px" }}
        >
          <div style={{ width: "220px" }} />
          {userReviewsData.map((review: any) => {
            const { attributes } = review;
            let catalogueIdTemp = attributes.catalogue_id;
            return (
              <div key={attributes.id} style={{ width: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: 700,
                      marginBottom: "15px",
                      marginTop: "15px",
                      fontSize: "16px",
                    }}
                  >
                    Your Review of {attributes.catalogue_name}
                  </Typography>
                  <Button
                    variant="contained"
                    style={{
                      background: "#fff",
                      color: "#A5259F",
                      margin: "6px 0",
                      border: "2px solid",
                      height: "35px",
                    }}
                    onClick={() => handleOpen(catalogueIdTemp)}
                  >
                    UPDATE
                  </Button>
                </Box>

                <Typography
                  variant="body2"
                  style={{ fontWeight: 400, marginBottom: "15px" }}
                >
                  {attributes.comment}
                </Typography>

                <Box>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "30px",
                    }}
                  >
                    <Grid container spacing={2} style={{ width: "85%" }}>
                      <Grid item md={4}>
                        <Typography
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            padding: "0 0 5px",
                            fontSize: "15px",
                          }}
                        >
                          Overall Rating
                        </Typography>
                        <Rating
                          name={"rating"}
                          value={attributes.rating}
                          readOnly
                          style={webStyles.emptyStar}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <Typography
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            padding: "0 0 5px",
                            fontSize: "15px",
                          }}
                        >
                          Quality
                        </Typography>
                        <Rating
                          name={"rating"}
                          value={attributes.quality_rating}
                          readOnly
                          style={webStyles.emptyStar}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <Typography
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            padding: "0 0 5px",
                            fontSize: "15px",
                          }}
                        >
                          Value
                        </Typography>
                        <Rating
                          name={"rating"}
                          value={attributes.value_rating}
                          readOnly
                          style={webStyles.emptyStar}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Box>
                <Box>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "30px",
                    }}
                  >
                    <Grid container spacing={2} style={{ width: "85%" }}>
                      <Grid item md={4}>
                        <Typography
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            padding: "0 0 5px",
                            fontSize: "15px",
                          }}
                        >
                          Communication
                        </Typography>
                        <Rating
                          name={"rating"}
                          value={attributes.communication_rating}
                          readOnly
                          style={webStyles.emptyStar}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <Typography
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            padding: "0 0 5px",
                            fontSize: "15px",
                          }}
                        >
                          Hospitality
                        </Typography>
                        <Rating
                          name={"rating"}
                          value={attributes.hospitality_rating}
                          readOnly
                          style={webStyles.emptyStar}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <Typography
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            padding: "0 0 5px",
                            fontSize: "15px",
                          }}
                        >
                          Networking
                        </Typography>
                        <Rating
                          name={"rating"}
                          value={attributes.networking_rating}
                          readOnly
                          style={webStyles.emptyStar}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              </div>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
export default Reviews;

const webStyles = {
  main: {
    display: "flex",
    justifyContent: "center",
    height: "100px",
    background: "000",
  },
  bottomRight: {
    display: "flex",
    flexDirection: "column",
    width: "75%",
  },
  formCard: {
    background: "#fff",
    marginBottom: "20px",
  },
  formDesign: {
    display: "flex",
    padding: "40px 20px",
    gap: "30px",
  },
  avatar: {
    height: "150px",
    width: "150px",
    borderRadius: "50%",
  },
  emptyStar: {
    color: "black",
  },
};
