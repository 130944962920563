// Customizable Area Start
import * as React from 'react';

import {
  Box,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  TextField,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Grid,
  makeStyles, 
  withStyles,
  IconButton,
  Modal,
  Container,
  Input,
  Divider,
  CircularProgress,
  Paper
} from "@material-ui/core";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { LandingPageImg } from "./assets";


const CoverImg = ({
    accountData,
    handleProfilePic,
    handleCoverPic
}: any) => { 
    const [accountAPIData, setAccountAPIData ] = React.useState([]);
    const [profilepic, setProfilePic] = React.useState({ profile_pic:"" as any});
    const [previewprofile, setPreviewProfile] = React.useState("");
    const [coverpic, setCoverPic] = React.useState({cover_pic:"" as any});
    const [coverpreview, setCoverPreview] = React.useState("");

    React.useEffect(() => {
        setAccountAPIData(accountData)
    }, [accountData])  

    const handleUpdateProfileImage = (e: any) => {
            setProfilePic({
                ...profilepic,
               "profile_pic" : e.target.files[0]
            });
            setPreviewProfile(URL.createObjectURL(e.target.files[0]) as any)
    }

    const handleUpdateCoverImage = (e:any) => {
        setCoverPic({
            ...coverpic,
           "cover_pic" : e.target.files[0]
        });
        console.log("cover pic 2nd", coverpic)
        setCoverPreview(URL.createObjectURL(e.target.files[0]) as any)
    }

    React.useEffect(() => {
        if(profilepic.profile_pic !== ""){
            setTimeout(() => {
                handleProfilePic(profilepic)
                console.log("Image saved",profilepic)
              }, 2000);
              console.log("set time out===> ")
        }     
    }, [profilepic]) 

    React.useEffect(() => {
        if(coverpic.cover_pic !== ""){
            console.log("jemin outside")
            setTimeout(() => {
                handleCoverPic(coverpic)
                console.log("Image saved",coverpic)
              }, 2000);
              console.log("set time out===> ")
        }     
    }, [coverpic]) 

    const handleEditProfile = (e:any) => {
        window.location.replace('/Settings2');
    }

    return( 
        <>
        {accountData === null ? 
         <Grid container style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
            <CircularProgress />
         </Grid> : <>
             <img src={accountData.cover_pic == "" ? (coverpreview == "" ? LandingPageImg : coverpreview) : coverpreview=="" ? accountData.cover_pic : coverpreview } alt="cover-image" height="250px" width="100%" style={{ objectFit: "cover", objectPosition: "center top"}} />
                <Box sx={{bgcolor: "#ebeced"}}>
                    <Container>
                    <Box sx={webStyles.headSection}>
                        <Box sx={webStyles.headLeft}>
                            <Box sx={webStyles.avatarSection}>
                            <img src={accountData.profile_pic == "" ? (previewprofile == "" ? LandingPageImg : previewprofile) : previewprofile == "" ? accountData.profile_pic : previewprofile} alt="profile-picture" style={webStyles.avatar} />
                                <label htmlFor="icon-button-file">
                                    <Input id="icon-button-file" 
                                    name="profile_pic"
                                    type="file" style={{display: 'none'}} 
                                    onChange={handleUpdateProfileImage} 
                                    />
                                    <IconButton aria-label="upload picture" component="span" style={{position: 'absolute', top: '175px', right: '80px', padding: '8px', backgroundColor: '#a5259f', height: '40px', width: '40px', borderRadius: '50%'}}>
                                        <PhotoCameraIcon style={{fill: "white"}} />
                                    </IconButton>
                                </label>
                            </Box>
                            <Box sx={{marginLeft: "50px"}}>
                                <Typography variant="h3">{accountData.first_name} {accountData.last_name} {accountData.title ? ("("+ accountData.title + ")") : ""}</Typography>
                                <Typography variant="subtitle1" style={{color: "#ccc"}} >Member Since August 2021 USA</Typography>
                            </Box>
                            <Box sx={webStyles.headContent}>
                                <Typography variant="h3" style={{color: "#A5259F"}} >0</Typography>
                                <Typography variant="h5" style={{fontWeight: 700, marginLeft: "5px"}} >Events</Typography>
                            </Box>
                            <Box sx={webStyles.headContent}>
                                <Typography variant="h3" style={{color: "#A5259F"}} >0</Typography>
                                <Typography variant="h5" style={{fontWeight: 700, marginLeft: "5px"}} >Discussions</Typography>
                            </Box>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "column", alignContent: "center"}}>
                            <Button variant="contained" component="label" style={{ background: "#A5259F", color: "#fff", marginTop: "10px"}}>
                            <label htmlFor="icon-button-cover">
                                + COVER IMAGE
                                <Input
                                    type="file"
                                    id="icon-button-cover"
                                    hidden
                                    onChange={handleUpdateCoverImage}
                                    name="cover_pic"
                                    style={{display: 'none'}} 
                                />
                                </label>
                            </Button>
                            <Button variant="outlined" style={{ border: "1px solid #A5259F", color: "red", marginTop: "10px" }} onClick={handleEditProfile}>EDIT PROFILE</Button>
                        </Box>
                    </Box>
                    <Divider />

                    </Container>
                </Box>
       </> }
        </>
    )
}
export default CoverImg;

const webStyles = {
    landingText: {
        fontSize: "42px",
        fontWeight: 600,
    },
    headSection: {
        display: "flex",
        justifyContent: "space-between"
    },
    headLeft: {
        display: "flex",
        alignItems: "center"
    },
    avatarSection: {
        position: "relative",
        top: "-50px"
    },
    avatar: {
        height: "200px",
        width: "200px",
        borderRadius: "50%"
    },
    headContent: {
        display: "flex",
        alignItems: "end",
        marginLeft: "50px"
    },
    bottomSection: {
        display: "flex",
        justifyContent: "space-between",
        padding: "50px 0px"
    },
    bottomLeft: {
        background: "#fff",
        width: '22%',
        height: "fit-content"
    },
    cameraPhoto: {
        position: 'absolute',
        top: '135px',
        right: '66px'
    },
    card: {
        // padding: theme.spacing(1),
        marginTop: '20px',
        zIndex: 1,
        borderRadius: '5px',
        width: '80%',
        marginLeft: '10%',
    },
}
// Customizable Area End
