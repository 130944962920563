import React from 'react';
import { Box,Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import moment from "moment";

export default class ScreeningandAwards  extends React.Component{
    
    render () {
    return(
        <>
        <Box>
            <Paper elevation={2}>
                <Grid container spacing={2}>
               {
                //@ts-ignore
               this.props.ProjectInfo?.attributes?.screening_and_awards && this.props?.ProjectInfo?.attributes?.screening_and_awards.map((element, key) => {return(
               <>
                <Grid xs={12} sm={6} style={{padding:"10px"}}>
                    
                    <div style={{display:"flex",alignItems:"center"}}>
                        <Typography variant="h4" style={{fontWeight:500,minWidth:"200px"}}>Event:</Typography>
                        <Typography>{element.event == null ? "" : element.event}</Typography>
                    </div> 
                    <div style={{display:"flex",alignItems:"center"}}>
                        <Typography variant="h4" style={{fontWeight:500,minWidth:"200px"}}>City:</Typography>
                        <Typography>{element.city == null ? "" : element.city}</Typography>
                    </div> 
                    <div style={{display:"flex",alignItems:"center"}}>
                        <Typography variant="h4" style={{fontWeight:500,minWidth:"200px"}}>Country:</Typography>
                        <Typography>{element.country == null ? "" : element.country}</Typography>
                    </div>
                    
                </Grid>
                <Grid xs={12}  sm={6} style={{padding:"10px"}}>
                    <div style={{display:"flex",alignItems:"center"}}>
                        <Typography variant="h4" style={{fontWeight:500,minWidth:"200px"}}>Premiere:</Typography>
                        <Typography>{element.premiere == null ? "" : element.premiere}</Typography>
                    </div>
                    <div style={{display:"flex",alignItems:"center"}}>
                        <Typography variant="h4" style={{fontWeight:500,minWidth:"200px"}}>Screening Date:</Typography>
                        <Typography>{element.screeing_date == null ? "" : moment(element.screeing_date).format("MMM Do YY")}</Typography>
                    </div> 
                </Grid>
                {
                    //@ts-ignore
                key+1 != this.props?.ProjectInfo?.attributes?.screening_and_awards?.length && <hr></hr>}
               </>
                 )})}  
                </Grid>
            </Paper>
        </Box>
        </>
    )
}
}