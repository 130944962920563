// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  Modal,
  TextField,
  makeStyles,
} from "@material-ui/core";

interface S {
  fileOpen: boolean;
  articleOpen: boolean;
  linkOpen: boolean;
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const sideLinks = ({
  handleSubmitLinks,
  handleSubmitNews,
  handleFileAttachLinks,
  accountData
}: any) => {
  const [Fileopen, setFileOpen] = React.useState(false);
  const [articleopen, setArticleOpen] = React.useState(false);
  const [linkOpen, setLinkOpen] = React.useState(false);
  const [btnDisabled, setBtnDisabled] = React.useState(false) 
  const [isURLValid, setIsURLValid] = React.useState(true)

  const classes = useStyles();

  const [newsreview, setNewsReview] = React.useState({
    title: "",
    publication: "",
    url: "",
  });
  const [fileattach, setFileAttach] = React.useState({
    name: "",
    attachment: "",
  });
  const [links, setLinks] = React.useState({ title: "", url: "" });

  const [fileNameError, setFileNameError] = React.useState(false)
  const [fileAttachError, setFileAttachError] = React.useState(false)

  const [newsreviewTitleError, setnewsreviewTitleError] = React.useState(false)
  const [newsreviewPubError, setnewsreviewPubError] = React.useState(false)
  const [newsreviewUrlError, setnewsreviewUrlError] = React.useState(false)

  const [linksTitleError, setlinksTitleError] = React.useState(false)
  const [linksUrlError, setlinksUrlError] = React.useState(false)

  const handleFileOpen = () => setFileOpen(true);
  const handleFileClose = () => setFileOpen(false);

  const handleArticleOpen = () => setArticleOpen(true);
  const handleArticleClose = () => setArticleOpen(false);

  const handleLinkOpen = () => setLinkOpen(true);
  const handleLinkClose = () => setLinkOpen(false);

    React.useEffect (() => {
      if(!newsreview.title){
          setnewsreviewTitleError(true)
      }else {
          setnewsreviewTitleError(false)
      }
      if(!newsreview.publication){
          setnewsreviewPubError(true)
      } else {
          setnewsreviewPubError(false)
      }
      if(!newsreview.url){
          setnewsreviewUrlError(true)
      } else {
          setnewsreviewUrlError(false)
      }
  }, [newsreview])

  React.useEffect (() => {
      setnewsreviewTitleError(false)
      setnewsreviewPubError(false)
      setnewsreviewUrlError(false)
  }, [])

  const handleArticleChange = (e: any) => {
    if(e.target.name === "url"){
      setBtnDisabled(!isValidURL(e.target.value))
      setIsURLValid(isValidURL(e.target.value))
    }
    setNewsReview({
      ...newsreview,
      [e.target.name]: e.target.value,
    });
  };

  const handleArticleSave = (e: any) => {
    if(!newsreview.title){
        setnewsreviewTitleError(true)
    }
    if(!newsreview.publication){
        setnewsreviewPubError(true)
    }
    if(!newsreview.url){
        setnewsreviewUrlError(true)
    }
    if(newsreview.title && newsreview.publication && newsreview.url) {
      e.preventDefault();
      if(newsreview.url){
        setBtnDisabled(!isValidURL(newsreview.url))
        setIsURLValid(isValidURL(newsreview.url))
      }
      isURLValid && handleSubmitNews(newsreview);
      isURLValid && setArticleOpen(false);
    }
  };

  React.useEffect (() => {
        if(!fileattach.attachment){
            setFileAttachError(true)
        }else {
            setFileAttachError(false)
        }
        if(!fileattach.name){
            setFileNameError(true)
        } else {
            setFileNameError(false)
        }
    }, [fileattach])
    React.useEffect (() => {
        setFileAttachError(false)
        setFileNameError(false)
    }, [])

  const handleFileChange = (e: any) => {
    if (e.target.name == "name") {
      setFileAttach({
        ...fileattach,
        [e.target.name]: e.target.value,
      });
    } else {
      setFileAttach({
        ...fileattach,
        attachment: e.target.files[0],
      });
    }
  };

  const handleFileUpload = () => {
    if(!fileattach.attachment){
        setFileAttachError(true)
    }
    if(!fileattach.name){
        setFileNameError(true)
    }
    if(fileattach.attachment && fileattach.name) {
      handleFileAttachLinks(fileattach);
      setFileOpen(false);
    } 
  };

  React.useEffect (() => {
        if(!links.title){
            setlinksTitleError(true)
        }else {
            setlinksTitleError(false)
        }
        if(!links.url){
            setlinksUrlError(true)
        } else {
            setlinksUrlError(false)
        }
    }, [links])

    React.useEffect (() => {
        setlinksTitleError(false)
        setlinksUrlError(false)
    }, [])

  const handleLinkChange = (e: any) => {
    if(e.target.name === "url"){
      setBtnDisabled(!isValidURL(e.target.value))
      setIsURLValid(isValidURL(e.target.value))
    }
    setLinks({
      ...links,
      [e.target.name]: e.target.value,
    });
  };

  const handleLinkUpload = () => {
    if(!links.title){
        setlinksTitleError(true)
    }
    if(!links.url){
        setlinksUrlError(true)
    }
    if(links.title && links.url) {
      if(newsreview.url){
        setBtnDisabled(!isValidURL(newsreview.url))
        setIsURLValid(isValidURL(newsreview.url))
      }
      isURLValid && handleSubmitLinks(links);
      isURLValid && setLinkOpen(false);
    }
  };

  const isValidURL = (string: any) => {
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
  };
  return (
    <>
      <>
        <Typography variant={"body2"} className={classes.fontBold}>
          News & Reviews
        </Typography>
         {accountData && accountData.news[0] ?
        <div className={classes.newsDataSection}>
          <a href={accountData.news[0] && accountData.news[0].url ? accountData.news[0].url : "#"}>{accountData.news[0] && accountData.news[0].title ? accountData.news[0].title : ""}</a>
          <div>{accountData.news[0] && accountData.news[0].publication ? accountData.news[0].publication : "" }</div>
        </div>
        : 
        <Paper elevation={2} className={classes.newsLinkSection}>
          <Typography
            color="primary"
            variant={"body2"}
            onClick={handleArticleOpen}
            style={{ color: "#4080EA" }}
          >
            +Add Article
          </Typography>
          <Modal
            open={articleopen}
            onClose={handleArticleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Paper
                elevation={2}
                className={classes.notUploadedVideoSectionPaper}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    variant="body2"
                    className={classes.notUploadedVideoText}
                  >
                    Add Article / Review
                  </Typography>
                  <div
                    onClick={handleArticleClose}
                    style={{
                      cursor: "pointer",
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                  >
                    X
                  </div>
                </div>
                <Typography
                  variant="body2"
                  className={classes.notUploadedVideoTextURL}
                >
                  Title
                </Typography>
                <TextField
                  fullWidth
                  name="title"
                  placeholder="Example: New director from.."
                  value={newsreview.title || ""}
                  onChange={handleArticleChange}
                  InputProps={{
                    classes: {
                      input: classes.textFiled,
                    },
                  }}
                />
                {newsreviewTitleError && <div style={{color: "red", fontSize:"12px"}}>Required</div>}
                <Typography
                  variant="body2"
                  className={classes.notUploadedVideoTextURL}
                >
                  Publication
                </Typography>
                <TextField
                  fullWidth
                  name="publication"
                  placeholder="Example: daily varient"
                  value={newsreview.publication || ""}
                  onChange={handleArticleChange}
                  InputProps={{
                    classes: {
                      input: classes.textFiled,
                    },
                  }}
                />
                 {newsreviewPubError && <div style={{color: "red", fontSize:"12px"}}>Required</div>}
                <Typography
                  variant="body2"
                  className={classes.notUploadedVideoTextURL}
                >
                  Link
                </Typography>
                <TextField
                  fullWidth
                  name="url"
                  placeholder="Example: www.freeforyou.com"
                  onChange={handleArticleChange}
                  value={newsreview.url || ""}
                  InputProps={{
                    classes: {
                      input: classes.textFiled,
                    },
                  }}
                />
               {newsreviewUrlError ? <div style={{color: "red", fontSize:"12px"}}>Required</div> : !isURLValid ?
                 <div style={{color: "red", fontSize:"12px" }}>Please enter valid URL</div> : " " }
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    variant="contained"
                    style={{
                      background: "#fff",
                      color: "#A5259F",
                      margin: "20px 10px 0 10px",
                    }}
                    onClick={handleArticleClose}
                  >
                    Cancle
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      background: "#A5259F",
                      color: "#fff",
                      margin: "20px 10px 0 10px",
                    }}
                    disabled={btnDisabled}
                    onClick={handleArticleSave}
                  >
                    Save
                  </Button>
                </div>
              </Paper>
            </Box>
          </Modal>
        </Paper>
        }
      </>
      <>
        <Typography variant={"body2"} className={classes.fontBold}>
          Files & Attachment
        </Typography>
        {accountData && accountData.profile_attachments.data[0] ?
        <div className={classes.newsDataSection}>
         <div>{accountData.profile_attachments.data[0] && accountData.profile_attachments.data[0].attributes.name ? accountData.profile_attachments.data[0].attributes.name : "" }</div>
          <a href={accountData.profile_attachments.data[0] && accountData.profile_attachments.data[0].attributes.attachment ? accountData.profile_attachments.data[0].attributes.attachment : "#"} download>Download</a>
        </div>
        :
        <Paper elevation={2} className={classes.newsLinkSection}>
          <Typography
            color="primary"
            variant={"body2"}
            onClick={handleFileOpen}
            style={{ color: "#4080EA" }}
          >
            +Add File/s
          </Typography>
          <Modal
            open={Fileopen}
            onClose={handleFileClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Paper
                elevation={2}
                className={classes.notUploadedVideoSectionPaper}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    variant="body2"
                    className={classes.notUploadedVideoText}
                  >
                    Add File
                  </Typography>
                  <div
                    onClick={handleFileClose}
                    style={{
                      cursor: "pointer",
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                  >
                    X
                  </div>
                </div>
                <Typography variant="caption">
                  Here you can add downloadable files such as a PDF press kit or
                  student ID.
                </Typography>
                <Typography
                  variant="body2"
                  className={classes.notUploadedVideoTextURL}
                >
                  Name
                </Typography>
                <TextField
                  fullWidth
                  name="name"
                  placeholder="Example: Student press kit"
                  onChange={handleFileChange}
                  value={fileattach.name || ""}
                  InputProps={{
                    classes: {
                      input: classes.textFiled,
                    },
                  }}
                />
                {fileNameError && <div style={{color: "red", fontSize: "12px"}}>Required</div>}
                <Typography
                  variant="body2"
                  className={classes.notUploadedVideoTextPassword}
                >
                  Upload
                </Typography>
                <input
                  type="file"
                  placeholder="choose file"
                  name="attachment"
                  onChange={handleFileChange}
                  // value={fileattach.attachment || ""}
                  className={classes.notUploadedVideoTextURL}
                />
                {fileAttachError && <div style={{color: "red", fontSize: "12px"}}>Required</div>}
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    variant="contained"
                    style={{
                      background: "#fff",
                      color: "#A5259F",
                      margin: "20px 10px 0 10px",
                    }}
                    onClick={handleFileClose}
                  >
                    Cancle
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      background: "#A5259F",
                      color: "#fff",
                      margin: "20px 10px 0 10px",
                    }}
                    onClick={handleFileUpload}
                  >
                    Upload
                  </Button>
                </div>
              </Paper>
            </Box>
          </Modal>
        </Paper>
        }
      </>
      <>
        <Typography variant={"body2"} className={classes.fontBold}>
          Links
        </Typography>
        {accountData && accountData.links[0] ?
        <div className={classes.newsDataSection}>
          <a href={accountData.links[0] && accountData.links[0].url ? accountData.links[0].url : ""}>{accountData.links[0] && accountData.links[0].title ? accountData.links[0].title : "Links title"}</a>
        </div>
        : 
        <Paper elevation={2} className={classes.newsLinkSection}>
          <Typography
            color="primary"
            variant={"body2"}
            onClick={handleLinkOpen}
            style={{ color: "#4080EA" }}
          >
            +Add Link/s
          </Typography>
          <Modal
            open={linkOpen}
            onClose={handleLinkClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Paper
                elevation={2}
                className={classes.notUploadedVideoSectionPaper}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    variant="body2"
                    className={classes.notUploadedVideoText}
                  >
                    Add Link/s
                  </Typography>
                  <div
                    onClick={handleLinkClose}
                    style={{
                      cursor: "pointer",
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                  >
                    X
                  </div>
                </div>
                <Typography
                  variant="body2"
                  className={classes.notUploadedVideoTextURL}
                >
                  Name
                </Typography>
                <TextField
                  fullWidth
                  name="title"
                  placeholder="Example: Student press kit"
                  onChange={handleLinkChange}
                  value={links.title || ""}
                  InputProps={{
                    classes: {
                      input: classes.textFiled,
                    },
                  }}
                />
                {linksTitleError && <div style={{color: "red", fontSize:"12px"}}>Required</div>}
                <Typography
                  variant="body2"
                  className={classes.notUploadedVideoTextPassword}
                >
                  Link
                </Typography>
                <TextField
                  fullWidth
                  name="url"
                  placeholder="Example: www.freeforyou.com"
                  onChange={handleLinkChange}
                  value={links.url || ""}
                  InputProps={{
                    classes: {
                      input: classes.textFiled,
                    },
                  }}
                />
                {linksUrlError ? <div style={{color: "red", fontSize:"12px"}}>Required</div> : !isURLValid ?
                 <div style={{color: "red", fontSize:"12px" }}>Please enter valid URL</div> : " " }
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    variant="contained"
                    style={{
                      background: "#fff",
                      color: "#A5259F",
                      margin: "20px 10px 0 10px",
                    }}
                    onClick={handleLinkClose}
                  >
                    Cancle
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      background: "#A5259F",
                      color: "#fff",
                      margin: "20px 10px 0 10px",
                    }}
                    disabled={btnDisabled}
                    onClick={handleLinkUpload}
                  >
                    Save
                  </Button>
                </div>
              </Paper>
            </Box>
          </Modal>
        </Paper>
        }
      </>
    </>
  );
};

export default sideLinks;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  addTrailerSection: {
    textAlign: "center",
    background: "#fff",
    height: "300px",
    width: "100%",
    borderRadius: "12px",
    marginTop: "15px",
  },
  addShortSection: {
    height: "200px",
    width: "100%",
    background: "#fff",
    borderRadius: "12px",
    display: "flex",
  },
  filmography: {
    height: "130px",
    width: "100%",
    background: "#fff",
    borderRadius: "5px",
    display: "flex",
    marginTop: "20px",
  },
  addPersonalDetail: {
    background: "#fff",
    color: "#A5259F",
    width: "100%",
    height: "55px",
  },
  addBiography: {
    background: "#A5259F",
    color: "#fff",
    marginTop: "30px",
    width: "100%",
    height: "55px",
  },
  newsLinkMainSection: {
    marginTop: "16px",
    width: "100%",
  },
  fontBold: {
    fontWeight: "bold",
    padding: "25px 5px 5px 5px",
    background: "#EBECED",
  },
  newsLinkSection: {
    height: "55px",
    textAlign: "center",
    paddingTop: "20px",
    cursor: "pointer",
  },
  newsDataSection: {
    paddingLeft: "5px",
    cursor: "pointer",
    background: "#EBECED"
  }, 
  notUploadedVideoSectionPaper: {
    padding: "20px",
  },
  notUploadedVideoText: {
    fontWeight: "bold",
  },
  notUploadedVideoTextURL: {
    marginTop: "20px",
    fontWeight: "bold",
  },
  notUploadedVideoTextPassword: {
    marginTop: "25px",
    fontWeight: "bold",
  },
  padding10px: {
    paddingLeft: "10px",
  },
  textFiled: {
    fontSize: "13px",
    padding: "10px",
  },
  addPhotos: {
    width: "100%",
    paddingTop: "50px",
    paddingRight: "160px",
    paddingLeft: "160px",
    paddingBottom: "160px",
    background: "#ebeced",
  },
}));
// Customizable Area End
