import React from "react";
import {
  Box,
  Button,
  CardContent,
  Card,
  List,
  ListItem,
} from "@material-ui/core";

const RightCard = () => {
  return (
    <>
      <Box style={{ backgroundColor: "#fff", marginBottom: "20px" }}>
        <div style={webStyles.cardTopLink}>
          <div style={webStyles.headerTop}>Upcoming</div>
          <a href="#" style={webStyles.cardLink}>
            View all
          </a>
        </div>
        <Card style={webStyles.card}>
          <CardContent>
            <div style={webStyles.heading}>You have no dates approaching</div>
            <a href="#">Create a new Call for Entries</a>
          </CardContent>
        </Card>
      </Box>

      <Box style={{ marginBottom: "20px" }}>
        <div style={webStyles.cardTopLink}>
          <div style={webStyles.headerTop}>Submission</div>
          <a href="#" style={webStyles.cardLink}>
            View all
          </a>
        </div>
        <Box sx={webStyles.bottomLeft}>
          <List disablePadding style={webStyles.listItem}>
            <ListItem>
              <div style={webStyles.submissionsLeft}>Last 7 days</div>
              <div style={webStyles.submissionsRight}>0</div>
            </ListItem>
            <ListItem>
              <div style={webStyles.submissionsLeft}>Last 30 days</div>
              <div style={webStyles.submissionsRight}>0</div>
            </ListItem>
            <ListItem>
              <div style={webStyles.submissionsLeft}>Undecided</div>
              <div style={webStyles.submissionsRight}>0</div>
            </ListItem>
            <ListItem>
              <div style={webStyles.submissionsLeft}>Total this season</div>
              <div style={webStyles.submissionsRight}>0</div>
            </ListItem>
          </List>
          <Button style={webStyles.submissionsButton}>SUBMISSIONS</Button>
        </Box>
      </Box>
    </>
  );
};
export default RightCard;

const webStyles = {
  card: {
    borderRadius: "12px",
    minWidth: 256,
    textAlign: "left" as const,
    width: "100%",
  },
  avatar: {
    width: 60,
    height: 60,
    margin: "auto",
  },
  heading: {
    fontSize: 18,
    fontWeight: 700,
    letterSpacing: "0.5px",
    marginTop: 0,
    marginBottom: 8,
  },
  subheader: {
    fontSize: 14,
    color: "#ccc",
    marginBottom: "0.875em",
  },
  statLabel: {
    fontSize: 12,
    color: "#ccc",
    fontWeight: 500,
    margin: 0,
  },
  listItem: {},
  statValue: {
    fontSize: 20,
    fontWeight: 700,
    marginBottom: 4,
    letterSpacing: "1px",
  },
  landingText: {
    fontSize: "42px",
    fontWeight: 600,
  },
  headSection: {
    display: "flex",
    justifyContent: "space-between",
  },
  headLeft: {
    display: "flex",
    alignItems: "center",
  },
  avatarSection: {
    position: "relative",
    top: "-50px",
  },
  headContent: {
    display: "flex",
    alignItems: "end",
    marginLeft: "50px",
  },
  bottomSection: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 0px",
  },
  bottomLeft: {
    background: "#fff",
    width: "100%",
    height: "fit-content",
    padding: "0 20px 20px 20px",
  },
  headerTop: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#2B282E",
    paddingBottom: "10px",
  },
  cardLink: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#ccc",
    cursor: "poniter",
  },
  cardTopLink: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F2F3F5",
  },
  submissionsLeft: {
    justifyContent: "start",
    width: "100%",
    fontSize: "16px",
    fontWeight: 600,
    padding: "10px 0"
  },
  submissionsRight: {
    fontSize: "16px",
    fontWeight: 600,
    padding: "10px 0"
  },
  submissionsButton: {
    backgroundColor: "#BC2BB5",
    color: "#fff",
    width: "100%",
    padding: "15px",
  },
};
