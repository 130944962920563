import React, { useEffect } from 'react';
import { makeStyles,withStyles} from '@material-ui/core/styles';
import { Box,Button,Typography,Modal,Paper,Grid,FormLabel,CircularProgress } from '@material-ui/core';
import CoverImg from '../../../components/src/CoverImage.web';
import Navigations from './Navigation.web';
import Footer from '../../user-profile-basic/src/footer.web';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor:"#F2F3F5"
        // padding: theme.spacing(2),
    },
    tab: {
        padding: theme.spacing(3),
        boxShadow:"none"
    },
}));
    
const MyFestival = () => {
    const classes = useStyles();

    return(
        <Box className={classes.root}>
            <CoverImg navigation={undefined} id={''} /> 
            <Navigations />
            <Footer />
        </Box>
    )
}
export default MyFestival;