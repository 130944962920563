import React from 'react';
import { Box,Container,FormControl,FormControlLabel,Radio,RadioGroup,TextField,Switch,Table,TableBody,TableCell,TableContainer,TableHead,TableRow, Checkbox, Button,Typography,Paper,Grid,FormLabel,CircularProgress } from '@material-ui/core';
import { makeStyles,withStyles} from '@material-ui/core/styles';
import { Formik } from "formik";
import { stepTwoSchema, formInitialValues } from "./validationSchemas"
import DiscountwaiverController, {
    Props
  } from "./DiscountwaiverController";
  import {facebookIcon, twitterIcon,DeleteIcon} from './assets'
  import "./style.css";

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#52d869',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
    // @ts-ignore
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

class DiscountWaiver  extends DiscountwaiverController{
    constructor(props:any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End   
      }

      useStyle = makeStyles((theme:any) => ({
        root: {
            flexGrow: 1,
            backgroundColor:"#F2F3F5",
            "& .MuiInputBase-input": {
                height: "1.5em",
                backgroundColor:"#fff"
              }
            // padding: theme.spacing(2),
        },
        paperstyle: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
              margin: theme.spacing(1),
              width: "100%",
              height: "100%",
            },
        },
        inputClass: {
            "& .Mui-focused:after": {
              border: "1px solid green",
            },
          },
          icon: {
            color: "#a5259f",
          },
          selectbox: {
            "& .MuiInputBase-input": {
              height: "1.5em",
            }
          },
    }));

    render() {
        const { radiovalue,waiverData,discountForm,tabelForm,loader,discount_type, code,label,discount_value,discount_per,max_uses,valid_from,valid_to} = this.state;
        const classes = this.useStyle    
    return(
        <Formik
        enableReinitialize
        initialValues={formInitialValues}
        // validationSchema={stepOneSchema}
        onSubmit={(values, actions) => {
          this.handleSubmitEvent(values,radiovalue)
        }}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {props => (
        <form onSubmit={props.handleSubmit} style={{width:"100%"}}>
        <Container style={{paddingLeft:"30px"}}>
           <Box style={{padding:"0px 0px 0px 7px"}}>
                <Typography variant="h4" style={{fontWeight:800, fontSize:"0.875rem",marginBottom:"10px"}}>Create a Discount or Waiver</Typography>
            </Box>
{console.log('tabelForm==>', tabelForm)}
           {tabelForm ? 
            <Box style={{flexWrap: 'wrap'}}>  
                <TableContainer component={Paper}>
                <Table style={webStyles.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                    <TableCell><Checkbox
                        // checked={checked}
                        // onChange={handleChange}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></TableCell>
                    <TableCell align="left">Code</TableCell>
                    <TableCell align="left">Valid Through</TableCell>
                    <TableCell align="left">Public </TableCell>
                    <TableCell align="left">Share</TableCell>
                    <TableCell align="left">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody> 
                    {/* {rows.map((row) => ( */}
                    <TableRow>
                        <TableCell><Checkbox
                            // checked={checked}
                            // onChange={handleChange}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        /></TableCell>
                        <TableCell component="th" scope="row">
                            <Box>
                                "hello"
                            </Box>   
                            <Box>
                                "Code":
                            </Box>
                            <Box>
                                "hello"
                            </Box>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Box>
                                Jun 30, 2022 - Jul 25, 2022
                            </Box>
                            <Box>
                                Uses: 0 / Unlimited
                            </Box>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Box>
                            <FormControlLabel
                                    // @ts-ignore
                                    control={<IOSSwitch checked={this.state.checkedswitch} onChange={this.handleSwitchChange} name="checkedswitch" />}
                                    label=""
                                />
                            </Box>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Box style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                <img src={facebookIcon} style={{height:"30px", width:"30px"}}></img>
                                <img src={twitterIcon} style={{height:"30px", width:"30px"}}></img>
                            </Box>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            <Box>
                                <img src={DeleteIcon} style={{height:"30px", width:"30px"}}></img>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableBody>
                </Table>
                </TableContainer>
                <Box style={{margin:"8px 0px 0px 0px"}}>
                    <Button color="primary" onClick={this.handleDiscountForm}>Create New Code</Button>
                </Box>
            </Box>
            :
            <Box>
                <Paper style={{padding:"15px 15px 30px 15px"}}>
                    <FormControl component="fieldset" style={{width:"100%"}}>
                        <FormLabel component="legend" style={{fontWeight:700}}>Code Type</FormLabel>
                        <RadioGroup 
                            aria-label="discount_type" 
                            name="discount_type" 
                            onChange={this.handleSelect}
                            // onBlur={props.handleBlur}
                            value={this.state.radiovalue}
                            >
                            <div style={{display:"flex", justifyContent:"space-between"}}>
                            <div>
                                <FormControlLabel value="flat_discount" control={<Radio size="small" color="primary"/>} label="Discount" />
                                <Typography>Allows for discounted entry fees.</Typography>
                            </div>
                            <div>
                                <FormControlLabel value="entry_fee_waiver" control={<Radio size="small" color="primary"/>} label="Entry Fee Waiver" />
                                <Typography>Allows for free entry to your festival.</Typography>
                            </div>
                            <div>
                                <FormControlLabel value="deadline_waiver" control={<Radio size="small" color="primary"/>} label="Deadline Waiver" />
                                <Typography>Allows for late entry after your final deadline.</Typography>
                                <Typography>You can edit the duration here.</Typography>
                            </div>
                            </div>
                        </RadioGroup>
                    </FormControl>

                    <Box style={{marginTop:"20px"}}>
                        <Grid container spacing={6}>
                            <Grid item xs={6}>
                                <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                    <FormLabel component="legend" style={{fontWeight:700}}>Code <span className="astrick">*</span></FormLabel>
                                    <TextField  
                                        id="outlined-disabled"
                                        placeholder="Eg. #857648"
                                        style={{height: "1.5em"}}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.code}
                                        //@ts-ignore
                                        InputProps={{ className: classes.root }}
                                        name="code"
                                        // value={initialValues.facebook_link}
                                        //@ts-ignore  
                                        className={classes.inputClass}
                                    />
                                </FormControl>
                                {console.log("props.values.code",props.values.code)}
                                <Typography>Use only alphanumeric characters.</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl  style={{ padding: '20px 0px 0px', width: '100%'}}>
                                    <FormLabel component="legend" style={{fontWeight:700}}>Code Label <span>(Optional)</span></FormLabel>
                                    <TextField
                                        id="standard-select-currency-native"
                                        placeholder="Enter a label"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.label}
                                        //@ts-ignore
                                        InputProps={{ className: classes.root }}
                                        name="label"
                                        // value={initialValues.instagram_link}  
                                        //@ts-ignore
                                        className={classes.inputClass}
                                        />
                                </FormControl>
                                <Typography>For internal labeling purposes only.</Typography>
                            </Grid>
                        </Grid>

                        {radiovalue == "flat_discount" ?
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                    <FormLabel component="legend" style={{fontWeight:700}}>Discount Amount</FormLabel>
                                    <TextField
                                        type="number"  
                                        id="outlined-disabled"
                                        placeholder="0.00"
                                        style={{height: "1.5em"}}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.discount_value}
                                        //@ts-ignore
                                        InputProps={{ className: classes.root }}
                                        name="discount_value"
                                        // value={initialValues.facebook_link}
                                        //@ts-ignore  
                                        className={webStyles.inputClass}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{ padding: '50px 0px 0px 25px'}}>or</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl  style={{ padding: '50px 0px', width: '100%'}}>
                                    <TextField
                                        type="number"  
                                        id="outlined-disabled"
                                        placeholder="0"
                                        style={{height: "1.5em"}}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.discount_per}
                                        //@ts-ignore
                                        // InputProps={{ className: classes.root }}
                                        name="discount_per"
                                        // value={initialValues.facebook_link}
                                        //@ts-ignore  
                                        className={webStyles.inputClass}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{ padding: '50px 0px'}}>% off entry fee</Typography>
                            </Grid>
                        </Grid>
                        :
                        ""
                        }
                        
                        <Grid container spacing={6}>
                            <Grid item xs={6}>
                                <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                    <FormLabel component="legend" style={{fontWeight:700}}>Maximum Uses <span className="astrick"> *</span></FormLabel>
                                    <TextField
                                        id="standard-select-currency-native"
                                        select
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.max_uses}
                                        // InputProps={{ className: classes.root }}
                                        SelectProps={{
                                        // classes: { icon: classes.icon },
                                        native:true
                                        }}
                                        variant="standard"
                                        name="max_uses"
                                    // value={gender}
                                  >
                                      <option value={""}>select from option</option>
                                      <option value={'unlimited'}>Unlimited</option>
                                      <option value={'limited'}>limited</option>
                                  </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                    <FormLabel component="legend" style={{fontWeight:700}}>Start Date</FormLabel>
                                    <TextField
                                        id="standard-select-currency-native"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.valid_from}
                                        //@ts-ignore
                                        InputProps={{ className: classes.root }}
                                        name="valid_from"
                                        type="date"
                                        placeholder="DD / MM/ YYYY"
                                        // value={initialValues.instagram_link}  
                                        //@ts-ignore
                                        className={webStyles.inputClass}
                                        />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl  style={{ padding: '20px 0px', width: '100%'}}>
                                    <FormLabel component="legend" style={{fontWeight:700}}>End Date</FormLabel>
                                    <TextField
                                        id="standard-select-currency-native"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.valid_to}
                                        //@ts-ignore
                                        InputProps={{ className: classes.root }}
                                        name="valid_to"
                                        type="date"
                                        placeholder="Eg. Did you attend film school?"
                                        // value={initialValues.instagram_link}  
                                        //@ts-ignore
                                        className={webStyles.inputClass}
                                        />
                                </FormControl>
                            </Grid>
                        </Grid>  
                    </Box>
                </Paper>
                <Box style={{margin:"30px 0px 0px 0px"}}>
                    <Button color="primary" type="submit">SAVE CODE</Button>
                </Box>
            </Box>
            }
        </Container>
         </form>
         )}
        </Formik>
    )
}
}

export default DiscountWaiver;

const webStyles = {
    table: {
        minWidth: 650
      }
  };