// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import { ToastContainer,toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Favourites from "../../blocks/Favourites/src/Favourites";
import Maps from "../../blocks/Maps/src/Maps";
import EmailLists from "../../blocks/EmailLists/src/EmailLists";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import ActivityLog from "../../blocks/ActivityLog/src/ActivityLog";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import LogoDesign2 from "../../blocks/LogoDesign2/src/LogoDesign2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import AdvancedSearch from "../../blocks/AdvancedSearch/src/AdvancedSearch";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import ContentFlag from "../../blocks/ContentFlag/src/ContentFlag";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import Payments from "../../blocks/Payments/src/Payments";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import WorldMapInterface2 from "../../blocks/WorldMapInterface2/src/WorldMapInterface2";
import CustomForm2 from "../../blocks/CustomForm2/src/CustomForm2";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import CollectTransactionFees from "../../blocks/CollectTransactionFees/src/CollectTransactionFees";
import PaymentAdmin from "../../blocks/PaymentAdmin/src/PaymentAdmin";
import DownloadableProducts2 from "../../blocks/DownloadableProducts2/src/DownloadableProducts2";
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";
import DiscountsOffers from "../../blocks/DiscountsOffers/src/DiscountsOffers";
import PromoCodes from "../../blocks/PromoCodes/src/PromoCodes";
import Settings2 from "../../blocks/Settings2/src/Settings2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Couponcodegenerator from "../../blocks/couponcodegenerator/src/Couponcodegenerator";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import LandingPage2 from "../../blocks/LandingPage2/src/LandingPage2";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import Sorting from "../../blocks/sorting/src/Sorting";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import UniversalLinks from "../../blocks/UniversalLinks/src/UniversalLinks";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import MerchantDashboard2 from "../../blocks/MerchantDashboard2/src/MerchantDashboard2";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import DataImportexportcsv from "../../blocks/DataImportexportcsv/src/DataImportexportcsv";
import OrderDetailView from "../../blocks/OrderDetailView/src/OrderDetailView";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Notifications from "../../blocks/Notifications/src/Notifications";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Reviews from "../../blocks/Reviews/src/Reviews";
import Location4 from "../../blocks/Location4/src/Location4";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import EventRegistration from "../../blocks/EventRegistration/src/EventRegistration";
import AdHocReporting from "../../blocks/AdHocReporting/src/AdHocReporting";
import Events2 from "../../blocks/Events2/src/Events2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PromoteContent from "../../blocks/PromoteContent/src/PromoteContent";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import VimeoEmbedding2 from "../../blocks/VimeoEmbedding2/src/VimeoEmbedding2";
import ProductDescription from "../../blocks/ProductDescription/src/ProductDescription";
import CreateEmail from "../../blocks/CreateEmail/src/CreateEmail";
import ApiIntegration8 from "../../blocks/ApiIntegration8/src/ApiIntegration8";
import BrowseFestival from "../../blocks/Events2/src/BrowseFestival.web"
import AddProject from "../../blocks/add-project/src/AddProject";

import LaurelEditor from "../../blocks/user-profile-basic/src/LaurelEditor.web";

import MyFestival from "../../blocks/Events2/src/FestivalView.web"
import FestivalView from "../../blocks/Events2/src/FestivalView.web";
import FestivalList from "../../blocks/Events2/src/FestivalList.web"

const routeMap = {
Favourites:{
 component:Favourites,
path:"/MyprojectDetail/:id"},
Maps:{
 component:Maps,
path:"/Maps"},
EmailLists:{
 component:EmailLists,
path:"/EmailLists"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
ActivityLog:{
 component:ActivityLog,
path:"/ActivityLog"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
LogoDesign2:{
 component:LogoDesign2,
path:"/LogoDesign2"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
CustomisedOrderStatus:{
 component:CustomisedOrderStatus,
path:"/CustomisedOrderStatus"},
ContentFlag:{
 component:ContentFlag,
path:"/ContentFlag"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
Payments:{
 component:Payments,
path:"/Payments"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
WorldMapInterface2:{
 component:WorldMapInterface2,
path:"/WorldMapInterface2"},
CustomForm2:{
 component:CustomForm2,
path:"/CustomForm2"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
CollectTransactionFees:{
 component:CollectTransactionFees,
path:"/CollectTransactionFees"},
PaymentAdmin:{
 component:PaymentAdmin,
path:"/PaymentAdmin"},
DownloadableProducts2:{
 component:DownloadableProducts2,
path:"/DownloadableProducts2"},
PhotoLibrary3:{
 component:PhotoLibrary3,
path:"/PhotoLibrary3"},
DiscountsOffers:{
 component:DiscountsOffers,
path:"/DiscountsOffers"},
PromoCodes:{
 component:PromoCodes,
path:"/PromoCodes"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Couponcodegenerator:{
 component:Couponcodegenerator,
path:"/Couponcodegenerator"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
LandingPage2:{
 component:LandingPage2,
path:"/LandingPage2"},
ViewProfile:{
    component:Dashboard,
    path:"/ViewProfile"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},

EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
ReviewAndApproval:{
 component:ReviewAndApproval,
path:"/ReviewAndApproval"},
UniversalLinks:{
 component:UniversalLinks,
path:"/UniversalLinks"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
MerchantDashboard2:{
 component:MerchantDashboard2,
path:"/MerchantDashboard2"},
UserProfileBasicBlockLaurelEditor:{
    component:LaurelEditor,
    path:"/UserProfileBasicBlock/create-laurel",
    exact: true,
},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
 path:"/UserProfileBasicBlock",
 exact: true,
},
MyFestivalView:{
    component:FestivalView,
    exact: true,
    path:"/myFestival/view/:id"},
MyFestivalList:{
    component:FestivalList,
    exact: true,
    path:"/myFestival/list"},
// MyFestivalEdit:{
//   component:EventRegistration,
//   exact: true,
//   path:"/EventRegistration/edit/:id"},
// MyFestivalAdd:{
//   component:EventRegistration,
//   exact: true,
//   path:"/EventRegistration/add/"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
DataImportexportcsv:{
 component:DataImportexportcsv,
path:"/DataImportexportcsv"},
OrderDetailView:{
 component:OrderDetailView,
path:"/OrderDetailView"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
Location4:{
 component:Location4,
path:"/Location4"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
EventRegistration:{
 component:EventRegistration,
path:"/EventRegistration"},
AdHocReporting:{
 component:AdHocReporting,
path:"/AdHocReporting"},
MyFestival:{
  component:Events2,
path:"/myFestival/manage/:id",
exact: true},
Events2:{
 component:Events2,
path:"/Myproject"},

CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
PromoteContent:{
 component:PromoteContent,
path:"/PromoteContent"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
VimeoEmbedding2:{
 component:VimeoEmbedding2,
path:"/VimeoEmbedding2"},
ProductDescription:{
 component:ProductDescription,
path:"/ProductDescription"},
CreateEmail:{
 component:CreateEmail,
path:"/CreateEmail"},
ApiIntegration8:{
 component:ApiIntegration8,
path:"/ApiIntegration8"},
AddProject:{
 component:AddProject,
path:"/AddProject"},
BrowseFestival:{
    component:BrowseFestival,
    path:"/BrowseFestival"},

  Home: {
component:LandingPage2,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

    render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100%' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
        <ToastContainer />
      </View>
    );
  }
}

export default App;
