import * as React from "react";
import { Box, Checkbox, Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const EmailPreferences = ({
  emailPreferencesData,
  updateEmailPreferencesCall,
}: any) => {
  const [emailPreferencesAPIData, setEmailPreferencesAPIData] = React.useState([]);

  React.useEffect(() => {
    setEmailPreferencesAPIData(emailPreferencesData);
  }, [emailPreferencesData]);

  const onCheckBoxClick = (id: number) => {
   
    let updatedList: any = emailPreferencesAPIData.map((item: any) => {
      if (item.id === id) {
        const { attributes } = item;
        const updateCheckbox = {
          ...attributes,
          state: attributes.state === "inactive" ? "active" : "inactive",
        };
        return { ...item, attributes: updateCheckbox };
      }
      return item;
    });
    setEmailPreferencesAPIData(updatedList);
  };

  const handleSaveClick = () => {
    let updatedEmailPrefs: any = emailPreferencesAPIData.map((item: any) => {
        const { attributes, id } = item;
        const { state } = attributes;
        return {
            id: id,
            state: state,
        };
      });
    updateEmailPreferencesCall(updatedEmailPrefs);
  };

  return (
    <Box sx={webStyles.bottomRight}>
      <Box sx={webStyles.formCard}>
        <Box sx={{ bgcolor: "#320b4c", padding: "10px" }}>
          <Typography variant="h4" style={{ color: "#FFF" }}>
            Email Preferences
          </Typography>
        </Box>
        <Box sx={{ padding: "20px" }}>
          {emailPreferencesAPIData.map((emailPreference: any) => {
            return (
              <Box sx={webStyles.itemsBorderBottom} key={emailPreference.id}>
                <CheckboxWithGreenCheck
                  checked={emailPreference.attributes.state === "active"}
                  onChange={() => onCheckBoxClick(emailPreference.id)}
                />
                <Box sx={{ padding: "20px" }}>
                  <Typography variant="h6" style={{ fontWeight: 800 }}>
                    {emailPreference.attributes.title}
                  </Typography>
                  <Typography variant="body1" style={{ color: "#999" }}>
                    {emailPreference.attributes.description}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box>
        <Button
          variant="contained"
          style={{ background: "#A5259F", color: "#fff", marginTop: "10px" }}
          onClick={() => handleSaveClick()}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};
export default EmailPreferences;

const webStyles = {
  main: {
    display: "flex",
    justifyContent: "center",
    height: "100px",
    background: "000",
  },
  bottomRight: {
    display: "flex",
    flexDirection: "column",
    width: "75%",
  },
  formCard: {
    background: "#fff",
    marginBottom: "50px",
  },
  itemsBorderBottom: {
    borderBottom: "1px solid #999",
    display: " flex",
    "&:nth-last-child(1)": {
      borderBottom: "none",
    },
  },
};

const CheckboxWithGreenCheck = withStyles({
  root: {
    "&$checked": {
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
      },
      "& .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "#A82BA2",
        zIndex: -1,
      },
      "&.Mui-checked": {
        color: " #fff",
      },
    },
  },
  checked: {},
})(Checkbox);
