import React from 'react';
import { Box,Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

export default class Distribution extends React.Component{
    render() {
    return(
        <>
        <Box>
            <Paper elevation={2}>
            <Grid xs={6} style={{padding:"10px"}}>
               <div style={{display:"flex",alignItems:"center"}}>
                    <Typography variant="h4" style={{fontWeight:500,minWidth:"200px"}}>Distributor:</Typography>
                    <Typography>{
                        //@ts-ignore
                    this.props.ProjectInfo?.attributes?.distributors?.data[0]?.attributes?.name == null ? "" : this.props.ProjectInfo.attributes.distributors.data[0].attributes.name}</Typography>
                </div> 
            </Grid>
            </Paper>
        </Box>
        </>
    )
}
}