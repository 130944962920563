// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { State, City } from "country-state-city";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  formik?: any;
  handleNextStep?: any;
  handleBack?: any;
  handleLoadingFalse?: any;
  handleLoadingTrue? : any;
  loading?: any;
  listButton? :any;
}

interface S {
  countryOrigin: string;
  venueCountry: string;
  allCities: any;
  venueCities: any;
  allStates: any;
  venueStates: any;
  customRow: any;
  anchorEl: any;
  isReinitailize: boolean;
  festivalData: any;
  submissionData: any;
  festivalID: any;
  seprateAddress: boolean;

  venueId: any;
  openVenue: boolean;
  addBtnVisible: boolean;
  venueError: any;

  subCountry: string;
  allSubStates: any;
  allSubCities: any;
  isDraft: boolean;
  file: any;
}

interface DraggableLocation {
  droppableId: string;
  index: number;
}

interface Combine {
  draggableId: string;
  droppableId: string;
}

interface DragResult {
  reason: 'DROP' | 'CANCEL';
  destination?: DraggableLocation;
  source: DraggableLocation;
  combine?: Combine;
  mode: 'FLUID' | 'SNAP';
  draggableId: number;
}

interface SS {
  id: any;
}

export default class CoverImageController extends BlockComponent<
  Props,
  S,
  SS
> {
  editEventCall: any;
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  getFestivalAPICall: string = '';
  updateEventAPICall: string = ''

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.PostDetailDataMessage)
    ]

    this.state = {
      countryOrigin: "",
      venueCountry: "",
      allCities: new Array(),
      venueCities: new Array(),
      allStates: new Array(),
      venueStates: new Array(),
      customRow: [{
        id: Math.random(), type: "event_vanue",
        attributes: {
          vanue_name: "", online_event: false, event_vanue_order: "", venue_image: "",
          vanue_address: { address: "", country: "", state: "", city: "", postal_code: "" }
        }
      }],
      anchorEl: null,
      isReinitailize: true,
      festivalData: null,
      submissionData: null,
      festivalID: null,
      seprateAddress: false,
      venueId: null,
      openVenue: false,
      addBtnVisible: false,
      venueError: null,

      subCountry: "",
      allSubStates: new Array(),
      allSubCities: new Array(),
      isDraft: true,
      file: null
    };
    // @ts-ignore
    this.callRef = React.createRef(); 
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const id = window.location.href.split("/")[5]
    if(id){
      this.setState({ festivalID: id})
    }
    this.getFestivalData()
  }

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.PostDetailDataMessage) === message.id){
      if(message.properties.text === "SAVE_FESTIVAL_FORM_DATA"){
          //@ts-ignore
          this.callRef?.current.click();
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getFestivalAPICall) {
        const { festivalID } = this.state;
        this.props.handleLoadingFalse();
        responseJson && responseJson.data.map((rank: any, i: any, row: any) => {
          if(festivalID){
            if (rank.id === festivalID) {
              this.setState({
                festivalData: rank.attributes.contact_and_vanue_info,
                submissionData: rank.attributes.submission_address,
                isDraft: rank.attributes.is_draft,
                seprateAddress:  rank.attributes.submission_address && rank.attributes.submission_address.address ? true : false,
                customRow: rank.attributes.event_vanues?.data || [{
                  id: Math.random(), type: "event_vanue",
                  attributes: {
                    vanue_name: "", online_event: false, event_vanue_order: "", venue_image: "",
                    vanue_address: { address: "", country: "", state: "", city: "", postal_code: "" }
                  }
                }],
              })
            }
            if (rank.id === festivalID && rank.attributes.contact_and_vanue_info) {
              this.setState({
                countryOrigin: rank.attributes.contact_and_vanue_info.country,
                allStates: State.getStatesOfCountry(rank.attributes.contact_and_vanue_info.country),
                allCities: City.getCitiesOfState(rank.attributes.contact_and_vanue_info.country, rank.attributes.contact_and_vanue_info.state)
              })
            }
            if (rank.id === festivalID && rank.attributes.submission_address) {
              this.setState({
                subCountry: rank.attributes.submission_address.country,
                allSubStates: State.getStatesOfCountry(rank.attributes.submission_address.country),
                allSubCities: City.getCitiesOfState(rank.attributes.submission_address.country, rank.attributes.submission_address.state)
              })
            }
          } else {
            if (i + 1 === row.length) {
              this.setState({
                festivalData: rank.attributes.contact_and_vanue_info,
                submissionData: rank.attributes.submission_address,
                seprateAddress: rank.attributes.submission_address && rank.attributes.submission_address.address ? true : false,
                festivalID: rank.id,
                customRow: rank.attributes.event_vanues?.data || [{
                  id: Math.random(), type: "event_vanue",
                  attributes: {
                    vanue_name: "", online_event: false, event_vanue_order: "", venue_image: "",
                    vanue_address: { address: "", country: "", state: "", city: "", postal_code: "" }
                  }
                }],
              })
            }
            if (rank.attributes.contact_and_vanue_info) {
              this.setState({
                countryOrigin: rank.attributes.contact_and_vanue_info.country,
                allStates: State.getStatesOfCountry(rank.attributes.contact_and_vanue_info.country),
                allCities: City.getCitiesOfState(rank.attributes.contact_and_vanue_info.country, rank.attributes.contact_and_vanue_info.state)
              })
            }
            if (rank.attributes.submission_address) {
              this.setState({
                subCountry: rank.attributes.submission_address.country,
                allSubStates: State.getStatesOfCountry(rank.attributes.submission_address.country),
                allSubCities: City.getCitiesOfState(rank.attributes.submission_address.country, rank.attributes.submission_address.state)
              })
            }
          }
          setTimeout(() => { this.setState({ isReinitailize: false }) }, 2000);
        })
      }

      if (apiRequestCallId === this.updateEventAPICall) {
        this.props.handleLoadingFalse();
        if(responseJson && !responseJson.errors) {
          toast.success("Congratulations, Your changes have been saved")
          this.props.handleNextStep();
          this.setState({
            festivalID: null,
          })
        } else {
          toast.error(`Error, ${responseJson.errors[0]}`)
        }
      }
    }
  }

  handleSubmitEvent = (values: any, customRow?: any) => {
    var formData = new FormData();
    formData.append('event[contact_and_vanue_info_attributes][website]', values.website);
    formData.append("event[contact_and_vanue_info_attributes][email]", values.email);
    formData.append('event[contact_and_vanue_info_attributes][phone]', values.phone);
    formData.append("event[contact_and_vanue_info_attributes][address]", values.address);
    formData.append('event[contact_and_vanue_info_attributes][city]', values.city);
    formData.append("event[contact_and_vanue_info_attributes][state]", values.state);
    formData.append('event[contact_and_vanue_info_attributes][postal_code]', values.postal);
    formData.append("event[contact_and_vanue_info_attributes][country]", values.country);
    formData.append('event[contact_and_vanue_info_attributes][twitter]', values.twitter);
    formData.append("event[contact_and_vanue_info_attributes][facebook]", values.fb);
    formData.append('event[contact_and_vanue_info_attributes][instagram]', values.insta);
    formData.append("event[contact_and_vanue_info_attributes][imdb]", values.imdb);
    formData.append('event[is_draft]',this.state.isDraft ? "true" : "false");

    formData.append("event[contact_and_vanue_info_attributes][seprate_address]", this.state.seprateAddress ? "true": "false");
    formData.append("event[contact_and_vanue_info_attributes][submission_address_attributes][address]", values.subAddress);
    formData.append('event[contact_and_vanue_info_attributes][submission_address_attributes][city]', values.subCity);
    formData.append("event[contact_and_vanue_info_attributes][submission_address_attributes][state]", values.subState);
    formData.append('event[contact_and_vanue_info_attributes][submission_address_attributes][postal_code]', values.subPostal);
    formData.append("event[contact_and_vanue_info_attributes][submission_address_attributes][country]", values.subCountry);

    customRow.forEach((element: any, id: any) => {
      formData.append(`event[contact_and_vanue_info_attributes][event_vanues_attributes]${[id]}[vanue_name]`, element.attributes.vanue_name);
      formData.append(`event[contact_and_vanue_info_attributes][event_vanues_attributes]${[id]}[event_vanue_order]`, id);
      formData.append(`event[contact_and_vanue_info_attributes][event_vanues_attributes]${[id]}[vanue_address_attributes][address]`, element.attributes.vanue_address.address);
      formData.append(`event[contact_and_vanue_info_attributes][event_vanues_attributes]${[id]}[vanue_address_attributes][country]`, element.attributes.vanue_address.country);
      formData.append(`event[contact_and_vanue_info_attributes][event_vanues_attributes]${[id]}[vanue_address_attributes][state]`, element.attributes.vanue_address.state);
      formData.append(`event[contact_and_vanue_info_attributes][event_vanues_attributes]${[id]}[vanue_address_attributes][city]`, element.attributes.vanue_address.city);
      formData.append(`event[contact_and_vanue_info_attributes][event_vanues_attributes]${[id]}[vanue_address_attributes][postal_code]`, element.attributes.vanue_address.postal_code);
      formData.append(`event[contact_and_vanue_info_attributes][event_vanues_attributes]${[id]}[online_event]`, element.attributes.online_event);
      element.attributes.venue_image && formData.append(`event[contact_and_vanue_info_attributes][event_vanues_attributes]${[id]}[venue_image]`, element.attributes.venue_image);
    })

    if(!customRow[0].attributes.vanue_name){
      toast.error(`Error!, Please provide atleast one venue before proceeding to next step`)
    } else {
      this.updateEventDetails(formData, this.state.festivalID)
    }
    this.props.handleLoadingTrue(); 
  }

  updateEventDetails = (UpdatedData: any, festivalID: any) => {
    let url = `${configJSON.endPointApiUpdateEventDetails + festivalID}`

    const headers = {
      "Content-Type": configJSON.APiContentType,
      token: localStorage.getItem('authToken'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateEventAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      UpdatedData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFestivalData(): boolean {
    this.props.handleLoadingTrue(); 
    const header = {
      token: localStorage.getItem('token')
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFestivalAPICall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiFestivalData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  addCustomFile = () => {
    this.setState({
      openVenue: true,
      addBtnVisible: false,
      customRow: [...this.state.customRow, { id: Math.random(), type: "event_vanue", 
      attributes: { vanue_name: "",  online_event: false, event_vanue_order: "", venue_image: "",
        vanue_address: {address: "", country: "", state: "", city: "", postal_code: "" }
      } }]
    });
  };

  deleteRow = (index: any) => {
    const { customRow } = this.state;
    const lastVenue = customRow[customRow.length - 1];
    const values = [...this.state.customRow];
    values.splice(values.findIndex(value => value.id === index), 1);
    if (lastVenue.id === index) {
      this.setState({ addBtnVisible: true })
    }
    this.setState({ customRow: values });
  };

  uploadLogoHandler = (event: any, id:any) => {
      const newInputFields = this.state.customRow.map((item: any) => {
        if (id === item.id) {
          const { attributes } = item
          if(event.target.name === 'venue_image' && event.target.files && event.target.files[0]){
          attributes[event.target.name as any] = event.target.files[0]
         }
        }
        return item;
      })
      this.setState({ customRow: newInputFields });
  }

  handleChangeInput = (id: any, event: any) => {
    const newInputFields = this.state.customRow.map((item: any) => {
      if (id === item.id) {
        const { attributes } = item
        if (event.target.name === 'online_event') {
          attributes[event.target.name as any] = event.target.checked
        } else if(event.target.name === 'vanue_name'){
          attributes[event.target.name as any] = event.target.value
        } else {
          attributes["vanue_address"][event.target.name as any] = event.target.value
        }
      }
      return item;
    })
    this.setState({ customRow: newInputFields });
  }

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };
  handlePopoverOpen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleVenueEdit = (id: number) => {
    const { customRow } = this.state;
    let currentVenueRow = customRow.filter((row: any) => row.id === id)
    const {  vanue_address } = currentVenueRow[0].attributes;
    const { country, state } = vanue_address;
    this.setState({
      venueCountry: country,
      venueStates: State.getStatesOfCountry(country),
      venueCities: City.getCitiesOfState(country, state),
      venueId: id,
      openVenue: true
    })
  }

  handleVenueCollapse = (id: number) => {
    this.setState({ venueId: id, openVenue: false })
  }

  handleVenueSave = (id: number) => {
    const { customRow } = this.state;
    let currentVenueRow = customRow.filter((row: any) => row.id === id)
    const { online_event, vanue_address, vanue_name } = currentVenueRow[0].attributes;
    const { country, state, city, address, postal_code } = vanue_address;

    if (online_event) {
      if (vanue_name) {
        this.setState({ venueId: null, openVenue: false, addBtnVisible: true })
      } else {
        let venueError = `Please provide information related to venue such as:
        ${!vanue_name ? "Venue name" : ""}`
        toast.error(`Error!, ${venueError}`)
      }
    } else {
      if (vanue_name && address && country && state && city && postal_code) {
        this.setState({ venueId: null, openVenue: false, addBtnVisible: true })
      } else {
        let venueError = `Please provide information related to venue such as:
        ${!vanue_name ? "Venue name," : ""}
        ${!address ? "Address," : ""}
        ${!country ? "Country," : ""}
        ${!state ? "State," : ""}
        ${!city ? "City," : ""}
        ${!postal_code ? "Postal" : ""} `
        toast.error(`Error!, ${venueError}`)
      }
    }
  }

  handleCountryBlur = (event: any, setFieldValue:any) => {
    const { value } = event.target;
    this.setState({
      countryOrigin: value,
      allStates: State.getStatesOfCountry(value)
    })
    setFieldValue("country" , value)
    setFieldValue("state" , "")
    setFieldValue("city" , "")
  }

  handleStateBlur = (event: any, setFieldValue:any) => {
    const { value } = event.target;
    this.setState({
      allCities: City.getCitiesOfState(this.state.countryOrigin, value)
    })
    setFieldValue("state" , value)
    setFieldValue("city" , "")
  };

  handleCountryChange = (id: number, event: any) => {
    const { value } = event.target;
    this.setState({
      venueCountry: value,
      venueStates: State.getStatesOfCountry(value)
    })
    const newInputFields = this.state.customRow.map((item: any) => {
      if (id === item.id) {
        const { attributes } = item
        attributes["vanue_address"][event.target.name as any] = event.target.value,
        attributes["vanue_address"]["state"] = "",
        attributes["vanue_address"]["city"] = ""
      }
      return item;
    })
    this.setState({ customRow: newInputFields });
  }

  handleStateChange = (id: number, event: any) => {
    const { value } = event.target;
    this.setState({
      venueCities: City.getCitiesOfState(this.state.venueCountry, value)
    })
    const newInputFields = this.state.customRow.map((item: any) => {
      if (id === item.id) {
        const { attributes } = item
        attributes["vanue_address"][event.target.name as any] = event.target.value,
        attributes["vanue_address"]["city"] = ""
      }
      return item;
    })
    this.setState({ customRow: newInputFields });
  };

  handleSubCountryBlur = (event: any, setFieldValue:any) => {
    const { value } = event.target;
    this.setState({
      subCountry: value,
      allSubStates: State.getStatesOfCountry(value)
    })
    setFieldValue("subCountry" , value)
    setFieldValue("subState" , "")
    setFieldValue("subCity" , "")
  }

  handleSubStateBlur = (event: any, setFieldValue:any) => {
    const { value } = event.target;
    this.setState({
      allSubCities: City.getCitiesOfState(this.state.subCountry, value)
    })
    setFieldValue("subState" , value)
    setFieldValue("subCity" , "")
  };
  
  handleSelect = () => {
    const { seprateAddress } = this.state;
    this.setState({ seprateAddress: !seprateAddress })
  }
}
 // Customizable Area End
